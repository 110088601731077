export const getStringFromHTML = (html) => {
  if (!html) return '';
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent
}

export const patchFormAdapter = (formObj) => {
  const data = []
  for (const key in formObj) {
    // if (formObj[key] === null) continue
    data.push({
      path: `/${key}`,
      op: 'add',
      value: formObj[key],
    })
  }

  return data
}

export const getChangesFormData = (oldObj, newObj) => {
  const data = {}

  for (const key in newObj) {
    if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
      data[key] = newObj[key]
    }
  }
  return data
}

export const generateSiteUrl = ({
                                  presentationId,
                                  slideId,
                                  topicId,
                                  lessonId,
                                  articleMode,
                                  microArticleMode,
                                  screenShotId
                                }) => {
  const url = new URL(window.location.origin + window.location.pathname);

  // Add query parameters based on provided arguments
  url.searchParams.set('presentation', presentationId);
  if (slideId) url.searchParams.set('screenSlide', slideId);
  if (topicId) {
    url.searchParams.set('type', 'topic');
    url.searchParams.set('topicID', topicId);
  } else if (lessonId) {
    url.searchParams.set('type', 'lesson');
    url.searchParams.set('lessonID', lessonId);
  }
  if (articleMode) url.searchParams.set('articleMode', articleMode);
  if (microArticleMode) url.searchParams.set('microArticleMode', microArticleMode);
  if (screenShotId) {
    url.searchParams.set('screenShotId', screenShotId);
    url.searchParams.set('screenShot', 'true');
  }

  return url.toString();
};
