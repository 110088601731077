import React, {Component, useRef} from "react";
import ReactDOM from "react-dom";
import ArticleModeHeader from "../../components/articleModeHeader";
import LeftSide from "../../components/leftSideRefactored";
import MapHeader from "../../components/mapHeader";
import RightSide from "../../components/rightSide";
import Timeline from "../../components/timeline";
import "./style.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import {connect} from "react-redux";
import PageLoader from "../../components/UI/PageLoader";
import {HandleToken, LogIn} from "../../helpers";
import {getGeoJsonDataFromStorage} from "../../service/geoJsonData";
import {getArticleFromAPI} from "../../store/actions/articles";
import {parse, stringify} from "flatted";
import {
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    updateCategoriesToggle,
} from "../../store/actions/categories";
// import {getTimeGeoJsonDataFromStorage} from "../../store/actions/geoJSONData";
import {
    setNextLessonID,
    setPrevLessonID,
    setSelectedLesson,
} from "../../store/actions/handleLesson";
import {
    getLampInfo,
    setLampModalData,
    setLampModalState, setLampModalStateIndex,
} from "../../store/actions/lamp";
import {getLessonsFromAPI, setLessonData} from "../../store/actions/lessons";
import {loadingMap} from "../../store/actions/map";
import {
    dispatchMapMarkerInfo,
    getScreenShotSingleDataAPI,
    setMapStylesDarkId,
    setMapStylesIdST,
    setScreenShotBaseMapIsLightST,
    setScreenShotBaseMapGroupId,
} from "../../store/actions/mapStateAction";
import {getQuizInfo} from "../../store/actions/quiz";
import {
    setMiddleTimeLineLessonsData,
    setMiddleMaxTimeLineLessonsData,
    setTimeLineArticlesData,
    setTimeLineItems,
    setTimeLineLessonData,
    setTimeLineTopicsData,
    setMiddleTimeLineTopicsData,
    timeLineLoadingReaction,
    updateTimeLineGroupToggle,
} from "../../store/actions/timeLine";
import {setTimeLineExpend} from "../../store/actions/timeLineExpend";
import {setMapBounce, setMapZoom} from "../../store/actions/zoom";
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getIsTimeLineLoading,
    getLampData,
    getLampModalData,
    getLampModalState,
    getMapLoading,
    getMapStylesData,
    getMapZoomData,
    getNextLessonIDData,
    getPrevLessonIDData,
    getScreenShotLoadingST,
    getSelectedArticleData,
    getSelectedLessonId,
    getSelectedMapRequirements,
    getSelectedMapStyle,
    getTimeLineClickState,
    getTimeLineGroupsData,
    getTimeLineItemsData,
    getTimeLineExpendData,
    getTopicId,
    getSelectedMapStyleDark,
    getLegends,
    getFilteredLegendsData,
    getTimeLineZoomST,
    getMiddleTimeLineItemsData,
    getMiddleMaxTimeLineItemsData,
    getLegendsData,
    getTopicsData,
    getIsTopicLoading,
    getLessons,
    getIsLessonLoading,
    getMapSelectedCluster,
    getMapZoomToActionData,
    getMapStateSingleData,
    getMapStylesListData,
    getUser,
    getShowNotificationST,
    getPainterGeoJsonDataST,
    getRulerClickedState,
    getResetCompass,
    getSlidesSelectedSlideData,
    getMapMarkerInfo,
    getNewAngle,
    getTopicNavigationData,
    getLessonNavigationData,
    getLineDrawToolboxState,
    getCommentToolboxState,
    getScreenshotLanguage,
    getMapConfigData,
    getMenuExpendData,
    getBaseMapIsLight,
    getDrawerToolboxClickedState,
    getEraserClickedState,
    getPolygonDrawToolboxState,
    getStickyNotesClickedState,
    getStickyNotesMarkersState,
    getTextToolboxState,
    getMicroArticleToolClickedState,
} from "../../store/selectors";
import {dispatchTopicNavigation, getTopicsFromAPI, setTopicId} from "./../../store/actions/topics";
import {
    addAndPositionLayer,
    addArticlePoint,
    addPoint,
    addSymbol,
    determinateURLParams,
    determineCatIds,
    extractId,
    formatLessonTime,
    formatServerResponseTime,
    generateQueryString,
    generateTimeLineQueryString,
    getData,
    getSelectedYear,
    getShowByType,
    isPoint,
    isSymbol,
    mapUtilities,
    subCatFilterLogic,
    _filterByFeatureId,
    MAX_ZOOM_MAP
} from "./utils";
import {
    getMapChangeRequest,
    mapLessonAPIRequest,
    mapLiveAPIRequest,
    mapPreviewAPIRequest,
} from "./utils/mapPreviewMode";


import {constructSelectedItemApiParams} from "../accountPage/utils";
import * as turf from "@turf/turf";
import {
    setMapCenterPositionST,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setTimeLIneClickState,
} from "../../store/actions/mapStateAction";
import {
    dispatchFilteredLegends,
    getLegendsFromAPI,
    setFilteredLegends,
    updateLegendToggle
} from "../../store/actions/legends";
import {setMapSelectedCluster} from "../../store/actions/map";

import {
    changeMapStyles,
    getBaseMapConfigurationsFromAPI,
    getBaseMapsFromAPI, getBaseMapsSettingsFromAPI, setMapStyleExtraLayers,
    setMapStylesDark,
    setMapStylesList
} from "../../store/actions/mapStyles";
import Supercluster from "supercluster";
import MapElementsPopup from "../../components/UI/MapElementsPopup";
import ReactDOMServer from 'react-dom/server';
import MapElementsPopupRadius from "../../components/UI/MapElementsPopupRadius";
import "./style.css";
import TimeLineClock from "../../assets/imgs/timeline_clock";
import {sanitizeResult} from "../../service/legends";
import axios from "axios";
import {getSpriteURLFromAPI, getSpriteFromAPI} from "../../store/actions/sprite";
import {mapItemAdapter, MapSlider} from "../../components/mapSlider";
import {
    getHeightByLngLat,
    getMapScale,
    MAP_TOOLBOX_ITEMS,
    MAP_TOOLBOX_KEYS,
    Toolbox
} from "../../components/mapToolbox";

import {setSubjectsSettings} from "../../store/actions/subjects";

import {NavLink} from "react-router-dom";
import {
    setCompassNewAngle,
    setCompassRotate,
    setDisableCompass,
    setResetCompass
} from "../../store/actions/compassStateAction";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint';
import {isMobile} from "react-device-detect";
import {setupMapExtraLayers} from "../../service/mapStyles";
import {mapboxGlDrawStyles} from "./constants";
import StickyNotesEditPopup from "../../components/toolbox/StickyNotes/StickyNotesEditPopup";
import {setIsPresentationShowF} from "../../store/actions/presentationMode";
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import Popup from "../../components/UI/Popup";
import SlideRemove from "../../components/UI/Slides/DeleteSlide";
import DynamicScreenShotShare from "../../components/UI/DynamicScreenshotCard/DynamicScreenShotShare";
import NoLanguageContent from "../../components/UI/NoLanguageContent/NoLanguageContent";
import {setAllMicroArticles, setIsMicroArticlesLoading} from "../../store/actions/microArticle";
import {MicroArticleService} from "../../components/microArticle/service";
import {BRAINOGRAPH_GIS_API, BRAINOGRAPH_PIN_GIS_API} from "../newMap/utils/featureHelpers";

const queryParams = new URLSearchParams(window.location.search);

const zeroingItems = {
    toggle: true,
    menuExpanded: true,
    bounds: true,
    activeCatId: true,
    activeSubCatId: true,
    categories: true,
    allCategories: true,
    zoom: true,
    articlePopup: true,
    articleCollaps: true,
    timeLineSelectedDataItems: true,
    timeLineSelectedDataGroup: true,
    runScreenShotStates:null,
    screenShoteGenF:null,
};

// const mapboxDrawDefaultSimpleSelect = {...MapboxDraw.modes.simple_select};
// const mapboxDrawDefaultDirectSelect = {...MapboxDraw.modes.direct_select};

// const newModesForMapboxDraw = {
//     ...MapboxDraw.modes,
//     static: StaticMode,
//     default_simple_select: mapboxDrawDefaultSimpleSelect,
//     default_direct_select: mapboxDrawDefaultDirectSelect
// };

// const mapboxDrawModes = MapboxDrawWaypoint.enable(newModesForMapboxDraw);
const mapboxGlDrawStylesIds = {
    "gl-draw-polygon-fill-inactive.hot": true,
    "gl-draw-polygon-fill-inactive.cold": true,
    "gl-draw-polygon-fill-active.hot": true,
    "gl-draw-polygon-fill-active.cold": true,
    "gl-draw-polygon-midpoint.hot": true,
    "gl-draw-polygon-midpoint.cold": true,
    "gl-draw-polygon-stroke-inactive.hot": true,
    "gl-draw-polygon-stroke-inactive.cold": true,
    "gl-draw-polygon-stroke-active.hot": true,
    "gl-draw-polygon-stroke-active.cold": true,
    "gl-draw-line-inactive.hot": true,
    "gl-draw-line-inactive.cold": true,
    "gl-draw-line-active.hot": true,
    "gl-draw-line-active.cold": true,
    "gl-draw-polygon-and-line-vertex-stroke-inactive.hot": true,
    "gl-draw-polygon-and-line-vertex-stroke-inactive.cold": true,
    "gl-draw-polygon-and-line-vertex-inactive.hot": true,
    "gl-draw-polygon-and-line-vertex-inactive.cold": true,
    "gl-draw-point-point-stroke-inactive.hot": true,
    "gl-draw-point-point-stroke-inactive.cold": true,
    "gl-draw-point-inactive.hot": true,
    "gl-draw-point-inactive.cold": true,
    "gl-draw-point-stroke-active.hot": true,
    "gl-draw-point-stroke-active.cold": true,
    "gl-draw-point-active.hot": true,
    "gl-draw-point-active.cold": true,
    "gl-draw-polygon-fill-static.hot": true,
    "gl-draw-polygon-fill-static.cold": true,
    "gl-draw-polygon-stroke-static.hot": true,
    "gl-draw-polygon-stroke-static.cold": true,
    "gl-draw-line-static.hot": true,
    "gl-draw-line-static.cold": true,
    "gl-draw-point-static.hot": true,
    "gl-draw-point-static.cold": true
};

let initialState = {};
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced2000 = debounce((fn) => fn(), 2000);
const debeounced1500 = debounce((fn) => fn(), 1500);
const debeounced500 = debounce((fn) => fn(), 500);
const debeounced10 = debounce((fn) => fn(), 10);
const debeounced1 = debounce((fn) => fn(), 1);
const debeounced100 = debounce((fn) => fn(), 100);
class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalLoading: true,
            selectedElement: null,
            markerAnimation: null,
            url: {
                lessonID: queryParams.get("lessonID"),
                topicID: queryParams.get("topicID"),
                gradeID: queryParams.get("gradeID"),
                subjectID: queryParams.get("subjectID"),
                articleID: queryParams.get("articleID"),
                preview: queryParams.get("preview"),
                type: queryParams.get("type"),
                articleReadMode: queryParams.get("articleReadMode"),
                bookmarkID: queryParams.get("bookmarkID"),
                screenShot: queryParams.get("screenShot"),
                screenSlide: queryParams.get("screenSlide"),
                userId: queryParams.get("userId"),
            },
            screenShotFirstLoad: true,
            superCluster: null,
            time: null,
            bounds: {},
            toggleMapMode: "all",
            // Map
            firstLayer: null,
            mapData: null,
            geoJsonData: null,
            loadFirst: true,
            defaultLayers: [],
            addedLayers: [],
            addedFeautures: [],
            firstGeoJsonPath: null,
            selectedStyleId: 1,
            timeLineChanged: false,
            lessonData: [],
            geoJsonZipData: null,
            hiddenLayarsID: {},
            nextLessonId: null,
            prevLessonId: null,
            customTimeLastData: null,
            subjectID: null,
            gradeID: null,
            currentDataIndex: 0,
            metadata:null,
            loadingOverlay: false,
            isScreenshot: false,
            languageId: 1,
            hasLanguageContent: true
        };
        this.map = React.createRef();
        this.mapContainer = React.createRef();
        this.timeLine = React.createRef();
        this.timeLineRelated = React.createRef();
        this.mapUtilities = mapUtilities(this.map, this.timeLine);
        this.mapUtilities = mapUtilities(this.map, this.timeLineRelated);
        this.customLineRef = React.createRef();
        this.geojsonRef =  React.createRef();
        this.stopCustomTimeChange =  React.createRef();


    }
  async componentDidMount() {
    window.scrrenShot = false
    this.props.getSpriteFromAPI('/BaseSprite')
    const baseMapConfigs = await this.props.getBaseMapConfigurationsFromAPI()
    mapboxgl.accessToken = baseMapConfigs.mapBoxAccessToken;

        await HandleToken();
      const { id, type } = determinateURLParams(this.state.url);
      if(this.state.url.screenShot){
          this.setState(()=>({ 'isScreenshot':true }))
          if (this.state.screenShotFirstLoad && !!this.state.url.userId) {
              window.scrrenShot = true
              if(!isNaN(+this.state.url.screenShot)){
                 const getApi =  await this.props.getScreenShotSingleDataAPI(+this.state.url.screenShot,'MapState')
                 await getApi.call()
                      .then((res) => getApi.serialized(res))
                      .then((res) => getApi.setLanguage(res))
                      .then((res) => getApi.setLocation(res))
                  this.setState(()=>({screenShoteGenF:getApi}))
              }else{
                  const getApi =  await this.props.getScreenShotSingleDataAPI(+this.state.url.screenSlide,'Slide')
                  await getApi.call()
                      .then((res) => getApi.serialized(res))
                      .then((res) => getApi.setLanguage(res))
                      .then((res) => getApi.setLocation(res))
                  this.setState(()=>({screenShoteGenF:getApi}))
              }
          }
      }
      const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
      this.setState({languageId: lng})
      const metadata = mapLiveAPIRequest(id, type,lng)
      if(this.state.url.preview === 'true' && this.state.url.type === 'article'){
          this.props.setIsPresentationShowF(false)
      }
      await Promise.all([metadata])
              .then(values=>{
                  this.setState(()=>({metadata: values[0]}))
                  return values
              })
             .then(values=>{
                 const { url, metadata } = this.state;
                 const noLanguageContent = (url.type !== 'article' && !metadata?.language?.length) ||
                     (url.type === 'article' && !metadata?.content?.length);
                 if (noLanguageContent) {
                     this.setState({ hasLanguageContent: false });
                     throw new Error('no language');
                 }
                  return values
              })

              .then( (res)=>{
                  return this.installMapImportantFeatures(res[0],type)
              })
              .then( (res)=>{
                  const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
                  const styleId = this.props.selectedMapStyle?.lightIsDefault ? this.props.selectedMapStyle?.light : this.props.selectedMapStyle?.dark
                  const legendsBody = {
                      [`${this.state.url.type}Id`]: +this.state.url[`${this.state.url.type}ID`],
                      baseMapId:styleId || 1,
                      includeSubLegends:true
                  }
                  return this.props.getLegendsFromAPI(legendsBody,lng)
              })
              .then( (res)=>{
                  this.setState(()=>({legends: res}))
                  return res
              })
              .then( async ()=>{
                 if (this.state.screenShotFirstLoad && !!this.state.url.userId) {
                      const styleId = this.props.baseMapIsLight ? this.props.selectedMapStyle?.light : this.props.selectedMapStyle?.dark
                      return await this.setupMapFirstView(styleId || 1);
                  }else{
                     const styleId = this.props.selectedMapStyle?.lightIsDefault ? this.props.selectedMapStyle?.light : this.props.selectedMapStyle?.dark
                     return await this.setupMapFirstView(styleId);
                  }
              })
              .then(()=> {
                  this.map.current?.on('load', e => {
                      // if(!this.map.current?.draw) {
                      //     const drawInstance = new MapboxDraw({
                      //         defaultMode: 'simple_select',
                      //         userProperties: true,
                      //         styles: mapboxGlDrawStyles,
                      //         modes: mapboxDrawModes,
                      //     });
                      //
                      //     this.map.current?.addControl(drawInstance);
                      //     this.map.current.draw = drawInstance;
                      // }
                      this.initMapToolboxListeners()

                      this.props.setIsMicroArticlesLoading(true);
                      const loadAllMicroArticles = async () => {
                          const microArticles = await MicroArticleService.getMicroArticlesForMap();
                          this.props.setAllMicroArticles(microArticles);
                      }
                      loadAllMicroArticles();
                      this.props.setIsMicroArticlesLoading(false);

                      this.mapLiveView(id, type,this.state.legends, null, null,null,null,this.state.metadata);
                  })
              })

    }

    async componentDidUpdate() {
        if (
            !this.state.timeLineLoading &&
            !this.state.articleLoading &&
            !this.state.loadFirst &&
            this.state.globalLoading &&
            !initialState &&
            Object.keys(this.state.bounds).length > 0
        ) {
            if (!!this.state.url.lessonID) {
                if (
                    this.state.timeLineSelectedDataItems.length > 0 &&
                    this.state.timeLineSelectedDataGroup.length > 0
                )
                    // this.setState( {initialState:this.handleSaveIntial(this.state)})
                    initialState = JSON.parse(JSON.stringify(this.state));
                this.setState({globalLoading: false});

                this.timeLine.current.on("mouseDown", (event) => {
                    if (event.y > 130 || (this.state.timelineExtend && event.y > 36)) {
                        this.timeLine.current.removeCustomTime("t1");
                        this.timeLine.current.addCustomTime(event.time, "t1");
                        this.handleTimeChange(event.time);
                    } else {
                    }
                });
            } else {
                this.setState({globalLoading: false});
            }

        }

    }

    stopPinAnimation = () => {
        this.map.current?.fire('closeAnimationMarker');
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }

    };
    setupCustomCollections = async (resData,legends) => {
        const subCat = {};
        const cat = {}
        this.props.categories?.forEach?.((el)=> {
            cat[el.id] = el
            el?.subCategories?.forEach?.((item)=> {
                subCat[item.id] = item
            })
        })
        const features =[]
        features.push(...resData.clusterElements.reduce((acum,el)=>{
            const article = this.props.filteredCategories.getById(el?.articleIds[0])
            const mainSub = article?.subcategory?.find(el=>el.isMain)
            const mainCatId = mainSub?.categoryId || article?.categoryId
            const mainSubId = mainSub?.subCategoryId
            acum.push(
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [el.location.longitude, el.location.latitude]
                     },
                    properties: {
                        id:el.id,
                        relationType:el.lessonRelationType,
                        articleIds:el.articleIds,
                        name: el.displayName || 'no name ',
                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
                        startTime:0,
                        endTime:0,
                        visible:'visible',
                        relationFilter:'visible',
                        // iconPath:subCat?.iconPath,
                        iconPath:'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
                        // color:subCat?.color || '#fff'
                        key:  legends?.getKeyById(el?.layerId)?.key,
                        subId:mainSubId,
                        catId:mainCatId,
                        color: cat[mainCatId]?.color,
                        catColor:cat[mainCatId]?.color,
                        // spriteIcon:cat[this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId]?.spriteIcon,
                        spriteIcon:legends?.getKeyById(el?.layerId)?.icon,
                        element:true,
                    }
                }
            )
            return acum
        },[]))
        features.push(...resData.clusterArticles.reduce((acum,el,idx)=>{
            const mainSub = el?.subcategory.find(el=>el.isMain)
            const mainCatId = mainSub?.categoryId || el?.categoryId
            const mainSubId = mainSub?.subCategoryId
            acum.push(
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: [el.location.longitude, el.location.latitude]
                    },
                    properties: {
                        visible:'visible',
                        relationFilter:'visible',
                        id:el.id,
                        relationType:el.lessonRelationType,
                        articleIds:[el.id],
                        name: el.content[0]?.shortTitle ? el.content[0]?.shortTitle : (el.content[0]?.mainTitle || 'no name '),
                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear() }`,
                        startTime:0,
                        endTime:0,
                        catId:cat[mainCatId]?.id,
                        catColor:cat[mainCatId]?.color,
                        subId:subCat[`${mainSubId}`]?.id,
                        iconPath:subCat[`${mainSubId}`]?.iconPath,
                        spriteIcon:subCat[`${mainSubId}`]?.spriteIcon,
                        color:subCat[`${mainSubId}`]?.color || '#fff',
                        key:subCat[`${mainSubId}`]?.key,
                        isArticle:true,
                        active:idx === 0
                    }
                }
            )
            return acum
        },[]))
        const index = new Supercluster({
            radius: 30,
            maxZoom: MAX_ZOOM_MAP
        }).load(features);
        this.setState({'superCluster':index})
        const sourceOptions = {
            type: 'geojson',
            data: {
                "type": "FeatureCollection",
                "features": features
            },
            cluster: true,
            clusterMaxZoom: MAX_ZOOM_MAP, // Max zoom to cluster points on
            clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)

        }
        // if(this.state.url.type !== 'article'){
            sourceOptions.filter = ['any',
                ['all',
                    ["==", ['get', 'relationFilter'], 'visible'],
                    ["==", ['get', 'visible'], 'visible'],
                    // ["==", ['get','active'], true],
                    ['any',
                        ["!=", ['get', 'startTime'], ['get', 'endTime']],
                        ['all', [">=", ['get', 'startTime'], ['get', 'yearStart']], ["<=", ['get', 'startTime'], ['get', 'yearEnd']]],
                        ['all', [">=", ['get', 'endTime'], ['get', 'yearStart']], ["<=", ['get', 'endTime'], ['get', 'yearEnd']]]
                    ]
                ],
                ["==", ['get', 'cluster'], true],
            ];
        // }
        ['unclustered-text','unclustered-point','clusters','cluster-count'].forEach(layer=>{
            if(this.map.current.getLayer(layer)){
                this.map.current.removeLayer(layer)
            }
        })
        if (this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)) {
            this.map.current.removeSource(BRAINOGRAPH_PIN_GIS_API)
        }
        this.map.current.addSource(BRAINOGRAPH_PIN_GIS_API, sourceOptions);

    }


    installMapImportantFeatures = async (resData,type) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
        const catIds = determineCatIds(resData, type);
        this.setState(()=>({
            mapData: resData,
            subjectID: resData.subjectId,
            gradeID: resData.gradeId,
        }));
        if (this.state.url?.type === "lesson" && (!resData?.subjectId || !this.state.mapData?.showTimeline) ) {
            await axios.get(`${process.env.REACT_APP_HEADING_URL}/api/Topic/${resData?.topicIds?.[0]}/Language/1`)
                .then(async (res) => {
                    this.setState({topic: res.data.data[0]})
                    await this.getSubjectSettings(resData?.subjectId || res.data.data[0].subjectId)
                    return true
                }).then(async ()=>{
                    // const activeMapIds = this.state.subject?.setting?.mapTypes.map(el=>el.mapTypeId)
                    // await this.props.getBaseMapsFromAPI({
                    //     "subjectSettingId": this.state.subject?.setting?.id,
                    //     includeBaseMaps:false
                    // },lng);
                    await this.props.getBaseMapsSettingsFromAPI({
                        [`${this.state.url.type}Id`]: +this.state.url[`${this.state.url.type}ID`],
                        IncludeBaseMapGroup:true
                    },lng);
                }).then(async ()=>{
                    await this.props.setMapStylesList(this.state.subject?.setting?.mapTypes);
                })
        }else{
            await this.getSubjectSettings(resData?.subjectId)
                .then(async ()=>{
                    // const activeMapIds = this.state.subject?.setting?.mapTypes.map(el=>el.mapTypeId)
                    // await this.props.getBaseMapsFromAPI({
                    //     "subjectSettingId": this.state.subject?.setting?.id,
                    //     includeBaseMaps:false
                    // },lng);
                    await this.props.getBaseMapsSettingsFromAPI({
                        [`${this.state.url.type}Id`]: +this.state.url[`${this.state.url.type}ID`],
                        IncludeBaseMapGroup:true
                    },lng);
                })
                .then(async ()=>{
                    await this.props.setMapStylesList(this.state.subject?.setting?.mapTypes);
                })
        }
        if(this.state.url.screenShot){
            this.setState(()=>({ 'isScreenshot':true }))
            if (this.state.screenShotFirstLoad && !!this.state.url.userId) {
                window.scrrenShot = true

                if(!isNaN(+this.state.url.screenShot)){
                    await this.state.screenShoteGenF.setData(this.state.screenShoteGenF.nextArg())
                        .then((runScreenShotStates)=>{
                            this.setState(()=>({runScreenShotStates}))
                        })
                    // await this.props.getScreenShotSingleDataAPI(+this.state.url.screenShot,'MapState')
                    //     .then((runScreenShotStates)=>{
                    //         this.setState(()=>({runScreenShotStates}))
                    //     })
                }else{
                    await this.state.screenShoteGenF.setData(this.state.screenShoteGenF.nextArg())
                        .then((runScreenShotStates)=>{
                            this.setState(()=>({runScreenShotStates}))
                        })

                    // await this.props.getScreenShotSingleDataAPI(+this.state.url.screenSlide,'Slide')
                    //     .then((runScreenShotStates)=>{
                    //         this.setState(()=>({runScreenShotStates}))
                    //     })
                }
            }
        }
        await this.props.getCategoriesFromAPI(lng)
            .then(async(res)=> await this.props.getFilteredCategoriesFromAPI(resData, catIds, type,res))
        return
    }
    mapLiveView = async (id, type,legends,stopFly=false,onlymap=false,time,loadFeatures,resData) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
        const selectedMapStyle = this.props.selectedMapStyle.active
        const styleId = this.props.selectedMapStyle[selectedMapStyle]
        this.props.setScreenShotBaseMapGroupId(this.props.selectedMapStyle.id)
        this.props.setScreenShotBaseMapIsLightST(selectedMapStyle === 'light')
        const catIds = determineCatIds(resData, type);
        // this.props.loadingMap(true) getById
        this.setupCustomCollections(resData,legends)
        await this.props.setMapStyleExtraLayers(styleId || 1)
            .then(res=>{
                res.forEach(layer=>{
                    this.map.current.addLayer(layer)
                })
            })
        if(!onlymap) {
        const getAllClusters = () => {
            var bounds = this.map.current.getBounds();
            var zoom = Math.floor(this.map.current.getZoom());
            var clusters = this.state.superCluster.getClusters([-180, -90, 180, 90], zoom);
            return clusters;
        }
        // #725a4f
        let _thisMapCurrent = this.map.current
        const layers = this.map.current
            .getStyle()
            .layers
            .map((l) => l.id)

            const touchAndClickHandlerForMap = (el, e)=> {

                if(isMobile) {
                    const touchEndTime = Date.now();
                    const touchDuration = touchEndTime - touchStartTime;
                    if (touchDuration < 800 && !isDragging) {
                        // Handle single tap
                        isDragging = false;
                    } else if (isDragging) {
                        // Handle drag
                        return;
                    }

                    // Reset variables
                    touchStartTime = 0;
                    startCoordinates = null;
                    isDragging = false;

                    if (e.originalEvent?.target.className.includes("shape")) return;
                }

                if (!e.point ||
                    this.props.getRulerClickedState ||
                    this.props.getCommentToolboxState ||
                    this.props.getTextToolboxState ||
                    this.props.getDrawerToolboxClickedState ||
                    this.props.getEraserClickedState ||
                    this.props.getLineDrawToolboxState ||
                    this.props.getPolygonDrawToolboxState ||
                    this.props.getStickyNotesClickedState ||
                    this.props.getMicroArticleToolClickedState ||
                    el === "gl-draw-line-active.cold" ||
                    el === "gl-draw-line-active.hot" ||
                    el === "gl-draw-point-active.hot" ||
                    el === "gl-draw-point-active.cold" ||
                    el === "gl-draw-polygon-and-line-vertex-active.active" ||
                    el === "gl-draw-polygon-and-line-vertex-active.cold" ||
                    el === "gl-draw-polygon-and-line-vertex-inactive.cold" ||
                    el === "gl-draw-polygon-and-line-vertex-inactive.active" ||
                    el === "gl-draw-polygon-stroke-inactive.cold" ||
                    el === "gl-draw-polygon-stroke-inactive.active"
                ) return;

                const activeSources = [BRAINOGRAPH_PIN_GIS_API,BRAINOGRAPH_GIS_API]

                const allFeatures = _thisMapCurrent.queryRenderedFeatures(e.point)?.filter(el => activeSources.includes(el.source))
                const features = _thisMapCurrent.queryRenderedFeatures(e.point, {layers: [el]});
                if (allFeatures[0] && allFeatures[0].properties.cluster) {
                    this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    const clusters = this.map.current.queryRenderedFeatures(e.point, {layers: ["clusters"]});
                    const lng = e.lng || e.lngLat.lng;
                    const lat = e.lat || e.lngLat.lat;
                    const cluster = clusters.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || clusters[1]
                    const clusterId = cluster?.properties.cluster_id;

                    const saveCLusterCords = {
                        lng: cluster.geometry.coordinates[0] || e.lng || e.lngLat.lng,
                        lat: cluster.geometry.coordinates[1] || e.lat || e.lngLat.lat
                    }

                    this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                        .getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {
                                if (err) {
                                    this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                                        .getClusterChildren(clusterId, (error, features) => {
                                            if (!error) {
                                                this.generMarker(cluster.geometry.coordinates, features, zoom,clusterId)
                                            }
                                        });
                                    return
                                }
                                ;
                                if (zoom <= MAX_ZOOM_MAP) {
                                    return this.map.current.easeTo({
                                        center: {lng, lat},
                                        zoom: zoom + 0.1
                                    });
                                }
                                this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                                    .getClusterChildren(clusterId, (error, features) => {
                                        if (!error) {
                                            this.generMarker(cluster.geometry.coordinates, features, zoom,clusterId)
                                        }
                                    });
                                return
                            });
                    return
                }
                if(allFeatures.find(feature => feature.source === "mapbox-gl-draw-cold" || feature.source === "mapbox-gl-draw-hot")) {
                    return;
                }
                if ((allFeatures[0] && allFeatures[0].layer && allFeatures[0].layer.id === "unclustered-text") || (features[features.length - 1] && features[features.length - 1].layer.id === "unclustered-point")) {
                    this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    const unclusterPoint = this.map.current.queryRenderedFeatures(e?.point, {layers: ["unclustered-point"]});
                    if(!unclusterPoint[1]) return;
                    const markerInfo = {
                        id: unclusterPoint[1]?.properties.id,
                        cordinates: unclusterPoint[1]?.geometry.coordinates,
                        pointCount: 0,
                        articleIds: JSON.parse(unclusterPoint[1]?.properties.articleIds),
                        catColor: unclusterPoint[1]?.properties?.catColor
                    }
                    this.props.dispatchMapMarkerInfo(markerInfo)
                    this.generAnimationMarker(markerInfo)
                    this.setState({'selectedElement': unclusterPoint[1]})
                    return
                }
                const feature = allFeatures.find(el=> 'articleids' in el?.properties)
                if (feature) {
                    this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    this.props.getArticleFromAPI(feature?.properties.articleids)
                }
            }

            let touchStartTime = 0;
            let startCoordinates = null;
            let isDragging = false;

            if(isMobile) {
                this.map.current.on('touchstart', (e) => {
                    // Check if the original event is a TouchEvent and has touches
                    if (e.originalEvent instanceof TouchEvent) {
                        touchStartTime = Date.now();
                        startCoordinates = [e.point.x, e.point.y];
                        isDragging = false;

                        // Detect pinch zoom
                        if (e.originalEvent.touches.length > 1) {}
                    }
                });

                this.map.current.on('touchmove', (e) => {
                    if (!startCoordinates) return;

                    // Calculate movement
                    const moveX = e.point.x - startCoordinates[0];
                    const moveY = e.point.y - startCoordinates[1];

                    // Determine if it's a drag based on movement threshold
                    if (Math.abs(moveX) > 5 || Math.abs(moveY) > 5) {
                        isDragging = true;
                    }
                });
            }

            layers.forEach(el => {
                if(mapboxGlDrawStylesIds[el]) return;
                this.map.current.on(isMobile ? "touchend" : "click", el, e => debeounced10(() => touchAndClickHandlerForMap(el, e)))
            })

      this.map.current.on("zoom", (e) => {
          const maxPitch = this.map.current.getMaxPitch()
          if (this.map.current.getZoom() >= 5) {
              if(!maxPitch){
                  this.map.current.setMaxPitch(45)
                  this.props.setDisableCompass(false)
              }

              } else {
                  if (this.map.current.getPitch() !== 0) {
                      if(maxPitch){
                          this.map.current.setPitch(0)
                          this.map.current.compassTilt3D()
                      }
                  }
                  if(maxPitch) {
                      this.map.current.setMaxPitch(0)
                      this.props.setDisableCompass(true)
                  }
              }

          if (this.state.selectedElement) {
            debeounced100(() => {
                const unclusterPoint = this.map.current.querySourceFeatures(BRAINOGRAPH_PIN_GIS_API, {
                    filter: ['==', ['get', 'id'], this.state.selectedElement.properties?.id]
                })
                if (unclusterPoint?.length > 0) {
                    if (
                        (+this.state.selectedElement.geometry.coordinates[0].toFixed(0) === +unclusterPoint[1].geometry.coordinates[0].toFixed(0))
                        && (+this.state.selectedElement.geometry.coordinates[1].toFixed(0) === +unclusterPoint[1].geometry.coordinates[1].toFixed(0))
                    ) {
                        this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
                        const markerInfo = {
                            id: unclusterPoint[1].properties.id,
                            cordinates: unclusterPoint[1].geometry.coordinates,
                            pointCount: 0,
                            articleIds: unclusterPoint[1].properties.articleIds,
                            catColor: unclusterPoint[1].properties.catColor
                        }
                        this.props.dispatchMapMarkerInfo(markerInfo)
                        this.generAnimationMarker(markerInfo)
                    }
                } else {
                    this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
                    const features = getAllClusters()
                    let dx = this.state.selectedElement?.geometry?.coordinates[0]
                    let dy = this.state.selectedElement?.geometry?.coordinates[1]
                    const markerInfo = features
                        ?.reduce((acum, el) => {
                            if (!acum?.id) {
                                acum.id = el.id
                                acum.cordinates = el.geometry.coordinates
                                acum.pointCount = el.properties.point_count || 0
                            } else {
                                let acumX = (dx - acum?.cordinates[0]);
                                let acumy = (dy - acum?.cordinates[1]);
                                let elX = (dx - el.geometry?.coordinates[0]);
                                let ely = (dy - el.geometry?.coordinates[1]);
                                let acumRadius = Math.sqrt(acumX * acumX + acumy * acumy);
                                let elRadius = Math.sqrt(elX * elX + ely * ely);
                                if (acumRadius > elRadius) {
                                    acum.id = el.id
                                    acum.cordinates = el.geometry.coordinates
                                    acum.pointCount = el.properties.point_count || 0
                                }
                            }
                            return acum
                        }, {})
                    this.props.dispatchMapMarkerInfo(markerInfo)
                    this.generAnimationMarker(markerInfo)
                }
            });
        }
        const currentZoom = this.map.current.getZoom();
        debeounced500(() => {
            this.props.setMapZoom(currentZoom);
        });
    })
      this.props.getLampInfo(resData, type);
      const {startFrom, endTo} = formatServerResponseTime(resData);
            this.setState({

                    mapData: resData,
                    subjectID: resData.subjectId,
                    gradeID: resData.gradeId,
                });
        this.processTimeLineRendering(resData, type, false, lng);
        if(this.state.url.type === 'article') {
          this.props.getArticleFromAPI(this.state.url.articleID, false)
      }
        if(this.state.url.type !== 'article') {
            this.props.getQuizInfo(type, id)
            }
        if (resData?.clusterBounds?.coordinates) {
          setTimeout(()=>{
              const padding = 0.1
              const buffer = 0.5;
              const point = turf?.[resData?.clusterBounds?.type.toLowerCase()](resData?.clusterBounds?.coordinates)
              const buffered = turf.buffer(point, buffer, { units: 'kilometers' });
              const bboxMap = turf.bbox(buffered)
              // const bboxMap = turf.bbox(turf?.[resData?.clusterBounds?.type.toLowerCase()](resData?.clusterBounds?.coordinates))
              // Calculate the dimensions of the bbox
              const width = bboxMap[2] - bboxMap[0];
              const height = bboxMap[3] - bboxMap[1];
              const bbox1 = bboxMap[0] - (width * padding)
              const bbox2 = bboxMap[1] - (height * padding)
              const bbox3 = bboxMap[2] + (width * padding)
              const bbox4 = bboxMap[3] + (height * padding)
              const paddedBounds = [
                  bbox1 > 180 || bbox1 < -180 ? bboxMap[0] : bbox1,
                  bbox2 > 90 || bbox2 < -90 ? bboxMap[1] : bbox2,
                  bbox3 > 180 || bbox3 < -180 ? bboxMap[2] : bbox3,
                  bbox4 > 90 || bbox4 < -90 ? bboxMap[3] : bbox4
              ];
              this.map.current.fitBounds(paddedBounds);
              this.props.setMapBounce(paddedBounds);
          },700)
      }
     }
        setTimeout(()=> {
            this.setState({globalLoading: false});
        },500)
        if(onlymap) {
            const gisApiSources = this.map.current.getStyle()?.sources[BRAINOGRAPH_PIN_GIS_API]
            if (!gisApiSources || !loadFeatures) return;
            gisApiSources.data.features = loadFeatures
            this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)._updateWorkerData()
        }
        if (this.state.url.screenShot && this.state.screenShotFirstLoad && !!this.state.url.userId) {
            const statesST = ['mapSTF','toolBarSTF','compassSTF',]
            this.state?.runScreenShotStates?.(statesST)
        }
      };

    setLampModalDataAndState = (time, isScreenShot) => {
        const timeYear = Math.abs(new Date(time).getFullYear());

        let data = this.props.lampData.filter(
            (item) => {
                if (!item?.timeEnd?.isBc &&
                    !item?.timeStart?.isBc &&
                    timeYear - 5 <= Math.abs(item.timeEnd.year) &&
                    timeYear + 5 >= Math.abs(item.timeStart.year)) {
                    return true
                } else if (
                    item?.timeEnd?.isBc &&
                    item?.timeStart?.isBc &&
                    timeYear + 5 >= Math.abs(item.timeEnd.year) &&
                    timeYear - 5 <= Math.abs(item.timeStart.year)
                ) {
                    return true
                } else {
                    return false
                }
            }
        );
        this.props.setLampModalData(data);

        if (isScreenShot) {
            this.props.setLampModalState(this.props.lampModalState);
            return;
        }
        this.props.setLampModalState(data.length > 0);
        this.props.setLampModalStateIndex(0)

    };

    handleTimeChange = (time, isScreenShot) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
        this.map.current?.fire('closeAllPopups');
        this.map.current?.fire('closeAnimationMarker');
        let _setTime = time.toString().split('-')
        if (_setTime.length > 1) {
            _setTime = _setTime.join('B.C. ')
        } else {
            _setTime = _setTime.join('')
        }
        this.timeLine.current.setCustomTimeTitle(_setTime, "t1");

        this.setState({
            time: time,
        });
        this.setLampModalDataAndState(time, isScreenShot);
        const year = getSelectedYear(time, this.timeLine);
        const newObj = generateTimeLineQueryString(
            year,
            this.state.url,
            this.props.selectedLesson,
            this.state.mapData?.id,
            lng
        );
        const _doesExist = (item) => this.map.current.getLayer(item.id);
        const _removeLayersFromMap = (item) => {
            this.map.current.removeLayer(item.id);
            if (document.getElementById(item.id)) {
                document.getElementById(item.id).style.visibility = "hidden";
            }
        };

        if (Object.keys(newObj).length !== 0) {

            const queryString = Object.keys(newObj)
                .map((key) => key + "=" + newObj[key])
                .join("&") ;
            const tileUrl = new URL(this.map.current.getStyle()
                .sources[BRAINOGRAPH_GIS_API].tiles[0]);
            this.map.current.getSource(BRAINOGRAPH_GIS_API)
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
        }
    };

    // Start
    // TimeLine rendering
    processTimeLineRendering = async (data, type, fromTopic,lng=1) => {
        if (type === "lesson") {
            if (fromTopic === false && this.props.selectedLesson === null) {
                this.props.setSelectedLesson(this.state.url.lessonID);
            }
            this.props.setMiddleMaxTimeLineLessonsData(data);
            this.props.setMiddleTimeLineLessonsData(data);
            this.props.setTimeLineLessonData(
                data,
                this.state.mapData.id,
                data.id,
                data.articles,
                fromTopic,
                this.props.setTopicId,
                this.props.dispatchTopicNavigation,
                lng
            );

        }
        if (type === "topic") {
            await this.props.setTimeLineTopicsData(data, type,lng);
            await this.props.setMiddleTimeLineTopicsData(data);
        }
        if (type === "article") {
            this.props.setTimeLineArticlesData(data, type,lng);
        }
    };

    onMouseEnterScrollZone = () => {
        const scrollZone = document.getElementsByClassName('scroll_and_line_change')[0];
        this.timeLine.current.on("timechange", (properties) => {
            const eventPopover = document.getElementById('event-popover')
            const eventIcon = document.getElementById('event-icon')
            if (eventIcon) eventIcon.remove()
            if (eventPopover) {
                eventPopover.dataset.timeYear = properties.time.getFullYear() < 0 ? `${i18n.t('bc')} ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
            } else {
                const popover = document.createElement('span')
                popover.className = 'event-popover'
                popover.id = 'event-popover'
                popover.dataset.timeYear = properties.time.getFullYear() < 0 ? `${i18n.t('bc')} ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
                document.getElementsByClassName('t1')[0].appendChild(popover)
            }
            const scrollZoneParams = scrollZone.getBoundingClientRect();
            const customTimeLineParams = this.customLineRef?.current?.getBoundingClientRect();
            if (customTimeLineParams?.x + customTimeLineParams?.width >= scrollZoneParams.x) {
                const ms = new Date(properties.time).getTime();
                const changeMs = new Date(ms - 4.154e10);
                this.timeLine.current.removeCustomTime("t1");
                this.timeLine.current.addCustomTime(changeMs, "t1");
                this.timeLine.current.redraw();
                this.handleTimeChange(properties.time);
                const customBar = document.querySelector(".t1");
                this.customLineRef.current = customBar;
            }
        });
        this.timeLine.current.on("rangechange", () => {
            const {start, end} = this.timeLine.current.getWindow();
            if (start) {
                this.timeLineRelated.current?.setWindow(start, end, {animation: false});
            }
        });
        this.timeLineRelated.current?.on("rangechange", () => {
            const {start, end} = this.timeLineRelated.current?.getWindow();
            if (start) {
                this.timeLine.current.setWindow(start, end, {animation: false});
            }
        });
        this.timeLine.current.on("mouseUp", () => {
            const customBar = document.querySelector(".t1");
            this.customLineRef.current = customBar;
        });
    };

     processSelectedWhateverView = (data) => {
         this.onMouseEnterScrollZone();
        this.timeLine.current.on("click", this.handleTimeLineClickAction);
         this.timeLine.current.on("timechanged", this.handleTimeLineClickAction);
        this.timeLine.current.on("rangechange", (e)=>{
            this.stopCustomTimeChange.current = true
        });
         this.timeLine.current.on("rangechanged", (e)=>{
             setTimeout(()=>this.stopCustomTimeChange.current = false, 0)
         });
        this.timeLineRelated.current?.on("click", this.handleTimeLineClickAction);
        this.setCurrentOnTimeline(data);
        this.setTimeLineOptions(data);
        this.reactToTimeChange();
      };

    setTimeLineOptions = (data) => {
        this.timeLine.current.setOptions?.({
            start: data.start,
            end: data.end,
            min: data.start,
            max: data.end,
        });
        this.timeLineRelated.current?.setOptions?.({
            start: data.start,
            end: data.end,
            min: data.start,
            max: data.end,
        });
    };
    filterLegend = () =>{
        this.filterFeatuers()
        debeounced500(()=>this.filtredLegendsByViewport(this.props.legendsData))
    }
    onMapRenderComplete  = (map, fn,i=0) => {
        if(i >= 500) return new Error('RENDER COUNT IS MUCH')
        if (map.isStyleLoaded()) {
            debeounced100(() => process.nextTick(fn))
            if(i !== -1) debeounced500(() => this.onMapRenderComplete(map, fn, -1))
            return
        }
        map.once('data', () => this.onMapRenderComplete(map, fn, ++i))
    }
    filterLegendsViewport = ()=> this.filtredLegendsByViewport(this.props.legendsData)

    reactToTimeChange = () => {

        this.timeLine.current.redraw();
        this.timeLineRelated.current?.redraw();
        this.timeLine.current.on("timechanged", (properties) => {
            let time = properties.time;
            const activeEvent = this.props.timeLineItems?.getItemByDateRange?.(this.timeLine.current?.timeAxis?.step?.step || 0, time)
            if (activeEvent.length > 0) {
                time = activeEvent[0].start
            }
            this.timeLine.current.removeCustomTime('t1')
            this.timeLine.current.addCustomTime(time, 't1')

            this.setLampModalDataAndState(time);
            this.handleTimeChange(time);
            setTimeout(() => {
                const eventIcon = document.getElementById('event-icon')
                const customTimeLine = document.querySelector(".t1")
                const timeLineClock = document.querySelector(".time-line-clock")
                if (!timeLineClock) {
                    const rootElement = document.createElement("div")
                    rootElement.className = 'time-line-clock'
                    ReactDOM.render(<TimeLineClock/>, rootElement);
                    customTimeLine?.appendChild(rootElement)
                }
                const customTimeLineParams = customTimeLine?.getBoundingClientRect();
                document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
                document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);

                if (eventIcon) {
                    eventIcon.remove()
                }
                if (activeEvent.length > 0) {
                    const icon = document.createElement('i')
                    icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
                    icon.id = 'event-icon'
                    customTimeLine.appendChild(icon)
                }

                debeounced100(()=>this.onMapRenderComplete(this.map.current,this.filterLegend))

            }, 0)

        });
    };

    setCurrentOnTimeline = (data) => {
    // if (this.state.url.type !== 'article' && !this.state.mapData?.showTimeline) return;

    const customDate = data.start
    const customBar = document.querySelector('.t1')
    this.customLineRef.current = customBar
    if(!this.props.selectedLesson){
      customBar === null &&  this.timeLine.current.addCustomTime(null, 't1')
      customBar !== null && this.timeLine.current.setCustomTime(customDate, 't1')
    }else{
      customBar === null
          ? this.timeLine.current.addCustomTime(customDate, 't1')
          : this.timeLine.current.setCustomTime(customDate, 't1')
    }
  }
  // End

    // Start
    // Functions where component did update
    componentDidUpdate(prevProps, prevState) {
        const customBar = document.querySelector(".t1");
        this.customLineRef.current = customBar;
        if (
            !this.state.timeLineLoading
            && !this.state.articleLoading
            && this.state.loadFirst
            && !this.state.globalLoading
            && !initialState
        ) {
                this.setState({loadFirst: false})
                initialState = JSON.parse(JSON.stringify(this.state));
        }
        if(prevProps.getScreenShotLoadingST !== this.props.getScreenShotLoadingST && this.state.url.screenShot){
            this.setState({loadingOverlay: this.props.getScreenShotLoadingST});
        }
        if(prevProps.getScreenShotLoadingST !== this.props.getScreenShotLoadingST && (this.props.getScreenShotLoadingST || this.state.screenShotFirstLoad) ){
            setTimeout(() => {
               if(this.props.getMapMarkerInfo && !!Object.keys(this.props.getMapMarkerInfo).length){
                   this.generAnimationMarker(this.props.getMapMarkerInfo)
               }else{
                   this.map.current?.fire('closeAllPopups');
                   this.map.current?.fire('closeAnimationMarker');
               }


            },500)

        }
        if(+this.state.url.userId === +this.props.user?.data?.id){

            if(!this.props.getShowNotificationST && !this.props.getScreenShotLoadingST && this.state.url.screenShot && this.state.screenShotFirstLoad){
                const statesST = ['checkSettingsSTF']
                this.state?.runScreenShotStates?.(statesST)
            }

        }
        if (this.props.getSlidesSelectedSlideData?.id !== prevProps.getSlidesSelectedSlideData?.id) {
            this.handleChangeToInitialState()
        }
        if (
            prevState.timeLineChanged === true &&
            this.state.timeLineChanged === false
        ) {
            let body = {
                type: this.state.url.type,
                id: this.state.mapData.id,
                geoJsonPath: `${this.state.mapData.geoJsonPath}.gz`,
            };

            this.setState({
                time: this.state.metadata?.timeStart?.year,
            });
            // this.addAllOnMap(body);
            let type = this.props.selectedLesson !== null ? 'lesson' : this.state.url.type
            const newObj = {}
            if (type === 'lesson') {
                newObj.lessonId = this.props.selectedLesson !== null ? this.props.selectedLesson : this.state.mapData.id
            }
            if (type === 'topic') {
                newObj.topicId = this.state.mapData.id
            }
            if (type === 'article') {
                newObj.articleId = this.state.mapData.id
            }

            const queryString = Object.keys(newObj)
                .map((key) => key + "=" + newObj[key])
                .join("&") ;
            const tileUrl = new URL(this.map.current.getStyle()
                .sources[BRAINOGRAPH_GIS_API].tiles[0]);
            this.map.current.getSource(BRAINOGRAPH_GIS_API)
                .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?&${queryString}`]);
        }
        if (
            (
                prevProps.selectedMapStyle &&
                prevProps.selectedMapStyle !== this.props.selectedMapStyle
            ) || (
                prevProps.selectedMapStyleDark &&
                prevProps.selectedMapStyleDark !== this.props.selectedMapStyleDark
            )
        ) {
            this.handleMapStyleChange();
        }
        if(prevProps.menuExpend !== this.props.menuExpend && this.props.menuExpend){
            this.filterFeatuers()
            debeounced100(()=>this.filtredLegendsByViewport(this.props.legendsData))
        }
        if (
            prevProps.selectedArticle &&
            prevProps.selectedArticle !== this.props.selectedArticle &&
            !this.props.selectedArticle?.fly
        ) {
            // this.startPinAnimation(`article_${this.props.selectedArticle.id}`);
            this.handleFlyToObject();
        }
        if (this.state.url.screenShot && prevProps.mapZoom !== this.props.mapZoom && this.state.loadFirst
            || prevProps.mapZoom !== this.props.mapZoom && this.props.mapZoomToAction) {

            this.changeMapZoom(this.props.mapZoom);

        }
        if (this.props.mapSelectedCluster?.forScreenShoot && !prevProps.mapSelectedCluster && JSON.stringify(prevProps.mapSelectedCluster) !== JSON.stringify(this.props.mapSelectedCluster)) {
           this.triggerClickMapCluster(this.props.mapSelectedCluster);
            this.setState({
                loadFirst: false,
            });
        }
        if (
            prevProps.selectedMapRequirement &&
            prevProps.selectedMapRequirement !== this.props.selectedMapRequirement
        ) {
            this.handleRequirements();
        }
        if (
            prevProps.timeLineLoading !== this.props.timeLineLoading
            && this.props.timeLineItems.length > 0
        ) {
            this.processSelectedWhateverView(this.props.timeLineItems[0]);
        }
        if (
            !this.props.mapIsLoading &&
            !this.props.timeLineLoading &&
            Object.keys(this.props.selectedArticle).length === 0 &&
            this.state.url?.bookmarkID
        ) {
            const _this = this;
            setTimeout(() => {
                _this.showArticleReadMoreSection();
            }, 2000);
        }

        if (this.state.mapData?.subjectId && prevState.mapData?.subjectId !== this.state.mapData?.subjectId) {
            if (this.state.url?.type === 'topic') {
                this.getTopicsData(this.state.mapData?.gradeId);
            }
        }

        if (this.state.mapData?.topicId && prevState.mapData?.topicId !== this.state.mapData?.topicId) {
            if (this.state.url?.type === 'lesson') {
                this.getLessonsData(this.state.mapData?.topicIds?.[0]);
            }
        }

        if (this.state.url?.type === 'topic' && prevProps.topicsIsLoading && prevProps.topicsIsLoading !== this.props.topicsIsLoading) {
            this.setState({
                currentDataIndex: this.props.topics.findIndex((x) => x.id === this.state.mapData?.id),
            })
        }

        if (this.state.url?.type === 'lesson' && prevProps.lessonsIsLoading && prevProps.lessonsIsLoading !== this.props.lessonsIsLoading) {
            this.setState({
                currentDataIndex: this.props.lessons?.data?.findIndex((x) => x.id === this.state.mapData?.id),
            })
        }




    }

    triggerClickMapCluster = (selectedCluster) => {
       this.map.current.fire('closeMapboxGlDrawPopupsAndSelection');
       setTimeout(() => {
           const coords = {
               lat: selectedCluster.lat || selectedCluster?.elementProps?.cords?.[1],
               lng: selectedCluster.lng || selectedCluster?.elementProps?.cords?.[0],
           }
           if(coords.lat && coords.lng){
               this.map.current.fire("click", {
                   ...coords,
                   point:this.map.current.project(coords)
               })
           }

           if (selectedCluster.elementProps) {
               setTimeout(() => {
                   const {item, cords, count} = selectedCluster.elementProps
                   this.selectElement(item, cords, count, true);
               }, 500)
           }
       },3000)
    }

    selectElement = (item, cords, count, isScreenShoot) => {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            catColor:null,
            flyTo:false
        }
        this.props.dispatchMapMarkerInfo(markerInfo)
        this.generAnimationMarker(markerInfo)
        this.setState({'selectedElement': item})
        const element =document.getElementById(`element-${item.id || item?.properties?.id}`)
        if(element) element.dataset.active = true

    }


    handleRequirements = () => {
        this.map.current.fire('closeAllPopups');
        this.map.current.fire('closeAnimationMarker');
        const type = this.props.selectedMapRequirement;
        const data = this.map.current.getStyle()?.layers
        const types = {
            'Mandatory': 1,
            'Additional': 2,
            'All': 3
        }

        function getOptionsFromBitmask(bitmask) {
            let options = [];

            if ((bitmask & 1) !== 0) {
                options.push("Mandatory");
            }

            if ((bitmask & 2) !== 0) {
                options.push("Additional");
            }
            return options;
        }

        let source = [...(this.map.current.getStyle().sources[BRAINOGRAPH_PIN_GIS_API]?.data.features || [])]
        source.map(el => {
            if (el.properties.relationType) {
                el.properties.relationFilter = 'visible'
                console.log(el.properties.relationType,'el.properties.relationType')
                console.log(types[type],'el.properties.relationType')
                if (types[type] && !getOptionsFromBitmask(el.properties.relationType).includes(type)) {
                    el.properties.relationFilter = 'none'
                }

            }
            return el
        });
        this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)?._updateWorkerData(source)
    };

    handleFlyToObject = async () => {
        let fly = true;
        const flyingObject = this.props.selectedArticle;
        const url = `${process.env.REACT_APP_GIS_URL}/Articles/${flyingObject.id}/Elements/Geometries/Bounds`;
        axios.get(url)
            .then(resp => {
                const buffer = 0.5;
                const buffered = turf.buffer(resp.data, buffer, { units: 'kilometers' });
                return turf.bbox(buffered)
            })
            .then(resp => {
                this.map.current.syncMapAndCompassStart()
                this.map.current.on('idle',this.map.current.syncMapAndCompassEnd)
                this.map.current?.fitBounds(resp, {padding: 2})
            })
    }

    handleMapStyleChange = async () => {
        if( !this.map.current) return
        this.setState({globalLoading: true});
        this.geojsonRef.current =  {
            type: "FeatureCollection",
            features: [],
        }
        const time = this.state.time
        const gisApiSources = this.map.current?.getStyle()?.sources[BRAINOGRAPH_PIN_GIS_API]
        const selectedMapStyle = this.props.selectedMapStyle?.active
        const styleId = this.props.selectedMapStyle?.[selectedMapStyle]
        this.props.getSpriteFromAPI()
        this.props.setMapStylesIdST(styleId)
        this.props.setScreenShotBaseMapGroupId(this.props.selectedMapStyle.id)
        this.props.setScreenShotBaseMapIsLightST(selectedMapStyle === 'light')
        this.map.current.setStyle(`${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`);
        this.map.current.baseMapId = styleId
        this.map.current.once('styledata', () => {
            const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
            const selectedMapStyle = this.props.selectedMapStyle?.active
            const styleId = this.props.selectedMapStyle?.[selectedMapStyle]

            const year = getSelectedYear(time, this.timeLine);

            const newObj = generateTimeLineQueryString(
                year,
                this.state.url,
                this.props.selectedLesson,
                this.state.mapData?.id,
                lng
            );
            if (Object.keys(newObj).length !== 0) {
                const queryString = Object.keys(newObj)
                    .map((key) => key + "=" + newObj[key])
                    .join("&")
                const tileUrl = new URL(this.map.current.getStyle()
                    .sources[BRAINOGRAPH_GIS_API].tiles[0]);
                this.map.current.getSource(BRAINOGRAPH_GIS_API)
                    .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
            }
        })
        this.map.current.once("idle", () => {
            const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1

            const {id, type} = determinateURLParams(this.state.url);
            // if (type === "article") {
            //     window.location.href = `/`;
            // }
            ['unclustered-text','unclustered-point','clusters','cluster-count'].forEach(layer=>{
                if(this.map.current.getLayer(layer)){
                    this.map.current.removeLayer(layer)
                }
            })
            if (this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)) {
                this.map.current.removeSource(BRAINOGRAPH_PIN_GIS_API)
            }

            const legendsBody = {
                [`${this.state.url.type}Id`]: +this.state.url[`${this.state.url.type}ID`],
                baseMapId:styleId,
                includeSubLegends:true
            }
            const legendsData = this.props.getLegendsFromAPI(legendsBody,lng)
            Promise.all([legendsData])
                .then(values=>{
                    this.setState(()=>({ legends: values[0]}))
                })
                .then(()=>{
                    this.mapLiveView(id, type, this.state.legends, true, true,time,gisApiSources?.data?.features,this.state.metadata)
                })
        })
    };

    // End
    handleGetPopupAnchor = () =>{
        return window.innerWidth < 512
                ?'bottom'
                :'left'
    };
    handleGetPopupWidth = () => {
        return window.innerWidth < 512
            ?'55vw'
            :'30vw'
    }
    handleGetPopupOffset = () => {
        return window.innerWidth < 512
            ? 50
            : 20
    }
    handleSetSuperCluster = (newFeatures) => {
        const index = new Supercluster({
            radius: 30,
            maxZoom: 8
        }).load(newFeatures);
        this.setState({'superCluster': index})
    }
    changeClusterItemsTime = (date) => {
        const newFeatures = []
        const year = new Date(date).getFullYear()
        const source = this.map.current.getStyle().sources[BRAINOGRAPH_PIN_GIS_API]?.data.features?.map(el => {
            const {yearStart, yearEnd, visible} = el.properties
            el.properties.startTime = year
            el.properties.endTime = year
            if (
                (visible === 'visible') &&
                ((year !== year) ||
                    (year >= yearStart && year <= yearEnd) ||
                    (year >= yearStart && year <= yearEnd))
            ) {
                newFeatures.push(el)
            }
            return el
        });
        this.handleSetSuperCluster(newFeatures)

    this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)?._updateWorkerData(source)
  }
    generAnimationMarker ({id,cordinates,pointCount,articleIds,catColor,flyTo = true}) {
        if(Array.isArray(articleIds) && !Number.isNaN(+articleIds[0])) this.props.getArticleFromAPI(articleIds[0],!flyTo)
        if(!('closeAnimationMarker' in this.map.current._listeners)){
            this.map.current.on('closeAnimationMarker', (e) => {
                if(!('ids' in e) || e.ids.includes(id)){
                    this.state.markerAnimation.remove();
                    this.setState({'selectedElement':null})
                }else if(this.state.selectedElement?.properties?.id && e.ids.includes(this.state.selectedElement?.properties?.id)){
                    this.state.markerAnimation.remove();
                    this.setState({'selectedElement':null})
                }
                this.props.dispatchMapMarkerInfo({})
            });
        }
        const generSize  = (count) =>{
            const sizes = [40,40,45,60,70]
            const elemCount = [1,10,40,100]
            const elemCountIndex = elemCount.findIndex(elCount=>elCount > count)
            if(elemCountIndex === -1) return sizes[sizes.length -1];
            return sizes[elemCountIndex];
        }
        let mapMarker = document.getElementById("markerAnimation");

        const circleSize = generSize(pointCount)
        if (mapMarker && +this.state.markerAnimation.getElement().dataset.id === +id) return

        if (mapMarker) {
            this.state.markerAnimation.setLngLat(cordinates);
            this.state.markerAnimation.getElement().dataset.id = id;
            this.state.markerAnimation.getElement().style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
        } else {
            const el = document.createElement('div');
            el.id = 'markerAnimation';
            el.classList.add("tic_animation");
            el.dataset.id = id
            el.style.cssText = `--size:${circleSize}px;--catColor:${catColor}`;
            el.style.zIndex = "1";
            const markerAnimation = new mapboxgl.Marker(el).setLngLat(cordinates).addTo(this.map.current);
            this.setState({'markerAnimation': markerAnimation})
        }
    }
    selectELements (item, cords, count) {
        const popupELems = document.getElementsByClassName('elements-popup-radius-content')
        if (popupELems && popupELems.length > 0) {
            [...popupELems].forEach(el => {
                el.dataset.active = false
            })
        }
        const markerInfo = {
            id: item.properties.id,
            cordinates: cords,
            pointCount: count,
            articleIds: item.properties.articleIds,
            flyTo:false
        }

        this.props.dispatchMapMarkerInfo(markerInfo)
        this.generAnimationMarker(markerInfo)

        this.setState({'selectedElement': item})
        if (document.getElementById(`element-${item.id || item?.properties?.id}`)) {
            document.getElementById(`element-${item.id || item?.properties?.id}`).dataset.active = true
        }
    }
    generMarker (coordinates, child, zoomInfo,clusterId) {
        const currentZoom = this.map.current.getZoom();
        const zoom = currentZoom <= 8 ? 8 : zoomInfo
        this.map.current.flyTo({
            center: coordinates,
            zoom: zoom && zoom > 16 ? 16 : zoom || (currentZoom < 6 ? 6 : currentZoom),
        });


        let popup = null
        this.map.current.once('moveend', () => {
            const cluster = this.map.current.queryRenderedFeatures(null, {layers: ["clusters"]}).find(el=>el.id===clusterId);
            if(zoom > 17){
                this.map.current.flyTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                })
            }else{
                this.map.current.easeTo({
                    center: cluster?.geometry.coordinates,
                    zoom: zoom || (currentZoom < 6 ? 6 : currentZoom),
                })
            }

            setTimeout(()=> {
                this.map.current.once('moveend', () => {

                    if (child.length <= 6) {
                        const circleRadius = 85; // Adjust the radius as needed
                        child.forEach((item, index) => {
                            const angle = (360 / child.length) * index;
                            let radians = (angle * Math.PI) / 180;
                            const x = circleRadius * Math.cos(radians);
                            const y = circleRadius * Math.sin(radians);
                            child[index].x = x * -1
                            child[index].y = y
                            child[index].radians = radians
                        });
                        const div = document.createElement("div")
                        ReactDOM.render(<MapElementsPopupRadius activeStyle={this.props.selectedMapStyle} child={child}
                                                                coordinates={cluster?.geometry.coordinates}
                                                                selectELements={this.selectELements.bind(this)}/>, div)
                        popup = new mapboxgl.Popup({
                            closeOnClick: false,
                            closeOnMove: true,
                            focusAfterOpen: true,
                            anchor: 'center',
                            maxWidth: '30vw',
                            className: 'elements-popup-radius'
                        }).setLngLat(cluster?.geometry.coordinates)
                            .setDOMContent(div)
                            .addTo(this.map.current);
                        if (this.state.selectedElement) {
                            const elem = document.getElementById(`element-${this.state.selectedElement?.id || this.state.selectedElement?.properties?.id}`)
                            if (elem) {
                                elem.dataset.active = true
                            }
                        }
                    } else {

                        const sortChiled = Object.values(child.reduce((acum, el) => {
                            if (!acum[el?.properties?.catId]) acum[el?.properties?.catId] = []
                            acum[el?.properties?.catId].push(el)
                            return acum
                        }, {}))
                            ?.map(sublist => sublist.sort((a, b) => a?.properties?.name - b?.properties?.name))
                            ?.flat(1)
                        const div = document.createElement("div")
                        ReactDOM.render(<MapElementsPopup child={sortChiled} coordinates={cluster?.geometry.coordinates}
                                                          selectELements={this.selectELements.bind(this)}/>, div)
                        popup = new mapboxgl.Popup({
                            closeOnClick: false,
                            closeOnMove: true,
                            focusAfterOpen: true,
                            anchor: this.handleGetPopupAnchor(),
                            offset: this.handleGetPopupOffset(),
                            maxWidth: this.handleGetPopupWidth(),
                            className: 'elements-popup-modal'
                        }).setLngLat(cluster?.geometry.coordinates)
                            .setDOMContent(div)
                            .addTo(this.map.current);

                    }

                    this.map.current.on('closeAllPopups', () => {
                        popup.remove();
                    });
                })
            },300)
        });
    }

    handleTimeLineClickAction = (e) => {
    if(this.stopCustomTimeChange.current) return;
    if (e.event.target.id === "group_content") return;
    // if (e.event.target.id === "lesson-item") return;
    let time = e.time
    const customTimeLine = document.querySelector(".t1")
    const timeLineClock = document.querySelector(".time-line-clock")
    if(!timeLineClock){
      const rootElement = document.createElement("div")
      rootElement.className = 'time-line-clock'
      ReactDOM.render(<TimeLineClock/>, rootElement);
      customTimeLine?.appendChild(rootElement)
    }
    const customTimeLineParams = customTimeLine?.getBoundingClientRect();
    document.querySelector('.iconic-clock-minute-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left * 6},192,192)`);
    document.querySelector('.iconic-clock-hour-hand').setAttribute('transform', `rotate(${customTimeLineParams?.left},192,192)`);
    const activeEvent = this.props.timeLineItems?.getItemByDateRange?.(this.timeLine.current?.timeAxis?.step?.step || 1, time)
    const eventIcon = document.getElementById('event-icon')
        if(activeEvent[0]?.elementType === "Lamp"){
            this.map.current.once('moveend', () => {
                const {lng,lat} = {lng:activeEvent[0].bounds.coordinates[0],lat:activeEvent[0].bounds.coordinates[1]}
                const cords = this.map.current.project(activeEvent[0].bounds.coordinates)
                const elements = this.map.current.queryRenderedFeatures(cords, {layers: ["clusters","unclustered-point"]})
                const element = elements.find(item => item.geometry?.coordinates?.[0] === lng && item.geometry?.coordinates?.[1] === lat) || elements[1]
                if(element?.layer.id === "clusters"){
                    const clusterId = element?.properties.cluster_id;
                    this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                        .getClusterExpansionZoom(
                            clusterId,
                            (err, zoom) => {

                                if (err) {
                                    this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                                        .getClusterChildren(clusterId, (error, features) => {
                                            if (!error) {
                                                this.generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                                const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                                this.selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                            }
                                        });

                                    return
                                };
                                if (zoom <= 8) {
                                    return this.map.current.easeTo({
                                        center: {lng,lat},
                                        zoom: zoom + 0.1
                                    });
                                }
                                this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)
                                    .getClusterChildren(clusterId, (error, features) => {
                                        if (!error) {
                                            this.generMarker(element.geometry.coordinates, features, zoom,clusterId)
                                            const activeChild = features.find(el=>el.properties.articleIds.includes(activeEvent[0].articleId))
                                            this.selectELements(activeChild,activeEvent[0].bounds.coordinates,features?.length || 0)
                                        }
                                    });

                                return
                            });
                }
                if(element?.layer.id === "unclustered-point") {
                        const markerInfo = {
                            id: element.properties.id,
                            cordinates: element.geometry.coordinates,
                            pointCount: 0,
                            articleIds: JSON.parse(element.properties.articleIds),
                            catColor: element.properties?.catColor
                        }
                        this.props.dispatchMapMarkerInfo(markerInfo)
                        this.generAnimationMarker(markerInfo)
                        this.setState({'selectedElement':element})
                    }
            })
        }
        if(activeEvent[0]?.elementType === "Border"){
            const bbox = turf.bbox(activeEvent[0].bounds)
            this.map.current.syncMapAndCompassStart()
            this.map.current.on('idle',this.map.current.syncMapAndCompassEnd)
            this.map.current.fitBounds(bbox,{padding: 20})
        }
        if (eventIcon) {
            eventIcon.remove()
        }

        if (activeEvent?.length === 1) {
            time = activeEvent[0].start
            const icon = document.createElement('i')
            icon.className = 'event-icon' + ' ' + activeEvent[0]?.elementType
            icon.id = 'event-icon'
            document.getElementsByClassName('t1')[0].appendChild(icon)
        }
        this.props.setTimeLineCursorStatusST(true);
        this.props.setTimeLineEventDataST({
            targetId: e.event.target.id,
            item: e.item,
            time: time,
        });
      this.setState({ timeLineChanged: true });
      const customBar = document.querySelector(".t1");
      if (customBar === null) {
        this.timeLine.current.addCustomTime(time, "t1");
      } else {
        this.timeLine.current.setCustomTime(time, "t1");
      }
      this.handleTimeChange(time);
      debeounced100(()=>this.onMapRenderComplete(this.map.current,this.filterLegend))
      this.changeClusterItemsTime(time)
      let data = this.props.timeLineItems.find((x) => x.id === e.item);
      if (data) {
        if(data?.articleId) this.props.getArticleFromAPI(data.articleId, false)
      }
    };

    handleTimeLineClickActionST = (e,isScreenShot) => {
    if (e.targetId === "group_content") return;
    if (e.targetId === "lesson-item") return;
      this.setState({ timeLineChanged: true });
      const customBar = document.querySelector(".t1");
      if (customBar === null) {
        this.timeLine.current.addCustomTime(e.time, "t1");
      } else {
        this.timeLine.current.setCustomTime(e.time, "t1");
      }
      this.handleTimeChange (e.time,isScreenShot);
      this.changeClusterItemsTime(e.time)
  };

    processLessonMode = (id) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
        mapLessonAPIRequest(
            id,
            lng,
            this.state.url.preview,
            this.props.setLessonData
        ).then((resData) => {
            this.setState({lessonData: resData});
            this.setState({nextLessonId: resData.next});
            this.setState({nextLessonId: resData.previous});
            const type = "lesson";
            const catIds = determineCatIds(resData, type);
            this.props.getFilteredCategoriesFromAPI(resData, catIds, type);
            this.props.getLampInfo(resData, type);
            const {startFrom, endTo} = formatServerResponseTime(resData);
            this.setState({mapData: resData});
            if (this.state.toggleMapMode === "all") {
                let type = this.props.selectedLesson !== null ? 'lesson' : this.state.url.type
                const newObj = {}
                if (type === 'lesson') {
                    newObj.lessonId = this.props.selectedLesson !== null ? this.props.selectedLesson : this.state.mapData.id
                }
                if (type === 'topic') {
                    newObj.topicId = this.state.mapData.id
                }
                if (type === 'article') {
                    newObj.articleId = this.state.mapData.id
                }

                const queryString = Object.keys(newObj)
                    .map((key) => key + "=" + newObj[key])
                    .join("&") ;
                const tileUrl = new URL(this.map.current.getStyle()
                    .sources[BRAINOGRAPH_GIS_API].tiles[0]);
                this.map.current.getSource(BRAINOGRAPH_GIS_API)
                    .setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?&${queryString}`]);

                //Remove layar filters
                const layers = this.map.current.getStyle().layers;
                layers.forEach((layer) => {
                    if (layer && layer.source === BRAINOGRAPH_GIS_API && layer['source-layer'] === "brainograph") {
                        const filterOptions = [];
                        this.map.current.getFilter(layer.id)
                            .forEach(filter => {
                                if (filter && !(filter[0] == '!=' && filter[1] == 'id')) {
                                    filterOptions.push(filter);
                                }
                            })
                        this.map.current.setFilter(layer.id, filterOptions);
                    }
                });
                this.setState({
                    loadFirst: false,
                });
                this.props.loadingMap(false)
            }
            this.processTimeLineRendering(resData, type, false, lng);
            this.props.getQuizInfo(this.state.url.type, id)
        });
    };

    goToTopics = async () => {
        if (
            window.location.href.includes("preview=true") &&
            window.location.href.includes("type=lesson")
        ) {
            window.location.href = `/map?preview=true&type=topic&topicID=${this.props.topicId}`;
        } else if (
            !window.location.href.includes("preview=true") &&
            window.location.href.includes("type=lesson")
        )
            window.location.href = `/map?type=topic&topicID=${this.props.topicId}`;
        else {
            window.location.reload();
        }
    };
    filterFeatuers = () => {
        const features = this.map.current.querySourceFeatures(BRAINOGRAPH_GIS_API, {
            'sourceLayer': "brainograph"
        })
        const layers = this.map.current.getStyle().layers.filter(x => x['source-layer'] == 'brainograph' || x['sourceLayer'] == 'brainograph')
        const filterOptions = {}
        features.forEach(el => {
            if (!Array.isArray(filterOptions[el.properties.layer])) {
                filterOptions[el.properties.layer] = layers.filter(x => x.filter.find(f => f[0] === "==" && f[1] === "layer" && f[2] == el.properties.layer))
                filterOptions[el.properties.layer].show = true
                filterOptions[el.properties.layer].textShow = true
                if (this.props.filteredLegends?.checkIsHideItem?.(el.properties.layer)) {
                    filterOptions[el.properties.layer].show = false
                }
                if (this.props.filteredLegends?.checkIsHideItemText?.(el.properties.layer)) {
                    filterOptions[el.properties.layer].textShow = false
                }
            }
        });
        Object.keys(filterOptions).forEach(layer => {
            filterOptions[layer].forEach(targetLayer => {
                if (filterOptions[layer].show) {
                    this.map.current.setLayoutProperty(targetLayer.id, 'visibility', 'visible');
                } else {
                    this.map.current.setLayoutProperty(targetLayer.id, 'visibility', 'none');
                }
                if (targetLayer?.layout && 'text-field' in targetLayer?.layout) {
                    if (filterOptions[layer].textShow) {
                        this.map.current.setLayoutProperty(targetLayer.id, 'text-field', '{name}');
                    } else {
                        this.map.current.setLayoutProperty(targetLayer.id, 'text-field', '');
                    }
                }
            })
        })
    }
    toggleLegendsFromMapByID = async (layerId, layerKey, subLayerId, key, type, action) => {

        await this.props.updateLegendToggle(layerId, subLayerId, key, type, action)
        this.filterFeatuers()

        this.onMapRenderComplete(this.map.current,this.filterLegendsViewport)
    }
    handleGetItemVisibility = (data) => {
        const newItems = data.filter(el => {
            if (this.props.filteredCategories?.checkIsHideFromTimeLineItem?.('a_' + el.articleId)) return el
        });
        return newItems
    }
    toggleItemsFromTimeLineByID = (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) => {
        let newItems;
        this.props.updateCategoriesToggle(
            categoryId,
            subCategoryId,
            type,
            articleItemId,
            action,
            show
        );

        if (this.props.timelineExtend === 1) return
        if (this.props.timelineExtend === 2) {
            newItems = this.handleGetItemVisibility(this.props.middleTimeLineItems)
        } else if (this.props.timelineExtend === 3) {
            newItems = this.handleGetItemVisibility(this.props.middleMaxTimeLineItems)
        }
        this.timeLineRelated.current?.setItems(newItems);
        this.timeLineRelated.current?.redraw();

    }
    toggleItemsFromMapByID = (categoryId, subCategoryId, type, articleItemId, elementsIds, action, show) => {
        // TODO improve this logic

        if (false && this.state.url.screenShot && this.state.screenShotFirstLoad) {
            this.props.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                this.props.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            this.toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );

            this.setState({screenShotFirstLoad: false});
        } else {
            this.props.updateCategoriesToggle(
                categoryId,
                subCategoryId,
                type,
                articleItemId,
                action,
                show
            );
            this.props.updateTimeLineGroupToggle(categoryId);
            const isShow = getShowByType(
                this.props.filteredCategories,
                categoryId,
                subCategoryId,
                type,
                articleItemId
            );
            this.toggleItemFromMap(
                categoryId,
                type,
                isShow,
                subCategoryId,
                articleItemId,
                elementsIds
            );
        }
    };

    toggleLampFromMap = (id, isShow) => {
        const data = this.state.geoJsonData
        let layerIds;
        const getFilteredLayerIds = (feature) => {
            return data?.layers?.filter(_filterByFeatureId(feature)).map(extractId);
        };
        layerIds = (data?.features || [])
            .filter((feature) => feature.properties.articleId === id)
            .map(getFilteredLayerIds)
            .flat();
        this.makeMapChanges(layerIds, isShow);
    };
    toggleItemFromMap = (id, type, isShow, subID, articleItemId, elementsIds = [], onlyHide) => {
        const mapCurrent = this.map.current
        const data = mapCurrent.getStyle()
        let features = [];
        // Query for all features within the bounding box
        const categories = this.props.filteredCategories
        if (!data || !data.layers.length) {
            return
        }
        const _subCatFilterIfNeeded = (feature) => {
            if (type === 'category') {
                return true
            } else {
                return subCatFilterLogic(categories, feature, id, isShow, subID)
            }
        }
        const getFilteredLayerIds = (feature) => {
            return data.layers
                .filter(_filterByFeatureId(feature))
                .map(extractId)
        }
        const toggleItemFromMapFUnc = (isShow, mapCurrent, elementsIds, articleItemIds) => {
            mapCurrent.fire('closeAllPopups');
            mapCurrent.fire('closeAnimationMarker', {ids: [...elementsIds, ...articleItemIds]});
            if (!isShow || onlyHide) {
                let sourceBrainographPINGISAPI = [...(this.map.current.getStyle().sources[BRAINOGRAPH_PIN_GIS_API]?.data.features || [] )]
                sourceBrainographPINGISAPI.map(el => {
                    if (el.properties.visible && el.properties.articleIds && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        el.properties.visible = 'visible'

                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'none'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'none'
                        }
                    }
                    return el
                });
                this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)?._updateWorkerData(sourceBrainographPINGISAPI)
            }
            else {
                const layers = mapCurrent.getStyle().layers;
                layers.forEach((layer) => {
                    if (layer && layer.source === BRAINOGRAPH_GIS_API && layer['source-layer'] === "brainograph") {
                        const filterOptions = [];
                        mapCurrent.getFilter(layer.id)
                            .forEach(filter => {
                                if (filter && !(filter[0] == '!=' && filter[1] == 'id' && elementsIds.includes(filter[2]))) {
                                    filterOptions.push(filter);
                                }
                            })
                        mapCurrent.setFilter(layer.id, filterOptions);
                    }
                });
                const source = [...(this.map.current.getStyle().sources[BRAINOGRAPH_PIN_GIS_API]?.data.features || [])]
                source.map(el => {
                    if (el.properties.visible && el.properties.articleIds && el.properties.articleIds.some(item => articleItemIds.includes(item))) {
                        if (elementsIds.includes(el.properties.id)) {
                            el.properties.visible = 'visible'
                        }
                        if (el?.properties?.isArticle) {
                            el.properties.visible = 'visible'
                        }
                    }
                    return el
                });
                this.map.current.getSource(BRAINOGRAPH_PIN_GIS_API)?._updateWorkerData(source)
            }
        }
        if (type === 'article' || type === 'lamp') {
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIds, [articleItemId])
        } else if (type === 'layar' || type === 'category') {
            const articleItemIds = []
            const elementsIdsFromCat = categories
                .filter(categorie => categorie.show === isShow)
                .reduce((acum, item) => {
                    item.subCategories.forEach(subCategorie => {
                        subCategorie.articles.forEach(article => {
                            articleItemIds.push(article.id)
                            acum.push(...article.elementsIds)
                        })
                    })
                    return acum
                }, [])
            toggleItemFromMapFUnc(isShow, mapCurrent, elementsIdsFromCat, articleItemIds)
            this.props.filteredCategories.updateItems()
        }
    }

    makeMapChanges = (layerID, show, checkLamp) => {
        layerID.forEach((item) => {
            const pin = document.getElementById(item);
            const datasetFilter = pin?.dataset?.filter;
            if (!datasetFilter || datasetFilter === "visible") {
                if (pin) {
                    this.map.current.setLayoutProperty(
                        item,
                        "visibility",
                        show ? "visible" : "none"
                    );
                    if (pin.classList.contains(`lamp_pin`) && checkLamp) {
                        if (!show) {
                            pin.classList.remove("show_lamp_article");
                            pin.classList.add("hide_lamp_article");
                        } else {
                            pin.classList.remove("hide_lamp_article");
                            pin.classList.add("show_lamp_article");
                        }
                    } else {
                        pin.style.visibility = show ? "visible" : "hidden";
                    }
                }
            }
        });
    };
    // End

    // not movable functions

    filtredLegendsByViewport = (data) => {
        const features = this.map.current.queryRenderedFeatures().filter(feature => (
            feature.source === BRAINOGRAPH_GIS_API
            || feature.source === "Brainograph"
            || feature.source === BRAINOGRAPH_PIN_GIS_API
        ));
        const featureLayerKeys = new Set(features.reduce((acum,feature)=>{
            if(this.props.filteredLegends.hasElement(feature.properties.layer)) acum.push(feature.properties.layer)
                return acum
            },[])
        )
        const layerKey = {}
        const filterLayers = data.reduce((acum, layer) => {
            layerKey[layer.key] = false
            // if (layer.parentId) {
            layer.subLegends.forEach(subLayer => {
                layerKey[subLayer.key] = false
                if (featureLayerKeys.has(subLayer.key)) acum.layers.push(subLayer)
            })
            // } else {
                acum.parents[layer.id] = layer
            // }
            return acum
        }, {layers: [], parents: {}})
        const uniqueIds = []
        const result = filterLayers.layers.reduce((acum, el) => {
            layerKey[el.key] = true
            acum.push(el)
            if (!uniqueIds.includes(el.parentId)) {
                layerKey[filterLayers.parents[el.parentId].key] = true
                acum.push(filterLayers.parents[el.parentId])
            }
            uniqueIds.push(el.parentId)
            return acum
        }, [])
        Object.keys(layerKey).forEach(key=>{
            this.props.filteredLegends.updateId(key, layerKey[key])
        })
        this.props.dispatchFilteredLegends(sanitizeResult(result))
    }
    handleGetMapStyle = () => {
        if(!this.props.selectedMapStyle?.light || !this.props.selectedMapStyle?.dark){
            return this.props.selectedMapStyle?.light || this.props.selectedMapStyle?.dark
        }
        if (typeof this.props.baseMapIsLight === 'boolean' && this.state.url.screenShot && this.state.screenShotFirstLoad && !!this.state.url.userId) {
            return this.props.baseMapIsLight ? this.props.selectedMapStyle?.light : this.props.selectedMapStyle?.dark
        }else{
            return this.props.selectedMapStyle[this.props.selectedMapStyle.active]
        }

    }
    setupMapFirstView = async (styleId = 1) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : (i18n.language === 'en' ? 2 : 1)
        let year = this.state.metadata?.timeStart?.year || 100;
        if(this.state.metadata?.timeStart?.isBc) year = year * -1
        this.setState({"time": year})
        const newObj = generateTimeLineQueryString(
            year,
            this.state.url,
            this.props.selectedLesson,
            this.state.mapData?.id,
            lng
        );
        const queryString = Object.keys(newObj)
            .map((key) => key + "=" + newObj[key])
            .join("&") ;

        this.map.current = await new mapboxgl.Map({
            container: this.mapContainer.current,
            style: `${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?${this.state.url.type}Id=${this.state.url[`${this.state.url.type}ID`]}`,
            center: [11, 42],
            minZoom: this.props.mapConfigData.minZoom,
            maxZoom: this.props.mapConfigData.maxZoom + 0.2,
            zoom: 0.865,
            maxPitch:45,
            renderWorldCopies: false,
            preserveDrawingBuffer: true,
            transformRequest: (url, resourceType) => {
                let sendUrl = url
                if(sendUrl === `${process.env.REACT_APP_GIS_URL}/BaseSprite/sprite@2x.png`) {
                    return {
                        url: `${sendUrl}?icon=${new Date().getTime()}`,
                        headers: {"access-control-allow-origin" : "*",}
                    }
                }
                if (url.startsWith(process.env.REACT_APP_GIS_URL)) {
                    if(url.startsWith(`${process.env.REACT_APP_GIS_URL}/Tiles`)) {
                        const queryString = url.split('?')[1];
                        const params = new URLSearchParams(queryString);
                        // if (!params.has('year')) params.append('year', queryString);
                        if (!params.has('baseMapId')) params.append('baseMapId', this.handleGetMapStyle());
                        if (!params.has('languageId')) params.append('languageId', lng);

                        // if(!sendUrl.includes('year')){
                        //     sendUrl = process.env.REACT_APP_GIS_URL  + `/Tiles/1/0/0.pbf?${queryString}`
                        // }
                        // if(!sendUrl.includes('baseMapId')) {
                        //     sendUrl += `&baseMapId=${this.handleGetMapStyle()}`
                        // }
                        // if(!sendUrl.includes('languageId')) {
                        //     sendUrl += `&languageId=${lng}`
                        // }
                        sendUrl = `${sendUrl.split('?')[0]}?${params.toString()}`;
                        return {
                            url: sendUrl,
                            headers: {'Cache-Control': 'public'}
                        }
                    }
                    return {
                        url: sendUrl,
                        headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
                    }
                }
            }
            // maxPitch:60,
        })
        this.map.current.baseMapId = styleId
        let _thisMapCurrent = this.map.current
        this.map.current.on('idle', () => {
            setTimeout(() => {
                this.map.current.resize()
            }, 10)
        })
        this.map.current.on("styledata", () => {
            this.filterFeatuers()
        })
        this.map.current.on("moveend", () => {
           if(this.props.menuExpend){
               debeounced100(()=> {
                   this.filterFeatuers()
                   this.filtredLegendsByViewport(this.props.legendsData)
               })
           }
        });
    }

    moveLeft = () => this.move(0.2);

    moveRight = () => this.move(-0.2);
    changeMapZoom = (zoom) => {
        this.map.current.zoomTo(zoom, {duration: 1000});
    };

  // Timeline
    move = (percentage) => {
    const currentTime = this.timeLine.current.current_time;
    let range = this.timeLine.current.getWindow();
    let interval = range.end - range.start;

        this.timeLine.current.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end: range.end.valueOf() - interval * percentage,
        })
        this.timeLine.current.setCurrentTime(currentTime);
        this.timeLineRelated.current?.setCurrentTime(currentTime);
    }
    onTimeLineShowChange = () => {
        this.map.current?.fire('closeAllPopups');
        this.map.current?.fire('closeAnimationMarker');
        this.setState({timeLineChanged: false})
    }
    showArticleReadMoreSection = () => {
        this.props.getArticleFromAPI(this.state.url?.bookmarkID);
    };

    getSubjectSettings = async (id=1) => {
        const lng = this.state.isScreenshot ? this.props.screenshotLanguage : i18n.language === 'en' ? 2 : 1
        const res = await axios.get(`${process.env.REACT_APP_DICTIONARY_URL}/api/SubjectSetting/Subject/${id}/Language/${lng}`)
        const setting = res.data.data[0];
        const toolbox = {};
        MAP_TOOLBOX_ITEMS.forEach(item => {
            if (!!(setting?.toolboxSetting & (2 ** item.id))) {
                toolbox[item.key] = item;
            }
        })
        this.setState(() => ({
            subject: {
                setting,
                toolbox
            }
        }))
        return this.props.setSubjectsSettings({...setting, ...toolbox})
    }
    initMapToolboxListeners = () => {
        const toolbox = this.state.subject?.toolbox;
        const listenMouseMove = toolbox[MAP_TOOLBOX_KEYS.HEIGHT] || toolbox[MAP_TOOLBOX_KEYS.COORDS]
        const listenZoomEnd = !!toolbox[MAP_TOOLBOX_KEYS.SCALE]

        if (!toolbox) return;

        if (listenMouseMove) {
            let heightTimer;
            let coordsTimer;
            this.map.current.on('mousemove', (e) => {
                if (toolbox[MAP_TOOLBOX_KEYS.HEIGHT]) {
                    clearTimeout(heightTimer)
                    heightTimer = setTimeout(async () => {
                      const coordinates = {lng: e.lngLat.wrap().lng, lat: e.lngLat.wrap().lat};
                      const heightResult = Math.floor(+(await this.map.current.queryTerrainElevation(coordinates)));
                      this.setState({
                        toolboxValues: {
                          ...this.state.toolboxValues,
                          [MAP_TOOLBOX_KEYS.HEIGHT]: heightResult
                        }
                      })
                    }, 200)
                }

                if (toolbox[MAP_TOOLBOX_KEYS.COORDS]) {
                    clearTimeout(coordsTimer)
                    coordsTimer = setTimeout(() => {
                        this.setState({
                            toolboxValues: {
                                ...this.state.toolboxValues,
                                [MAP_TOOLBOX_KEYS.COORDS]: {
                                    lng: e.lngLat.wrap().lng,
                                    lat: e.lngLat.wrap().lat,
                                }
                            }
                        })
                    }, 200)
                }

            });

        }

        if (listenZoomEnd) {
            const handleCalculateScale = () => {
                if (toolbox[MAP_TOOLBOX_KEYS.SCALE]) {
                    const zoom = this.map.current.getZoom();
                    const center = this.map.current.getCenter();
                    const latitude = center.lat;

                    const scaleValue = getMapScale(this.map.current);
                    this.setState({
                        toolboxValues: {
                            ...this.state.toolboxValues,
                            [MAP_TOOLBOX_KEYS.SCALE]: scaleValue
                        }
                    })
                }
            }
            this.map.current.on('zoomend', handleCalculateScale);

            handleCalculateScale()
        }
    }


    getTopicsData = (gradeID) => {
        const {url, body} = constructSelectedItemApiParams(
            "grade",
            gradeID
        );
        getTopicsFromAPI(url, body);
    };

    getLessonsData = (topicId) => {
        const {url, body} = constructSelectedItemApiParams("topic", topicId);
        topicId && this.props.getLessonsFromAPI(url, body);
    };

    navigateTo = (to) => () => {
        const {topics} = this.props;
        const {currentDataIndex, url} = this.state;
        const isTopicMode = url?.type === 'topic';
        const baseUrl = isTopicMode ? "/map?type=topic&topicID=" : "/map?type=lesson&lessonID=";
        const dataByType = isTopicMode ? topics : this.props.lessons?.data;
        const navigationType = isTopicMode ? this.props.topicNavigation : this.props.lessonNavigationData;
        let path;
        if (to === 'next') {
            path = `${baseUrl}${navigationType?.next?.id}`;
        } else if (to === 'prev') {
            path = `${baseUrl}${navigationType?.previous?.id}`;
        }
        if(!path) return
        window.location.href = path;
    }

    clickSliderItemHandler = (item) => {
        const {url} = this.state;

        if (url.type === "topic") {
            window.location.href = `/map?type=lesson&lessonID=${item.id}`
        }
    }
    handleChangeToInitialState = () => {
        Object.keys(initialState).forEach((e) => {
            if (zeroingItems[e]) {
                if (
                    JSON.stringify(this.state[e]) !==
                    JSON.stringify(initialState[e])
                ) {
                    this.setState({
                        [e]: JSON.parse(JSON.stringify(initialState[e])),
                    })
                }
            }
        })
        if (initialState.zoom !== this.state.zoom) {
            this.map.current.fitBounds(this.state.bounds, {
                padding: 250,
            })
        }

    }

    render() {
        const dataByType = this.state.url === 'topic' ? this.props.topics : this.props.lessons?.data;
        const sidebarHeader = this.state.url.type === 'topic' ? `${this.props.t('topic')} - ${this.state.mapData?.language?.[0]?.title}` :
            <div className={'lesson__name'}>
                <p id={'lesson_title'}>{this.props.t('lesson')} - {this.state.mapData?.language?.[0]?.title}</p>
                <p class="lesson_slash">|</p>
                <a href={`/map?type=topic&topicID=${this.state.topic?.id}`}>
                   <p id={'topic_title'}>{this.props.t('topic')} - {this.state.topic?.title}</p>
                </a>
            </div>
        if(!this.state.hasLanguageContent) {
            return (
                <NoLanguageContent title={this.props.t(`noLanguageContent.${this.state.url.type}` )}/>
            )
        }
        return (
            <div className="map-boby">
                {/*{this.props.mapIsLoading ||*/}
                {/*    (this.props.getScreenShotLoadingST && this.state.url.screenShot && (*/}
                {/*        <div*/}
                {/*            style={{*/}
                {/*                position: "absolute",*/}
                {/*                width: "100%",*/}
                {/*                height: "100%",*/}
                {/*                display: "flex",*/}
                {/*                justifyContent: "center",*/}
                {/*                alignItems: "center",*/}
                {/*                zIndex: 999999,*/}
                {/*                background: "rgb(0 0 0 / 44%)",*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <PageLoader/>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*{this.state.url.articleReadMode && (*/}
                {/*    <ArticleModeHeader id={this.props.selectedArticle.id}/>*/}
                {/*)}*/}
                {!this.state.url.articleReadMode  && (
                    <MapHeader tools={this.state.subject?.toolbox} map={this.map.current}
                               mapTypes={this.state.subject?.setting?.mapTypes}
                               globalLoading={this.state.globalLoading}
                               runScreenShotStates={this.state.runScreenShotStates}
                    />
                )}
                <div ref={this.mapContainer} className="map" id={"map"}/>
                {/*{this.state.globalLoading &&*/}
                {/*    <WorldMapLoader/>*/}
                {/*}*/}
                {this.state.loadingOverlay &&
                    <div className={"loading__overlay"} />
                }
                <main className="main">
                    {!this.state.url.articleReadMode && (
                        <LeftSide
                            leftBarSTF={this.state.leftBarSTF}
                            toggleItemFromMap={this.toggleItemFromMap}
                            globalLoading={this.state.globalLoading}
                            toggleItemsFromMap={(
                                categoryID,
                                subCategoryID,
                                type,
                                articleItemId,
                                elementsIds,
                                actions,
                                show
                            ) =>
                                this.toggleItemsFromMapByID(
                                    categoryID,
                                    subCategoryID,
                                    type,
                                    articleItemId,
                                    elementsIds,
                                    actions,
                                    show
                                )
                            }
                            toggleItemsFromTimeLine={(
                                categoryID,
                                subCategoryID,
                                type,
                                articleItemId,
                                elementsIds,
                                actions,
                                show
                            ) =>
                                this.toggleItemsFromTimeLineByID(
                                    categoryID,
                                    subCategoryID,
                                    type,
                                    articleItemId,
                                    elementsIds,
                                    actions,
                                    show
                                )
                            }
                            stopPinAnimation={this.stopPinAnimation}
                            toggleLegendsFromMapByID={(layerId, layerKey, subLayerId, key, type, action) => this.toggleLegendsFromMapByID(layerId, layerKey, subLayerId, key, type, action)}
                            toggleLampFromMap={(id, isShow) => this.toggleLampFromMap(id, isShow)}
                            timeLine={this.timeLine}
                        />
                    )}
                    <RightSide mapState={this.map.current}
                                stopPinAnimation={this.stopPinAnimation}
                                globalLoading={this.state.globalLoading}
                    />
                </main>
                <Toolbox attachTimeline={this.state.mapData?.showTimeline} coords={this.state.toolboxValues?.coords}
                         height={this.state.toolboxValues?.height}
                         scale={this.state.toolboxValues?.scale}/>
                {
                    this.state.url.type !== "article" && this.state.mapData && !this.state.mapData?.showTimeline &&
                    <MapSlider hasNext={this.state.url?.type === 'lesson' ? !!this.props.lessonNavigationData?.next : this.props.topicNavigation?.next}
                               hasPrev={this.state.url?.type === 'lesson' ? !!this.props.lessonNavigationData?.previous : this.props.topicNavigation?.previous}
                               onClickItem={this.clickSliderItemHandler}
                               onNext={this.navigateTo('next')}
                               onPrev={this.navigateTo('prev')}
                               noBody={this.state.url?.type === 'lesson'}
                               items={this.state.mapData?.lessons?.map(mapItemAdapter) || []}
                               header={sidebarHeader}/>
                }
                {!this.state.globalLoading && this.props.getStickyNotesMarkersState.map(note =>
                    <StickyNotesEditPopup
                        key={note.id + (this.props.getSlidesSelectedSlideData?.id ?? "")}
                        id={note.id}
                        color={note.markerColor}
                        description={note.description}
                        background={note.background}
                        markerWidth={note.markerWidth}
                        markerHeight={note.markerHeight}
                        isEdited={note.isEdited}
                        isMaximize={note.isMaximized}
                        left={note.left}
                        top={note.top}
                    />
                )}
                <div
                    style={{display: this.state.url?.type !== 'article' && this.state.mapData && !this.state.mapData?.showTimeline ? 'none' : 'block',zIndex:'99'}}
                    data-is-windows={navigator.appVersion.indexOf("Windows") !== -1}
                >
                    <Timeline
                        runScreenShotStates={this.state.runScreenShotStates}
                        handleTimeChange={(time) => this.handleTimeChange(time)}
                        dataType={this.state.url.type}
                        timeLine={this.timeLine}
                        timeLineRelated={this.timeLineRelated}
                        goToTopics={() => this.goToTopics()}
                        moveLeft={() => this.moveLeft()}
                        moveRight={() => this.moveRight()}
                        toggleItemsFromMap={(categoryID, subCategoryID, type) =>
                            this.toggleItemsFromMapByID(categoryID, subCategoryID, type)
                        }
                        mapData={this.state.mapData}
                        onMapRenderComplete={this.onMapRenderComplete}
                        handleGetItemVisibility={this.handleGetItemVisibility}
                        filterLegend={this.filterLegend}
                        subjectID={this.state.subjectID}
                        gradeID={this.state.gradeID}
                        timeLineChanged={this.state.timeLineChanged}
                        onTimeLineShowChange={this.onTimeLineShowChange}
                        map={this.map}
                        processLessonMode={this.processLessonMode}
                        nextLessonId={this.state.nextLessonId}
                        prevLessonId={this.state.prevLessonId}
                        handleTimeLineClickActionST={this.handleTimeLineClickActionST}
                        handleSetSuperCluster={this.handleSetSuperCluster}
                        globalLoading={this.state.globalLoading}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedMapStyleDark: getSelectedMapStyleDark(state),
    getMicroArticleToolClickedState: getMicroArticleToolClickedState(state),
    selectedMapStyle: getSelectedMapStyle(state),
    baseMapIsLight: getBaseMapIsLight(state),
    mapConfigData: getMapConfigData(state),
    mapStyles: getMapStylesData(state),
    mapStylesList: getMapStylesListData(state),
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    selectedArticle: getSelectedArticleData(state),
    mapZoom: getMapZoomData(state),
    mapZoomToAction: getMapZoomToActionData(state),
    selectedMapRequirement: getSelectedMapRequirements(state),
    prevLessonID: getPrevLessonIDData(state),
    nextLessonID: getNextLessonIDData(state),
    timeLineItems: getTimeLineItemsData(state),
    middleTimeLineItems: getMiddleTimeLineItemsData(state),
    middleMaxTimeLineItems: getMiddleMaxTimeLineItemsData(state),
    timeLineGroups: getTimeLineGroupsData(state),
    timeLineLoading: getIsTimeLineLoading(state),
    selectedLesson: getSelectedLessonId(state),
    lampData: getLampData(state),
    lampModalState: getLampModalState(state),
    lampModalData: getLampModalData(state),
    timelineExtend: getTimeLineExpendData(state),
    topicId: getTopicId(state),
    mapIsLoading: getMapLoading(state),
    getScreenShotLoadingST: getScreenShotLoadingST(state),
    getTimeLineClickState: getTimeLineClickState(state),
    legends: getLegends(state),
    legendsData: getLegendsData(state),
    filteredLegends: getFilteredLegendsData(state),
    timeLineZoomST: getTimeLineZoomST(state),
    topics: getTopicsData(state),
    topicsIsLoading: getIsTopicLoading(state),
    lessons: getLessons(state),
    lessonsIsLoading: getIsLessonLoading(state),
    mapSelectedCluster: getMapSelectedCluster(state),
    screenShotSingleData:getMapStateSingleData(state),
    user:getUser(state),
    getRulerClickedState: getRulerClickedState(state),
    getCommentToolboxState: getCommentToolboxState(state),
    getStickyNotesClickedState: getStickyNotesClickedState(state),
    getTextToolboxState: getTextToolboxState(state),
    getLineDrawToolboxState: getLineDrawToolboxState(state),
    getPolygonDrawToolboxState: getPolygonDrawToolboxState(state),
    getShowNotificationST:getShowNotificationST(state),
    getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
    getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
    getEraserClickedState: getEraserClickedState(state),
    getResetCompass: getResetCompass(state),
    getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),
    getMapMarkerInfo: getMapMarkerInfo(state),
    getNewAngle: getNewAngle(state),
    topicNavigation: getTopicNavigationData(state),
    lessonNavigationData: getLessonNavigationData(state),
    setMapStylesDark: setMapStylesDark,
    setMapStylesDarkId: setMapStylesDarkId,
    menuExpend: getMenuExpendData(state),
    screenshotLanguage: getScreenshotLanguage(state),
    getStickyNotesMarkersState: getStickyNotesMarkersState(state)
});

const mapDispatchToProps = {
    setResetCompass,
    setCompassNewAngle,
    setIsPresentationShowF,
    setCompassRotate,
    getCategoriesFromAPI,
    getLegendsFromAPI,
    getBaseMapsFromAPI,
    getBaseMapsSettingsFromAPI,
    getBaseMapConfigurationsFromAPI,
    getSpriteURLFromAPI,
    getSpriteFromAPI,
    dispatchFilteredLegends,
    getLampInfo,
    getFilteredCategoriesFromAPI,
    setMapZoom,
    setMapBounce,
    setTimeLineExpend,
    updateCategoriesToggle,
    updateLegendToggle,
    getArticleFromAPI,
    setNextLessonID,
    dispatchTopicNavigation,
    setMapStyleExtraLayers,
    setTimeLineTopicsData,
    setMiddleTimeLineTopicsData,
    setMiddleTimeLineLessonsData,
    setMiddleMaxTimeLineLessonsData,
    setTimeLineLessonData,
    setPrevLessonID,
    setSelectedLesson,
    updateTimeLineGroupToggle,
    setLampModalState,
    setLampModalData,
    setFilteredLegends,
    setTopicId,
    setLessonData,
    getLessonsFromAPI,
    // getTimeGeoJsonDataFromStorage,
    timeLineLoadingReaction,
    setTimeLineArticlesData,
    loadingMap,
    setTimeLineItems,
    getQuizInfo,
    getScreenShotSingleDataAPI,
    setTimeLineCursorStatusST,
    setTimeLineEventDataST,
    setMapCenterPositionST,
    changeMapStyles,
    setTimeLIneClickState,
    getTopicsFromAPI,
    setSubjectsSettings,
    setLampModalStateIndex,
    setMapStylesList,
    setMapStylesIdST,
    setScreenShotBaseMapGroupId,
    setScreenShotBaseMapIsLightST,
    setDisableCompass,
    dispatchMapMarkerInfo,
    setAllMicroArticles,
    setIsMicroArticlesLoading,
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Map));
