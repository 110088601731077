import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getUserData, isUserLoggedIn} from "../../store/selectors";
import "./style.css";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

const Home = (props) => {
  useEffect(() => {
    if (props.isLoggedIn && props.user) {
      if (props.user?.countryId) {
        window.location.href = "/account-boards";
      } else {
        window.location.href = "/account-info";
      }
    } else {
      window.location.href = "/login";
    }
  }, [props.isLoggedIn, props.user]);

  return <div className="text" style={{ padding: 20 }}></div>;
};

const mapStateTopProps = (state) => ({
  isLoggedIn: isUserLoggedIn(state),
  user: getUserData(state)
});

export default connect(mapStateTopProps, null)(withNavigation(Home));
