import React from 'react'

import {Skeleton} from 'antd'
import {cn} from '../../../../utils/utils'

const PresentationCard = ({ name, onClick, id, image, onSuccess }) => {
  return (
    <div className="text-[#2C476C]">
      <div className={cn('flex flex-col gap-3 mb-2 cursor-pointer')}>
        <Skeleton.Image
          active={true}
          fullSize={true}
          className="!rounded-2xl !w-full !h-full aspect-[5/3] object-cover"
        />

        <div className="flex items-center justify-between">
          <Skeleton paragraph={{ rows: 1 }} title={false} active={true} />
        </div>
      </div>
    </div>
  )
}

export default PresentationCard
