import { connect } from "react-redux";
import {parse} from "flatted";

//COMPONENTS
import CardBody from "../CustomCardComponents/CardBody";
import NewTab from "../CustomCardComponents/NewTab";
import DynamicTitle from "../CustomCardComponents/DynamicTitle";
import Line from "../CustomCardComponents/Line";
import DynamicInfo from "./DynamicInfo";
import DateSection from "./DateSection";
import DynamicScreenShotEdit from "./DynamicScreenShotEdit";

//CONSTANTS
import { ARTICLE_READ_MORE_SECTIONS } from "./constants";

//UTILS
import { checkIsEmptyValues, getInfoSection } from "./utils";

//STYLES
import "./style.css";
import DynamicActionBtn from "./DynamicActionBtn";
import Popup from "../Popup";
import React, { useEffect, useState } from "react";
import DynamicScreenShotRemove from "./DynamicScreenShotRemove";
import { getRemoveCardId } from "../../../store/selectors";
import ScreenShotDynamicInfo from "./ScreenShotDynamicInfo/ScreenShotDynamicInfo";

const DynamicScreenshotCard = (props) => {
  const { item } = props;

  const [editPopupShow, setEditPopupShow] = useState(false);
  const [removePopupShow, setRemovePopupShow] = useState(false);
  const [state, setState] = useState(true);

  //EDIT
  const handleOpenEditPopup = () => {
    setEditPopupShow(() => true);
  };
  const handleCloseEditPopup = () => {
    setEditPopupShow(() => false);
  };
  const handleEditItem = (id) => {
    handleOpenEditPopup();
  };

  //REMOVE
  const handleOpenRemovePopup = () => {
    setRemovePopupShow(() => true);
  };
  const handleCloseRemovePopup = () => {
    setRemovePopupShow(() => false);
  };
  const handleRemoveItem = (id) => {
    handleOpenRemovePopup();
  };
  // const getLanguage = (item) => {
  //   const data = parse(item.data)
  //   return data?.languageId || 1
  // }

  useEffect(() => {
    setTimeout(() => {
      setState(!(item.id === props.getRemoveCardId));
    }, 400);
  }, [props.getRemoveCardId]);


  return (
    <>
      {state && (
        <>
          <CardBody
            styles={{ aspectRatio: "637/570" }}
            classN={`${
              item.id === props.getRemoveCardId
                ? "screenshot_item_wrapper_remove_animation"
                : ""
            }`}
          >
            <NewTab
              content={getInfoSection(ARTICLE_READ_MORE_SECTIONS[0], item)}
              actionType={"screenShot"}
              languageId={item.languageId}
            />
            <div>
              <DynamicTitle
                content={getInfoSection(ARTICLE_READ_MORE_SECTIONS[1], item)}
              >
                <DynamicActionBtn
                  // handleEditItem={() => handleEditItem(item)}
                  // handleRemoveItem={() => handleRemoveItem(item)}
                  handleEditItem={() => handleEditItem(item)}
                  handleRemoveItem={() => handleRemoveItem(item)}
                />
              </DynamicTitle>
              <Line />
            </div>
            <div>
              {checkIsEmptyValues(ARTICLE_READ_MORE_SECTIONS[2], item) &&
                getInfoSection(ARTICLE_READ_MORE_SECTIONS[2], item).map(
                  (el) => <ScreenShotDynamicInfo key={el.id} content={el} />
                )}
            </div>
            <div>
              {checkIsEmptyValues(ARTICLE_READ_MORE_SECTIONS[3], item) && (
                <DateSection
                  content={getInfoSection(ARTICLE_READ_MORE_SECTIONS[3], item)}
                  link={getInfoSection(ARTICLE_READ_MORE_SECTIONS[0], item)}
                />
              )}
            </div>
          </CardBody>
          <Popup
            isOpened={editPopupShow}
            onClose={handleCloseEditPopup}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
          >
            <DynamicScreenShotEdit onClose={handleCloseEditPopup} item={item} />
          </Popup>
          <Popup
            isOpened={removePopupShow}
            onClose={handleCloseRemovePopup}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
          >
            <DynamicScreenShotRemove
              id={item?.id}
              userId={item?.userId}
              onClose={handleCloseRemovePopup}
            />
          </Popup>
        </>
      )}
    </>
  );
};

const mapStateTopProps = (state) => ({
  getRemoveCardId: getRemoveCardId(state),
});

export default connect(mapStateTopProps, null)(DynamicScreenshotCard);
