import useShapes from "../useShapes";
import {
    calculatePixelSizeFromMeters, getLngLatValue,
    getRandomNumber,
    getSvgChildrenDimensions,
    replaceShapeTransform,
    svgToURL
} from "../utils";
import mapboxgl from "mapbox-gl";
import {useCallback, useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import ShapeMarker from "./ShapeMarker";
import {store} from "../../../../store";
import {Provider} from "react-redux";
import {allShapes, SHAPE_SIZES, shapeCategories} from "../mock-data";
import GroupShapeMarker from "./GroupShapeMarker";
import {debounce} from "lodash";
import {throttle} from "lodash/function";
import {useLocation} from "react-router-dom";
import {setShapes} from "../../../../store/actions/shapeTool";
import {DESIRED_SIZE_IN_METERS} from "../constants";


const ShapeToolEditor = ({map}) => {
  const { onSetShapes, shapes,shapeModeIsActive,onSetShaperClickState, onSetActiveShape} = useShapes();
    const markersRef = useRef({});
    const shapeModeIsActiveRef = useRef(shapeModeIsActive);
    const shapesRef = useRef(shapes);
    const prevLocation = useRef(null);
    const location = useLocation();

    useEffect(() => {
        document.getElementsByClassName('mapboxgl-canvas')?.[0]?.addEventListener("touchstart", (e) => {
            onSetShaperClickState(false);
            onSetActiveShape(null);
        })
    },[])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const prevQueryParams = new URLSearchParams(prevLocation.current?.search);
        const screenSlide = queryParams.get("screenSlide");
        const prevScreenSlide = prevQueryParams.get("screenSlide");
        if (location.search.includes('screenShot=true') && screenSlide !== prevScreenSlide) {
            onSetShapes([]);
            onSetActiveShape(null)
            onSetShaperClickState(false)
        }

    }, [location.search]);
    useEffect(() => {
        prevLocation.current = location;
    }, [location]);
    useEffect(() => {
        shapeModeIsActiveRef.current = shapeModeIsActive
    },[shapeModeIsActive])

    useEffect(() => {
        shapesRef.current = shapes
    },[shapes])

    const handleShapeChange = useCallback((uuid, transform) => {
        onSetShapes(shapesRef.current.map(shape => {
            if (shape.uuid === uuid) {

                if (typeof transform === 'string') {
                    return {
                        ...shape,
                        transform
                    };
                } else if (typeof transform === 'object') {
                    return {
                        ...shape,
                        meta:transform
                    };
                }

                return shape

            }
            return shape;
        }))

    },[shapesRef, onSetShapes]);


    const handleChangeColor = useCallback((uuid, color, type) => {
        onSetShapes(shapesRef.current.map(shape => {
            if (shape.uuid === uuid) {
                return {
                    ...shape,
                   colors:{
                       ...shape.colors,
                       [type]:color
                   }
                };
            }
            return shape;
        }))

    },[shapesRef, onSetShapes]);


    // Update custom element size on zoom
    const updateElementSize = (marker, desiredSizeInMeters) => {
        const customElement = marker?.getElement?.() || marker._element;
        const pixelSize = calculatePixelSizeFromMeters(marker, desiredSizeInMeters, map);
        let {width, height} = SHAPE_SIZES[customElement.dataset.name] || {width: 100, height: 100};
        const mapNode = document.getElementById("map");

        if (mapNode) {
            mapNode.dataset.scale = pixelSize;
        }

        customElement.style.width = `${1}px`;
        customElement.style.height = `${1}px`;

        const targetElement = customElement.getElementsByClassName('shape-marker--target')[0];
        if (targetElement) {
            targetElement.style.width = `${pixelSize * width}px`;
            targetElement.style.height = `${pixelSize * height}px`;

            // if (!targetElement.style.transform) {
            //     targetElement.style.transform = `translate(-50%,-50%) scale(1,1) rotate(0deg)`;
            // }
        }
        return pixelSize;
    };

    const deleteHandler = useCallback((uuid)=> {
        const newShapes = shapesRef.current.filter(shape => shape.uuid !== uuid);

        if (!newShapes.length) {
            onSetShaperClickState(false);
            onSetActiveShape(null)
        }

        onSetShapes(shapesRef.current.filter(shape => shape.uuid !== uuid))
    },[onSetShapes, shapesRef.current])

    const calculateMarkersScale = () => {
        Object.keys(markersRef.current).forEach(uuid => {
            // Assuming 'marker' is an instance of Mapbox Marker
            const marker = markersRef.current[uuid];
            // Initial size update
            updateElementSize(marker, DESIRED_SIZE_IN_METERS);

        });
    }

    useEffect(() => {
        // Create a set of UUIDs from the shapes and markers
        const shapeUUIDs = new Set(shapes.map(shape => shape.uuid));
        map.doubleClickZoom.disable()
        // const mapZoomLevel = map.getZoom();

        // Remove markers for shapes that have been deleted
        Object.keys(markersRef.current).forEach(uuid => {
            if (!shapeUUIDs.has(uuid)) {
                // Remove the marker from the map
                markersRef.current[uuid].remove();
                // Delete the marker from the ref
                delete markersRef.current[uuid];
            }
        });

        // const debeounced400 = debounce((fn) => fn(), 400);

        // map.on('zoom', throttle(calculateMarkersScale, 20));
        map.on('zoom', calculateMarkersScale);

        shapes.forEach((shapeMarker) => {

            if (markersRef.current[shapeMarker.uuid]) {

                if (!shapeModeIsActiveRef.current) {
                    markersRef.current[shapeMarker.uuid].setDraggable(false);
                    return;
                } else {
                    markersRef.current[shapeMarker.uuid].setDraggable(true);
                }
                // If the marker already exists for this shape, skip to the next shape
                return;
            }

            const markerNode = document.createElement('div');
            markerNode.className = 'shape-marker';
            markerNode.setAttribute('data-shape-id', shapeMarker.id);
            markerNode.setAttribute('data-name', shapeMarker.name);

            markerNode.setAttribute('id', shapeMarker.uuid);
            const svg = allShapes[shapeMarker.category]?.find?.(shape => shape.id === shapeMarker.id).svg || shapeMarker.svg
            const component = allShapes[shapeMarker.category]?.find?.(shape => shape.id === shapeMarker.id).component || shapeMarker.component
            // let sizesObj = {width: 0, height: 0}



            const onUpdateSizes = () => {
                return updateElementSize(marker, DESIRED_SIZE_IN_METERS);
            }
            //
            // if (shapesSizeMap[shapeMarker.id]) {
            //     sizesObj = shapesSizeMap[shapeMarker.id]
            // } else {
            //     sizesObj = getSvgChildrenDimensions(svg)
            //     shapesSizeMap[shapeMarker.id] = sizesObj
            // }
            let {width, height} = SHAPE_SIZES[shapeMarker.name] || {width: 100, height: 100};

            markerNode.style.width = `${1}px`;
            markerNode.style.height = `${1}px`;
            ReactDOM.render(
                <Provider store={store}>
                    <ShapeMarker
                        transform={shapeMarker.transform ? replaceShapeTransform(shapeMarker.transform) : ''}
                        onDelete={deleteHandler}
                        svg={svg}
                        keepRatio={shapeMarker.keepRatio}
                        id={shapeMarker.id}
                        type={shapeMarker.type}
                        map={map}
                        resizable={shapeMarker.resizable}
                        Component={component}
                        meta={shapeMarker.meta}
                        onLoad={onUpdateSizes}
                        uuid={shapeMarker.uuid}
                        fillColor={shapeMarker?.colors?.fillColor}
                        strokeColor={shapeMarker?.colors?.strokeColor}
                        onTransform={handleShapeChange}
                        onChangeColor={handleChangeColor}
                        width={width}
                        height={height}
                        // isEditMode={shapeModeIsActive}
                    />
                </Provider>,
                markerNode
            );
            const mapZoomLevel = map.getZoom();

            const marker = new mapboxgl.Marker({ draggable: true, element: markerNode,
                rotationAlignment: "map",
                // pitchAlignment: "map",
            })
                .setLngLat([shapeMarker.lng || map.getCenter().lng + getLngLatValue(mapZoomLevel), shapeMarker.lat || map.getCenter().lat + getLngLatValue(mapZoomLevel)])  // Update this to position based on shape data
                .addTo(map);

            // Add dragging behavior
            function onDragEnd() {
                const lngLat = marker.getLngLat();
                onSetShapes(shapesRef.current.map(shape => {
                    if (shape.uuid === markerNode.id) {
                        return {
                            ...shape,
                            lat: lngLat.lat,
                            lng: lngLat.lng
                        };
                    }
                    return shape;
                }));
            }
            marker.on?.('dragend', onDragEnd);

            // Save the marker instance in the ref
            markersRef.current[shapeMarker.uuid] = marker;
        });
        // Note: no cleanup function is necessary because markers are manually managed
        // Returning a cleanup function would be necessary if we added, removed,
        // or changed listeners that needed cleanup.
        return () => {
            map.doubleClickZoom.enable();
        }
    }, [shapes, deleteHandler, map,shapeModeIsActiveRef.current, onSetShapes]);



    return null;
};

export default ShapeToolEditor
