import React, {useEffect, useState} from "react";
import './style.css'
import Pause from '../../../../assets/imgs/icons/pause.svg'
import Play from '../../../../assets/imgs/icons/play.svg'
import Mute from '../../../../assets/imgs/icons/mute.svg'
import Unmute from '../../../../assets/imgs/icons/unmute.svg'
import SlidesInfo from '../../../../assets/imgs/icons/slidesInfo.svg'
import HideSlides from '../../../../assets/imgs/icons/hideSlides.svg'
import OpenSlides from '../../../../assets/imgs/icons/openSlides.svg'
import {PlayDurationIcon} from "../../PlayDurationIcon";
import ShowSlidesMenu from "../../ShowSlidesMenu";
import {PlayBack} from "../../PlayBack";
import {PlayNext} from "../../PlayNext";
import {getSlidesData} from "../../../../store/selectors";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {getLocalStorage, setLocalStorage} from "../../../../utils/utils";
import {getExternalDuration} from "../utils";
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced300 = debounce((fn) => fn(), 300);
const PlayMode = ({handleShowSlides,
                      showSlide,
                      handleSlideInfoPopup,
                      slideNavigation,
                      pauseCountdown,
                      playCountdown,
                      handleEditDurationTime,
                      slideTransitionDuration,
                      slides,
                      currentSlide,
                      setIsPlaying,
                      isPlaying,
                      globalLoading

}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const query = new URLSearchParams(location.search);
    const durFromStorage =
        typeof getExternalDuration() === 'number'
            ? getExternalDuration()
            : slideTransitionDuration
    const [duration, setDuration] = useState(durFromStorage)
    const [isMenuHided, setIsMenuHided] = useState(false)
    const [isMuted, setIsMuted] = useState(false)
    const handleMenuOpen = () => {
        setIsMenuHided(!isMenuHided)
    }

    const handleDurationTime = () => {
        if(duration === 20){
            setDuration(0)
        } else if(duration === 15) {
            setDuration(20)
        } else if(duration === 10) {
            setDuration(15)
        } else {
            setDuration(10)
        }
    }

    const handlePlayingMode = () => {
        if(isPlaying) pauseCountdown()
        if(!isPlaying) playCountdown(true)
        setIsPlaying(!isPlaying)
    }

    const handleMuteState = () => {
        setIsMuted(!isMuted)
    }
    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        if(searchParams.get("playBack")) {
            const play = searchParams.get("playBack") === 'true'
            if(isPlaying === play) return
            setTimeout(()=>{
                if(!play) pauseCountdown()
                if(play) playCountdown(true)
                setIsPlaying(play)
            },1000)
        }
    },[location.search])
    useEffect(()=>{
        if(!!query.get('external')) {
            const storageTransitionDuration = {
                ...getLocalStorage("slideTransitionDuration")
            }
            storageTransitionDuration[query.get('presentation')] = duration
            setLocalStorage("slideTransitionDuration", storageTransitionDuration)
            return;
        }
        if(slideTransitionDuration === duration) return
        debeounced300(()=>handleEditDurationTime(duration))
    },[duration])
    useEffect(()=>{
        if(slideTransitionDuration === duration || typeof +slideTransitionDuration !== "number" || isNaN(+slideTransitionDuration)) return

        if(!!query.get('external')){
            let storageTransitionDuration = getLocalStorage("slideTransitionDuration")
            const presentationId=query.get('presentation')
            if(!storageTransitionDuration?.[query.get('presentation')]){
                const newObj = {
                    [presentationId]:slideTransitionDuration,
                    ...storageTransitionDuration
                }
                setLocalStorage("slideTransitionDuration",newObj)
                storageTransitionDuration = getLocalStorage("slideTransitionDuration")
            }
            if(storageTransitionDuration?.[query.get('presentation')] !== duration ){
                const storageTransitionDuration = getLocalStorage("slideTransitionDuration")
                storageTransitionDuration[query.get('presentation')] = duration || slideTransitionDuration
                setLocalStorage("slideTransitionDuration", storageTransitionDuration)
            }
            if(![0,10,15,20].includes(storageTransitionDuration[query.get('presentation')])){
                storageTransitionDuration[query.get('presentation')] = slideTransitionDuration
                setLocalStorage("slideTransitionDuration", storageTransitionDuration)
            }
            setDuration(()=>storageTransitionDuration[query.get('presentation')])
            return;
        }
        setDuration(()=>slideTransitionDuration)

    },[slideTransitionDuration])
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === ' ') {
                handlePlayingMode();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handlePlayingMode]);

    return null;

    return (
        <div className='play_container'>
            {!isMenuHided &&
            <>
            <button onClick={()=>slideNavigation(-1)}
                    data-is-loading={globalLoading}>
                <PlayBack active={currentSlide?.id !== slides[0]?.id}/>
            </button>
            <button onClick={handlePlayingMode}
                    data-is-loading={globalLoading}>
                <img src={isPlaying ? Pause : Play}/>
            </button>
            <button onClick={() => slideNavigation(1)}
                    data-is-loading={globalLoading}>
                <PlayNext active={currentSlide?.id !== slides[slides.length-1]?.id}/>
            </button>
            <button onClick={handleDurationTime}
                    data-is-loading={globalLoading}>
                <PlayDurationIcon duration={duration}/>
            </button>
            {/*<button onClick={handleMuteState}*/}
            {/*        data-is-loading={globalLoading}>*/}
            {/*    <img src={isMuted ? Unmute : Mute}/>*/}
            {/*</button>*/}
            <button onClick={handleShowSlides}
                    data-is-loading={globalLoading}>
                <ShowSlidesMenu active={showSlide}/>
            </button>
            <button onClick={handleSlideInfoPopup}
                    data-is-loading={globalLoading}>
                <img src={SlidesInfo}/>
            </button></>}
            <button onClick={handleMenuOpen} className={isMenuHided ? 'hided_icon' : ''}
                    data-is-loading={globalLoading}>
                <img src={isMenuHided ? OpenSlides : HideSlides}/>
            </button>
        </div>
    )
}

const mapDispatchToProps = {
};
const mapStateToProps = (state) => ({
    slides: getSlidesData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayMode);

