import * as React from 'react'

const MinimizeIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g id="Minimize">
      <path
        id="Vector"
        d="M14.5789 26.5789V17H5"
        stroke="#2C476C"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M17 5V14.5789H26.5789"
        stroke="#2C476C"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_3"
        d="M29.7895 2L18 13.7895"
        stroke="#2C476C"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_4"
        d="M13.7895 18L2 29.7895"
        stroke="#2C476C"
        strokeWidth={3.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
export default MinimizeIcon
