import React from 'react'
import { isMobile } from 'react-device-detect'
import styles from './PopupDetailMobile.module.css'
import {
  Alert,
  Button,
  Card,
  Carousel,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import CollapseIn from '../../../../assets/imgs/MicroArticleIcons/collapse_in_icon.svg'
import CloseIcon from '../../../../assets/imgs/MicroArticleIcons/close_icon.svg'
import CollapseOut from '../../../../assets/imgs/MicroArticleIcons/collapse_out_icon.svg'
import MediaRenderer from '../MediaRenderer'
import Dots from '../Dots'
import IconButton from '../../UI/IconButton'
import PresentationIcon from '../../../../assets/imgs/MicroArticleIcons/presentation_icon.svg'
import EditIcon from '../../../../assets/imgs/MicroArticleIcons/edit_icon.svg'
import Portal from '../Portal'
import Title from 'antd/lib/typography/Title'

const CardMobileVersion = ({
  isOwner,
  isMaximized,
  getMicroArticleData,
  getIsSingleMicroArticleLoading,
  microArticleOwner,
  togglePopupSize,
  setMicroArticleFirstImageIdx,
  onClose,
  microArticleFirstImageIdx,
  loading,
  error,
  carouselContainerRef,
  handleMouseDown,
  handleTouchStart,
  carouselRef,
  handleAfterChange,
  ignoreClickRef,
  handleOpenGallery,
  currentSlide,
  handleDotClickOrSwipe,
  getPopupDetailFontSize,
  increaseFontSize,
  decreaseFontSize,
  setPresentationsIsOpen,
  changeToEditMode,
}) => {
  return (
    <Portal
      containerSelector={isMobile ? '.map-boby' : '.main'}
      className={isMobile ? 'full-screen-form-popup' : ''}>
      <div
        className={
          styles.container +
          (isMaximized ? '' : ' ' + styles.minimized) +
          ' fontStyles microArticleFormPopupContainer'
        }>
        <Card
          className={isMaximized ? styles.card : styles.cardMinimized}
          styles={{
            body: {
              padding: 24,
              overflowY: 'auto',
              display: isMaximized ? 'block' : 'none',
              // height: '90%',
              // maxHeight: '90%',
            },
            header: {
              padding: 0,
              margin: 0,
              width: '100%',
              // minHeight: '45px',
              // height: '56px',
              borderBottom: 'none',
            },
          }}
          title={
            isMaximized ? (
              <div className="headerSkeletonWrapper">
                {getIsSingleMicroArticleLoading ? (
                  <div className="headerSkeleton">
                    <Skeleton.Avatar
                      className="headerSkeletonInitials"
                      active
                      shape="square"
                      size={30}
                    />
                    <Skeleton.Input active style={{ width: 200 }} />
                  </div>
                ) : (
                  <div className={styles.header}>
                    <div className={styles.titleContainer}>
                      <div
                        style={{
                          minHeight: 'max-content',
                          margin: '.8em 0 .5em 0',
                        }}
                      />
                      <div>
                        <Title level={4} className={styles.title}>
                          <div
                            className={styles.titleText}
                            style={{
                              fontSize:
                                getMicroArticleData &&
                                getMicroArticleData?.title &&
                                getMicroArticleData?.title.length > 60
                                  ? '18px'
                                  : '20px',
                            }}>
                            <span
                              className={styles.nameInitials}
                              style={
                                {
                                  // background: getMicroArticleData?.iconBackgroundColor,
                                  // color: getContrastColor(getMicroArticleData?.iconBackgroundColor),
                                }
                              }>
                              {microArticleOwner.id
                                ? `${microArticleOwner.firstName[0].toUpperCase()} ${microArticleOwner.lastName[0].toUpperCase()}`
                                : ''}
                            </span>
                            <span
                              style={{ color: '#2C476C', padding: '0 12px' }}>
                              {getMicroArticleData
                                ? getMicroArticleData?.title?.toUpperCase()
                                : ''}
                            </span>
                          </div>
                        </Title>
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.buttons}>
                  <Button
                    type="text"
                    icon={
                      <img className={styles.customIcon} src={CollapseIn} />
                    }
                    onClick={togglePopupSize}
                    className={styles.toggleButton}
                  />
                  <Button
                    type="text"
                    icon={
                      <img
                        style={{ width: '24px', height: '24px' }}
                        src={CloseIcon}
                      />
                    }
                    onClick={() => {
                      setMicroArticleFirstImageIdx(null)
                      onClose()
                    }}
                    className={styles.closeButton}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.minimizedHeader}>
                <div className={styles.minimizedButtons}>
                  <Button
                    type="text"
                    icon={
                      <img className={styles.customIcon} src={CollapseOut} />
                    }
                    onClick={togglePopupSize}
                    className={styles.toggleButton}
                  />
                  <Button
                    type="text"
                    icon={
                      <img
                        style={{
                          width: '24px',
                          height: '24px',
                          objectFit: 'contain',
                        }}
                        src={CloseIcon}
                      />
                    }
                    onClick={onClose}
                    className={styles.closeButton}
                  />
                </div>
                {microArticleFirstImageIdx !== null &&
                getMicroArticleData?.media?.length ? (
                  <div className={styles.minimizedImageWrapper}>
                    <img
                      src={
                        getMicroArticleData?.media[microArticleFirstImageIdx]
                          .path
                      }
                      alt={
                        getMicroArticleData?.media[microArticleFirstImageIdx]
                          .caption
                      }
                      className={styles.minimizedImage}
                    />
                  </div>
                ) : null}
              </div>
            )
          }
          bordered={false}>
          {isMaximized && (
            <>
              {getIsSingleMicroArticleLoading || loading ? (
                <div className="skeletonContainer">
                  <div className="carouselSkeleton">
                    <Skeleton.Image
                      active
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                  <div className="descriptionSkeleton">
                    <Skeleton paragraph={{ rows: 2 }} active />
                  </div>
                  <div className="buttonsSkeleton">
                    <Skeleton.Button
                      className="skeletonButton"
                      active
                      shape="square"
                    />
                    <Skeleton.Button
                      className="skeletonButton"
                      active
                      shape="square"
                    />
                    <Skeleton.Button
                      className="skeletonButton"
                      active
                      shape="square"
                    />
                    <Skeleton.Button
                      className="skeletonButton"
                      active
                      shape="square"
                    />
                  </div>
                </div>
              ) : error ? (
                <Alert message={error} type="error" showIcon />
              ) : getMicroArticleData ? (
                <div className={styles.content}>
                  {/* Carousel Section */}
                  {getMicroArticleData?.media?.length > 0 && (
                    <div className={styles.carouselContainerWrapper}>
                      <div
                        className={styles.carouselContainer}
                        ref={carouselContainerRef}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleTouchStart}>
                        <Carousel
                          ref={carouselRef}
                          draggable={true}
                          arrows={false}
                          infinite={false}
                          autoplay={false}
                          dots={false}
                          afterChange={handleAfterChange} // Sync Carousel -> State
                          className={styles.carousel}>
                          {getMicroArticleData?.media.map(
                            (mediaItem, index) => (
                              <div
                                key={mediaItem.mediaId || index}
                                className={styles.carouselItem}
                                onClick={(e) => {
                                  // If user was dragging, ignore click
                                  if (ignoreClickRef.current) {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    return
                                  }
                                  handleOpenGallery()
                                }}>
                                <MediaRenderer mediaItem={mediaItem} />
                              </div>
                            ),
                          )}
                        </Carousel>
                      </div>
                    </div>
                  )}

                  {/* Dot Pagination Section */}
                  {getMicroArticleData?.media?.length > 0 && (
                    <Dots
                      total={getMicroArticleData?.media.length}
                      active={currentSlide}
                      onScrollTo={handleDotClickOrSwipe}
                      maxDots={6}
                      passiveDotWidth={8}
                      activeDotWidth={10}
                      passiveDotHeight={8}
                      activeDotHeight={10}
                      passiveColor="#dbdbdb"
                      activeColor="#2C476C"
                      marginHorizontal={10}
                      smallerDotScale={0.7}
                    />
                  )}

                  {/* Description Section */}
                  {getMicroArticleData?.description && (
                    <div
                      className={`${styles.description} ${styles.customScroll}`}>
                      <Typography.Paragraph
                        className={styles.paragraph}
                        style={{ fontSize: `${getPopupDetailFontSize}px` }}>
                        {getMicroArticleData?.description}
                      </Typography.Paragraph>
                    </div>
                  )}

                  {/* Action Buttons */}
                  <div className={styles.actionButtons}>
                    <Space>
                      <IconButton
                        disabled={
                          !getMicroArticleData?.description?.length ||
                          getPopupDetailFontSize === 20
                        }
                        className={styles.actionButton}
                        icon={<Typography.Text strong>A +</Typography.Text>}
                        onClick={increaseFontSize}
                      />
                      <IconButton
                        disabled={
                          !getMicroArticleData?.description?.length ||
                          getPopupDetailFontSize === 14
                        }
                        className={styles.actionButton}
                        icon={<Typography.Text strong>A -</Typography.Text>}
                        onClick={decreaseFontSize}
                      />
                      {!isMobile && isOwner && (
                        <IconButton
                          onClick={() => setPresentationsIsOpen(true)}
                          icon={
                            <img
                              style={{ width: '16px', height: '16px' }}
                              src={PresentationIcon}
                            />
                          }
                        />
                      )}
                      {isOwner && (
                        <IconButton
                          icon={
                            <img
                              style={{ width: '16px', height: '16px' }}
                              src={EditIcon}
                            />
                          }
                          className={styles.actionButton}
                          onClick={changeToEditMode}
                          disabled={loading}
                        />
                      )}
                    </Space>
                  </div>
                </div>
              ) : (
                <div>No data available.</div>
              )}
            </>
          )}
        </Card>
      </div>
    </Portal>
  )
}

export default CardMobileVersion
