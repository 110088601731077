import React, { useEffect } from 'react'
import withRedux from '../../mapper'

import EyeLamp from '../../../UI/EyeLamp'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'

const LampItem = ({
  data,
  getArticleFromAPI,
  changeLampDataShow,
  subCategoryData,
  getLampSelectedId,
  setLampSelectedId,
}) => {
  const onChangeStatus = (e) => {
    e.preventDefault()
    e.stopPropagation()
    changeLampDataShow(data.id)
  }

  useEffect(() => {
    if (getLampSelectedId) getArticleFromAPI(getLampSelectedId)
  }, [getLampSelectedId])

  const lampItemClickHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
    getArticleFromAPI(data.id)
    setLampSelectedId(data.id)
  }

  return (
    <li
      className={`main__subitem-item main__subitem-item_lamp`}
      style={{
        opacity: data.isHide ? 0.5 : 1,
        height: "fit-content",
        width: "100%"
      }}
      onClick={lampItemClickHandler}>
      <div className="hints__subitem_item hints__subitem_item_lamp">
        <div className="hints__subitem_item_lamp_content">
          <div className="with-svg">
            <ConnectedSpriteSvgComponent name={subCategoryData?.spriteIcon} />
          </div>
          <div className="main__subitem-btn main__subitem-btn_lamp">
            {data?.content[0]?.mainTitle}
          </div>
        </div>
        <label
          id={`main__subitem-label_lamp_${data.id}`}
          className="main__subitem-label main__subitem-label_lamp"
          data-show={!data.isHide}
          onClick={onChangeStatus}>
          <EyeLamp className="eye_lamp_icon" show={!data.isHide} />
        </label>
      </div>
    </li>
  )
}

export default withRedux(LampItem)
