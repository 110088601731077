import React from 'react'
import { Form, Spin } from 'antd'
import styles from '../PopupForm.module.css'
import Loader from '../../../../../assets/imgs/MicroArticleIcons/loader.svg'
import Icon from '@ant-design/icons'
import {isMobile} from "react-device-detect";
const FileListLoader = () => {
  return (
    <Form.Item style={{ margin: '5px 0px', padding: 0 }}>
      <div
        className={`${styles.previewContainer} custom-scroll-v3`}
        style={{
          height: 90,
          minHeight: 90,
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 80,
              height: 80,
              marginLeft: 5,
            }}>
            <Spin
              indicator={
                <Icon
                  component={() => <img src={Loader} alt="Loader" />}
                  style={{ fontSize: isMobile ? 40 : 30 }}
                  spin
                />
              }
              size="large"
            />
          </div>
        </div>
      </div>
    </Form.Item>
  )
}

export default FileListLoader
