import axios from "axios";
import ErrorMessages from "./shared/error-message.json";
import { uploadURI, userInfoSettingsURI, userInfoURI } from "./shared/mockData";
import i18n from "i18next";

export const LOCAL_STORAGE_DATA_ITEM_TEMPL = {
  user: "userInfo",
};
export function LogIn() {
  let body = {
    email: "map_user@bg.com",
    password: "A12345678a",
  };
  axios
    .post(`${process.env.REACT_APP_IDENTITY_URL}/api/User/SignIn`, body)
    .then((response) => {
      if (response.data.accepted) {
        localStorage.setItem(
          "adminAccessToken",
          response.data.data[0].accessToken
        );
        localStorage.setItem(
          "adminRefreshToken",
          response.data.data[0].refreshToken
        );
      }
    });
}

export function getCurrentUser() {
  return axios
    .get(userInfoURI)
    .then(async (response) => {
      if (response.data.accepted && response.data.data[0]) {
        return await axios.get(userInfoSettingsURI)
            .then(settings => {
              localStorage.setItem("userInfo", JSON.stringify({data:response.data.data[0]}));
              // if(settings.data.data[0]?.[0] && settings.data.data[0][0].settingId == 1) {
              //   const language = settings.data.data[0][0].value == 1 ? 'hy': 'en'
              //   i18n.changeLanguage(language)
              //   localStorage.setItem("language", language);
              // }
              if(settings.data.data[0]?.[1] && settings.data.data[0][1].settingId == 2) {
                const regLanguage = settings.data.data[0][1].value
                localStorage.setItem("regLanguage", regLanguage);
                if(!localStorage.getItem("language")){
                  const language = regLanguage == 1 ? 'hy': 'en'
                  i18n.changeLanguage(language)
                  localStorage.setItem("language", language || 'hy');
                }
              }
            })
      }
    });
}

export function LogOut() {
  const language = localStorage.getItem("language")
  localStorage.clear();
  localStorage.setItem("language", language || 'hy');
  window.location.href = "/login";
}

export function GetAllCategories() {
  let body = {
    page: 1,
    perPage: 50,
    includeLanguage: true,
    includeSubCategory: true,
    includeAttribute: true,
  };

  axios
    .post(
      `${process.env.REACT_APP_ARTICLE_URL}/api/Category/DataProvider/1`,
      body
    )
    .then((response) => {
      if (response.data.accepted) {
        let data = [];
        response.data.data[0].items.map((item, index) => {
          let obj = [];
          if (item.subCategories !== null) {
            item.subCategories.map((elem) => {
              obj.push({ ...elem, ...{ show: true, articles: [] } });
            });
          }
          item.subCategories = obj;
          data.push({ ...item, ...{ show: true } });
        });
        this.setState({
          allCategories: data,
          categories: data,
        });
      }
    });
}

export const HandleToggleMenu = (type, data) => {
  Object.keys(data).forEach((i) => {
    if (i !== type) {
      data[i] = false;
    }
    data[type] = !data[type];
  });
  return data;
};

export const getErrorMessage = (errorMessages) => {
  const firstError = errorMessages[0];
  const errMsg = errorMessages[0]
  return ErrorMessages[firstError] || errMsg;
};

export const HandleToken = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    window.location.href = "/login";
  }
  return;
};

export const uploadFile = (file) => {
  const data = new FormData();
  data.append("title", file.name);
  data.append("content", file);
  data.append("alt", file.name);
  data.append("access", "PublicRead");
  return axios.post(uploadURI, data);
};

export const handleImageUpload = (data) => {
  return {
    id: data.data[0].id,
    url: data.data[0].mediaInfo.optimized,
  };
};
