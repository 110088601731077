import Popup from "../Popup";
import './style.css'
import NoLanguage from "../../../assets/imgs/no_language";
import {useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "../Button";
import i18n from "i18next";
import {setScreenShotLanguage} from "../../../store/actions/mapStateAction";
import {useDispatch} from "react-redux";
import {cn} from "../../../utils/utils";

const NoLanguageContent = ({title}) => {
    const {t} = useTranslation()
    const [searchParams] = useSearchParams();
    const isPresentation = searchParams.get("presentation");
    const [clicked, setClicked] = useState(false)

    const dispatch = useDispatch()

    const toHomePage = () => {
        window.location.href = "/account-boards";
    }

    const submitChangeLanguageHandler = () => {
        const currentLng = localStorage.getItem('language');
        const newLanguage = currentLng === 'en' ? 'hy' : 'en';
        dispatch(setScreenShotLanguage(newLanguage));
        i18n.changeLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);

        setClicked(true)
        setTimeout(() => {
            window.location.reload();
        },200)
    }

    if (clicked) return null;

    return (
        <Popup
            isOpened={true}
            styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
        >
            <div
                className={cn("no-language-content", {
                    "!h-[310px]": isPresentation
                })}
            >
                <div className={"no-language-content-title"}>
                     <span className={'no-language-icon'}>
                       <NoLanguage size={64}/>
                    </span>
                </div>
                <div className={"no-language-content-description"}>
                    <h3>{title}</h3>
                </div>
                {
                    isPresentation &&
                    <>
                    <div className={"dynamic-screen-shot-warning-content__body py-5"}>
                        <p>{t('presentationSettings.warningDescription')}</p>
                    </div>
                    <div className={"dynamic-screen-shot-warning-content__footer pb-1"}>
                        <Button
                            className={"px-4 dynamic-screen-shot-warning-content__footer-btn dynamic-screen-shot-warning-content__footer-cancel-btn"}
                            action={toHomePage}
                            text={t('cancel')}
                        />
                        <Button
                            className={"px-4 dynamic-screen-shot-warning-content__footer-btn dynamic-screen-shot-warning-content__footer-success-btn"}
                            action={submitChangeLanguageHandler}
                            text={t('continue')}
                        />
                    </div>
                    </>
            }
                {
                    !isPresentation &&
                    <div>
                        <button
                            className="no-language-content-btn"
                            onClick={toHomePage}
                        >
                            OK
                        </button>
                    </div>
                }
            </div>
        </Popup>
    );
};
export default NoLanguageContent
