import { connect } from "react-redux";
import React, { useRef, useEffect, useState } from "react";
import { getCategoriesData } from "../../../store/selectors";
//COMPONENTS
import CardBody from "../CustomCardComponents/CardBody";
import NewTab from "../CustomCardComponents/NewTab";
import DynamicTitle from "../CustomCardComponents/DynamicTitle";
import Line from "../CustomCardComponents/Line";
import BookmarkInfo from "./BookmarkInfo";
import BookmarkContentInfo from "./BookmarkContentInfo";

//CONSTANTS
import { BOOKMARK_SECTIONS } from "./constants";

//UTILS
import { getInfoSection } from "./utils";
import { getSpriteFromAPI } from "../../../store/actions/sprite";

//STYLES
import "./style.css";

const BookmarkCard = (props) => {
  const { item, categories, handleUpdateBookmarkList } = props;
  const cardBodyRef = useRef(null);
  const [cardState, setCardState] = useState(true);
  const modifiedArticleDataAddCategories = (item) => {
    const result = item;
    result.categoryInfo = categories.filter(
      (el) => el?.id === result?.categories[0]?.categoryId,
    );
    return result;
  };
  const handleUnlikeBookmark = () => {
    setTimeout(() => {
      setCardState(() => false);
    }, 400);
    handleUpdateBookmarkList();
  };
  useEffect(() => {
    props.getSpriteFromAPI('/BaseSprite')
  }, [])
  return (
    <>
      {cardState ? (
        <CardBody
          hasIcon={false}
          styles={{ aspectRatio: "637/570", height: "fit-content" }}
          ref={cardBodyRef}>
          <NewTab
            content={getInfoSection(BOOKMARK_SECTIONS[0], item)}
            languageId={item.languageId}
          />
          <div>
            <DynamicTitle
              content={getInfoSection(BOOKMARK_SECTIONS[1], item)}
            />
            <Line />
          </div>
          <div>
            <BookmarkInfo
              content={getInfoSection(BOOKMARK_SECTIONS[2], item)}
            />
          </div>
          <div>
            <BookmarkContentInfo
              content={getInfoSection(
                BOOKMARK_SECTIONS[3],
                modifiedArticleDataAddCategories(item)
              )}
              handleUnlikeBookmark={handleUnlikeBookmark}
            />
          </div>
        </CardBody>
      ) : null}
    </>
  );
};
const mapStateTopProps = (state) => ({
  categories: getCategoriesData(state),
});
const mapDispatchTopProps = {
  getSpriteFromAPI: getSpriteFromAPI,
};

export default connect(mapStateTopProps, mapDispatchTopProps)(BookmarkCard);
