import { connect } from "react-redux";
import "./style.css";
import Enter from "../../Enter";
import React, {useState} from "react";
import { useSearchParams } from "react-router-dom";
import {Button} from "../../Button";
import {getLocaleKey} from "../../../../utils/helpers";
import WarningLanguage from "../../PresentationsCard/WarningLanguage";
import Popup from "../../Popup";
import {userInfoSettingsURI} from "../../../../shared/mockData";
import axios from "axios";
import i18n from "i18next";
import {hrefToPresentationLocation} from "../../PresentationsCard/utils";
import {changeLanguage} from "../../../../utils/utils";
const NewTab = (props) => {
  const { content = "#", actionType, languageId } = props;
  const [warningLanguagePopupShow, setWarningLanguagePopupShow] = useState(false);
  let [searchParams] = useSearchParams(content);
  let topicID = searchParams.get("topicID");
  let lessonID = searchParams.get("lessonID");

  const type = content.includes("type=lesson") ? "type=lesson" : "type=topic";
  const ID = content.includes("lessonID=")
    ? `lessonID=${lessonID}`
    : `topicID=${topicID}`;

  const getLastScreenShotAndUserIdUrl = (url) => {
    const urlParts = url?.split("&");
    let screenShotCount = 0;
    let userIdCount = 0;
    let lastScreenShot;
    let lastUserId;

    for (let i = 0; i < urlParts?.length; i++) {
      if (urlParts[i].startsWith("screenShot")) {
        screenShotCount++;
        lastScreenShot = urlParts[i].split("=")[1];
      } else if (urlParts[i].startsWith("userId")) {
        userIdCount++;
        lastUserId = urlParts[i].split("=")[1];
      }
      if (screenShotCount === 2 && userIdCount === 2) {
        break;
      }
    }
    const href =  `${window.location.origin}/map?${type}&${ID}&screenShot=${lastScreenShot}&userId=${lastUserId}&screenShotId=${lastScreenShot}`;
    window.open(href, "_blank")
  };
  const url = content;
  const hrefToOpen = () => {
    if(getLocaleKey(languageId ) !== localStorage.getItem('language')) {
      setWarningLanguagePopupShow(true)
      return false
    }
    actionType === 'screenShot' ? getLastScreenShotAndUserIdUrl(url) : window.open(content, "_blank")
  }
  const handleCloseWarningPopup = () => {
    setWarningLanguagePopupShow(() => false);
  };

  const handleHrefToLocation = (url) =>{
    changeLanguage(getLocaleKey(languageId))
    setWarningLanguagePopupShow(false)
    return actionType === 'screenShot' ? getLastScreenShotAndUserIdUrl(url) : window.open(content, "_blank")
  }
  return (
    <div className="card-body__dynamic-new-tab dynamic-new-tab">
      <Button
        action={hrefToOpen}
        className="dynamic-new-tab__btn"
        text={<Enter show={true} />}
      />
      <Popup
          isOpened={warningLanguagePopupShow}
          onClose={handleCloseWarningPopup}
          styleOverlayUser={{ backgroundColor: "#1b283ae8" }}
      >
        <WarningLanguage
            onClose={handleCloseWarningPopup}
            item={url}
            onSubmit={handleHrefToLocation}
            isScreenShot={true}
        />
      </Popup>
    </div>
  );
};
export default connect(null, null)(NewTab);
