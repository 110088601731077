import ReactDOM from "react-dom";
import GroupTemplate from "../components/GroupTemplate";
import ItemTemplate from "../components/ItemTemplate";

//Ruler popup icons
import IconCar from "../assets/imgs/PaintBar/icon-car.svg";
import IconClose from "../assets/imgs/PaintBar/icon-close.svg";
import IconHorse from "../assets/imgs/PaintBar/icon-horse.svg";
import IconPlane from "../assets/imgs/PaintBar/icon-plane.svg";
import IconWalk from "../assets/imgs/PaintBar/icon-walk.svg";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export const loginURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/SignIn`;
export const loginByPhoneURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/SignInByPhoneNumber`;
export const uploadURI = `${process.env.REACT_APP_MEDIA_CONTENT_URL}/api/Upload`;
export const userInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const userInfoSettingsURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/Settings`;
export const updateUserInfoURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User`;
export const changeUserPasswordURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/ChangePassword`;
export const deleteUserURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/Deactivate`;
export const checkUserPasswordURI = `${process.env.REACT_APP_IDENTITY_URL}/api/User/CheckPassword`;

export const getCategoriesAPIParams = (lng = 1) => {
  return {
    url: `${process.env.REACT_APP_ARTICLE_URL}/api/Category/DataProvider/${lng}`,
    body: {
      page: 1,
      perPage: 50,
      includeLanguage: true,
      includeSubCategory: true,
      includeAttribute: true,
    },
  };
};

export const mapRequirements = [
  {
    id: 1,
    value: "all",
    title: "Բոլորը",
    active: true,
  },
  {
    id: 2,
    value: 0,
    title: "Պարտադիրները",
    active: false,
  },
  {
    id: 3,
    value: 1,
    title: "Հավելյալները",
    active: false,
  },
];

export const mapStyles = [
  {
    id: 1,
    value: "physical",
    title: "Ֆիզիկական",
    style: "mapbox://styles/brainograph/ckyypimuc004114s0jxn3nytt",
    active: true,
  },
  {
    id: 2,
    value: "satellite",
    title: "Արբանյակային",
    style: "mapbox://styles/brainograph/ckyypqrvd000j15lb9osv5hda",
    active: false,
  },
  {
    id: 3,
    value: "politics",
    title: "Քաղաքական",
    style: "mapbox://styles/brainograph/ckyypjczl001w15qrx5woidl5",
    active: false,
  },
  {
    id: 4,
    value: "terrain",
    title: "Տեղանքային",
    style: "mapbox://styles/brainograph/cl3bx9ey8003b14rxe6nnha51",
    active: false,
  },
];

export const typesNumbers = [
  "Pin",
  "River",
  "Lake",
  "Sea",
  "Ocean",
  "Country",
  "Other",
];

export const timeLineDataItems = [];

export const timeLineDataGroup = [];

export const timeLineSelectedDataItems = [];

export const timeLineSelectedDataGroup = [];

export const specialityOptions = [
  {
    value: 1,
    text: "Teacher",
    label: "Ուսուցիչ",
  },
  {
    value: 3,
    text: "Student",
    label: "Աշակերտ",
  },
  {
    value: 4,
    text: "Other",
    label: "Այլ",
  },
];

export const genderOptions = [
  {
    value: "Female",
    label: 'Իգական'
  },
  {
    value: "Male",
    label: 'Արական',
  },
];

export const timeLineOptions = {
  autoResize: false,
  stack: true,
  showCurrentTime: false,
  horizontalScroll: true,
  verticalScroll: true,
  zoomKey: "ctrlKey",
  maxHeight: "230px",
  minHeight:
  window.innerWidth > 800 && window.innerWidth < 1800 ? "160px" : "230px",
  zoomMin: 315569520000,
  // groupHeightMode: 'fixed',
  format: {
    minorLabels: function (a) {
      if (a.format("YYYY").includes("-")) {
        return "Ք.ա. " + a.format("y").replace("-", "");
      }
      return a.format("y");
    },
  },
  orientation: {
    item: "top",
    axis: "bottom",
  },
  order: function (a, b) {
    return a.order - b.order;
  },
  groupTemplate: function (group, element) {
    return ReactDOM.createPortal(
      ReactDOM.render(<GroupTemplate group={group} />, element),
      element
    );
  },
  template: function (item, element) {
    return ReactDOM.createPortal(
      ReactDOM.render(<ItemTemplate item={item} />, element),
      element
    );
  },
};

export const dropDownCustomAccountStyles = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    minHeight: '35px',
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};
export const dropDownCustomAccountStylesForLittleScreens = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    minHeight: '35px',
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 5,
    background: "rgba(255, 255, 255, 1)", // corrected the background color
    color: "white",
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: 150,
    overflowY: 'auto',
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};

export const dropDownCustomAccountStylesForRegions = {
  control: (base, state) => ({
    ...base,
    width: "100%",
    background: "transparent",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    border: "none",
    borderRadius: 20,
    color: "#000",
    marginBottom: "5px",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      border: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#2c476c" : "black",
    background: state.isSelected ? "#42D3B0" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#42D3B0",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    maxHeight: 200,
    overflowY: 'auto',
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 10,
    color: "#2c476c",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 10,
    color: state.isSelected ? "#2c476c" : "#2c476c",
  }),
};

export const dropDownCustomStyles = {
  control: (base, state) => ({
    ...base,
    background: "transparent",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: "transparent",
    color: "white",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: "transparent",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
    background: state.isSelected ? "#f3bfda" : "transparent",
    "&:hover": {
      // Overwrittes the different states of border
      background: "#f3bfda",
    },
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 5,
    overflow: "hidden",
    background: "rgba(255, 255, 255,)",
    color: "white",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    paddingLeft: 40,
    color: "white",
  }),
  placeholder: (base, state) => ({
    ...base,
    paddingLeft: 40,
    color: state.isSelected ? "white" : "white",
  }),
};

export const years = [...Array(new Date().getFullYear() - 1899).keys()].map(
  (e) => e + 1900
);
export const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

//Painter

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomString = Math.random().toString(36).substr(2, 5);
  return timestamp + randomString;
}

export const CURSOR_TYPE = {
  DRAW: "DRAW",
  ERASER: "ERASER",
  RULER: "RULER",
  LINE_DRAW: "LINE_DRAW",
  COMMENT_BOX: "COMMENT_BOX",
  TEXT_BOX: "TEXT_BOX",
  STICKY_NOTES: "STICKY_NOTES",
  MICRO_ARTICLE: "MICRO_ARTICLE",
};

export const changeCursorIcon = (type) => {
  switch (true) {
    case type === CURSOR_TYPE.MICRO_ARTICLE:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.add("mapboxgl_canvas_microArticle");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_eraser");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_ruler");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_painter");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_commentBox");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_textBox");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_lineDraw");
      break;
    case type === CURSOR_TYPE.LINE_DRAW:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_textBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_lineDraw");
      break;
    case type === CURSOR_TYPE.COMMENT_BOX:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_textBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_commentBox");
      break;
      case type === CURSOR_TYPE.STICKY_NOTES:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_stickyNotes");
      break;
      case type === CURSOR_TYPE.TEXT_BOX:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_textBox");
      break;
    case type === CURSOR_TYPE.DRAW:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_textBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_painter");
      break;
    case type === CURSOR_TYPE.ERASER:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_textBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_eraser");
      break;
    case type === CURSOR_TYPE.RULER:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_textBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.add("mapboxgl_canvas_ruler");
      break;

    default:
      document
          .getElementsByClassName("mapboxgl-canvas")[0]
          ?.classList.remove("mapboxgl_canvas_microArticle");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_eraser");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_painter");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_ruler");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_lineDraw");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_commentBox");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_stickyNotes");
      document
        .getElementsByClassName("mapboxgl-canvas")[0]
        ?.classList.remove("mapboxgl_canvas_textBox");
      break;
  }
};

export const SPEED_NAME = {
  AIRPLANE: "AIRPLANE",
  CAR: "CAR",
  HORSE: "HORSE",
  WALK: "WALK",
};

export const SPEED = {
  AIRPLANE: 1000,
  CAR: 80,
  HORSE: 56/24,
  WALK: 30 / 24,
};
const getTimeV2 = (value, type, unit = 'km') => {
  const t = i18n.t
  const distanceInKm = unit === 'mile' ? value * 1.60934 : value;

  const speedInKmPerHour = SPEED[type];
  const hoursRequired = distanceInKm / speedInKmPerHour;
  const target_date = new Date().getTime() + hoursRequired * 3600000;
  const current_date = new Date().getTime();
  let seconds_left = (target_date - current_date) / 1000;

  const days = parseInt(seconds_left / 86400);
  seconds_left %= 86400;

  const hours = parseInt(seconds_left / 3600);
  seconds_left %= 3600;

  const minutes = parseInt(seconds_left / 60);

  if (days > 0 && hours === 0) {
    return `${days} ${t('day')}`;
  } else if (days > 0 && hours > 0) {
    return `${days} ${t('day')} ${hours} ${t('hour')}`;
  } else if (hours > 0 && minutes === 0) {
    return `${hours} ${t('hour')}`;
  } else if (hours > 0 && minutes > 0) {
    return `${hours} ${t('hour')} ${minutes} ${t('minute')}`;
  } else {
    return `${minutes} ${t('minute')}`;
  }
};

export const getUniqueNumber = () => {
  return `${new Date().getTime()}`;
};

export const isNotEmpty = (obj) => {
  return !!obj && Object.keys(obj).length !== 0;
};

export const getRulerPopupTemplate = (distance = 0) => {
  const MILES_CONVERSION_FACTOR = 1.60934;
  const distanceInMiles = distance / MILES_CONVERSION_FACTOR;

  const rulerParent = document.createElement('div');
  rulerParent.className = 'ruler_parent';

  const rulerModal = document.createElement('div');
  rulerModal.className = 'ruler_modal';

  const distanceParagraphKilometers = document.createElement('p');
  distanceParagraphKilometers.style.marginBottom = '5px';
  distanceParagraphKilometers.textContent = `${distance.toFixed(2)} ${i18n.t('toolbox.km')}`;

  const distanceParagraphMiles = document.createElement('p');
  distanceParagraphMiles.style.marginBottom = '5px';
  distanceParagraphMiles.textContent = `${distanceInMiles.toFixed(2)} ${i18n.t('toolbox.mile')}`;


  const closeIcon = document.createElement('img');
  closeIcon.src = IconClose;
  closeIcon.className = 'close_icon';
  closeIcon.id = 'ruler_modal';

  rulerModal.appendChild(distanceParagraphKilometers);
  rulerModal.appendChild(distanceParagraphMiles);
  rulerModal.appendChild(closeIcon);

  const appendTravelTimeDiv = (iconSrc, iconClass, speedName) => {
    const travelTimeDiv = document.createElement('div');

    const icon = document.createElement('img');
    icon.src = iconSrc;
    icon.className = iconClass;

    const timeSpan = document.createElement('span');
    timeSpan.textContent = getTimeV2(distance.toFixed(2), speedName);

    travelTimeDiv.appendChild(icon);
    travelTimeDiv.appendChild(timeSpan);

    rulerModal.appendChild(travelTimeDiv);
  };

  appendTravelTimeDiv(IconPlane, 'IconPlane', SPEED_NAME.AIRPLANE);
  appendTravelTimeDiv(IconCar, 'IconCar', SPEED_NAME.CAR);
  appendTravelTimeDiv(IconHorse, 'IconHorse', SPEED_NAME.HORSE);
  appendTravelTimeDiv(IconWalk, 'IconWalk', SPEED_NAME.WALK);

  rulerParent.appendChild(rulerModal);

  return rulerParent;
};
