import React, {memo, useEffect} from 'react';
import {connect} from "react-redux";
import {
    getIsFormPopupVisible,
    getMapStyledId,
    getMicroArticleBarClickedState,
    getPainterGeoJsonDataST,
    getPainterStartData,
    getScreenShootButtonStateST,
} from "../../store/selectors";

import MicroArticleTool from "./microArticleTool";
import MapScreenShotButton from "../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import {setMicroArticleBar, setMicroArticleBarClickedState} from "../../store/actions/painterStart";

const MicroArticleBar = (props) => {
  const {
      getMicroArticleBarClickedState,
      setMicroArticleBarClickedState,
      getIsFormPopupVisible,
      getAllFeaturesOfSuperCluster,
      superCluster,
      useMapLiveViewRef,
      map,
      globalLoading,
  } = props

    useEffect(() => {
        if(getIsFormPopupVisible) {
            setMicroArticleBarClickedState(false);
        }
    }, [getIsFormPopupVisible]);
  return (
    <div
      className="paintBar_container microArticleBar"
      style={{ display: getMicroArticleBarClickedState ? 'flex' : 'none' }}>
      {/*<div id={'screen-shot-icon'} className="lastToolboxBtn">*/}
      {/*    {getMicroArticleBarClickedState && (*/}
      {/*        <MapScreenShotButton map={map} disable={isScreenShot ? getScreenShootButtonStateST : undefined} />*/}
      {/*    )}*/}
      {/*</div>*/}
      <MicroArticleTool superCluster={superCluster} useMapLiveViewRef={useMapLiveViewRef} getAllFeaturesOfSuperCluster={getAllFeaturesOfSuperCluster} globalLoading={globalLoading} map={map} />
    </div>
  )
}

const mapStateTopProps = (state) => ({
  getMicroArticleBarClickedState: getMicroArticleBarClickedState(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getMapStyledId: getMapStyledId(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
})

const mapDispatchToProps = {
    setMicroArticleBarClickedState,
};

export default connect(mapStateTopProps, mapDispatchToProps)(memo(MicroArticleBar));

