import React, { useEffect, useMemo, useState } from 'react'
import TextIcon from '../icons/TextIcon'
import PenIcon from '../icons/PenIcon'
import CopyIcon from '../icons/CopyIcon'
import TrashIcon from '../icons/TrashIcon'
import { Button, Dropdown, Modal } from 'antd'
import DotsIcon from '../icons/DotsIcon'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { cn } from '../../../../utils/utils'
import { CircleDot, GripVertical, XIcon } from 'lucide-react'
import PresentationFormWrapper from '../form/PresentationFormWrapper'
import {
  useCloneSlideMutation,
  useGetArticleQuery,
  useGetMicroArticleQuery,
  useRemoveSlideMutation,
} from '../../store/presentationApi'
import ConnectedSpriteImageComponent from '../../../UI/ConnectedSpriteImageComponent'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllMicroArticles,
  getCategoriesData,
} from '../../../../store/selectors'
import {
  setCurrentSlide,
  setPlaybackPresentation,
} from '../../store/presentationSlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import {
  INITIAL_ICON_OBJECT,
  isIconKeyValid,
} from '../../../microArticle/utils'
import ScreenShotIcon from '../../../../assets/imgs/icons/screenshot-icon.svg'
import { generateSiteUrl } from '../../lib/utils'
import { setSelectedSlideData } from '../../../../store/actions/slides'
import {useInViewport} from "react-in-viewport";

const PresentationSlideItem = ({
  name,
  id,
  onClick,
  presentationId,
  onStart,
  isActive,
  number,
  articleId,
  microArticleId,
  screenShotId,
  presentation,
  description,
  viewMode,
  hideEdit,
                                 isCreator,
                                 isLoading,
                                 isDeleted,
}) => {
  const [patumIsOpen, setPatumIsOpen] = useState(false)
  const [editIsOpen, setIsEditMode] = useState(false)
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const containerRef = React.useRef(null)
  const [currentUser] = useState(
      JSON.parse(localStorage.getItem('userInfo'))?.data,
  )
  const dispatch = useDispatch()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: Number(id), disabled: isDeleted || viewMode })

  const {
    inViewport,
  } = useInViewport(
      containerRef,
      null,
  );

  const [onClone, { isLoading: isCloning }] = useCloneSlideMutation()
  const [onDelete, { isLoading: isDeleting }] = useRemoveSlideMutation()

  const {
    data: microArticleData,
    refetch: refetchMicroArticle,
    isUninitialized,
  } = useGetMicroArticleQuery(microArticleId, {
    skip: !microArticleId || !inViewport || isLoading,
  })

  const { data: articleData } = useGetArticleQuery(articleId, {
    skip: !articleId || !inViewport || isLoading,
  })
  const microArticles = useSelector(getAllMicroArticles)

  const article = articleData?.data?.[0]
  const microArticle = microArticleData?.data?.[0]
  const isScreenshot = !articleId && !microArticleId

  const categories = useSelector(getCategoriesData)

  const startHandler = (e) => {
    e.stopPropagation()
    dispatch(setPlaybackPresentation(presentation))
    dispatch(setCurrentSlide(''))
    search.set('presentation', presentation?.id)
    if (!articleId) {
      // search.set('screenShot', true)
    } else {
      search.set('articleMode', articleId)
    }
    search.set('screenShot', true)
    search.set('loading', true)
    search.set('playBack', true)

    if (id) {
      search.set('screenSlide', id)
    }

    navigate(
      {
        pathname: window.location.pathname,
        search: search.toString(),
      },
      { replace: true },
    )

    window.location.reload()

    onStart?.(presentation)
  }

  const subCategoryInfo = useMemo(() => {
    const subCategoryId = article?.subCategory?.find(
      (item) => item.isMain,
    )?.subCategoryId
    if (!subCategoryId || !categories?.length) {
      return null
    }

    let icon
    let color

    categories?.forEach((item) => {
      item.subCategories?.forEach?.((subCategory) => {
        if (subCategory?.id === subCategoryId) {
          icon = subCategory?.spriteIcon
          color = subCategory?.color
        }
      })
    })

    return { icon, color }
  }, [article, categories])

  const items = [
    {
      icon: <TextIcon />,
      label: t('presentation_mode.narrative'),
      key: 'text',
      disabled: !description,
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'editSlide',
      disabled: hideEdit || !isCreator,
    },
    {
      icon: <CopyIcon />,
      label: t('presentation_mode.duplicate'),
      key: 'clone',
      disabled: !isCreator,
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
      disabled: !isCreator,
    },
  ]
  const itemsRemovedElement = [
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'editSlide',
      disabled: hideEdit,
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
    },
  ]

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const clickDropdownHandler = (e) => {
    e.domEvent.stopPropagation()
    switch (e.key) {
      case 'text':
        setPatumIsOpen(true)
        break
      case 'editSlide':
        setIsEditMode(true)
        break
      case 'copy_url':
        const url = generateSiteUrl({
          presentationId: id,
          articleMode: articleId && false,
          microArticleMode: microArticleId && false,
          slideId: id,
          topicId: search.get('topicID'),
          lessonId: search.get('lessonID'),
          screenShotId,
        })
        navigator.clipboard.writeText(url)
        break
      case 'clone':
        onClone({
          presentationId,
          slideIds: [id],
        })
        break
      case 'remove':
        onDelete(id)
        break
      default:
        break
    }
  }

  const clickHandler = (e) => {
    if(isDeleted) return
    setPatumIsOpen(false)
    if (viewMode) {
      return;
    }
    onClick?.()
    previewHandler(e)
  }

  const previewHandler = (e) => {
    e.stopPropagation()
    // dispatch(setPlaybackPresentation(presentation))
    // dispatch(setCurrentSlide(''))

    // search.set('presentation', presentation?.id)

    if (!articleId) {
      search.delete('articleMode')
    } else {
      search.set('articleMode', articleId)
    }

    if (presentation.createdBy) {
      search.set('userId', presentation.createdBy || currentUser?.id)
    } else {
      search.delete('userId')
    }

    search.set('screenShot', 'true')
    search.set('loading', true)
    // search.set('playBack', true)

    // if (currentUser) {
    //   search.set('userId', currentUser?.id)
    // }
    //
    if (id) {
      search.set('screenSlide', id)
      dispatch(setCurrentSlide(id))
      // dispatch(setSelectedSlideData(firstSlide))
    }

    if (microArticleId) {
      search.set('microArticleMode', microArticleId)
    } else {
      search.delete('microArticleMode')
    }

    if (screenShotId) {
      search.set('screenShotId', screenShotId)
    } else {
      search.delete('screenShotId')
    }

    search.set('presentation', presentation?.id)

    navigate(
      {
        pathname: window.location.pathname,
        search: search.toString(),
      },
      { replace: true },
    )

    // window.location.href =
    //     window.location.origin +
    //     window.location.pathname +
    //     '?' +
    //     search.toString()

    // navigate({
    //   pathname: window.location.pathname,
    //   search: search.toString(),
    // }, { replace: true })

    onStart?.(presentation)
  }

  useEffect(() => {
    if (
      microArticles?.length &&
      !isUninitialized
      // && microArticles?.find(item => +item?.id === +microArticleId)
    ) {
      refetchMicroArticle()
    }
  }, [microArticles, microArticleId, isUninitialized])


  return (
    <>
      <div
        className={cn('text-[#2C476C] hover:bg-[#F6F6F6] rounded-md', {
          'bg-[#F6F6F6]': isActive,
        })}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}>
        <div ref={containerRef}
          className={cn('presentation-slide--item', {
            'bg-[#F6F6F6]': isDragging,
            '!cursor-default': viewMode,
          })}
          onClick={clickHandler}>
          {isDragging && <GripVertical className="!w-[15px]" />}
          <small data-is-loading={isLoading}>{number}</small>
          <div data-is-loading={isLoading} data-is-deleted={isDeleted} className="scale-75 w-[30px] h-[30px] [&>div] rounded-full flex items-center justify-center">
            {isScreenshot ? (
              <img
                className="w-6 h-6 rounded"
                src={ScreenShotIcon}
                alt="slide"
              />
            ) : article ? (
              <ConnectedSpriteImageComponent name={subCategoryInfo?.icon} />
            ) : microArticle ? (
              <div>
                <ConnectedSpriteImageComponent
                  name={
                    isIconKeyValid(microArticle.iconKey)
                      ? microArticle.iconKey.replace('light', 'dark')
                      : INITIAL_ICON_OBJECT.darkKey
                  }
                />
              </div>
            ) : (
              <CircleDot
                color="#2C476C"
                fontSize={12}
                className="!h-[28px] !w-[28px]"
              />
            )}
          </div>
          <div data-is-deleted={isDeleted} className="flex flex-1 items-center">
            <span className="flex-1  line-clamp-3 break-all" data-is-loading={isLoading}>{name}</span>

            {!viewMode && isCreator && (
              <Dropdown
                getPopupContainer={() => document.body}
                overlayClassName="app-dropdown"
                placement="bottomLeft"
                menu={{ items: isDeleted ? itemsRemovedElement : items, onClick: clickDropdownHandler }}
                trigger={['click']}>
                <button
                  data-is-loading={isLoading}
                  className="flex h-6 items-center ml-1"
                  onClick={(e) => e.stopPropagation()}>
                  <DotsIcon  data-is-loading={isLoading} width="6" height="10" className={cn("!h-4 !w-5", {'invisible': isLoading})} />
                </button>
              </Dropdown>
            )}
          </div>
        </div>
        {patumIsOpen && (
          <div className="my-1.5 p-2 presentation-patum--editor pr-[3px]">
            <div className="flex justify-between gap-2 items-center mb-1">
              <span className="mb-1 block text-sm text-[#2C476C]">
                {t('presentation_mode.narrative')}
              </span>
              <Button
                type="text"
                size="small"
                icon={
                  <XIcon fontSize={14} className="!w-4 !h-4" color="#2C476C" />
                }
                onClick={() => setPatumIsOpen(false)}
              />
            </div>
            <div className="max-h-[80px] overflow-auto border-[#e4e4e4] rounded-md p-2 border border-solid">
              {/*<div dangerouslySetInnerHTML={{ __html: description }} />*/}
              <ReactQuill
                value={description}
                readOnly={true}
                className="border-none [&>.ql-toolbar]:hidden readably--quill"
              />
            </div>

            {/*<TextEditor onClose={() => setPatumIsOpen(false)} placeholder="Ավելացնել պատում"/>*/}
          </div>
        )}
      </div>
      <Modal
        destroyOnClose
        title={presentation?.title}
        maskClosable={false}
        centered
        classNames={{
          body: 'max-h-[80vh] overflow-auto',
          header:
            'uppercase text-center !pb-3 tracking-wider text-[16px] font-medium !pt-4 !px-10',
        }}
        // getContainer={() => document.body}
        className="app-modal no-padding max-h-[90vh]"
        width={950}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsEditMode(false)}
        open={editIsOpen}>
        {editIsOpen && (
          <PresentationFormWrapper
            id={presentationId}
            slideId={id}
            onSuccess={() => setIsEditMode(false)}
          />
        )}
      </Modal>
    </>
  )
}

export default PresentationSlideItem
