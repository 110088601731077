import React, {useRef} from 'react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames'; // Utility for conditionally joining classNames
import styles from './IconButton.module.css'; // Ensure this CSS module exists
import './style.css';
import {isMobile} from "react-device-detect";

const IconButton = ({ title, icon, onClick, className, color, toggled, noHoverEffect, children, ...rest }) => {

    const tooltipState = {
        trigger:"hover",
    }

    if (isMobile) {
        tooltipState.open = toggled;
    }

    const button = (
        <Button
            className={classNames(
                "buttonInnerStyles",
                styles.iconButton,
                noHoverEffect ? styles.noHoverEffect : '',
                !color ? { [styles.iconButtonToggled]: toggled } : '',
                className,
            )}
            style={{ backgroundColor: toggled ? color : '' }}
            shape="default"
            icon={icon}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Button>
    );

    return title ? (
        <Tooltip
            {...tooltipState}
            title={title}
        >
            {button}
        </Tooltip>
    ) : (
        button
    );
};

IconButton.propTypes = {
    /**
     * The tooltip text. If not provided, no tooltip will be shown.
     */
    title: PropTypes.string,
    /**
     * The icon to be displayed inside the button.
     */
    icon: PropTypes.node.isRequired,
    /**
     * Function to handle button click.
     */
    onClick: PropTypes.func,
    /**
     * Additional CSS classes for custom styling.
     */
    className: PropTypes.string,
    /**
     * Indicates whether the button is in a toggled state.
     */
    toggled: PropTypes.bool,
};

IconButton.defaultProps = {
    title: null, // No tooltip by default
    onClick: () => {},
    className: '',
    toggled: false,
};

export default IconButton;
