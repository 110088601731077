import React from 'react'
import {Input, List} from 'antd'
import SearchIcon from '../icons/SearchIcon'
import {Link2} from 'lucide-react'
import {useGetArticlesFromArticleServiceQuery} from '../../store/presentationApi'
import useDebounce from '../../../../hooks/useDebounce'
import {useTranslation} from 'react-i18next'

const TextEditorLinkSetter = ({ onSelect,searchValue }) => {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = React.useState(searchValue || '')
  const debouncedTermValue = useDebounce(searchTerm, 500)
  const APP_URL = window.location.origin

  const { data } = useGetArticlesFromArticleServiceQuery({
    pageNumber: 0,
    pageSize: 1000,
    term: debouncedTermValue,
    // includeCategories: true,
    includeContent: true,
    // includeCountries: true,
    // includeHistoricalCountries: true,
    // includeHistoricalPeriods: true,
    // includeLessons: true,
    // includeRegions: true,
    // includeSubCategories: true,
    columnFilters: {
      'content.mainTitle': debouncedTermValue,
      status: 'Published',
    },
  })

  const records = data?.data?.[0]?.items || []

    const highlightSearchTerm = (text, term) => {
        if (!term) return text
        const regex = new RegExp(`(${term})`, 'gi') // Case-insensitive match
        return text.replace(regex, '<strong>$1</strong>') // Wrap match in <strong>
    }

  const selectHandler = (id) => {
    onSelect?.(
      APP_URL +
        `/map?preview=true&type=article&articleID=${id}&bookmarkID=${id}`,
    )
  }

  return (
    <div style={{ width: 450 }}>
      <Input
        variant="borderless"
        rootClassName="search-input mb-3 [&>input]:!text-[#2C476C]"
        value={searchTerm}
        placeholder={t('presentation_mode.search')}
        onChange={(e) => setSearchTerm(e.target.value)}
        suffix={<SearchIcon />}
      />
      <List
        size="small"
        dataSource={records}
        renderItem={(item) => (
            <List.Item
                className="!flex items-center justify-between !px-4 !py-1 hover:!bg-primary hover:!text-white rounded-3xl !text-[#2C476C]"
                onClick={() => selectHandler(item.id)}
                style={{cursor: 'pointer'}}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: highlightSearchTerm(item.content?.mainTitle || '', searchTerm),
                    }}
                />
                <Link2 color="white"/>
            </List.Item>
        )}
        style={{maxHeight: 200, overflowY: 'auto'}}
      />
        <Input
            rootClassName="h-[44px] rounded-xl mt-3 [&>input]:!text-[#2C476C]"
            onKeyPress={(e) => e.key === 'Enter' && onSelect(e.target.value)}
        placeholder={t('presentation_mode.paste_url')}
      />
    </div>
  )
}

export default TextEditorLinkSetter
