import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import MicroArticleToolIcon from '../../../assets/imgs/PaintBar/micro_article_tool_icon.svg'
import { setMicroArticleToolClickedState } from '../../../store/actions/painterStart'
import {
  getActiveMicroArticleIdST,
  getAllMicroArticles,
  getArticlesData,
  getCloseAndDeactivateAllActivator,
  getIsFormPopupVisible,
  getIsScreenShotMode,
  getIsViewPopupVisible,
  getMapStyledId,
  getMicroArticleBarClickedState, getMicroArticleData,
  getMicroArticleToolClickedState,
  getShowArticlePopup,
} from '../../../store/selectors'
import {connect, useDispatch, useSelector} from 'react-redux'
import { changeCursorIcon, CURSOR_TYPE } from '../../../shared/mockData'
import { isMobile } from 'react-device-detect'
import {
  DEFAULT_ICON_SPRITE_NAME, DEFAULT_ICON_KEY, isIconKeyValid,
  MICRO_ARTICLE_BACKGROUND_LAYER,
  MICRO_ARTICLE_LAYER,
  MICRO_ARTICLE_SOURCE
} from '../utils'
import usePulseAnimation from '../hooks/usePulseAnimation'
import PopupForm from '../components/PopupForm'
import ViewPopup from '../components/PopupDetail'
import Portal from '../components/Portal'
import {
  setAllMicroArticles,
  setActiveMicroArticleId,
  setViewPopupVisibility,
  setFormPopupVisibility, setCloseAndDeactivateAllMicroArticlesActivator, setFeatureMicroArticleId
} from '../../../store/actions/microArticle'
import {
  createEmptyMicroArticleFeature,
} from "../../../pages/newMap/utils/featureHelpers";
import {setShowArticlePopup} from "../../../store/actions/mapStateAction";
import {setArticlesAll} from "../../../store/actions/articlesAll";
import {closeArticlePopup} from "../../../store/actions/articles";
import {useNavigate, useSearchParams} from "react-router-dom";
import {selectMicroArticlePresentation, setMicroArticlePresentation} from "../../presentation/store/presentationSlice";
import PopupFormMobile from "../components/PopupFormMobile";
import navigation from "../../../navigation";

const MicroArticleTool = ({
                            getMicroArticleData,
                            getAllMicroArticles,
                            getCloseAndDeactivateAllActivator,
                            setCloseAndDeactivateAllMicroArticlesActivator,
                            setMicroArticleToolClickedState,
                            getMicroArticleToolClickedState,
                            getMicroArticleBarClickedState,
                            setViewPopupVisibility,
                            getIsViewPopupVisible,
                            getIsFormPopupVisible,
                            setFormPopupVisibility,
                            setActiveMicroArticleId,
                            globalLoading,
                            map,
                            activeMicroArticleId,
                            getAllFeaturesOfSuperCluster,
                            superCluster,
                            closeArticlePopup,
                            isScreenShotMode,
                            microArticleIdFromScreenshot,
                            setStoreFeatureMicroArticleId,
                          }) => {
  const draggingFeatureIdRef = useRef(null)
  const draggingFeatureIdx = useRef(null);
  const isTransitioningRef = useRef(false)
  const currentFeatureIdRef = useRef(null)
  const oldFeaturePropertiesRef = useRef({})
  const hasRun = useRef(false)
  const [modalCoordinates, setModalCoordinates] = useState({ lng: 0, lat: 0 })
  const [currentFeatureId, setCurrentFeatureId] = useState(null)
  const [currentFeatureMicroArticleID, setCurrentFeatureMicroArticleID] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const isEditRef = useRef(isEdit)
  const allFeaturesOfSuperClusterRef = useRef([])
  const [searchParams] = useSearchParams();
  const isScreenShot = searchParams.get("screenShot");
  const screenSlide = searchParams.get("screenSlide");
  const pmMicroArticleId = searchParams.get("microArticleMode");
  const navigation = useNavigate();
  const featuresIsLoaded = getAllFeaturesOfSuperCluster?.() !== undefined

  const getIsFormPopupVisibleRef = useRef(false);

  const pmMACreateMode = useSelector(selectMicroArticlePresentation);

  useEffect(() => {
    getIsFormPopupVisibleRef.current = getIsFormPopupVisible;
  }, [getIsFormPopupVisible]);

  const dispatch = useDispatch();

  const getMicroArticlesSource = () => map.getSource(MICRO_ARTICLE_SOURCE)

  useEffect(() => {
    if(getIsFormPopupVisible) {
      setViewPopupVisibility(false)
    }
  }, [getIsFormPopupVisible]);


  useEffect(() => {
    if (microArticleIdFromScreenshot && featuresIsLoaded) {
      setCurrentFeatureMicroArticleID(microArticleIdFromScreenshot);
      setTimeout(() => {
        const feature = allFeaturesOfSuperClusterRef.current.find(
            (f) =>
                String(f.properties.microArticleID) === String(microArticleIdFromScreenshot) && f.properties.isMicroArticle,
        )
        runFeature(feature, true)
      },400)
    }
  },[microArticleIdFromScreenshot, screenSlide, featuresIsLoaded]);

  useEffect(() => {
    isEditRef.current = isEdit
  }, [isEdit])

  useEffect(() => {
    if (getIsFormPopupVisible && isEdit) {
      const featureId = currentFeatureIdRef.current
      if (featureId) {
        const feature = allFeaturesOfSuperClusterRef.current?.find(
            (f) => f.properties.id === featureId && f.properties.isMicroArticle,
        )
        if (feature) {
          oldFeaturePropertiesRef.current = {
            ...feature.properties,
            coordinates: [...feature.geometry.coordinates],
          }
        }
      }
    }
  }, [getIsFormPopupVisible, isEdit])

  const stopPinAnimation = () => {
    let mapMarkers = document.getElementsByClassName(
        "mapboxgl-marker-anchor-center"
    );

    for (let i = 0; i < mapMarkers.length; i++) {
      mapMarkers[i].classList.remove("tic_animation");
      mapMarkers[i].style.zIndex = "1";
    }
  };


  const runFeature = (feature, noFly) => {
    stopPinAnimation(map);
    if (feature) {
      startPulse(feature)
      setCurrentFeatureId(feature.properties.id)
      setCurrentFeatureMicroArticleID(feature.properties.microArticleID)
      currentFeatureIdRef.current = feature.properties.id
      setModalCoordinates({
        lng: feature.geometry.coordinates[0],
        lat: feature.geometry.coordinates[1],
      })
      setViewPopupVisibility(true)
      if (!noFly) {
        map.flyTo({
          center: feature.geometry.coordinates,
          zoom: map.getMaxZoom(),
        })
      }

    } else {
      setCurrentFeatureId("none")
      setCurrentFeatureMicroArticleID(pmMicroArticleId)
      setViewPopupVisibility(true)
    }
  }

  const initialColor = () => {
    return allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === currentFeatureIdRef.current && f.properties.isMicroArticle,
    )?.properties.backgroundColor || '#1976d2'
  }

  const initialIconKey = () => {
    const iconKey = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === currentFeatureIdRef.current && f.properties.isMicroArticle,
    )?.properties.iconKey;
    return isIconKeyValid(iconKey) ? iconKey : DEFAULT_ICON_SPRITE_NAME;
  }

  useEffect(() => {
    if (getIsFormPopupVisible) return;
    if (!globalLoading && !hasRun.current || pmMicroArticleId) {
      hasRun.current = true

      const timer = setTimeout(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const microarticleID = urlParams.get('microarticleID') || pmMicroArticleId

        if (microarticleID && getAllFeaturesOfSuperCluster?.()?.length) {
          const feature = allFeaturesOfSuperClusterRef.current.find(
              (f) =>
                  String(f.properties.microArticleID) === String(microarticleID) && f.properties.isMicroArticle,
          )

          runFeature(feature)
          if (urlParams.get('microarticleID')) {
            const url = new URL(window.location)
            url.searchParams.delete('microarticleID')
            window.history.replaceState({}, document.title, url.toString())
          }

        }
      }, pmMicroArticleId ? 1400 : 1000)

      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLoading, getIsFormPopupVisible, pmMicroArticleId, JSON.stringify(getAllFeaturesOfSuperCluster?.())]) //TEMP solution


  useEffect(() => {
    allFeaturesOfSuperClusterRef.current = getAllFeaturesOfSuperCluster?.() || []
  }, [JSON.stringify(getAllFeaturesOfSuperCluster?.())]);


  const { startPulse, stopPulse, pulsingFeatureRef } = usePulseAnimation(
      map,
      MICRO_ARTICLE_BACKGROUND_LAYER,
      'pulse-source',
      'pulse-layer',
  )

  const changeIsViewPopupVisible = useCallback((bool) => {
    setViewPopupVisibility(bool)
  }, [])

  const changeEditMode = useCallback((bool) => {
    setIsEdit(bool)
  }, [])

  const changeIsModalVisible = useCallback((bool) => {
    setFormPopupVisibility(bool)
  }, [])

  const handleCreateSuccess = useCallback((microArticleId, yearStart, yearEnd, yearStartIsBc, yearEndIsBc) => {
    const featureId = currentFeatureIdRef.current

    if (!featureId) {
      console.warn('No current feature ID found.')
      return
    }

    const feature = allFeaturesOfSuperClusterRef.current.find(
      (f) => f.properties.id === featureId && f.properties.isMicroArticle,
    )

    if (feature) {
      feature.properties.microArticleID = microArticleId;

      feature.properties.yearStart = +`${yearStartIsBc ? '-' : ''}${yearStart || '4714'}`;
      feature.properties.yearEnd = +`${yearEndIsBc ? '-' : ''}${yearEnd || new Date().getFullYear()}`;

      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }

      // MicroArticleService.getMicroArticlesForMap().then((microArticles) => {
      //   setAllMicroArticles(microArticles);
      // }).catch((e) => {
      //   console.log('error', e)
      // });
    } else {
      console.warn(`Feature with ID ${featureId} not found.`)
    }

    if (pmMACreateMode || pmMicroArticleId) {
      // setViewPopupVisibility(false);
      // setCurrentFeatureId(null);
      // setCurrentFeatureMicroArticleID(null);
      // setActiveMicroArticleId(null);
      // stopPulse();
      // searchParams.set('microArticleMode', microArticleId);
      searchParams.delete('microArticleMode');
      navigation({
        pathname: window.location.pathname,
        search: searchParams.toString(),
      }, {replace: true});

      // return;
    }

    if (pmMicroArticleId) {
      setViewPopupVisibility(true);
      setCurrentFeatureId("none");
      setCurrentFeatureMicroArticleID(microArticleId);
      // setActiveMicroArticleId(microArticleId || pmMicroArticleId);
    } else {
      // alert("Micro article created successfully!")
      setViewPopupVisibility(true);
      setCurrentFeatureMicroArticleID(microArticleId)
      setActiveMicroArticleId(microArticleId)
      // setCurrentFeatureId("none");
    }
  }, [pmMACreateMode, pmMicroArticleId, getAllFeaturesOfSuperCluster])

  useEffect(() => {
    setStoreFeatureMicroArticleId(currentFeatureMicroArticleID)
  },[currentFeatureMicroArticleID])

  const mapClickHandler = useCallback((e) => {
    const { lng, lat } = e.lngLat

    const newFeature = createEmptyMicroArticleFeature(e.lngLat, DEFAULT_ICON_SPRITE_NAME, DEFAULT_ICON_KEY)
    currentFeatureIdRef.current = newFeature.properties.id
    allFeaturesOfSuperClusterRef.current.push(newFeature)

    if (getMicroArticlesSource()) {
      getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
    }

    startPulse(newFeature)

    setTimeout(() => {
      setMicroArticleToolClickedState(false)
      map.flyTo({ center: [lng, lat] })
    }, 50)

    setModalCoordinates({ lng, lat })
    setFormPopupVisibility(true)
    // THE NEW CREATED MICRO-ARTICLES DOESNT HAVE A MICRO_ARTICLE_ID, ITS SETS AFTER CREATING FROM THE BECK-END
    setCurrentFeatureMicroArticleID(null);
  }, [])

  const handleModalSave = useCallback(
      (formData) => {
        const featureId = currentFeatureIdRef.current
        if (!featureId) return

        const feature = allFeaturesOfSuperClusterRef.current.find(
            (f) => f.properties.id === featureId && f.properties.isMicroArticle,
        )
        if (feature) {
          feature.properties.title = formData.title || feature.properties.title
          feature.properties.backgroundColor = formData.color || feature.properties.backgroundColor

          if (getMicroArticlesSource()) {
            getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
          }

          if (
              pulsingFeatureRef.current &&
              pulsingFeatureRef.current.featureId === featureId
          ) {
            pulsingFeatureRef.current.backgroundColor =
                feature.properties.backgroundColor
          }
        }
      },
      [pulsingFeatureRef, currentFeatureIdRef.current],
  )

  const onFeatureClick = useCallback((e) => {
    if(isEditRef.current || getIsFormPopupVisibleRef.current) return;

    const features = map.queryRenderedFeatures(e.point, {
      layers: [MICRO_ARTICLE_BACKGROUND_LAYER],
    })

    if (!features.length) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === features[0].properties.id && f.properties.isMicroArticle,
    )
    const featureId = feature.properties.id
    const [lng, lat] = feature.geometry.coordinates
    const isAlreadySelected =
        pulsingFeatureRef.current &&
        pulsingFeatureRef.current.featureId === featureId

    if (!isAlreadySelected) {
      if (isTransitioningRef.current) return

      const cubicEaseOut = (t) => 1 - Math.pow(1 - t, 3)

      map.easeTo({
        center: [lng, lat],
        zoom: map.getMaxZoom(),
        duration: 3000,
        easing: cubicEaseOut,
        essential: true,
      })

      isTransitioningRef.current = true

      map.once('moveend', () => {
        isTransitioningRef.current = false
      })
    }

    if (!isAlreadySelected) {
      startPulse(feature)

      setCurrentFeatureMicroArticleID(feature.properties.microArticleID || pmMicroArticleId)
      setCurrentFeatureId(featureId)
      setModalCoordinates({ lng, lat })
      setViewPopupVisibility(true)
      currentFeatureIdRef.current = featureId
    }
  }, [isEditRef.current, isEdit, getIsFormPopupVisible, pmMicroArticleId])

  const deleteCurrentFeature = useCallback(() => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    // 1. Remove the feature from the in-memory features array
    function removeFeatureById(featureId) {
      const superCluster = getAllFeaturesOfSuperCluster();

      // Loop through the array backwards to avoid index issues when removing elements
      for (let i = superCluster.length - 1; i >= 0; i--) {
        if (superCluster[i].properties.id === featureId) {
          superCluster.splice(i, 1);
        }
      }
    }

    removeFeatureById(featureId);

    // 2. Reload Supercluster with the updated features
    if (superCluster) { // Ensure superCluster is available
      superCluster.load(getAllFeaturesOfSuperCluster());
    }
    if(getMicroArticlesSource()) {
      getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
    }

    const pulseSource = map.getSource('pulse-source')
    if (pulseSource && pulsingFeatureRef.current?.featureId === featureId) {
      pulseSource.setData({
        type: 'FeatureCollection',
        features: [],
      })
      pulsingFeatureRef.current = null
    }

    currentFeatureIdRef.current = null
    setFormPopupVisibility(false)
    setModalCoordinates({ lng: 0, lat: 0 })
    setViewPopupVisibility(false)
    setActiveMicroArticleId(null) // Reset active ID on delete
  }, [map, superCluster, getAllFeaturesOfSuperCluster()])

  const handleCoordinatesChange = useCallback(({ lat, lng }) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === featureId && f.properties.isMicroArticle,
    )

    if (feature) {
      feature.geometry.coordinates = [lng, lat]
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }

      if (
          pulsingFeatureRef.current &&
          pulsingFeatureRef.current.featureId === featureId
      ) {
        pulsingFeatureRef.current.coordinates = [lng, lat]
      }

      map.flyTo({ center: [lng, lat] })
    }

    setModalCoordinates({ lng, lat })
  }, [])

  const handleTitleChange = useCallback((title) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === featureId && f.properties.isMicroArticle,
    )
    if (feature) {
      feature.properties.title = title
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }
    }
  }, [])

  const handleColorChange = useCallback((color) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === featureId && f.properties.isMicroArticle,
    )
    if (feature) {
      feature.properties.backgroundColor = color
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }

      if (
          pulsingFeatureRef.current &&
          pulsingFeatureRef.current.featureId === featureId
      ) {
        pulsingFeatureRef.current.backgroundColor = color
      }
    }
  }, [])

  const handleIconChange = useCallback((iconKey, webIconKey) => {
    const featureId = currentFeatureIdRef.current
    if (!featureId) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === featureId && f.properties.isMicroArticle,
    )

    if (feature) {
      feature.properties.iconKey = isIconKeyValid(iconKey) ? iconKey : DEFAULT_ICON_SPRITE_NAME;
      feature.properties.webIconKey = isIconKeyValid(webIconKey) ? webIconKey : DEFAULT_ICON_SPRITE_NAME;
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }
    }
  }, [])

  const onDragMove = useCallback((e) => {
    if (!draggingFeatureIdRef.current) return

    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === draggingFeatureIdRef.current && f.properties.isMicroArticle,
    )
    if(!draggingFeatureIdx.current) {
      draggingFeatureIdx.current = allFeaturesOfSuperClusterRef.current.findIndex(
          (f) => f.properties.id === draggingFeatureIdRef.current && f.properties.isMicroArticle,
      )
    }

    if(draggingFeatureIdx.current) {
      getAllFeaturesOfSuperCluster()[draggingFeatureIdx.current].geometry.coordinates = [e.lngLat.lng, e.lngLat.lat]
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }

      if (
          pulsingFeatureRef.current &&
          pulsingFeatureRef.current.featureId === feature.properties.id
      ) {
        pulsingFeatureRef.current.coordinates = [e.lngLat.lng, e.lngLat.lat]
      }

      return;
    }

    if (feature) {
      feature.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat]
      if (getMicroArticlesSource()) {
        getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
      }

      if (
          pulsingFeatureRef.current &&
          pulsingFeatureRef.current.featureId === feature.properties.id
      ) {
        pulsingFeatureRef.current.coordinates = [e.lngLat.lng, e.lngLat.lat]
      }
    }
  }, [draggingFeatureIdRef.current, draggingFeatureIdx.current])

  const onDragEnd = useCallback((e) => {
    const feature = allFeaturesOfSuperClusterRef.current.find(
        (f) => f.properties.id === draggingFeatureIdRef.current && f.properties.isMicroArticle,
    )

    if (feature) {
      startPulse(feature)
    }

    if (draggingFeatureIdRef.current) {
      draggingFeatureIdRef.current = null
      map.getCanvas().style.cursor = ''
    }

    if(draggingFeatureIdx.current) {
      draggingFeatureIdx.current = null
    }

    map.setLayoutProperty(
        `${MICRO_ARTICLE_LAYER}-labels`,
        'visibility',
        'visible',
    )

    setModalCoordinates({ lng: e.lngLat.lng, lat: e.lngLat.lat })
    map.dragPan.enable()
    map.off('mousemove', onDragMove)
    map.flyTo({
      center: [e.lngLat.lng, e.lngLat.lat],
    })
  }, [draggingFeatureIdRef.current, draggingFeatureIdx.current])

  const onDragStart = useCallback((e) => {
    if (!getIsFormPopupVisibleRef.current) return

    const features = map.queryRenderedFeatures(e.point, {
      layers: [MICRO_ARTICLE_BACKGROUND_LAYER],
    })

    if (!features.length) return

    const selectedFeatureId = pulsingFeatureRef.current
        ? pulsingFeatureRef.current.featureId
        : null

    if(selectedFeatureId && selectedFeatureId !== features[0].properties.id) return

    const feature = features[0]
    const featureId = feature.properties.id

    stopPulse()

    if (featureId !== selectedFeatureId) {
      return
    }

    map.dragPan.disable()

    draggingFeatureIdRef.current = featureId
    map.getCanvas().style.cursor = 'grabbing'

    map.setLayoutProperty(`${MICRO_ARTICLE_LAYER}-labels`, 'visibility', 'none')

    map.on(isMobile ? 'touchmove' : 'mousemove', onDragMove)
    map.once(isMobile ? 'touchend' : 'mouseup', onDragEnd)
  }, [])

  const onPopupClose = useCallback((isSave, isCreate, isDelete) => {
    if (!isSave) {
      const featureId = currentFeatureIdRef.current
      if (featureId && oldFeaturePropertiesRef.current) {
        const feature = allFeaturesOfSuperClusterRef.current.find(
            (f) => f.properties.id === featureId && f.properties.isMicroArticle,
        )

        if (feature) {
          feature.geometry.coordinates = [
            ...oldFeaturePropertiesRef.current.coordinates,
          ]
          feature.properties = {
            ...oldFeaturePropertiesRef.current,
          }

          if (getMicroArticlesSource()) {
            getMicroArticlesSource()._updateWorkerData(getAllFeaturesOfSuperCluster())
          }

          if (
              pulsingFeatureRef.current &&
              pulsingFeatureRef.current.featureId === featureId
          ) {
            pulsingFeatureRef.current.coordinates = [
              ...oldFeaturePropertiesRef.current.coordinates,
            ]
            pulsingFeatureRef.current.backgroundColor =
                oldFeaturePropertiesRef.current.backgroundColor
          }

          setModalCoordinates({
            lng: feature.geometry.coordinates[0],
            lat: feature.geometry.coordinates[1],
          })
        }
      }
    }

    // if(isCreate) stopPulse();

    setFormPopupVisibility(false)
    setIsEdit(false)
    if(!isDelete) setViewPopupVisibility(true);
    // setCurrentFeatureId(null)
    // setCurrentFeatureMicroArticleID(null)
    // dispatch(setMicroArticlePresentation(null))
    // if(isCreate) stopPulse()
    // setActiveMicroArticleId(null) // Reset active ID when closing the form popup

    if (pmMicroArticleId) {
      setViewPopupVisibility(true);
      setCurrentFeatureId("none");
      setCurrentFeatureMicroArticleID(pmMicroArticleId);
      setActiveMicroArticleId(pmMicroArticleId);
    }

  }, [isEdit,pmMicroArticleId, isEditRef.current])

  const onViewPopupClose = useCallback(() => {
    setViewPopupVisibility(false)
    stopPulse()
    setModalCoordinates({ lng: 0, lat: 0 })
    setIsEdit(false)
    setCurrentFeatureId(null)
    setCurrentFeatureMicroArticleID(null)
    dispatch(setMicroArticlePresentation(null))
    setActiveMicroArticleId(null) // Reset active ID when closing the view popup
  }, [])

  const closeAndDeactivateAll = useCallback(() => {
    setViewPopupVisibility(false)
    setFormPopupVisibility(false)
    setIsEdit(false)
    stopPulse()
    setCurrentFeatureId(null)
    setCurrentFeatureMicroArticleID(null)
    setActiveMicroArticleId(null) // Reset active ID when closing the view popup
    setModalCoordinates({ lng: 0, lat: 0 })
  }, [pulsingFeatureRef.current])

  useEffect(() => {
    if(getCloseAndDeactivateAllActivator) {
      closeAndDeactivateAll();
      setCloseAndDeactivateAllMicroArticlesActivator(false);
    }
  }, [getCloseAndDeactivateAllActivator])

  useEffect(() => {
    if(getIsFormPopupVisible || getIsViewPopupVisible) {
      closeArticlePopup('all');
    }
  }, [getIsFormPopupVisible, getIsViewPopupVisible])

  useEffect(() => {
    map.on(isMobile ? 'touchstart' : 'mousedown', MICRO_ARTICLE_BACKGROUND_LAYER, onDragStart)
    map.on(isMobile ? 'touchend' : 'click', MICRO_ARTICLE_BACKGROUND_LAYER, onFeatureClick)

    return () => {
      map.off(isMobile ? 'touchstart' : 'mousedown', MICRO_ARTICLE_BACKGROUND_LAYER, onDragStart)
      map.off(isMobile ? 'touchmove' : 'mousemove', onDragMove)
      map.off(isMobile ? 'touchend' : 'mouseup', onDragEnd)
      map.off(isMobile ? 'touchend' : 'click', MICRO_ARTICLE_BACKGROUND_LAYER, onFeatureClick)
      stopPulse()
    }
  }, [])

  useEffect(() => {
    if (getMicroArticleBarClickedState && getMicroArticleToolClickedState) {
      setTimeout(() => {
        changeCursorIcon(CURSOR_TYPE.MICRO_ARTICLE)
      }, 50)
      map.on(isMobile ? 'touchend' : 'click', mapClickHandler)
    } else {
      changeCursorIcon()
      map.off(isMobile ? 'touchend' : 'click', mapClickHandler)
      setMicroArticleToolClickedState(false)
    }
  }, [getMicroArticleBarClickedState, getMicroArticleToolClickedState])

  useEffect(() => {
    if (!globalLoading && activeMicroArticleId || pmMicroArticleId) {
      const feature = allFeaturesOfSuperClusterRef.current.find(
          (f) =>
              String(f.properties.microArticleID) === String(pmMicroArticleId || activeMicroArticleId) && f.properties.isMicroArticle,
      )
      runFeature(feature)
    }
  }, [activeMicroArticleId, globalLoading, pmMicroArticleId])

  useEffect(() => {
    if (searchParams.get("presentation") && !searchParams.get("microArticleId") && !searchParams.get("microArticleMode")) {
      onViewPopupClose()
    }
  },[searchParams])

  return (
      <>
        <div
            className={`pain_items micro-article-button ${
                getMicroArticleToolClickedState ? 'button_active' : ''
            }`}
            onClick={() => {
              if(isScreenShotMode || isScreenShot) return;
              setMicroArticleToolClickedState(!getMicroArticleToolClickedState)
            }}
            style={{
              marginLeft: '7px',
              opacity: (isScreenShotMode || isScreenShot) ? 0.7 : 1,
            }}>
          <img
              src={MicroArticleToolIcon}
              alt="Micro Article Tool"
              className="icon_img"
          />
        </div>

        {getIsFormPopupVisible && (
            <Portal containerSelector={isMobile ? '.map-boby' : '.main'} className={isMobile ? 'full-screen-form-popup' : ['fixed', 'top-[52px]', 'right-0', '!z-[99999999995]']}>
              {
                isMobile ?
                    <PopupFormMobile
                        isEdit={isEdit}
                        map={map}
                        isVisible={getIsFormPopupVisible}
                        onClose={onPopupClose}
                        onCreateSuccess={handleCreateSuccess}
                        handleTitleChange={handleTitleChange}
                        onSave={handleModalSave}
                        coordinates={modalCoordinates}
                        onPopupClose={onPopupClose}
                        handleDeleteMicroArticle={deleteCurrentFeature}
                        onCoordinatesChange={handleCoordinatesChange}
                        featureMicroArticleID={currentFeatureMicroArticleID}
                        initialColor={(initialColor())}
                        initialIconKey={(initialIconKey())}
                        onColorChange={handleColorChange}
                        onIconChange={handleIconChange}
                    />
                    :
                    <PopupForm
                        isEdit={isEdit}
                        map={map}
                        isVisible={getIsFormPopupVisible}
                        onClose={onPopupClose}
                        onCreateSuccess={handleCreateSuccess}
                        handleTitleChange={handleTitleChange}
                        onSave={handleModalSave}
                        coordinates={modalCoordinates}
                        onPopupClose={onPopupClose}
                        handleDeleteMicroArticle={deleteCurrentFeature}
                        onCoordinatesChange={handleCoordinatesChange}
                        featureMicroArticleID={currentFeatureMicroArticleID}
                        featureId={currentFeatureId}
                        initialColor={(initialColor())}
                        initialIconKey={(initialIconKey())}
                        onColorChange={handleColorChange}
                        onIconChange={handleIconChange}
                    />
              }

            </Portal>
        )}

        {getIsViewPopupVisible && currentFeatureId && (
            <ViewPopup
                key={currentFeatureId}
                map={map}
                changeIsModalVisible={changeIsModalVisible}
                changeEditMode={changeEditMode}
                changeIsViewPopupVisible={changeIsViewPopupVisible}
                featureId={currentFeatureId}
                featureMicroArticleID={currentFeatureMicroArticleID}
                isVisible={getIsViewPopupVisible}
                onClose={onViewPopupClose}
            />
        )}
      </>
  )
}

const mapStateToProps = (state) => ({
  getMicroArticleData: getMicroArticleData(state),
  getMicroArticleToolClickedState: getMicroArticleToolClickedState(state),
  getMicroArticleBarClickedState: getMicroArticleBarClickedState(state),
  getAllMicroArticles: getAllMicroArticles(state),
  getMapStyledId: getMapStyledId(state),
  activeMicroArticleId: state.microArticle.activeMicroArticleId,
  getIsViewPopupVisible: getIsViewPopupVisible(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
  getShowArticlePopup: getShowArticlePopup(state),
  articleData: getArticlesData(state),
  getCloseAndDeactivateAllActivator: getCloseAndDeactivateAllActivator(state),
  isScreenShotMode: getIsScreenShotMode(state),
  microArticleIdFromScreenshot: getActiveMicroArticleIdST(state),

})

const mapDispatchToProps = {
  setMicroArticleToolClickedState,
  setAllMicroArticles,
  setActiveMicroArticleId,
  setViewPopupVisibility,
  setFormPopupVisibility,
  setShowArticlePopup,
  setArticlesAll,
  closeArticlePopup,
  setCloseAndDeactivateAllMicroArticlesActivator,
  setStoreFeatureMicroArticleId:setFeatureMicroArticleId,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(memo(MicroArticleTool))
