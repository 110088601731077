import React from 'react'
import { SplideSlide } from '@splidejs/react-splide'
import VideoSlide from './VideoSlide'

const Slides = ({
  galleryItems,
  convertToNoCookie,
  currentIndex,
}) => {
  const renderSlideContent = (slide) => {
    switch (slide.type) {
      case 0:
        return <img src={slide?.path} alt={slide.caption} />
      case 1:
        return (
          <video
            className="is-video"
            data-item={true}
            src={slide?.path}
            controls
          />
        )
      case 3:
        return (
          <VideoSlide
            currentIndex={currentIndex}
            convertToNoCookie={convertToNoCookie}
            slide={slide}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {galleryItems
        ?.filter((x) => x.type !== 4)
        ?.map((slide) => (
          <SplideSlide
            key={slide.mediaId}
            className={`gallery-item single-gallery ${slide.mediaId}`}>
            {renderSlideContent(slide)}
          </SplideSlide>
        ))}
    </>
  )
}

export default Slides
