// src/components/PopupDetail.js
import React, { useEffect, useState, useRef, useCallback } from 'react'
import {
  message,
  Modal,
} from 'antd'
import Portal from '../Portal' // Adjust import path
import { getYouTubeEmbedURL } from '../../utils' // Adjust import path
import styles from './PopupDetail.module.css' // Ensure this CSS is updated

import {
  setIsSingleMicroArticleLoading,
  setMicroArticleData,
  setPopupDetailFontSize,
  setViewPopupVisibility,
} from '../../../../store/actions/microArticle'
import {
  getGalleryFullScreen, getGalleryIndex, getGalleryTiliIndex,
  getIsFormPopupVisible,
  getIsSingleMicroArticleLoading,
  getMicroArticleData, getMicroArticleSt,
  getPopupDetailFontSize,
  getScreenShotLoadingST, getSelectedMapStyle,
} from '../../../../store/selectors'
import { connect, useSelector } from 'react-redux'

import GalleryNew from '../../../gallery-new/UI'
import { MicroArticleService } from '../../service'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PresentationsSelector from '../../../presentation/ui/PresentationsSelector'
import { useMapStateImportToSlideMutation } from '../../../presentation/store/presentationApi'
import { selectMicroArticlePresentation } from '../../../presentation/store/presentationSlice'
import { stringify } from 'flatted'
import { generateSiteUrl } from '../../../presentation/lib/utils'
import CardDesktopVersion from "./CardDesktopVersion";
import CardMobileVersion from "./CardMobileVersion";
import {isMobile} from "react-device-detect";
import {setMicroArticleStateST} from "../../../../store/actions/mapStateAction";
import usePrevious from "../../../../hooks/usePrevious";


const PopupDetail = ({
  featureId,
  featureMicroArticleID,
  isVisible,
  onClose,
  map,
  changeEditMode,
  changeIsViewPopupVisible,
  changeIsModalVisible,
  getMicroArticleData,
  setMicroArticleData,
  getScreenShotLoadingST,
  getIsSingleMicroArticleLoading,
  setIsSingleMicroArticleLoading,
  getPopupDetailFontSize,
  setPopupDetailFontSize,
  microArticleStateST,
  setMicroArticleStateST,
  galleryFullScreen,
  galleryIndex,
  galleryTiliIndex,
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const carouselRef = useRef(null)
  const carouselContainerRef = useRef(null) // Ref for carousel container
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isMaximized, setIsMaximized] = useState(true)
  const [isGalleryVisible, setIsGalleryVisible] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isOwnerOfMicroArticle, setIsOwnerOfMicroArticle] = useState(false);
  const [microArticleFirstImageIdx, setMicroArticleFirstImageIdx] =
    useState(null)
  const [presentationsIsOpen, setPresentationsIsOpen] = useState(false)

  const [microArticleOwner, setMicroArticleOwner] = useState({
    firstName: '',
    lastName: '',
    id: null,
  })
  const { t } = useTranslation()

  const previousMicroArticleST = usePrevious(microArticleStateST)

  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const [onImportToSlide] = useMapStateImportToSlideMutation()
  const microArticlePresentation = useSelector(selectMicroArticlePresentation)
  const activePresentation = searchParams.get('presentation') && {
    id: +searchParams.get('presentation'),
  }
  const [presentations, setPresentations] = useState([])
  const isPreview = searchParams.get('preview')
  const screenSlide = searchParams.get('screenSlide')

  const selectedMapStyle = useSelector(getSelectedMapStyle) || {};

  const mapStyledId = selectedMapStyle?.[selectedMapStyle.active];
  const baseMapGroupId = selectedMapStyle?.id;
  const baseMapIsLightSt = selectedMapStyle?.active === 'light';

  // For drag detection:
  const isDraggingRef = useRef(false)
  const dragStartX = useRef(0)
  const dragStartY = useRef(0)
  const dragThreshold = 5
  const ignoreClickRef = useRef(false)

  // --- Fetch micro article details when visible ---
  useEffect(() => {
    if (isVisible && featureId) {
      setIsSingleMicroArticleLoading(true)
      setMicroArticleStateST({});
      setLoading(true)
      MicroArticleService.getMicroArticleByID(featureMicroArticleID)
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            const createdBy = response?.data?.data[0]?.createdBy
            if (createdBy) {
              return axios
                .post(
                  `${process.env.REACT_APP_IDENTITY_URL}/api/User/GetUsersData`,
                  {
                    userIds: [createdBy],
                  },
                )
                .then((userInfo) => {
                  if (userInfo.status === 200 || userInfo.status === 204) {
                    const ownerData = userInfo.data.data[0]
                    if (ownerData && ownerData.length > 0) {
                      setMicroArticleOwner(ownerData[0])
                    }
                  } else {
                    message.error(
                      t('microArticle.messages.error.ownerInfoFailed'),
                    )
                  }
                  setMicroArticleData(response.data.data[0])
                })
                .catch(() => {
                  message.error(
                    t('microArticle.messages.error.ownerInfoFailed'),
                  )
                  setMicroArticleData(response.data.data[0])
                })
            } else {
              setMicroArticleData(response.data.data[0])
            }
          } else {
            setError(t('microArticle.messages.error.loadData'))
          }
        })
        .catch(() => {
          setError(t('microArticle.messages.error.loadData'))
        })
        .finally(() => {
          setLoading(false)
          setIsSingleMicroArticleLoading(false)
        })
    } else {
      // Cleanup
      setMicroArticleData(null)
      setMicroArticleOwner({ firstName: '', lastName: '', id: null })
      setError(null)
      setCurrentSlide(0)
    }
    return () => {
      setIsSingleMicroArticleLoading(false)
      setError(null)
      setCurrentSlide(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureMicroArticleID, featureId, isVisible])

  /**
   * "handleAfterChange" — whenever the carousel changes slide
   * (via swipe, arrow, etc.), we set currentSlide so that our Dots match.
   */
  const handleAfterChange = useCallback((newSlideIndex) => {
    setCurrentSlide(newSlideIndex)
    setMicroArticleStateST({
      slideIndex: newSlideIndex,
    })
  }, [])

  useEffect(() => {
    // if(carouselRef.current?.innerSlider?.state.currentSlide === currentSlide+1) return
     carouselRef.current?.goTo(currentSlide, true)
  }, [currentSlide])

  /**
   * "handleDotClickOrSwipe" — user clicked a dot => we move the carousel.
   * The carousel then calls afterChange(...) => state is updated => Dots are in sync.
   */
  const handleDotClickOrSwipe = useCallback((index) => {
    if (carouselRef.current) {
      carouselRef.current.goTo(index, false)
    }
  }, [])

  useEffect(() => {
    // Used for set the owner status of MA, for disabling or enabling the action buttons of it.
    if(getMicroArticleData) {
      setIsOwnerOfMicroArticle(Number(getMicroArticleData.createdBy) === Number(JSON.parse(localStorage.getItem('userInfo'))?.data?.id) && !isPreview)
    }
  }, [getMicroArticleData, isPreview])
  useEffect(() => {


    if (microArticleStateST.isMaximized !== undefined) {
       setIsMaximized(!!microArticleStateST.isMaximized)
     }
    if (microArticleStateST.slideIndex !== undefined && !galleryIndex) {
       setCurrentSlide(microArticleStateST.slideIndex || 0)
     }

     if (galleryIndex && microArticleStateST?.isGalleryVisible) {
       setCurrentSlide(galleryIndex || 0)
     }
    if (microArticleStateST.viewFontSize !== undefined) {
       setPopupDetailFontSize(microArticleStateST.viewFontSize || 14)
     }

     setTimeout(() =>  setIsGalleryVisible(!!microArticleStateST?.isGalleryVisible))

  },[microArticleStateST,galleryIndex, isGalleryVisible])


  useEffect(() => {
    setIsFullScreen(galleryFullScreen)
  }, [galleryFullScreen]);

  // --- When article data arrives, jump to the first image automatically ---
  useEffect(() => {
    const cantWork = !!getIsSingleMicroArticleLoading
    if (cantWork) return
    setMicroArticleFirstImageIdx(null)
    if (getMicroArticleData && getMicroArticleData?.media) {
      for (let index = 0; index < getMicroArticleData?.media.length; index++) {
        const item = getMicroArticleData?.media[index]
        if (item.type === 'Image') {
          // Set our local "currentSlide" state
          setCurrentSlide(index)
          // Move the carousel there (one-time jump)
          setTimeout(() =>  carouselRef.current?.goTo?.(index, true), 150)
          setMicroArticleFirstImageIdx(index)
          break
        }
      }
    }
  }, [getMicroArticleData, getIsSingleMicroArticleLoading, isMaximized])

  // Font size controls
  const increaseFontSize = () => {
    setPopupDetailFontSize(Math.min(getPopupDetailFontSize + 2, 20))
    setMicroArticleStateST({
      viewFontSize: Math.min(getPopupDetailFontSize + 2, 20),
    })
  }

  const decreaseFontSize = () => {
    setPopupDetailFontSize(Math.max(getPopupDetailFontSize - 2, 14))
    setMicroArticleStateST({
      viewFontSize: Math.max(getPopupDetailFontSize - 2, 14),
    })
  }

  const togglePopupSize = () => {
    setIsMaximized((prev) => {
      const value = !prev
      setMicroArticleStateST({ isMaximized: value })
      return value
    })
  }

  const changeToEditMode = () => {
    changeIsViewPopupVisible(false);
    changeEditMode(true);
    changeIsModalVisible(true);
    if (searchParams.get('screenSlide') && searchParams.get('playBack')) {
      searchParams.set('playBack', 'false');
    }
    navigation(
      {
        search: searchParams.toString(),
        pathname: window.location.pathname,
      },
      { replace: true },
    )
  }

  // Gallery open/close
  const handleOpenGallery = () => {
    setIsGalleryVisible(true)
    setMicroArticleStateST({
      isGalleryVisible: true,
    })
  }
  const toggleGallery = () => {
    setIsGalleryVisible((prev) => {
      const value = !prev;
      setMicroArticleStateST({ isGalleryVisible: value })
      return value;
    })
  }

  // --------------------- Draggable logic on the carousel (optional) ---------------------
  const handleMouseDown = (e) => {
    isDraggingRef.current = false
    dragStartX.current = e.clientX
    dragStartY.current = e.clientY
    ignoreClickRef.current = false
    if (carouselContainerRef.current) {
      carouselContainerRef.current.classList.add(styles.grabbing)
    }
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = (e) => {
    const dx = Math.abs(e.clientX - dragStartX.current)
    const dy = Math.abs(e.clientY - dragStartY.current)
    if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
      isDraggingRef.current = true
      ignoreClickRef.current = true
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.add(styles.grabbing)
      }
    }
  }

  const handleMouseUp = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing)
      }
    } else {
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing)
      }
    }
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  const handleTouchStart = (e) => {
    isDraggingRef.current = false
    const touch = e.touches[0]
    dragStartX.current = touch.clientX
    dragStartY.current = touch.clientY
    ignoreClickRef.current = false
    if (carouselContainerRef.current) {
      carouselContainerRef.current.classList.add(styles.grabbing)
    }
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchMove = (e) => {
    const touch = e.touches[0]
    const dx = Math.abs(touch.clientX - dragStartX.current)
    const dy = Math.abs(touch.clientY - dragStartY.current)
    if (!isDraggingRef.current && (dx > dragThreshold || dy > dragThreshold)) {
      isDraggingRef.current = true
      ignoreClickRef.current = true
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.add(styles.grabbing)
      }
    }
  }

  const handleTouchEnd = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing)
      }
    } else {
      if (carouselContainerRef.current) {
        carouselContainerRef.current.classList.remove(styles.grabbing)
      }
    }
    window.removeEventListener('touchmove', handleTouchMove)
    window.removeEventListener('touchend', handleTouchEnd)
  }
  // --------------------------------------------------------------------------------------

  const changePresentationsHandler = (presentations) => {
    presentations.forEach((presentation) => {
      const presentationId = presentation.id
      const payload = getMicroArticleData
      const topicID = payload?.topicIds?.[0] || searchParams.get('topicId')
      const lessonID = payload?.lessonIds?.[0] || searchParams.get('lessonId')
      onImportToSlide({
        presentationId,
        microArticlesData: [
          {
            id: featureMicroArticleID,
            languageId: payload.languageId,
            title: payload.title,
            topicId: topicID,
            lessonId: lessonID,
            state: stringify({
              getMapStyledId: mapStyledId,
              baseMapGroupIdST: baseMapGroupId,
              baseMapIsLight: baseMapIsLightSt,
              microArticleData: payload,
              url: generateSiteUrl({
                presentationId,
                microArticleMode: featureMicroArticleID,
                topicId: topicID,
                lessonId: lessonID,
              }),
            }),
          },
        ],
      })
    })
    setPresentationsIsOpen(false)
  }

  if (!isVisible) return null

  // Prepare items for GalleryNew
  const galleryItems = getMicroArticleData?.media?.map((item) => {
    let type
    let path = item.path
    if (item.type.trim().toLowerCase() === 'url') {
      // is it YouTube?
      const isYouTube = /youtube\.com|youtu\.be/.test(item.path)
      if (isYouTube) {
        type = 3 // iframe
        path = getYouTubeEmbedURL(item.path)
      } else {
        // some other URL => still treat as type=3 (iframe, e.g.)
        type = 3
      }
    } else {
      switch (item.type.trim().toLowerCase()) {
        case 'image':
          type = 0
          break
        case 'video':
          type = 1
          break
        case 'iframe':
          type = 3
          break
        default:
          type = -1
      }
    }
    return {
      ...item,
      type,
      path,
    }
  })
  return (
    <>
      {
        isMobile ?
        (
            <CardMobileVersion
                isOwner={isOwnerOfMicroArticle}
                isMaximized={isMaximized}
                getMicroArticleData={getMicroArticleData}
                getIsSingleMicroArticleLoading={getIsSingleMicroArticleLoading}
                microArticleOwner={microArticleOwner}
                togglePopupSize={togglePopupSize}
                setMicroArticleFirstImageIdx={setMicroArticleFirstImageIdx}
                onClose={onClose}
                microArticleFirstImageIdx={microArticleFirstImageIdx}
                loading={loading}
                error={error}
                carouselContainerRef={carouselContainerRef}
                handleMouseDown={handleMouseDown}
                handleTouchStart={handleTouchStart}
                carouselRef={carouselRef}
                handleAfterChange={handleAfterChange}
                ignoreClickRef={ignoreClickRef}
                handleOpenGallery={handleOpenGallery}
                currentSlide={currentSlide}
                handleDotClickOrSwipe={handleDotClickOrSwipe}
                getPopupDetailFontSize={getPopupDetailFontSize}
                increaseFontSize={increaseFontSize}
                decreaseFontSize={decreaseFontSize}
                setPresentationsIsOpen={setPresentationsIsOpen}
                changeToEditMode={changeToEditMode}
            />
        )
        :
        (
            <CardDesktopVersion
                isOwner={isOwnerOfMicroArticle}
                isMaximized={isMaximized}
                getMicroArticleData={getMicroArticleData}
                getIsSingleMicroArticleLoading={getIsSingleMicroArticleLoading}
                microArticleOwner={microArticleOwner}
                togglePopupSize={togglePopupSize}
                setMicroArticleFirstImageIdx={setMicroArticleFirstImageIdx}
                onClose={onClose}
                microArticleFirstImageIdx={microArticleFirstImageIdx}
                loading={loading}
                error={error}
                carouselContainerRef={carouselContainerRef}
                handleMouseDown={handleMouseDown}
                handleTouchStart={handleTouchStart}
                carouselRef={carouselRef}
                handleAfterChange={handleAfterChange}
                ignoreClickRef={ignoreClickRef}
                handleOpenGallery={handleOpenGallery}
                currentSlide={currentSlide}
                handleDotClickOrSwipe={handleDotClickOrSwipe}
                getPopupDetailFontSize={getPopupDetailFontSize}
                increaseFontSize={increaseFontSize}
                decreaseFontSize={decreaseFontSize}
                setPresentationsIsOpen={setPresentationsIsOpen}
                changeToEditMode={changeToEditMode}
            />
        )
      }

      {/* Fullscreen Gallery */}
      {isGalleryVisible && getMicroArticleData && (
        <Portal containerSelector="#root" className={['fixed', 'top-0', 'right-0', '!z-[9999999999999]']}>
          <GalleryNew
            isFromMicroArticle
            isGrid={galleryTiliIndex !== null}
            mapState={map}
            feauturedImageIdx={currentSlide}
            title={getMicroArticleData?.title}
            feauturedTiliPageIdx={galleryTiliIndex}
            gallery={isGalleryVisible}
            toggleGallery={toggleGallery}
            clickedMediaImage={getMicroArticleData?.media[currentSlide]?.path}
            feauturedImage={getMicroArticleData?.media[currentSlide]?.path}
            galleryItems={galleryItems}
            fromArticle={false}
            isFullScreen={isFullScreen}
            setGalleryFullScreen={setIsFullScreen}
            articleId={getMicroArticleData?.id}
            screenShotFirstLoad={getScreenShotLoadingST}
          />
        </Portal>
      )}

      {/* Modal with PresentationsSelector */}
      <Modal
        destroyOnClose
        title={t('presentation_mode.presentations')}
        centered
        classNames={{
          header:
            'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
        }}
        className="app-modal max-h-[90vh]"
        wrapClassName="!z-[1240]"
        width={950}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setPresentationsIsOpen(false)}
        open={presentationsIsOpen}>
        <PresentationsSelector
          onSelect={changePresentationsHandler}
          data={presentations}
          defaultSelected={activePresentation}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  getMicroArticleData: getMicroArticleData(state),
  getScreenShotLoadingST: getScreenShotLoadingST(state),
  getIsSingleMicroArticleLoading: getIsSingleMicroArticleLoading(state),
  getPopupDetailFontSize: getPopupDetailFontSize(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
  microArticleStateST: getMicroArticleSt(state),
  galleryFullScreen: getGalleryFullScreen(state),
  galleryIndex: getGalleryIndex(state),
  galleryTiliIndex: getGalleryTiliIndex(state),
})

const mapDispatchToProps = {
  setMicroArticleData,
  setIsSingleMicroArticleLoading,
  setPopupDetailFontSize,
  setViewPopupVisibility,
  setMicroArticleStateST
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(PopupDetail))
