import saveUserToLocalStorage from "./saveToLocalStorage";
import {identityStoreApi} from "../api/identityStoreApi";
import {indexingStoreApi} from "../api/indexingStoreApi";
import {articleStoreApi} from "../api/articleStoreApi";
import {mediaContentStoreApi} from "../api/mediaContentStoreApi";

const middlewares = [saveUserToLocalStorage,  identityStoreApi.middleware,
    indexingStoreApi.middleware,
    articleStoreApi.middleware,
    mediaContentStoreApi.middleware
];

export default middlewares;
