//generate circle outline plus icon component

import React from 'react'

const MapsIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.29395 34.8314C6.29395 38.3939 8.8252 39.8564 11.9002 38.0939L16.3064 35.5814C17.2627 35.0377 18.8564 34.9814 19.8502 35.4877L29.6939 40.4189C30.6877 40.9064 32.2814 40.8689 33.2377 40.3252L41.3564 35.6752C42.3877 35.0752 43.2502 33.6127 43.2502 32.4127V14.1689C43.2502 10.6064 40.7189 9.14395 37.6439 10.9064L33.2377 13.4189C32.2814 13.9627 30.6877 14.0189 29.6939 13.5127L19.8502 8.6002C18.8564 8.1127 17.2627 8.1502 16.3064 8.69395L8.1877 13.3439C7.1377 13.9439 6.29395 15.4064 6.29395 16.5877V34.8314Z"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0498 9.5V33.875"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4941 14.4126V39.5001"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 47L2 2L47 2L47 47L2 47Z"
        stroke="#2C476C"
        strokeWidth="3.3"
      />
    </svg>
  )
}

export default MapsIcon
