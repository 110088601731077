import { connect } from "react-redux";
import "./style.css";
import React from "react";
import {getStringFromHTML} from "../../../presentation/lib/utils";

const PresentationInfo = (props) => {
    const { content = {},children } = props;
    return (
        <div className="card-body__presentation-info presentation-info">
            {children}
            <span
                style={{
                    wordBreak: "break-all",
                }}
                className="presentation_body_content"
            >
                {content?.shortDescription || getStringFromHTML(content?.description)}
        </span>
        </div>
    );
};
export default connect(null, null)(PresentationInfo);
