import React from 'react'
// import {storeV2 as store} from "../store/storeConfig";
import {ConfigProvider} from 'antd'
import {Provider} from 'react-redux'

import {store} from '../../../store/index'

const PresentationProvider = ({ children }) => {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          hashed: false,
            token: {
                fontFamily: 'Noto Sans Armenian, sans-serif',
            },
          components: {
            Dropdown: {},
          },
        }}>
        {children}
      </ConfigProvider>
    </Provider>
  )
}

export default PresentationProvider
