import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import Eye from '../../../UI/Eye'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'
import Sort from '../../../UI/Sort'
import { useTranslation } from 'react-i18next'
import withRedux from '../../mapper'
import MicroArticlesIcon from '../../../UI/MicroArticlesIcon'
import { useDispatch } from 'react-redux'
import {
  setActiveMicroArticleId,
  setAllMicroArticles,
} from '../../../../store/actions/microArticle'
import {
  getContrastColor,
  INITIAL_ICON_OBJECT,
  isIconKeyValid,
} from '../../../microArticle/utils'
import {BRAINOGRAPH_PIN_GIS_API} from "../../../../pages/newMap/utils/featureHelpers";
import {useSearchParams} from "react-router-dom";
import {setMicroArticleStateST} from "../../../../store/actions/mapStateAction";
import usePrevious from "../../../../hooks/usePrevious";

const color = '#036147'

const MicroArticleTab = ({
  getAllMicroArticles,
  map,
  getAllFeaturesOfSuperCluster,
  globalLoading,
  sortBoard,
  toggleSorting,
  stopPinAnimation,
  browserInfo,
  microArticleTabOpen,
  setMicroArticleTabOpen,
  setSelectedCategoryId,
  // getActiveMicroArticleId,
   getMicroArticleStateST,
  setMicroArticleStateST,
  filterAction,
  setQuizPopup,
  setHints,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const tabRef = useRef(null)
  const title = t('microArticle.userDashboard.sectionName')
  const [searchParams] = useSearchParams()
  const isPreview = searchParams.get('preview')
  const STDataIsMounted = useRef(false)
  const STTimer = useRef(null)
  const screenShot = searchParams.get("screenShot")

  const previousMAState = usePrevious(getMicroArticleStateST)

  const sortArticles = (articles) => {
    if (filterAction === 'alphabetically') {
      return [...articles].sort((a, b) => a.title.localeCompare(b.title))
    } else if (filterAction === 'chronologically') {
      const sorted= [...articles].sort((a, b) => {
        if (a.startTimeIsBC !== b.startTimeIsBC) {
          return a.startTimeIsBC ? -1 : 1; // BC first, then AD
        }
        return a.startTimeIsBC
            ? b.startYear - a.startYear  // Descending for BC (earlier years later)
            : a.startYear - b.startYear; // Ascending for AD (earlier years first)
      });
      return sorted;
    }
    return articles
  }

  // Instead of keeping sortedMicroArticles in state (which might hold stale values),
  // we compute it on each render via useMemo so that any updates are immediately visible.
  const sortedMicroArticles = useMemo(
    () => sortArticles(getAllMicroArticles),
    [getAllMicroArticles, filterAction],
  )

  const calculateHeight = (element) => {
    if (!element) return 0
    const windowHeight = (window.innerHeight * 100) / 70
    const { top, height } = element.getBoundingClientRect()
    return Math.max(0, windowHeight - top - height - 135.5)
  }

  const handleTabClick = useCallback(() => {
    setSelectedCategoryId(null)
    setQuizPopup(false)
    setHints(false)
    setMicroArticleTabOpen((prev) => !prev)
  }, [setSelectedCategoryId, setMicroArticleTabOpen, setQuizPopup, setHints])

  const mainSubItemStyles = useMemo(() => {
    if (!tabRef.current) return
    return {
      height: 'fit-content',
      minHeight: '100px',
      maxHeight:
        window.innerWidth >= 900 && window.innerWidth <= 1800
          ? `${tabRef.current.height || calculateHeight(tabRef.current)}px`
          : `calc(100vh - 293px)`,
    }
  }, [tabRef.current])

  const mainSubItemsWrapperStyles = useMemo(() => {
    if (!tabRef.current) return
    return {
      flexDirection: 'column',
      height: 'fit-content',
      maxHeight:
        window.innerWidth >= 900 && window.innerWidth <= 1800
          ? `${
              (tabRef.current.height || calculateHeight(tabRef.current)) - 70 >
              0
                ? (tabRef.current.height || calculateHeight(tabRef.current)) -
                  70
                : 0
            }px`
          : browserInfo.isSafari && window.innerWidth > 1800
            ? `calc(100dvh - 657px)`
            : `calc(100dvh - 557px)`,
    }
  }, [tabRef.current, browserInfo.isSafari])

  const getMicroArticlesSource = () => map.getSource(BRAINOGRAPH_PIN_GIS_API);

  // Handle toggling all micro-articles
  const handleToggleAll = (fromST) => {
    if(!map) return;
    const microArticleIndexes = []
    const features =  getAllFeaturesOfSuperCluster();
    features?.forEach((ma, idx) => {
      ma.properties.isMicroArticle && microArticleIndexes.push(idx)
    })

    // FOR AVAILABLE IN SUPER CLUSTER
    const allVisible = !microArticleIndexes.some(
      (idx) => !features[idx].properties.show,
    )

    if (typeof fromST !== 'boolean' || !fromST) {
      setMicroArticleStateST({
        allHidden:allVisible,
        hiddenClicked: allVisible ? [] : getMicroArticleStateST.hiddenClicked
      })

      // if (!allVisible) {
      //   setMicroArticleStateST({
      //     hiddenClicked: [],
      //   })
      // }
    }


    microArticleIndexes.forEach((idx) => {
      features[idx].properties.show = !allVisible
      features[idx].properties.visible = features[idx].properties.show ? 'visible' :  'none'
    })
    const source = getMicroArticlesSource();

    if (source) {
      source._updateWorkerData(
          features,
      )
    }

    const updatedArticles = sortedMicroArticles.map((ma) => ({
      ...ma,
      show: !allVisible,
      visible: !allVisible ? 'visible' : 'none',
    }))
    dispatch(setAllMicroArticles(updatedArticles))
  }

  // Handle toggling a single micro-article
  const handleToggleSingle = (id) => {
    const features = getAllFeaturesOfSuperCluster();
    if (!Array.isArray(id)) {
      setMicroArticleStateST({
        hiddenClicked: getMicroArticleStateST.hiddenClicked?.includes?.(id) ? getMicroArticleStateST.hiddenClicked.filter(itemId => itemId !== id) :  [...new Set([...getMicroArticleStateST?.hiddenClicked || [], id])],
        allHidden: false
      })
    }

    features?.forEach((ma, idx) => {
      if (ma.properties.microArticleID === id || (Array.isArray(id) && id.includes(ma.properties.microArticleID))) {
        features[idx].properties.show = !features[idx].properties.show

        features[idx].properties.visible =
            features[idx].properties.visible === 'none'
            ? 'visible'
            : 'none'
      }
    })

    if (getMicroArticlesSource()) {
      getMicroArticlesSource()._updateWorkerData(
        features,
      )
    }

    const updatedArticles = sortedMicroArticles.map((ma) => {
      if (ma.id === id || (Array.isArray(id) && id.includes(ma.id))) {
        return {
          ...ma,
          show: !ma.show,
          visible: !ma.show ? 'visible' : 'none',
        }
      }
      return ma
    })

    dispatch(setActiveMicroArticleId(null))
    dispatch(setAllMicroArticles(updatedArticles))
  }

  useEffect(() => {
    STTimer.current = setTimeout(() => {
      if (!globalLoading && getMicroArticleStateST.hiddenClicked && !STDataIsMounted.current && screenShot) {
        if (getMicroArticleStateST.allHidden !== undefined && !!getMicroArticleStateST.allHidden && !getMicroArticleStateST.hiddenClicked.length) {
          handleToggleAll(true)
        }

        if (getMicroArticleStateST.hiddenClicked?.length) {
          handleToggleSingle(getMicroArticleStateST.hiddenClicked)
        }

        if (getMicroArticleStateST?.leftBarListSort) {
          toggleSorting(false, getMicroArticleStateST?.leftBarListSort)
        }
        STDataIsMounted.current = true
      }
   },500)

    return () => {
      clearTimeout(STTimer.current)
    }

  },[JSON.stringify(previousMAState),globalLoading,screenShot, JSON.stringify(getMicroArticleStateST)])

  useEffect(() => {
    STDataIsMounted.current = false
  }, [searchParams.get("screenSlide")]);

  if (isPreview) return null;

  return (
    <li
      className={`main__tab left_bar_tab persons ${microArticleTabOpen ? 'active' : ''}`}
      data-is-loading={globalLoading}>
      <button
        className="main__tab-btn"
        type="button"
        ref={tabRef}
        style={{
          color: `${getContrastColor(color)}`,
          backgroundColor: microArticleTabOpen ? color : 'transparent',
        }}
        onClick={handleTabClick}>
        <span className="main__tab-icon icon-wrapper">
          {MicroArticlesIcon({ isActive: microArticleTabOpen, isMap: true })}
        </span>
      </button>

      {microArticleTabOpen && (
        <>
          <div className="main__subitem" style={mainSubItemStyles}>
            <div className="main__subitem-heading" data-color={color}>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  '--data-color': color,
                }}
              />
              <h2 className="main__subitem-title">{title}</h2>
              <label className="main__subitem-label">
                <input
                  className="main__subitem-checkbox show-checkbox"
                  type="checkbox"
                  onChange={handleToggleAll}
                  checked={sortedMicroArticles.some((ma) => ma.show)}
                />
                <Eye
                  disableColor={'#f1f1f1'}
                  color={'#fff'}
                  show={sortedMicroArticles.some((ma) => ma.show)}
                />
              </label>
              <button
                className="main__subitem-sort"
                type="button"
                onClick={() => toggleSorting(!sortBoard)}>
                <span>
                  <Sort color={'#fff'} />
                </span>
              </button>
            </div>

            <div
              className="main__subitem-itemswrapper"
              style={mainSubItemsWrapperStyles}>
              <ul className="main__subitem-list person">
                {sortedMicroArticles.map((ma) => {
                  const darkIconKey = isIconKeyValid(
                    `micro_article_${ma.webIconKey}_dark`,
                  )
                    ? `micro_article_${ma.webIconKey}_dark`
                    : INITIAL_ICON_OBJECT.darkKey
                  return (
                    <li key={`microart_${ma.id}`} className="main__subitem-li">
                      <div
                        style={{
                          '--data-color': ma.iconBackgroundColor,
                          '--data-font-color': `${getContrastColor(ma.iconBackgroundColor, '#fff', '#2C476C')}`,
                          opacity: !ma.show ? 0.5 : 1,
                        }}>
                        <p className="with-svg">
                          <ConnectedSpriteSvgComponent name={darkIconKey} />
                        </p>
                        <div
                          className="main__subitem-libtn"
                          style={{
                            fontSize: '12px !important',
                            fontFamily:
                              'Noto Sans Armenian, sans-serif !important',
                          }}
                          onClick={() => {
                            stopPinAnimation()
                            setTimeout(() => {
                              dispatch(setActiveMicroArticleId(ma.id))
                            }, 100)
                          }}>
                          {ma.title}
                        </div>
                      </div>
                      <label
                        className="main__subitem-label category-filters category_filters_icon"
                        data-show={!ma.show}>
                        <input
                          className="main__subitem-checkbox show-checkbox"
                          type="checkbox"
                          onChange={() => handleToggleSingle(ma.id)}
                          checked={ma.show}
                        />
                        <Eye show={ma.show} />
                      </label>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
          <div
            style={{ left: '800%' }}
            className="sort-board category_sorted_tooltip"
            data-active={sortBoard}>
            <span className="sort-title" style={{ textTransform: 'uppercase' }}>
              {t('sortBy.title')}
            </span>
            <div onClick={() => toggleSorting(false, 'chronologically')}>
              <input
                checked={filterAction === 'chronologically'}
                name="sort-microarticles"
                type="radio"
                id="chronologically-microarticles"
              />
              <label htmlFor="chronologically-microarticles">
                {t('sortBy.timeRange')}
              </label>
            </div>
            <div onClick={() => toggleSorting(false, 'alphabetically')}>
              <input
                checked={filterAction === 'alphabetically'}
                name="sort-microarticles"
                type="radio"
                id="alphabetically-microarticles"
              />
              <label htmlFor="alphabetically-microarticles">
                {t('sortBy.alphabetically')}
              </label>
            </div>
          </div>
        </>
      )}
    </li>
  )
}

export default withRedux(MicroArticleTab)
