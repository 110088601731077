import { connect } from 'react-redux'
import {
  getCommentToolboxMarkersLoadingState,
  getCommentToolboxMarkersState,
  getCommentToolboxState,
  getDrawerToolboxClickedState,
  getEraserClickedState,
  getIsAllToolboxStatesLoaded, getIsFormPopupVisible,
  getLineDrawToolboxColorPickerState,
  getLineDrawToolboxColors,
  getLineDrawToolboxCurrentColor,
  getLineDrawToolboxState,
  getLineDrawToolboxWeight,
  getMapboxDrawToolboxGeoJSONState,
  getMapStyledDarkId,
  getMapStyledId, getMicroArticleBarClickedState,
  getMobileMenuState,
  getPainterGeoJsonDataST,
  getPainterStartData,
  getPolygonDrawToolboxColorPickerState,
  getPolygonDrawToolboxColors,
  getPolygonDrawToolboxCurrentFillColor,
  getPolygonDrawToolboxCurrentFillOpacity,
  getPolygonDrawToolboxCurrentStrokeColor,
  getPolygonDrawToolboxCurrentStrokeOpacity,
  getPolygonDrawToolboxPolygonsState,
  getPolygonDrawToolboxState,
  getRulerClickedState,
  getRulerGeoJsonDataST,
  getRulerPopupShowST,
  getScreenShootButtonStateST,
  getSelectedToolUndoRedo, getShapeClickedState,
  getStickyNotesClickedState,
  getStickyNotesMarkersLoadingState,
  getStickyNotesMarkersState,
  getTextToolboxMarkersLoadingState,
  getTextToolboxMarkersState,
  getTextToolboxState,
  getUndoRedoData,
} from '../../store/selectors'
import {
  setCommentToolboxState,
  setDrawerToolboxClickedState,
  setEraserClickedState,
  setLineDrawToolboxColorPickerState,
  setLineDrawToolboxColors,
  setLineDrawToolboxCurrentColor,
  setLineDrawToolboxState,
  setLineDrawToolboxWeight, setMicroArticleBar,
  setPolygonDrawToolboxColorPickerState,
  setPolygonDrawToolboxColors,
  setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity,
  setPolygonDrawToolboxState,
  setRulerClickedState, setShaperClickState,
  setStickyNotesClickedState,
  setTextToolboxState,
} from '../../store/actions/painterStart'
import {
  dispatchUndoRedoData,
  setCommentToolboxMarkersLoadingState,
  setCommentToolboxMarkersState,
  setMapboxDrawToolboxGeoJSONState,
  setPainterGeoJsonDataST,
  setPolygonDrawToolboxPolygonsState,
  setRulerGeoJsonDataST,
  setRulerPopupShowST,
  setSelectedToolUndoRedo,
  setStickyNotesMarkersLoadingState,
  setStickyNotesMarkersState,
  setTextToolboxMarkersLoadingState,
  setTextToolboxMarkersState,
  setUndoRedoData,
} from '../../store/actions/mapStateAction'

const mapStateToProps = (state) => ({
  getCommentToolboxMarkersLoadingState:
    getCommentToolboxMarkersLoadingState(state),
  getCommentToolboxMarkersState: getCommentToolboxMarkersState(state),
  getCommentToolboxState: getCommentToolboxState(state),
  getDrawerToolboxClickedState: getDrawerToolboxClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getIsAllToolboxStatesLoaded: getIsAllToolboxStatesLoaded(state),
  getLineDrawToolboxColorPickerState: getLineDrawToolboxColorPickerState(state),
  getLineDrawToolboxColors: getLineDrawToolboxColors(state),
  getLineDrawToolboxCurrentColor: getLineDrawToolboxCurrentColor(state),
  getLineDrawToolboxState: getLineDrawToolboxState(state),
  getLineDrawToolboxWeight: getLineDrawToolboxWeight(state),
  getMapStyledDarkId: getMapStyledDarkId(state),
  getMapStyledId: getMapStyledId(state),
  getMapboxDrawToolboxGeoJSONState: getMapboxDrawToolboxGeoJSONState(state),
  getMobileMenuState: getMobileMenuState(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getPainterStartData: getPainterStartData(state),
  getPolygonDrawToolboxColors: getPolygonDrawToolboxColors(state),
  getPolygonDrawToolboxColorPickerState:
    getPolygonDrawToolboxColorPickerState(state),
  getPolygonDrawToolboxCurrentFillColor:
    getPolygonDrawToolboxCurrentFillColor(state),
  getPolygonDrawToolboxCurrentFillOpacity:
    getPolygonDrawToolboxCurrentFillOpacity(state),
  getPolygonDrawToolboxCurrentStrokeColor:
    getPolygonDrawToolboxCurrentStrokeColor(state),
  getPolygonDrawToolboxCurrentStrokeOpacity:
    getPolygonDrawToolboxCurrentStrokeOpacity(state),
  getPolygonDrawToolboxPolygonsState: getPolygonDrawToolboxPolygonsState(state),
  getPolygonDrawToolboxState: getPolygonDrawToolboxState(state),
  getRulerClickedState: getRulerClickedState(state),
  getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
  getRulerPopupShowST: getRulerPopupShowST(state),
  getScreenShootButtonStateST: getScreenShootButtonStateST(state),
  getSelectedToolUndoRedo: getSelectedToolUndoRedo(state),
  getStickyNotesClickedState: getStickyNotesClickedState(state),
  getStickyNotesMarkersLoadingState: getStickyNotesMarkersLoadingState(state),
  getStickyNotesMarkersState: getStickyNotesMarkersState(state),
  getTextToolboxMarkersLoadingState: getTextToolboxMarkersLoadingState(state),
  getTextToolboxMarkersState: getTextToolboxMarkersState(state),
  getTextToolboxState: getTextToolboxState(state),
  getUndoRedoData: getUndoRedoData(state),
  painterStart: getPainterStartData(state),
  getShapeClickedState: getShapeClickedState(state),
  getMicroArticleBarClickedState: getMicroArticleBarClickedState(state),
  getIsFormPopupVisible: getIsFormPopupVisible(state),
})

const mapDispatchToProps = {
  dispatchUndoRedoData: dispatchUndoRedoData,
  setCommentToolboxMarkersLoadingState: setCommentToolboxMarkersLoadingState,
  setCommentToolboxMarkersState: setCommentToolboxMarkersState,
  setCommentToolboxState: setCommentToolboxState,
  setDrawerToolboxClickedState: setDrawerToolboxClickedState,
  setEraserClickedState: setEraserClickedState,
  setLineDrawToolboxColorPickerState: setLineDrawToolboxColorPickerState,
  setLineDrawToolboxColors: setLineDrawToolboxColors,
  setLineDrawToolboxCurrentColor: setLineDrawToolboxCurrentColor,
  setLineDrawToolboxState: setLineDrawToolboxState,
  setLineDrawToolboxWeight: setLineDrawToolboxWeight,
  setMapboxDrawToolboxGeoJSONState: setMapboxDrawToolboxGeoJSONState,
  setPainterGeoJsonDataST: setPainterGeoJsonDataST,
  setPolygonDrawToolboxColorPickerState: setPolygonDrawToolboxColorPickerState,
  setPolygonDrawToolboxColors: setPolygonDrawToolboxColors,
  setPolygonDrawToolboxCurrentFillColor: setPolygonDrawToolboxCurrentFillColor,
  setPolygonDrawToolboxCurrentFillOpacity:
    setPolygonDrawToolboxCurrentFillOpacity,
  setPolygonDrawToolboxCurrentStrokeColor:
    setPolygonDrawToolboxCurrentStrokeColor,
  setPolygonDrawToolboxCurrentStrokeOpacity:
    setPolygonDrawToolboxCurrentStrokeOpacity,
  setPolygonDrawToolboxPolygonsState: setPolygonDrawToolboxPolygonsState,
  setPolygonDrawToolboxState: setPolygonDrawToolboxState,
  setRulerClickedState: setRulerClickedState,
  setRulerGeoJsonDataST: setRulerGeoJsonDataST,
  setRulerPopupShowST: setRulerPopupShowST,
  setSelectedToolUndoRedo: setSelectedToolUndoRedo,
  setStickyNotesClickedState: setStickyNotesClickedState,
  setStickyNotesMarkersLoadingState: setStickyNotesMarkersLoadingState,
  setStickyNotesMarkersState: setStickyNotesMarkersState,
  setTextToolboxMarkersLoadingState: setTextToolboxMarkersLoadingState,
  setTextToolboxMarkersState: setTextToolboxMarkersState,
  setTextToolboxState: setTextToolboxState,
  setUndoRedoData: setUndoRedoData,
  setShaperClickState: setShaperClickState,
  setMicroArticleBar: setMicroArticleBar,
}

const toolboxStatesWithRedux = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component);
export default toolboxStatesWithRedux
