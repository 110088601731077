import axios from 'axios'

const extractData = (res) => {
    const { data } = res
    if (data.accepted && data.data[0]) {
        if (data.data && data.data[0]) {
            return data.data[0]
        }
    }
    return []
}
const extractDataByStatus = (res) => {
    const { data } = res
    if (res.status === 200 && data) return data
    return []
}
export const getSpriteURLAPI = (styleId) =>{
    return axios
        .get(`${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}/Sprite?t=${Date.now()}`)
        .then(extractDataByStatus)
}
// export const getSpriteAPI = (spriteUrl) =>{
//     return fetch(`${spriteUrl}/sprite.json`)
//         .then(res=>res.json())
// }
export const getSpriteAPI = () =>{
    return axios
        .get(`${process.env.REACT_APP_GIS_URL}/BaseSprite/sprite@2x.json?t=${Date.now()}`)
        .then(extractDataByStatus)
}

