import {useEffect, useRef} from 'react';
import {updateURLParameter} from "../utils/utils";


function useCountdownTimer(initialTime, callBack,autostart) {
    const timerRef = useRef(null);
    const startTimeRef = useRef(null);
    const pausedRef = useRef(false);
    const initialTimeRef = useRef(initialTime);
    const nextTimeRef = useRef(initialTime);
    const remainingTime = useRef(initialTime);

    // console.log("nextTimeRef::, initialTimeRef::, initial::", nextTimeRef.current, initialTimeRef.current, initialTime);


    const startCountdown = (isContinue) => {
        // console.error("initialTimeRef.current", initialTimeRef.current, isContinue, remainingTime.current, timerRef.current, pausedRef.current);
        if (timerRef.current && !pausedRef.current) {
            timerRef.current = null;
            startCountdown()
        };
        if (initialTimeRef.current < 0 || pausedRef.current) return;
        startTimeRef.current = Date.now();
        if(isContinue && !!remainingTime.current){
            initialTimeRef.current = remainingTime.current
        } else {
            initialTimeRef.current = nextTimeRef.current
        }
        if (timerRef.current === null) {

            const animate = () => {
                if(pausedRef.current) return
                // console.log("remainingTime----", remainingTime.current);
                const currentTime = Date.now();
                const elapsedTime = currentTime - startTimeRef.current;
                // console.log(elapsedTime,'elapsedTime')
                remainingTime.current = Math.max(0, initialTimeRef.current - elapsedTime);
                // console remianingTime.current every 5 seconds
                // if (remainingTime.current % 100 === 0 || remainingTime.current % 10 === 0) {
                //     console.log("remainingTime.current", remainingTime.current);
                // }

                // console.log("timerRef.current", timerRef.current, remainingTime.current, pausedRef.current);
                if (remainingTime.current > 0 && !pausedRef.current) {
                    timerRef.current = requestAnimationFrame(animate);
                } else {
                    timerRef.current = null;
                    // Use the callback from the ref
                    if (typeof callBack === 'function' && initialTimeRef.current > 0) {
                        // console.log("initialTimeRef", initialTimeRef.current);
                        callBack(true);
                        initialTimeRef.current = nextTimeRef.current
                    }
                }
            };


            timerRef.current = requestAnimationFrame(animate);
        }
    };

    const stopCountdown = () => {
        // console.error("STOPED COUNTDOWN::", timerRef.current);
        if (timerRef.current !== null) {
            cancelAnimationFrame(timerRef.current);
            timerRef.current = null;
            pausedRef.current = true;
        }
    };

    const pauseCountdown = () => {
        pausedRef.current = true;
        timerRef.current = null;
        const url = new URL(window.location.href);
        const newURL = updateURLParameter(url, 'playBack', 'false');
        window.history.pushState({ path: newURL }, '', newURL);

    };

    const playCountdown = (isContinue) => {
        if(!isContinue) stopCountdown()

        pausedRef.current = false;
        startCountdown(isContinue);
        const url = new URL(window.location.href);
        const newURL = updateURLParameter(url, 'playBack', 'true');
        window.history.pushState({ path: newURL }, '', newURL);
    };
    const changeInitialTime = (duration) =>{
        initialTimeRef.current = duration
        remainingTime.current = duration
    }
   const changeNextTime = (duration) =>{
         nextTimeRef.current = duration
         remainingTime.current = duration
   }

    const restartCountdown = () => {
        stopCountdown();
        startCountdown(); // Use the callback from the ref
    };

    useEffect(() => {
        if (!initialTimeRef.current) {
            initialTimeRef.current = initialTime
        }
    }, [initialTime]);

    return { changeNextTime,startCountdown, stopCountdown, restartCountdown, pauseCountdown, playCountdown, isPaused:pausedRef.current , changeInitialTime};
}

export default useCountdownTimer;
