import * as React from 'react'

const DurationIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M30.6055 30.605C36.4705 24.74 36.4705 15.245 30.6055 9.39502"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.39484 9.39502C3.52984 15.26 3.52984 24.755 9.39484 30.605"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0508 34.1147C16.6558 34.6697 18.3208 34.9398 20.0008 34.9398C21.6808 34.9248 23.3457 34.6697 24.9507 34.1147"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0508 5.88513C16.6558 5.33013 18.3208 5.06006 20.0008 5.06006C21.6808 5.06006 23.3457 5.33013 24.9507 5.88513"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1094 17.4952C15.1094 14.3752 17.3144 13.1002 20.0144 14.6602L22.1894 15.9203L24.3644 17.1802C27.0644 18.7402 27.0644 21.2902 24.3644 22.8502L22.1894 24.1102L20.0144 25.3702C17.3144 26.9302 15.1094 25.6552 15.1094 22.5352V20.0002V17.4952Z"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 2V38H2V2H38Z"
      stroke="white"
      strokeWidth={2.5}
    />
  </svg>
)
export default DurationIcon
