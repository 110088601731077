import * as React from 'react'

const PlayCircle2Icon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9603 29.3576C8.59649 29.3576 2.62695 23.3831 2.62695 16.0133C2.62695 8.6434 8.59649 2.66895 15.9603 2.66895C23.3241 2.66895 29.2936 8.6434 29.2936 16.0133C29.2936 23.3831 23.3241 29.3576 15.9603 29.3576Z"
      stroke="#2C476C"
      strokeWidth={3.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6523 14.0912C11.6523 11.3156 13.6123 10.1813 16.0123 11.5691L17.9457 12.69L19.879 13.811C22.279 15.1988 22.279 17.4673 19.879 18.8551L17.9457 19.976L16.0123 21.097C13.6123 22.4848 11.6523 21.3505 11.6523 18.5749V16.3197V14.0912Z"
      fill="#2C476C"
    />
  </svg>
)
export default PlayCircle2Icon
