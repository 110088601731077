import {
    SET_BASE_MAP_IS_LIGHT,
    SET_PRESENTATION_LIST_REFETCH,
    SET_BASE_MAP_GROUP_ID_ST,
    SET_CATEGORIES_ARTICLE_ITEM_ID,
    SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
    SET_LAMP_SELECTED_ID,
    SET_LAMP_SORTED_TOOLTIP_STATE,
    SET_MAP_CENTER_POSITION,
    SET_MAP_CENTER_POSITION_STATUS,
    SET_MAP_REQUIREMENT_SCREEN_SHOT,
    SET_MAP_STATE_DATA,
    SET_MAP_STATE_SINGLE_DATA,
    SET_MAP_STYLES_DARK_ID,
    SET_MAP_STYLES_ID,
    SET_MAP_STYLES_STATUS,
    SET_MOBILE_MENU_CLOSE,
    SET_OPEN_CATEGORY_ID,
    SET_OPEN_SUB_CATEGORY_ID,
    SET_PAINTER_GEOJSON_DATA_ST,
    SET_REMOVE_CARD_ID,
    SET_COMMENT_TOOLBOX_MARKERS_STATE,
    SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE,
    SET_SCREENSHOT_BUTTON_CLICK,
    SET_MAP_MARKER_INFO,
    SET_SCREENSHOT_BUTTON_STATE,
    SET_SCREENSHOT_INTERMEDIATE_DATA,
    SET_SCREENSHOT_LOADER,
    SET_SCREENSHOT_SEND_DATA,
    SET_SCREEN_SHOT_LOADING,
    SET_TEST_FILTERED_CATEGORIES,
    SET_TIMELINE_CLICK_STATE,
    SET_TIMELINE_CURSOR_STATUS_ST,
    SET_TIMELINE_EVENT_DATA_ST,
    SET_TIMELINE_MOVE_COUNT_ST,
    SET_TIMELINE_MOVE_DATA_ST,
    SET_TIMELINE_MOVE_DATA_STATUS_ST,
    SET_TIMELINE_MOVE_POSITION,
    SET_TIMELINE_MOVE_STATUS,
    SET_TIMELINE_ZOOM_INDEX_ST,
    SET_TIMELINE_ZOOM_LEVEL,
    SET_TIMELINE_ZOOM_ST,
    SET_TOPIC_STATIC_TITLE_ST,
    SET_TIMELINE_HEIGHT,
    SET_SHOW_NOTIFICATION,
    ADD_MAP_STATE_DATA,
    SET_FIRST_TIMELINE_CURSOR_STATUS_ST,
    SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE,
    SET_READ_MORE_MODAL_STATE,
    SET_GALLERY_MODAL_STATE,
    SET_GALLERY_INDEX,
    SET_GALLERY_FULL_SCREEN,
    SET_ARTICLE_POSITION,
    SET_CLOSED_BLOCKS,
    SET_GALLERY_ARTICLE_ID,
    SET_RULER_GEOJSON_DATA_ST,
    SET_RULER_POPUP_SHOW_ST,
    SET_IS_RULER_STATES_LOADED,
    SET_STICKY_NOTES_MARKERS_LOADING_STATE,
    SET_STICKY_NOTES_MARKERS_STATE,
    SET_IS_ALL_TOOLBOX_STATES_LOADED,
    SET_SELECTED_TOOL_UNDO_REDO,
    SET_UNDO_REDO_DATA,
    SET_GALLERY_TILI_INDEX,
    SET_TEXT_TOOLBOX_MARKERS_LOADING_STATE,
    SET_TEXT_TOOLBOX_MARKERS_STATE,
    SET_SCREENSHOT_LANGUAGE,
    SET_SHOW_ARTICLE_POPUP,
    SET_ACTIVE_SCREEN_SHOT,
    SET_IS_SCREEN_SHOT_MODE,
    SET_READ_MORE_MODAL_ACTIVE_STATE,
    SET_IS_FROM_READ_MORE,
    SET_ARTICLE_ID,
    SET_SCREEN_SHOT_GALLERY_INDEX,
    SET_SCREEN_SHOT_CURRENT_TILI_INDEX,
    SET_SCREEN_SHOT_FULL_SCREEN,
    SET_CHANGES_TIME_RANGE,
    RESET_ALL_STATE,
    SET_SCREEN_SHOT_FORM_IS_OPEN,
    SET_MICRO_ARTICLE_ID_ST,
    SET_MICRO_ARTICLE_STATE_ST,
    SET_COLLECTION_ELEMENTS
} from "../constants";

import {MAP_TOOLBOX_KEYS} from "../../components/mapToolbox";
import {undoRedoDataKeys} from "../../components/mapToolbox/utils";
import i18n from "i18next";

const initialState = {
    isLoading: false,
    screenShootButtonState: false,
    data: [],
    singleData: [],
    categoryId: null,
    subCategoryId: null,
    mapRequiredScreenShot: null,
    categoryArticleClickedId: null,
    lampDataFilteredScreenShot: [],
    lampSortedTooltipState: false,
    testFilteredCategories: [],
    lampSelectedId: null,
    mapStylesId: null,
    mapStylesDarkId: null,
    selectedToolUndoRedo: MAP_TOOLBOX_KEYS.DRAWER,
    undoRedoData: undoRedoDataKeys.reduce((acc, key) => {
        acc[key] = { history: [{type: "FeatureCollection", features: []}], index: 0 };
        return acc;
    }, {}),
    painterGeoJsonDataST: {},
    rulerGeoJsonDataST: {},
    isAllStatesLoaded: false,
    rulerPopupHide: false,
    mapboxDrawToolboxGeoJSON: [],
    polygonDrawToolboxPolygons: [],
    commentBoxMarkers: [],
    commentBoxMarkersJSONDataST: {},
    commentBoxMarkersLoading: true,
    stickyNotesMarkers: [],
    stickyNotesMarkersJSONDataST: {},
    stickyNotesMarkersLoading: true,
    textBoxMarkers: [],
    textBoxMarkersJSONDataST: {},
    textBoxMarkersLoading: true,
    mapStylesChangedStatus: null,
    timelineZoomST: 0,
    timelineZoomIndexST: null,
    timeLineMoveCountST: 0,
    timeLineCursorStatusST: null,
    firstTimeLineCursorStatusST: false,
    timeLineEventDataST: {},
    timeLineMOvePosition: {},
    timeLineMoveStatus: false,
    timeTineZoomLevel: null,
    screenShotLoading: true,
    mapCenterCoordinates: {},
    mapCenterCoordinatesStatus: false,
    screenShotSendData: "",
    screenShotIntermediateData: "",
    screenShotButtonClick: true,
    removeCardId: null,
    timeLineMovieDataStatus: false,
    timeLineMovieData: {},
    topicStaticTitle: "",
    timeLineClickState: false,
    mobileMenuState: false,
    timeLineHeight: 0,
    showNotificationST:null,
    markerInfo:{},
    isReadMore: false,
    showArticlePopup: false,
    isReadMoreActive: false,
    isGalleryMode: false,
    galleryIndex: null,
    galleryTiliIndex: null,
    galleryArticleId: "",
    galleryFullScreen: false,
    articlePosition: 0,
    closedBlocks: [],
    baseMapGroupIdST:null,
    baseMapIsLight:null,
    screenshotLanguage: i18n?.language === 'en' ? 2 : 1,
    activeScreenShot: "map",
    isScreenShotMode: false,
    isFromReadMore: false,
    articleId: null,
    screenShotGalleryIndex: null,
    currentTiliIndex: null,
    changesTimeRange: false,
    screenShotFormIsOpen: false,
    presentationListRefetch: false,
    microArticleIdST: null,
    microArticleST:{
        isMaximized: true
    },
    collectionElements: {}
};

export const mapStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COLLECTION_ELEMENTS:
            return {
                ...state,
                collectionElements: action.payload
            }
        case SET_PRESENTATION_LIST_REFETCH:
            return {
                ...state,
                presentationListRefetch: action.payload
            }
        case SET_BASE_MAP_IS_LIGHT:
            return {
                ...state,
                baseMapIsLight: action.payload
            }
        case SET_BASE_MAP_GROUP_ID_ST:
            return {
                ...state,
                baseMapGroupIdST: action.payload
            }
        case SET_UNDO_REDO_DATA:
            return {
                ...state,
                undoRedoData: action.payload,
            }
        case SET_SELECTED_TOOL_UNDO_REDO:
            return {
                ...state,
                selectedToolUndoRedo: action.payload,
            }
        case SET_IS_ALL_TOOLBOX_STATES_LOADED:
            return {
                ...state,
                isAllStatesLoaded: action.payload,
            }
        case SET_RULER_POPUP_SHOW_ST:
            return {
                ...state,
                rulerPopupHide: action.payload,
            }
        case SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE:
            return {
                ...state,
                mapboxDrawToolboxGeoJSON: action.payload,
            };
        case SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE:
            return {
                ...state,
                commentBoxMarkersLoading: action.payload,
            };
        case SET_COMMENT_TOOLBOX_MARKERS_STATE:
            return {
                ...state,
                commentBoxMarkers: action.payload,
            };
        case SET_STICKY_NOTES_MARKERS_LOADING_STATE:
            return {
                ...state,
                stickyNotesMarkersLoading: action.payload,
            };
        case SET_STICKY_NOTES_MARKERS_STATE:
            return {
                ...state,
                stickyNotesMarkers: action.payload,
            };
        case SET_TEXT_TOOLBOX_MARKERS_LOADING_STATE:
            return {
                ...state,
                textBoxMarkersLoading: action.payload,
            };
        case SET_TEXT_TOOLBOX_MARKERS_STATE:
            return {
                ...state,
                textBoxMarkers: action.payload,
            };
        case SET_SCREENSHOT_BUTTON_STATE:
            return {
                ...state,
                screenShootButtonState: action.payload,
            };
        case SET_MAP_STATE_DATA:
            return {
                ...state,
                data: action.payload,
            };
        case ADD_MAP_STATE_DATA:
            const newData = action.payload
            if(state.data?.items){
                newData.items = [...state.data?.items,...action.payload?.items]
            }
            return {
                ...state,
                data: newData,
            };
        case SET_MAP_STATE_SINGLE_DATA:
            return {
                ...state,
                singleData: action.payload,
            };
        case SET_OPEN_CATEGORY_ID:
            return {
                ...state,
                categoryId: action.payload,
            };
        case SET_OPEN_SUB_CATEGORY_ID:
            return {
                ...state,
                subCategoryId: action.payload,
            };
        case SET_MAP_REQUIREMENT_SCREEN_SHOT:
            return {
                ...state,
                mapRequiredScreenShot: action.payload,
            };
        case SET_CATEGORIES_ARTICLE_ITEM_ID:
            return {
                ...state,
                categoryArticleClickedId: action.payload,
            };
        case SET_LAMP_DATA_FILTERED_SCREEN_SHOT:
            return {
                ...state,
                lampDataFilteredScreenShot: action.payload,
            };
        case SET_LAMP_SORTED_TOOLTIP_STATE:
            return {
                ...state,
                lampSortedTooltipState: action.payload,
            };
        case SET_TEST_FILTERED_CATEGORIES:
            return {
                ...state,
                testFilteredCategories: action.payload,
            };
        case SET_LAMP_SELECTED_ID:
            return {
                ...state,
                lampSelectedId: action.payload,
            };
        case SET_MAP_STYLES_ID:
            return {
                ...state,
                mapStylesId: action.payload,
            };
        case SET_MAP_STYLES_DARK_ID:
            return {
                ...state,
                mapStylesDarkId: action.payload,
            };
        case SET_PAINTER_GEOJSON_DATA_ST:
            return {
                ...state,
                painterGeoJsonDataST: action.payload,
            };
        case SET_RULER_GEOJSON_DATA_ST:
            return {
                ...state,
                rulerGeoJsonDataST: action.payload,
            };
        case SET_MAP_STYLES_STATUS:
            return {
                ...state,
                mapStylesChangedStatus: action.payload,
            };
        case SET_TIMELINE_ZOOM_ST:
            return {
                ...state,
                timelineZoomST: action.payload,
            };
        case SET_TIMELINE_ZOOM_INDEX_ST:
            return {
                ...state,
                timelineZoomIndexST: action.payload,
            };
        case SET_TIMELINE_MOVE_COUNT_ST:
            return {
                ...state,
                timeLineMoveCountST: action.payload,
            };
        case SET_TIMELINE_CURSOR_STATUS_ST:
            return {
                ...state,
                timeLineCursorStatusST: action.payload,
            };
        case SET_FIRST_TIMELINE_CURSOR_STATUS_ST:
            return {
                ...state,
                firstTimeLineCursorStatusST: action.payload,
            };
        case SET_TIMELINE_EVENT_DATA_ST:
            return {
                ...state,
                timeLineEventDataST: action.payload,
            };
        case SET_TIMELINE_MOVE_POSITION:
            return {
                ...state,
                timeLineMOvePosition: action.payload,
            };
        case SET_TIMELINE_MOVE_STATUS:
            return {
                ...state,
                timeLineMoveStatus: action.payload,
            };
        case SET_TIMELINE_ZOOM_LEVEL:
            return {
                ...state,
                timeTineZoomLevel: action.payload,
            };
        case SET_SCREEN_SHOT_LOADING:
            return {
                ...state,
                screenShotLoading: action.payload,
            };
        case SET_MAP_CENTER_POSITION:
            return {
                ...state,
                mapCenterCoordinates: action.payload,
            };
        case SET_MAP_CENTER_POSITION_STATUS:
            return {
                ...state,
                mapCenterCoordinatesStatus: action.payload,
            };
        case SET_SCREENSHOT_SEND_DATA:
            return {
                ...state,
                screenShotSendData: action.payload,
            };
        case SET_SCREENSHOT_INTERMEDIATE_DATA:
            return {
                ...state,
                screenShotIntermediateData: action.payload,
            };
        case SET_SCREENSHOT_BUTTON_CLICK:
            return {
                ...state,
                screenShotButtonClick: action.payload,
            };
        case SET_MAP_MARKER_INFO:
            return {
                ...state,
                markerInfo: action.payload,
            };
        case SET_CHANGES_TIME_RANGE:
            return {
                ...state,
                changesTimeRange: action.payload,
            }
        case SET_SCREENSHOT_LOADER:
            return {
                ...state,
                isLoading: action.payload,
            };
        case SET_REMOVE_CARD_ID:
            return {
                ...state,
                removeCardId: action.payload,
            };
        case SET_TIMELINE_MOVE_DATA_STATUS_ST:
            return {
                ...state,
                timeLineMovieDataStatus: action.payload,
            };
        case SET_TIMELINE_MOVE_DATA_ST:
            return {
                ...state,
                timeLineMovieData: action.payload,
            };
        case SET_TOPIC_STATIC_TITLE_ST:
            return {
                ...state,
                topicStaticTitle: action.payload,
            };
        case SET_TIMELINE_CLICK_STATE:
            return {
                ...state,
                timeLineClickState: action.payload,
            };
        case SET_MOBILE_MENU_CLOSE:
            return {
                ...state,
                mobileMenuState: action.payload,
            };
        case SET_TIMELINE_HEIGHT:
            return {
                ...state,
                timeLineHeight: action.payload,
            };
        case SET_SHOW_NOTIFICATION:
            return {
                ...state,
                showNotificationST: action.payload
            }
        case SET_READ_MORE_MODAL_STATE:
            return {
                ...state,
                isReadMore: action.payload
            }
        case SET_SHOW_ARTICLE_POPUP:
            return {
                ...state,
                showArticlePopup: action.payload
            }
        case SET_READ_MORE_MODAL_ACTIVE_STATE:
            return {
                ...state,
                isReadMoreActive: action.payload
            }
        case SET_GALLERY_MODAL_STATE:
            return {
                ...state,
                isGalleryMode: action.payload
            }
        case SET_GALLERY_INDEX:
            return {
                ...state,
                galleryIndex: action.payload
            }
        case SET_GALLERY_TILI_INDEX:
            return {
                ...state,
                galleryTiliIndex: action.payload
            }
        case SET_GALLERY_FULL_SCREEN:
            return {
                ...state,
                galleryFullScreen: action.payload
            }
        case SET_GALLERY_ARTICLE_ID:
            return {
                ...state,
                galleryArticleId: action.payload
            }
        case SET_ARTICLE_POSITION:
            return {
                ...state,
                articlePosition: action.payload ?? 0
            }
        case SET_CLOSED_BLOCKS:
            return {
                ...state,
                closedBlocks: action.payload
            }
        case SET_SCREENSHOT_LANGUAGE:
            return {
                ...state,
                screenshotLanguage: action.payload
            }
        case SET_ACTIVE_SCREEN_SHOT:
            return {
                ...state,
                activeScreenShot: action.payload
            }
        case SET_IS_SCREEN_SHOT_MODE:
            return {
                ...state,
                isScreenShotMode: action.payload
            }
        case SET_IS_FROM_READ_MORE:
            return {
                ...state,
                isFromReadMore: action.payload
            }
        case SET_ARTICLE_ID:
            return {
                ...state,
                articleId: action.payload
            }
        case SET_SCREEN_SHOT_GALLERY_INDEX:
            return {
                ...state,
                screenShotGalleryIndex: action.payload
            }
        case SET_SCREEN_SHOT_CURRENT_TILI_INDEX:
            return {
                ...state,
                currentTiliIndex: action.payload,
                galleryTiliIndex: action.payload
            }
        case SET_SCREEN_SHOT_FORM_IS_OPEN:
            return {
                ...state,
                screenShotFormIsOpen: action.payload
            }
        case SET_SCREEN_SHOT_FULL_SCREEN:
            return {
                ...state,
                screenShootFullScreen: action.payload
            }
        case SET_MICRO_ARTICLE_ID_ST:
            return {
                ...state,
                microArticleIdST: action.payload
            }
        case SET_MICRO_ARTICLE_STATE_ST:
            const isEmpty = Object.keys(action?.payload || {}).length === 0;

            if (isEmpty) {
                return {
                    ...state,
                    microArticleST: {}
                }
            }

            return {
                ...state,
                microArticleST: {
                    ...state.microArticleST,
                    ...action.payload
                }
            }
        case RESET_ALL_STATE:
            return initialState
        default:
            return state;
    }
};
