import React from "react";

const DynamicScreenshot = ({isActive = false}) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5 2.3335H34.8333C41.3333 2.3335 45.6667 6.66683 45.6667 13.1668V17.5002"
                  stroke={isActive ? "#FF6539" : "#2C476C"}
                  fill="none"
                  strokeWidth="3.3" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="24.333" cy="24.3335" r="7.35" stroke={isActive ? "#FF6539" : "#2C476C"} fill="none" strokeWidth="3.3"/>
            <path d="M2.33301 17.5002V13.1668C2.33301 6.66683 6.66634 2.3335 13.1663 2.3335H17.4997"
                  stroke={isActive ? "#FF6539" : "#2C476C"}
                  strokeWidth="3.3" strokeLinecap="round" fill="none" strokeLinejoin="round"/>
            <path d="M30.5 45.6667H34.8333C41.3333 45.6667 45.6667 41.3333 45.6667 34.8333V30.5"
                  stroke={isActive ? "#FF6539" : "#2C476C"}
                  strokeWidth="3.3" strokeLinecap="round" fill="none" strokeLinejoin="round"/>
            <path d="M2.33301 30.5V34.8333C2.33301 41.3333 6.66634 45.6667 13.1663 45.6667H17.4997"
                  stroke={isActive ? "#FF6539" : "#2C476C"}
                  strokeWidth="3.3" strokeLinecap="round" fill="none" strokeLinejoin="round"/>
        </svg>
    );
};
export default DynamicScreenshot;
