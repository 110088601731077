import React, {forwardRef} from 'react';
import styles from "../PopupForm.module.css";
import {Button, Modal} from "antd";
import Webcam from "./Webcam";

const WebcamModal = forwardRef(({
                              isShowCameraModal,
                              closeModal,
                              cameraInputRef,
                              setIsShowCameraModal,
                              handleDesktopCapture
                          }, ref) => {
    return (
        <Modal
            width={'fit-content'}
            style={{ borderRadius: '50px' }}
            open={isShowCameraModal}
            centered={true}
            footer={null}
            header={null}
            closeIcon={null}
            destroyOnClose={true}
            onCancel={closeModal}
            className={styles.webcamModal}
            styles={{
                body: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '10px',
                },
            }}>

            <Webcam
                ref={cameraInputRef}
            />

            <div className={styles.modalButtonContainer}>
                <Button
                    type="primary"
                    onClick={async () => {
                        setIsShowCameraModal(false)
                        const imageFile = cameraInputRef.current?.getScreenshot({width: 800,height: 600});
                        await handleDesktopCapture(imageFile)
                    }}
                    className={styles.modalSaveButton}>
                    Նկարել
                    {/*{t('microArticle.mapView.mediaPart.mediaPopup.buttons.save')}*/}
                </Button>
                <Button
                    type="danger"d
                    onClick={() => {setIsShowCameraModal(false)}}
                    className={styles.modalDeleteButton}>
                    Փակել
                    {/*{t(*/}
                    {/*  'microArticle.mapView.mediaPart.mediaPopup.buttons.delete',*/}
                    {/*)}*/}
                </Button>
            </div>
        </Modal>
    );
});

export default WebcamModal;
