import React, {memo} from 'react';
import PropTypes from "prop-types";
import styles from "./mapSlider.module.css"
import TopicArrow from "../UI/TopicArrow";
import {Splide, SplideSlide} from "@splidejs/react-splide";

const MapSlider = ({items,noBody, className, header, hasNext, hasPrev, onNext, onPrev, onClickItem, renderItem}) => {
    const hasItems = items && items.length > 0;

    const clickItemHandler = (item) => () => {
        onClickItem(item);
    }

    return (
        <div id="mapSlider" className={`footer-main-element ${styles.mapSliderContainer} ${className || ''} ${noBody ? styles.noBody : ''}`}>
            <div className={styles.mapSliderHeader}>
                <button className={`${styles.leftArrowBtn} ${!hasPrev ? styles.buttonIsDisabled : ""}`} disabled={!hasPrev} onClick={onPrev}>
                    <TopicArrow/>
                </button>
                <div className={styles.mapSliderHeaderTitle}>
                    {header}
                </div>
                <button className={`${styles.rightArrowBtn} ${!hasNext ? styles.buttonIsDisabled : ""}`} disabled={!hasNext} onClick={onNext}>
                    <TopicArrow/>
                </button>
            </div>
            {
                (hasItems || noBody) &&
                <div className={styles.mapSliderBody}>
                    <Splide
                        options={{
                            pagination: false,
                            arrows: true,
                            rewind: true,
                            perPage: 4,
                            width: "100%",
                            gap: '3rem',
                            breakpoints: {
                                1024: {
                                    perPage: 2,
                                },
                                640: {
                                    perPage: 1,
                                },
                            }
                        }}
                        className={styles.mapSlider}
                    >
                        {items.map((item, index) => {
                            if (renderItem && typeof renderItem === "function") {
                                return renderItem(item, index);
                            }
                            return (
                                <SplideSlide
                                    key={item.id || index}
                                    onClick={clickItemHandler(item)}
                                >
                                    <div
                                        className={styles.mapSliderItem}
                                        id="lightgallery"
                                        type="button"
                                    >
                                        <span className={styles.mapSliderItemTitle}>{item.title}</span>
                                    </div>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                </div>
            }
        </div>
    );
};

MapSlider.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        id: PropTypes.number
    })),
    renderItem: PropTypes.func,
    className: PropTypes.string,
    hasNext: PropTypes.bool,
    hasPrev: PropTypes.bool,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onClickItem: PropTypes.func,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
}

export default memo(MapSlider);
