import React, { useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'

import withRedux from '../../mapper'

import CloseIcon from '../../../../assets/imgs/icons/Close-blue.svg'
import {useTranslation} from "react-i18next";

const splideOptions = {
  pagination: false,
  infinite: false,
  speed: 700,
}
const LampModal = ({
  isLandscape,
  menuExpend,
  globalLoading,
  lampModalState,
  onCloseLampSlide,
  lampModalData,
  setLampModalStateIndex,
  stopPinAnimation,
  getArticleFromAPI,
  getLampModalStateIndex,
}) => {
  const lampSplideRef = useRef(null)
  const {t} = useTranslation()
  const splideOnMoveHandler = (_, index) => {
    setLampModalStateIndex(index)
  }

  const splideSlideClickHandler = (item) => {
    stopPinAnimation()
    getArticleFromAPI(item)
  }

  const getFormatedDate = (item) => {
    const isSameEra =
      (item?.timeStart?.isBc && item?.timeEnd?.isBc) ||
      (!item?.timeStart?.isBc && !item?.timeEnd?.isBc)
    const isSameYear = item?.timeStart?.year === item?.timeEnd?.year

    const formatYear = (year, isBc) => (isBc ? `${t('bc')} ${year}` : year)

    return isSameEra && isSameYear
      ? formatYear(item?.timeStart?.year, item?.timeStart?.isBc)
      : `${formatYear(item?.timeStart?.year, item?.timeStart?.isBc)} - ${formatYear(item?.timeEnd?.year, item?.timeEnd?.isBc)}`
  }

  const getImageSrc = (item) => {
    const featuredImage =
      item?.content[0]?.featuredImageResized || item?.content[0]?.featuredImage
    const galleryImage = item?.gallery?.filter((x) => x.type === 0)[0]?.path
    const placeholderImage = require('../../../../assets/imgs/image_placeholder.png')

    return featuredImage || (galleryImage ? galleryImage : placeholderImage)
  }

  const hintsWrapperClassname =
    window.innerWidth <= 1024 && !isLandscape
      ? 'hints_lamp_popup_sm'
      : window.innerWidth <= 1024 && isLandscape && menuExpend
        ? 'hints_lamp_popup_landscape_menu_open_sm'
        : window.innerWidth <= 1024 && isLandscape && !menuExpend
          ? 'hints_lamp_popup_landscape_sm'
          : 'hints_lamp_popup_lg'

  const [lampSliderMoveMounted, setLampSliderMoveMounted] = useState(false)

  useEffect(() => {
    if (
      !lampSliderMoveMounted &&
      lampModalData?.length > 1 &&
      lampSplideRef.current &&
      lampModalState &&
      getLampModalStateIndex !== undefined &&
      getLampModalStateIndex !== null
    ) {
      lampSplideRef.current?.go(getLampModalStateIndex)
      setLampSliderMoveMounted(true)
    }
  }, [
    lampSplideRef,
    getLampModalStateIndex,
    lampModalData,
    lampModalState,
    lampSliderMoveMounted,
  ])

  return (
    <div className={hintsWrapperClassname} data-is-loading={globalLoading}>
      <div className="modal_close_block" onClick={onCloseLampSlide}>
        <img src={CloseIcon} alt={'close'} />
      </div>
      <div className={`hints_lamp`}>
        {lampModalState && (
          <Splide
            ref={lampSplideRef}
            options={splideOptions}
            onMove={splideOnMoveHandler}
            className="hints__content-items hints__content-items_lamp">
            {lampModalData.length > 0
              ? lampModalData.map((item, index) => {
                  return (
                    <SplideSlide key={`${item.id}_'lamp`}>
                      <div
                        className="hints__content-item hints__content-item_lamp"
                        onClick={() => splideSlideClickHandler(item.id)}>
                        <div className={'hints__content-parent'}>
                          <div className="hints__content-box">
                            <button
                              className="hints__content-img"
                              type="button">
                              <img
                                src={getImageSrc(item)}
                                alt={'hints__content-img'}
                              />
                            </button>
                            <div className="hero__media__liner-gradien"></div>
                          </div>
                          <div className="hints__content-bottom">
                            <span>{getFormatedDate(item)}</span>
                          </div>
                        </div>
                        <div className="hints__content-text">
                          <button className="hints__content-name" type="button">
                            <span>{item?.content[0]?.mainTitle}</span>
                          </button>
                        </div>
                      </div>
                    </SplideSlide>
                  )
                })
              : null}
          </Splide>
        )}
      </div>
    </div>
  )
}

export default withRedux(LampModal)
