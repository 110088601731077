import React, { useEffect, useState } from 'react'
import PresentationButton from './PresentationButton'
import { Popover } from 'antd'
import PresentationList from './PresentationList'
import { useDispatch, useSelector } from 'react-redux'
// import {selectPlaybackPresentation} from "../store/presentationSlice";
import Draggable from './elements/Draggable'
import {
  selectCurrentSlide,
  selectIsMinimized,
  selectPatumSize,
  selectPlaybackPresentation,
  selectPresentationsIsShown, selectScreenshotPresentation,
  setIsMinimized,
  setPlaybackPresentation,
  setPresentationsIsShown,
} from '../store/presentationSlice'
import {
  useGetPresentationQuery,
  useGetSlideQuery,
} from '../store/presentationApi'
import {useNavigate, useSearchParams} from 'react-router-dom'
import ReactQuill from 'react-quill'
import { cn } from '../../../utils/utils'
import { getIsScreenShotMode } from '../../../store/selectors'
import { getStringFromHTML } from '../lib/utils'
import {createPortal} from "react-dom";
import {ShapeToolEditor} from "../../toolbox/ShapeToolbox";

// const ALL_QUERY_KEYS = ['presentation', 'screenSlide', 'screenShot', 'minimize', 'playBack', 'playMode', 'userId', 'external', 'articleMode', 'microArticleMode', 'screenShotId', 'open']

const PresentationWrapper = ({ children, isGlobalLoading }) => {
  const dispatch = useDispatch()
  const playbackPresentation = useSelector(selectPlaybackPresentation)
  const isMinimized = useSelector(selectIsMinimized)
  const presentationsIsShown = useSelector(selectPresentationsIsShown)
  const isScreenShotMode = useSelector(getIsScreenShotMode)

  const currentSlide = useSelector(selectCurrentSlide)
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const patumSize = useSelector(selectPatumSize)
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem('userInfo'))?.data,
  )
  const isPreview = search.get('preview')
  const [loading, setLoading] = useState(false)



  const querySlideId = search.get('screenSlide')
  const isScreenShot = search.get("screenShot") && !search.get("presentation");
  const screenshotPresentation = useSelector(selectScreenshotPresentation)

  const presentationPatumSize = playbackPresentation?.descriptionFontSize;

  const { data: slideData } = useGetSlideQuery(
    currentSlide?.id || currentSlide || search.get('screenSlide'),
    { skip: !currentSlide && !search.get('screenSlide') },
  )
  // const {data:slideDataX} = useGetSlideQuery(2123)

  const { data } = useGetPresentationQuery(
    { id: search.get('presentation') },
    { skip: !search.get('presentation') },
  )

  const currentSlideData = slideData?.data?.[0]
  const isCreator =
      currentUser && (+currentUser?.id === +data?.data?.[0]?.createdBy || !+data?.data?.[0]?.createdBy) && !isPreview
  const showDescription =
      (isCreator && playbackPresentation?.showDescriptionPopup) ||
      (!isCreator && localStorage.getItem('patumTextSize') !== 'disabled')


  useEffect(() => {
    // document.querySelector(".map-boby").addEventListener("click", () => setIsOpened(false))
  }, [])

  useEffect(() => {
    if (
      data?.data?.[0] &&
      search.get('presentation') &&
      !search.get('open')
    ) {
      dispatch(setPlaybackPresentation(data?.data?.[0]))
    }

    if (search.get('presentation') && !search.get('screenShot') && !screenshotPresentation ) {
      search.set('screenShot','true')
      navigate({search: search.toString()})
    }

  }, [data, search, screenshotPresentation])

  useEffect(() => {
    if (playbackPresentation) {
      dispatch(setPresentationsIsShown(false))
    } else if (search.get('presentation') && search.get('open') && !search.get('playBack')) {
      dispatch(setPresentationsIsShown(true))
    }
  }, [playbackPresentation, search])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 650)
  }, [querySlideId, patumSize])

  const clickButtonHandler = () => {
    if (playbackPresentation) {
      dispatch(setIsMinimized(!isMinimized))
      return
    }
    dispatch(setPresentationsIsShown(!presentationsIsShown))
    search.delete('minimize')
    search.delete('presentation')
    search.delete('screenSlide')
    search.delete('playMode')
    search.delete('playBack')
    search.delete('screenShot')
    search.delete('userId')
    search.delete('external')
    search.delete('articleMode')
    search.delete('microArticleMode')
    search.delete('screenShotId')
    search.delete('open')

    navigate(
      {
        pathname: window.location.pathname,
        search: search.toString(),
      },
      { replace: true },
    )
  }

  return (
    <>
      <div className="presentation-left-bar--container text-[#2C476C]">
        {
          !isPreview &&
            <Popover
                open={presentationsIsShown && !playbackPresentation}
                trigger="click"
                overlayClassName="z-[9999]"
                className="block"
                content={!playbackPresentation && <PresentationList isCreator={isCreator} isLoading={isGlobalLoading}/>}
                disabled={playbackPresentation}
                getPopupContainer={() => document.querySelector('.map-boby')}
                placement="rightTop"
                arrow={false}
                destroyTooltipOnHide
                overlayInnerStyle={{
                  padding: 0,
                  borderRadius: 10,
                  overflow: 'hidden',
                  // transform: 'translateY(-5px)',
                }}>
              <>
                {' '}
                {children ? (
                    children()
                ) : (
                    <PresentationButton
                        isLoading={isGlobalLoading}
                        onClick={clickButtonHandler}
                        disabled={isScreenShotMode || isScreenShot}
                        isActive={presentationsIsShown && !playbackPresentation && !search.get('playBack')}
                    />
                )}
              </>
            </Popover>
        }
        {/*{*/}
        {/*    playbackPresentation && <PlaybackWrapper/>*/}
        {/*}*/}
      </div>

      {showDescription &&
          playbackPresentation &&
          !isGlobalLoading &&
          !loading &&
          getStringFromHTML(
              currentSlideData?.description ||
              currentSlideData?.shortDescription ||
              '',
          ) && createPortal(
              <Draggable>
                <div
                    className="p-4 text-[#2C476C] max-w-[500px] bg-white shadow-2xl rounded min-w-[140px] cursor-move"
                    style={{ fontSize: presentationPatumSize ? `${presentationPatumSize}px` : patumSize ? `${patumSize}px` : 'initial' }}>
                  <div data-is-loading={isGlobalLoading} className="max-h-[80vh] overflow-y-auto">
                    <ReactQuill
                        value={
                            currentSlideData?.description ||
                            currentSlideData?.shortDescription ||
                            ''
                        }
                        readOnly={true}
                        className={cn(
                            'border-none [&>.ql-toolbar]:hidden readably--quill cursor-move',
                            {
                              'inherit-fontsize--quill': patumSize,
                            },
                        )}
                    />
                  </div>
                </div>
              </Draggable>,
          document.body
      )}

    </>
  )
}

export default PresentationWrapper
