import * as React from 'react'

const PlayCircleIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M29.3086 29.2414C35.563 22.987 35.563 12.8615 29.3086 6.62305"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.69082 6.62305C0.436391 12.8775 0.436391 23.003 6.69082 29.2414"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7207 32.9844C14.4323 33.5762 16.2078 33.8642 17.9994 33.8642C19.7909 33.8482 21.5664 33.5762 23.278 32.9844"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7207 2.87986C14.4323 2.28801 16.2078 2 17.9994 2C19.7909 2 21.5664 2.28801 23.278 2.87986"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.7852 15.2609C12.7852 11.9337 15.1366 10.5741 18.0159 12.2376L20.3353 13.5813L22.6547 14.925C25.534 16.5885 25.534 19.3079 22.6547 20.9715L20.3353 22.3151L18.0159 23.6588C15.1366 25.3224 12.7852 23.9627 12.7852 20.6355V17.9322V15.2609Z"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default PlayCircleIcon
