import React, {useState} from 'react'
import ArrowLeftSVG from '../../gallery-new/components/svg/ArrowLeftSVG'
import {Button, Modal} from 'antd'
import {CirclePlus, LayoutGrid} from 'lucide-react'
import PresentationFormWrapper from './form/PresentationFormWrapper'
import PresentationsGrid from './PresentationsGrid'
import {cn} from '../../../utils/utils'
import {useTranslation} from 'react-i18next'

const PresentationListHeader = ({ canGoBack, onClickBack, isLoading }) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [presentationsIsOpen, setPresentationsIsOpen] = useState(false)

  const onCreateSuccess = () => {
    setVisible(false)
  }

  return (
    <div
      className={cn('presentation-list--header flex justify-between !pr-2', {
        '!pl-2': canGoBack,
      })}>
      <div className="flex gap-1 tracking-wider items-center uppercase">
        {canGoBack && (
          <span
            role="button"
            tabIndex="0"
            data-is-loading={isLoading}
            className={cn("presentation-list--header-back-button", {
                'opacity-40 pointer-events-none rounded': isLoading
            })}
            onClick={onClickBack}>
            <ArrowLeftSVG />
          </span>
        )}
          <span className={cn({
            'opacity-40 pointer-events-none rounded': isLoading
          })} data-is-loading={isLoading}>{canGoBack ? t('presentation_mode.all_presentations') : t('presentation_mode.presentations')}</span>
      </div>
      {!canGoBack && (
        <div className="flex gap-1 items-center" data-is-loading={isLoading}>
          <Button
            icon={<LayoutGrid fontSize={20} color="white" />}
            type="text"
            onClick={() => setPresentationsIsOpen(true)}
          />
          <Button
            icon={<CirclePlus color="white" />}
            type="text"
            onClick={() => setVisible(true)}
          />
        </div>
      )}
      <Modal
        destroyOnClose
        title={t('presentation_mode.new_presentation')}
        mask={true}
        maskClosable={false}
        centered
        classNames={{
          body: 'max-h-[83vh] overflow-auto',
            content:'overflow-hidden',
            mask:"!z-[1240]",
            header:
            'uppercase text-center !pb-3 text-[16px] tracking-wider font-medium !pt-4 !px-10',
        }}
        getContainer={() => document.body}
        className="app-modal no-padding max-h-[90vh]"
        width={950}
        wrapClassName="!z-[1240]"
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setVisible(false)}
        open={visible}>
        <PresentationFormWrapper createMode onSuccess={onCreateSuccess} />
      </Modal>

      <Modal
        destroyOnClose
        title={t('presentation_mode.presentations')}
        centered
        classNames={{
            mask:"!z-[1240]",
            header:
            'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-10  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
        }}
        getContainer={() => document.body}
        className="app-modal max-h-[90vh]"
        wrapClassName="!z-[1240]"
        maskClosable={false}
        width={950}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setPresentationsIsOpen(false)}
        open={presentationsIsOpen}>
        <PresentationsGrid />
      </Modal>
    </div>
  )
}

export default PresentationListHeader
