import React, {useState, useCallback, useEffect, useMemo} from "react";
import './style.css'
import AttachIcon from "../../../../assets/imgs/icons/attachIcon.svg";
import AddSlide from "../../../../assets/imgs/icons/addSlide.svg";
import PlayIcon from "../../../../assets/imgs/icons/play.svg";
import SettingsIcon from '../../../../assets/imgs/icons/settings.svg'
import MinimizeIcon from '../../../../assets/imgs/icons/minimize.svg'
import MaximizeIcon from '../../../../assets/imgs/icons/maximize.svg'
import CloseIcon from '../../../../assets/imgs/icons/Close-blue.svg'
import PresentationSlideBoxes from "../../PresentationSlideBoxes";
import {setPlaySlides, setSelectedSlide, setSlidesItems, setSlidesPage} from "../../../../store/actions/slides";
import {connect, useDispatch} from "react-redux";
import {
    getIsPresentationModeData,
    getMapStateData, getPresentationData, getPresentationModeData,
    getScreenShotLoading,
    getSlidesData, getSlidesPage, getSlidesSelectedSlideData, getSubjectsSettingsData, getUserData
} from "../../../../store/selectors";
import Popup from "../../Popup";
import SlidePageEdit from "../EditSlide";
import SlideRemove from "../DeleteSlide";
import SlideSave from "../SaveSlide";
import ChooseSlides from "../ChooseSlides";
import SliderSettings from "../SliderSettings";
import {createSlideAPI} from "../../../../store/actions/slides";
import {
    editPresentationAPI,
    getPresentationAPI,
    setIsPresentationMode
} from "../../../../store/actions/presentationMode";
import MapScreenShotButton from "../../DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import {
    dispatchScreenShotLoader,
    getScreenShotDataFromAPI,
    getScreenShotSingleDataAPI, resetAllState,
    setCommentToolboxMarkersLoadingState,
    setCommentToolboxMarkersState,
    setIsAllToolboxStatesLoaded,
    setMapboxDrawToolboxGeoJSONState, setMapCenterPositionStatusST,
    setMapMarkerInfoST,
    setPainterGeoJsonDataST, setRulerGeoJsonDataST, setRulerPopupShowST,
    setStickyNotesMarkersState,
    setTextToolboxMarkersLoadingState,
    setTextToolboxMarkersState
} from "../../../../store/actions/mapStateAction";
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {
    setDrawerToolboxClickedState,
    setEraserClickedState,
    setLineDrawToolboxState,
    setMicroArticleBar,
    setMicroArticleToolClickedState,
    setPainterStart,
    setPolygonDrawToolboxState,
    setRulerClickedState,
    setShaperClickState
} from "../../../../store/actions/painterStart";
import {closeArticlePopup} from "../../../../store/actions/articles";
import {setViewPopupVisibility} from "../../../../store/actions/microArticle";
import {setShapeToolState} from "../../../../store/actions/shapeTool";
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced300 = debounce((fn) => fn(), 300);
const debeounced1000 = debounce((fn) => fn(), 1000);
const CreateNewPresentation = ({setSlidesPage,handleCloseSlideInfoPopup,slidePage,firstLoad,setFirstLoad,firstRunScreenShotStates,activePlayMode,globalLoading,getSubjectsSettingsData,handleReplaceUrlPlayMod,playPresentationRef,close, setPlaySlideZs, setSlidesItems, slides:allSlides, setIsPresentationMode,setPlaySlides,map,getScreenShotSingleDataAPI,getMapStateData,getScreenShotLoading,getScreenShotDataFromAPI,getPresentationModeData,editPresentationAPI,getPresentationAPI,hidd,getSlidesSelectedSlideData,user,setSelectedSlide,dispatchScreenShotLoader, openSlides}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedId, setSelectedId] = useState(null);
    const [editPopup, setEditPopup] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deletePopup, setDeletePopup] = useState(false);
    const [savePopup, setSavePopup] = useState(false);
    const [chooseFromScreenshots, setChooseFromScreenshots] = useState(false);
    const [settingsMenu, setSettingsMenu] = useState(false);
    const [presentationEdit, setPresentationEdit] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const [runScreenShotStates, setRunScreenShotStates] = useState(null);
    const [page, setPage] = useState(1);
    const [searchParamsInfo, setSearchParamsInfo] = useState({});
    const [getNewData, setGetNewData] = useState(false);
    const [isEditTitle, setEditTitle] = useState(false);
    const [presentationTitle, setPresentationTitle] = useState(getPresentationModeData?.title);

    const dispatch = useDispatch();

    const slides = useMemo(() => {
        return allSlides.filter((el) => !el?.isHidden)
    }, [allSlides])

    const handleFill = () => {
        if(slides.find(el => el.id == selectedId)) {
            setSlidesItems(true, selectedId+1)
            setSelectedId('')
        } else {
            setSlidesItems(true, selectedId)
            setSelectedId('')
        }
    }

    const handlePlay = async () => {
        // close()
        setPlaySlides(true)
        setIsPresentationMode(true)
        handleReplaceUrlPlayMod()
    }

    const handleSelect = (id) => {
        setSelectedId(id);
    };

    const handleEdit = (single,type) => {
        setEditPopup(true)
        if(single) {
            setEditData(getPresentationModeData)
            setPresentationEdit(true)
        } else {
            setEditData(slides.find(el => el.id == selectedId))
            setPresentationEdit(false)
        }
    }
    const handleEditSettings = (body) =>{
        editPresentationAPI(getPresentationModeData.id,body)
            .then(resp=>{
                getPresentationAPI(getPresentationModeData.id)
            })
    }
    const handleCloseEdit = () => {
        setEditPopup(false)
    }
    const handleDelete = (type) => {
        setDeletePopup(type)
    }
    const handleCloseDelete = () => {
        setDeletePopup(false)
    }
    const handleSave = () => {
        setSavePopup(true)
    }
    const handleCloseSave= () => {
        setSavePopup(false)
    }
    const handleChooseForm = () => {
        setChooseFromScreenshots(true)
    }
    const handleCloseChoosing = () => {
        setChooseFromScreenshots(false)
    }

    const handleSettingsMenu = () => {
        setSettingsMenu(true)
    }
    const handleCloseSettingsMenu = () => {
        setSettingsMenu(false)
    }

    const handleMinimize = () => {
        setIsMinimized(!isMinimized)
    }

    const handleReplaceUrlPresentation = useCallback((id) =>{

        // if(+query.get("screenSlide")) return
        // Use the browser's history API to update the URL when isPresentationMode changes
        // const presentationParam = id ? `&presentation=${id}` : '';
        const newUrl = new URLSearchParams(window.location.search);
        if(id) {
            newUrl.set('presentation', id)
        }else if(newUrl.has('presentation')){
            newUrl.delete('presentation')
            newUrl.delete('playMode')
            newUrl.delete('playBack')
            if(newUrl.has('screenSlide')){
                newUrl.delete('screenSlide')
                newUrl.delete('screenShot')
                newUrl.delete('userId')
            }
            if(newUrl.has('external')){
                newUrl.delete('external')
            }
        }
        // const newUrl = `/map?type=topic&topicID=61${presentationParam}`;
        navigate({
            pathname: window.location.pathname,
            search: newUrl.toString(),
        }, { replace: false })
    },[])

    const handleReplaceUrl = useCallback((slide,slidePage) =>{
        const selectedId = slide?.id
        const articleId = slide?.articleId
        const microArticleId = slide?.microArticleId
      // Use the browser's history API to update the URL when isPresentationMode changes
        const newUrl = new URLSearchParams(window.location.search);
      // const presentationParam = (!!selectedId && typeof selectedId === 'number')
      //                                     ? `&screenSlide=${selectedId}&userId=101&screenShot=true&loading=true`
      //                                     : '';
        const currentUser = JSON.parse(localStorage.getItem('userInfo'))?.data
      if((!!selectedId && typeof selectedId === 'number')){
          newUrl.set('screenSlide',selectedId)
          newUrl.set('screenShot', "true")
          // currentUser?.id ? newUrl.set('userId',`${currentUser?.id}`) : newUrl.delete('userId')
          getPresentationModeData?.createdBy ? newUrl.set('userId',`${getPresentationModeData.createdBy}`) : newUrl.delete('userId')
          newUrl.set('loading','true')
          if (slide?.mapStateId) {
              newUrl.set('screenShotId', slide?.mapStateId)
          } else {
              newUrl.delete('screenShotId')
          }

      }else if(newUrl.has('screenSlide')){
          newUrl.delete('screenSlide')
          newUrl.delete('screenShot')
          newUrl.delete('userId')
          if(newUrl.has('screenShotId')) newUrl.delete('screenShotId')

      }

          if (!!articleId) {
              newUrl.set('articleMode', articleId)
          } else {
              newUrl.delete('articleMode')
          }

          if (!!microArticleId) {
              newUrl.set('microArticleMode', microArticleId)
          } else {
              newUrl.delete('microArticleMode')
          }


        dispatch(setMicroArticleBar(false))
        dispatch(closeArticlePopup("all"))
        dispatch(setViewPopupVisibility(false))
        dispatch(setMapMarkerInfoST({}))
        dispatch(setDrawerToolboxClickedState(false))
        dispatch(setPainterGeoJsonDataST({

        }))
        dispatch(setLineDrawToolboxState(false));
        dispatch(setPolygonDrawToolboxState(false));
        dispatch(setRulerClickedState(false));
        dispatch(setEraserClickedState(false));
        dispatch(setDrawerToolboxClickedState(false));

        dispatch(setMapboxDrawToolboxGeoJSONState({
            type: 'FeatureCollection',
            features: []
        }));
        dispatch(setStickyNotesMarkersState([]));
        dispatch(setTextToolboxMarkersState([]));
        dispatch(setTextToolboxMarkersLoadingState(false));
        dispatch(setIsAllToolboxStatesLoaded(false));
        dispatch(setCommentToolboxMarkersState([]));
        dispatch(setCommentToolboxMarkersLoadingState(false));
        dispatch(setIsAllToolboxStatesLoaded(false));
        dispatch(setPainterStart(false));
        dispatch(setRulerClickedState(false));
        dispatch(setRulerGeoJsonDataST([]));
        dispatch(setRulerPopupShowST(false));
        dispatch(setShapeToolState({
            shapes:[]
        }));
       dispatch(setShaperClickState(false));
       dispatch(setMapCenterPositionStatusST(false))

        setTimeout(() => {
            dispatch(setMicroArticleToolClickedState(false))
        },0)

        navigate({
            pathname: window.location.pathname,
            search: newUrl.toString(),
        }, { replace: true })
    },[])

    const runSlide = async (id) =>{
        // alert("runSlide CREATE PRESENTATTIOn MODE")

        if(!id) return
        window.scrrenShot = true
        dispatchScreenShotLoader(true)
        const getApi =  await getScreenShotSingleDataAPI(id,'Slide')
        await getApi.call()
            .then((res) => getApi.serialized(res))
            .then((res) => getApi.setLanguage(res))
            .then((res) => getApi.setLocation(res))
            .then((res) => {
                getApi.setData(res)
                    .then((runScreenShot)=>{
                        // if(!firstLoad){
                        //     const statesST = ['checkSettingsSTF','loadBaseMap','toolBarSTF','compassSTF','leftBarSTF','timelineSTF','leftBarCatOpenSTF','mapSTF','loadDoneSTF',]
                        //     runScreenShot?.(statesST)
                        // }
                        setRunScreenShotStates(()=>runScreenShot)
                    })
            })
        // await getScreenShotSingleDataAPI(id,'Slide')
        //     .then((runScreenShotStates)=>{
        //         // if(!firstLoad){
        //         //     const statesST = ['checkSettingsSTF','loadBaseMap','toolBarSTF','compassSTF','leftBarSTF','timelineSTF','leftBarCatOpenSTF','mapSTF','loadDoneSTF',]
        //         //     runScreenShot?.(statesST)
        //         // }
        //         setRunScreenShotStates(()=>runScreenShotStates)
        //     })
    }
    const handleChangePage = (e, value) => {
        const pageLoc = +value;
        if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
            setPage(() => pageLoc);
        }
    };
    const handleNextPage = () =>{
        const nextPage = page+1
        handleChangePage(null, nextPage)
    }
    const handleSetSearchParams = (e) =>{
        debeounced300(()=> {
            handleChangePage(null,1)
            const newSearch = {...searchParamsInfo}
            newSearch.title = e?.target?.value || ''
            setSearchParamsInfo(prev => newSearch)
        })
    }
    const handleGetData = () =>{
        let pageLoc = page;
        if (pageLoc > Math.ceil(getMapStateData.total / 10))
            pageLoc = Math.ceil(getMapStateData.total / 10);
        if (pageLoc < 1) pageLoc = 1;
        if (!isNaN(pageLoc) && typeof pageLoc === "number") {
            if (pageLoc !== page) setPage(() => pageLoc);
            getScreenShotDataFromAPI(pageLoc, user?.id,null,null,searchParamsInfo,9,'add');
            //     "page": pageLoc,
            //     "perPage": 1000,
            //     ...searchParams
            // });
        }
    }
    const closeEditTitle = () => {
        setEditTitle(false)
    }
    const openEditTitle = () => {
        setPresentationTitle(getPresentationModeData?.title)
        setEditTitle(true)
    }
    const submitEditTitle = () => {
        handleEditSettings({
            title: presentationTitle,
            shortDescription: getPresentationModeData?.shortDescription
        })
        closeEditTitle()
    }

    useEffect(()=>{
        if(getPresentationModeData?.id) handleReplaceUrlPresentation(getPresentationModeData?.id)
    },[])

    useEffect(()=>{
        if(query.get("loading") === 'true' || firstLoad  || !+query.get("screenSlide") || query.get("playMode") === "true" ) return
        // runSlide(selectedId || +query.get("screenSlide"))
    },[searchParams])

    useEffect(()=> {
        if(Object.keys(getSubjectsSettingsData).length > 0 ) {
            const statesST = ['checkSettingsSTF','loadDoneSTF',]
            // const statesST = ['articeModeeSTF','loadDoneSTF',]
            if(firstLoad){
                setFirstLoad(()=>false)
                firstRunScreenShotStates?.(statesST)
                return
            }
            runScreenShotStates?.(statesST)
        }

    },[getSubjectsSettingsData,runScreenShotStates])

    useEffect(()=>{
        if(!isNaN(+query.get("screenSlide")) && !getSlidesSelectedSlideData?.id){
            setSelectedId(()=>+query.get("screenSlide"))
            const slide = slides.find(el=>el.id === +query.get("screenSlide")) || []
            setSelectedSlide(slide)
        }
    },[slides])
    useEffect(()=>{
        if(!getSlidesSelectedSlideData?.id) return
        if(!isNaN(+query.get("screenSlide")) && (+query.get("screenSlide") === getSlidesSelectedSlideData?.id)) return;
        handleReplaceUrl(getSlidesSelectedSlideData,slidePage)
    },[getSlidesSelectedSlideData])

    useEffect(()=> {
        if(!getSlidesSelectedSlideData?.id) return
        setSelectedId(getSlidesSelectedSlideData?.id)
    },[getSlidesSelectedSlideData])

    useEffect(() => {
        if(user?.id){
            handleGetData()
        }
    }, [searchParamsInfo,user,page]);
    const header = () => {
        return (
            <div className={`header__container ${activePlayMode ? 'hidden' : ''}`}>
                <div className='header__first__section'>
                    <MapScreenShotButton handleFill={handleFill} map={map} globalLoading={globalLoading}/>
                    <button onClick={handleChooseForm}
                            data-is-loading={globalLoading}>
                        <img src={AddSlide}/>
                    </button>
                    <button ref={playPresentationRef} onClick={handlePlay} disabled={!slides.length}
                            data-is-loading={globalLoading}>
                        <img src={PlayIcon}/>
                    </button>
                    {isEditTitle ?
                        <input
                            className='edit_presentation_title_input'
                            type={'text'}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    submitEditTitle()
                                }
                            }}
                            onChange={(e) => setPresentationTitle(e.target.value)}
                            autoFocus={true}
                            onBlur={closeEditTitle}
                            value={presentationTitle}
                        />
                        : <p onDoubleClick={openEditTitle} data-is-loading={globalLoading}>
                            {getPresentationModeData?.title}
                        </p>
                    }
                </div>
                <div className='header__second__section'>
                    <button onClick={handleSettingsMenu}
                            data-is-loading={globalLoading}>
                        <img src={SettingsIcon}/>
                    </button>
                    <button onClick={handleMinimize}
                            data-is-loading={globalLoading}>
                        <img src={isMinimized ? MaximizeIcon : MinimizeIcon}/>
                    </button>
                    <button onClick={close}
                            data-is-loading={globalLoading}>
                        <img src={CloseIcon}/>
                    </button>
                </div>
            </div>
        )
    }

    return null

    if(activePlayMode) return <div className={'container-presentation-deactivate'}></div>
    return(
        <div className={`container-presentation ${activePlayMode ? 'hidden' : ''} create-new-presentation`} data-hidd={hidd} >
            {header()}
            {!isMinimized &&
                <PresentationSlideBoxes
                    globalLoading={globalLoading}
                    selectedId={selectedId}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleSave={handleSave}
                    onSelect={handleSelect}
                    showInfo={false}
                    map={map}
                    getNewData={getNewData}
                    setGetNewData={setGetNewData}
                    handleCloseSlideInfoPopup={handleCloseSlideInfoPopup}
                />}
            <Popup
                isOpened={editPopup}
                onClose={handleCloseEdit}
                styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}
            >
                <SlidePageEdit item={editData} onClose={handleCloseEdit} presentation={presentationEdit} />
            </Popup>
            <Popup
                isOpened={deletePopup}
                onClose={handleCloseDelete}
                styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}
            >
                <SlideRemove openSlides={openSlides} closePresentation = {close}  onClose={handleCloseDelete} selectedId={selectedId} deletePopup={deletePopup}/>
            </Popup>
            <Popup
                isOpened={savePopup}
                onClose={handleCloseSave}
                styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}
            >
                <SlideSave onClose={handleCloseSave} />
            </Popup>
            <Popup
                isOpened={chooseFromScreenshots}
                onClose={handleCloseChoosing}
                styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}
            >
                <ChooseSlides setSelectedId={setSelectedId}
                              selectedId={selectedId}
                              onClose={handleCloseChoosing}
                              data={getMapStateData?.items}
                              filteredTotal={getMapStateData?.filteredTotal}
                              handleSetSearchParams={handleSetSearchParams}
                              handleNextPage={handleNextPage}
                              handleGetData={handleGetData}
                              setGetNewData={setGetNewData}
                />
            </Popup>
            <Popup
                stylePopupUser={{placeItems: 'unset'}}
                isOpened={settingsMenu}
                onClose={handleCloseSettingsMenu}
                styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}
            >
                <SliderSettings
                    item={getPresentationModeData}
                    slides={slides}
                    setSelectedId={setSelectedId}
                    selectedId={selectedId}
                    onClose={handleCloseSettingsMenu}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    handleEditSettings={handleEditSettings}
                />
            </Popup>
        </div>
    )
}

const mapDispatchToProps =  {
    setSlidesItems: setSlidesItems,
    setPlaySlides: setPlaySlides,
    setIsPresentationMode: setIsPresentationMode,
    createSlideAPI,
    getScreenShotSingleDataAPI,
    getScreenShotDataFromAPI,
    editPresentationAPI:editPresentationAPI,
    getPresentationAPI:getPresentationAPI,
    setSelectedSlide:setSelectedSlide,
    dispatchScreenShotLoader:dispatchScreenShotLoader,
    setSlidesPage:setSlidesPage,

};

const mapStateTopProps = (state) => ({
    user: getUserData(state),
    slides: getSlidesData(state),
    getMapStateData: getMapStateData(state),
    getScreenShotLoading: getScreenShotLoading(state),
    getPresentationModeData: getPresentationModeData(state),
    getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),
    getSubjectsSettingsData:getSubjectsSettingsData(state),
    slidePage: getSlidesPage(state),


});

export default connect(mapStateTopProps, mapDispatchToProps)(CreateNewPresentation);
