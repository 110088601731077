import React from 'react'
import {useGetPresentationQuery} from '../store/presentationApi'
import {Button, Image, Skeleton} from 'antd'
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";


const IntroSkeleton = () => {
    return (
        <div className="overflow-hidden rounded-md">
          <Skeleton paragraph={false} title={false} active className="w-full flex aspect-[6/4]  bg-[#f4f4f4] max-h-[37dvh]"/>

          <div className="px-3 md:px-6 py-2">
            <h3 className="uppercase text-[#2C476C]">
              <Skeleton paragraph={false} active rootClassName="w-3/4"/>
            </h3>
            <h4 className="text-[#2C476C80] py-2">
              <Skeleton paragraph={false} active rootClassName="w-1/4"/>
            </h4>
            <div className="max-h-[130px] overflow-auto">
              <Skeleton paragraph={false} active rootClassName="w-1/4"/>
            </div>

            <div className="flex gap-3 p-3 justify-center w-full">
              <Skeleton.Button rootClassName="w-1/4"/>
            </div>
          </div>
        </div>
    )
}

const PresentationIntro = ({id, onStart, loading}) => {
  const {t} = useTranslation()
  const {data, isLoading} = useGetPresentationQuery({id}, {skip: !id})

  const presentation = data?.data[0]


  if (isLoading || loading) {
    return <IntroSkeleton/>
  }

  return (
      <div className="overflow-hidden rounded-md">
        {presentation?.imageUrl && (
            <Image
                rootClassName="w-full flex aspect-[6/4]  bg-[#f4f4f4] max-h-[37dvh]"
                className="object-contain"
                src={presentation?.imageUrl}
                preview={false}
            />
        )}
        <div className="px-3 md:px-6 py-2">
          <h3 className="uppercase text-[#2C476C]">{presentation?.title}</h3>
          {presentation?.creatorFirstName && (
              <h4 className="text-[#2C476C80] py-2">
                {t("presentation_mode.author")} {presentation?.creatorFirstName || ''} {presentation?.creatorLastName || ''}
              </h4>
          )}

          <div className="max-h-[130px] overflow-auto text-[#2C476C]">
            <ReactQuill value={presentation?.description || presentation?.shortDescription} readOnly={true}
                        className="border-none [&>.ql-toolbar]:hidden readably--quill"/>
          </div>

          <div className="flex gap-3 p-3 justify-center">
            <Button
                onClick={onStart}
                rootClassName="uppercase text-[12px] h-[30px] min-w-[130px] bg-[#FFDE5A] border-none text-black rounded-[38px] shadow-none cursor-pointer tracking-[2px] hover:!bg-[#FFDE5A] hover:!shadow-none hover:!text-black hover:!border-none"
            type="text">
            {t('presentation_mode.play')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PresentationIntro
