
export const mapboxGlDrawStyles = [
    // default themes provided by MB Draw
    // default themes provided by MB Draw
    // default themes provided by MB Draw
    // default themes provided by MB Draw


    {
        'id': 'gl-draw-polygon-fill-inactive',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'fill-color': '#3bb2d0',
            'fill-outline-color': '#3bb2d0',
            'fill-opacity': 0.1
        }
    },
    {
        'id': 'gl-draw-polygon-fill-active',
        'type': 'fill',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': '#fbb03b',
            'fill-outline-color': '#fbb03b',
            'fill-opacity': 0.1
        }
    },
    // {
    //   'id': 'gl-draw-polygon-midpoint-duplicate',
    //   'type': 'circle',
    //   'filter': ['all', ['==', '$type', 'Point'],
    //     ['==', 'meta', 'midpoint']
    //   ],
    //   'paint': {
    //     'circle-radius': 5,
    //     'circle-opacity': 1,
    //     'circle-color': '#fbb03b'
    //   },
    // },
    {
        'id': 'gl-draw-polygon-midpoint',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fbb03b'
        },
        // 'layout': {
        //   'symbol-placement': "line-center",
        //   'symbol-z-order': 100
        // }
    },
    {
        'id': 'gl-draw-polygon-stroke-inactive',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Polygon'],
            ['!=', 'mode', 'static']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#3bb2d0',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-active',
        'type': 'line',
        'filter': ['all', ['==', 'active', 'true'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#fbb03b',
            'line-dasharray': [0.2, 2],
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-line-inactive',
        'type': 'line',
        'filter': [
            'all',
            ['==', 'active', 'false'],
            ['==', '$type', 'LineString'],
            ['!=', 'mode', 'static'],
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        "paint": {
            "line-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            "line-width": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-line-active',
        'type': 'line',
        'filter': [
            'all',
            ['==', '$type', 'LineString'],
            ['==', 'active', 'true'],
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        "paint": {
            "line-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            // "line-dasharray": [0.2, 2],
            "line-width": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
        'type': 'circle',
        'filter': [
            'all',
            ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        "paint": {
            "circle-color": [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#fbb03b"
            ],
            "circle-radius": [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                7
            ],
            "circle-stroke-width": 7,
            "circle-stroke-color": "rgba(0,0,0,0)"
        }
    },
    {
        'id': 'gl-draw-polygon-and-line-vertex-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'meta', 'vertex'],
            ['==', '$type', 'Point'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-point-stroke-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-opacity': 1,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-inactive',
        'type': 'circle',
        'filter': ['all', ['==', 'active', 'false'],
            ['==', '$type', 'Point'],
            ['==', 'meta', 'feature'],
            ['!=', 'mode', 'static']
        ],
        'paint': {
            'circle-radius': 3,
            'circle-color': '#3bb2d0'
        }
    },
    {
        'id': 'gl-draw-point-stroke-active',
        'type': 'circle',
        'filter': ['all', ['==', '$type', 'Point'],
            ['==', 'active', 'true'],
            ['!=', 'meta', 'midpoint']
        ],
        'paint': {
            'circle-radius': 7,
            'circle-color': '#fff'
        }
    },
    {
        'id': 'gl-draw-point-active',
        'type': 'circle',
        'filter': [
            'all',
            ['==', '$type', 'Point'],
            ['!=', 'meta', 'midpoint'],
            ['==', 'active', 'true']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': "#FF0000"
        }
    },
    {
        'id': 'gl-draw-polygon-fill-static',
        'type': 'fill',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'paint': {
            'fill-color': '#404040',
            'fill-outline-color': '#404040',
            'fill-opacity': 0.1
        }
    },
    {
        'id': 'gl-draw-polygon-stroke-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Polygon']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#404040',
            'line-width': 2
        }
    },
    {
        'id': 'gl-draw-line-static',
        'type': 'line',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'LineString']
        ],
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': [
                "case",
                ["has", "user_portColor"],
                ["get", "user_portColor"],
                "#53D669"
            ],
            'line-width': [
                "case",
                ["has", "user_portWidth"],
                ["get", "user_portWidth"],
                3
            ]
        }
    },
    {
        'id': 'gl-draw-point-static',
        'type': 'circle',
        'filter': ['all', ['==', 'mode', 'static'],
            ['==', '$type', 'Point']
        ],
        'paint': {
            'circle-radius': 5,
            'circle-color': '#404040'
        }
    },
]

export const SOURCE_OPTIONS_FILTER =  ['any',
    ['all',
        ["==", ['get', 'relationFilter'], 'visible'],
        ["==", ['get', 'visible'], 'visible'],
        // ["==", ['get','active'], true],
        ['any',
            ["!=", ['get', 'startTime'], ['get', 'endTime']],
            ['all', ["==", ['get', 'startTime'], 0], ["==", ['get', 'endTime'], 0]],
            ['all', [">=", ['get', 'startTime'], ['get', 'yearStart']], ["<=", ['get', 'startTime'], ['get', 'yearEnd']]],
            ['all', [">=", ['get', 'endTime'], ['get', 'yearStart']], ["<=", ['get', 'endTime'], ['get', 'yearEnd']]]
        ]
    ],
    ["==", ['get', 'cluster'], true],
]
export const mapboxGlDrawStylesIds = {
    "gl-draw-polygon-fill-inactive.hot": true,
    "gl-draw-polygon-fill-inactive.cold": true,
    "gl-draw-polygon-fill-active.hot": true,
    "gl-draw-polygon-fill-active.cold": true,
    "gl-draw-polygon-midpoint.hot": true,
    "gl-draw-polygon-midpoint.cold": true,
    "gl-draw-polygon-stroke-inactive.hot": true,
    "gl-draw-polygon-stroke-inactive.cold": true,
    "gl-draw-polygon-stroke-active.hot": true,
    "gl-draw-polygon-stroke-active.cold": true,
    "gl-draw-line-inactive.hot": true,
    "gl-draw-line-inactive.cold": true,
    "gl-draw-line-active.hot": true,
    "gl-draw-line-active.cold": true,
    "gl-draw-polygon-and-line-vertex-stroke-inactive.hot": true,
    "gl-draw-polygon-and-line-vertex-stroke-inactive.cold": true,
    "gl-draw-polygon-and-line-vertex-inactive.hot": true,
    "gl-draw-polygon-and-line-vertex-inactive.cold": true,
    "gl-draw-point-point-stroke-inactive.hot": true,
    "gl-draw-point-point-stroke-inactive.cold": true,
    "gl-draw-point-inactive.hot": true,
    "gl-draw-point-inactive.cold": true,
    "gl-draw-point-stroke-active.hot": true,
    "gl-draw-point-stroke-active.cold": true,
    "gl-draw-point-active.hot": true,
    "gl-draw-point-active.cold": true,
    "gl-draw-polygon-fill-static.hot": true,
    "gl-draw-polygon-fill-static.cold": true,
    "gl-draw-polygon-stroke-static.hot": true,
    "gl-draw-polygon-stroke-static.cold": true,
    "gl-draw-line-static.hot": true,
    "gl-draw-line-static.cold": true,
    "gl-draw-point-static.hot": true,
    "gl-draw-point-static.cold": true
};
