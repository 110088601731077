const VolumeIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5 23.0002C5 26.0002 6.5 27.5002 9.5 27.5002H11.645C12.2 27.5002 12.755 27.6652 13.235 27.9502L17.615 30.6952C21.395 33.0652 24.5 31.3402 24.5 26.8852V13.1152C24.5 8.64517 21.395 6.93517 17.615 9.30517L13.235 12.0502C12.755 12.3352 12.2 12.5002 11.645 12.5002H9.5C6.5 12.5002 5 14.0002 5 17.0002V23.0002Z"
      stroke="white"
      strokeWidth={2.5}
    />
    <path
      d="M29 14C31.67 17.555 31.67 22.445 29 26"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.7441 10.25C36.0791 16.025 36.0791 23.975 31.7441 29.75"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 2V38H2V2H38Z"
      stroke="white"
      strokeWidth={2.5}
    />
  </svg>
)
export default VolumeIcon
