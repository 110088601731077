import { useRef, useEffect } from 'react';

const usePulseAnimation = (
    map,
    featureLayerId,
    pulseSourceId,
    pulseLayerId,
    { duration = 1500, maxRadius = 40 } = {}
) => {
    const pulsingFeatureRef = useRef(null);
    const animationFrameRef = useRef(null);
    let lastUpdateTime = 0;

    const updatePulseSource = () => {
        const pulseSource = map.getSource(pulseSourceId);
        if (!pulseSource) return;

        const currentData = pulseSource.serialize()?.data || { type: 'FeatureCollection', features: [] };

        if (pulsingFeatureRef.current) {
            const { featureId, radius, opacity, coordinates, backgroundColor } = pulsingFeatureRef.current;
            const newPulse = {
                type: 'Feature',
                geometry: { type: 'Point', coordinates },
                properties: { radius, opacity, featureId, backgroundColor },
            };

            const newPulseData = { type: 'FeatureCollection', features: [newPulse] };

            if (JSON.stringify(currentData) !== JSON.stringify(newPulseData)) {
                pulseSource.setData(newPulseData);
            }
        } else if (currentData.features.length > 0) {
            pulseSource.setData({ type: 'FeatureCollection', features: [] });
        }
    };

    const animatePulse = () => {
        const now = Date.now();
        if (now - lastUpdateTime < 16) {
            animationFrameRef.current = requestAnimationFrame(animatePulse);
            return;
        }
        lastUpdateTime = now;

        if (!pulsingFeatureRef.current) {
            animationFrameRef.current = null;
            return;
        }

        const currentTime = Date.now();
        const pulse = pulsingFeatureRef.current;
        const elapsed = currentTime - pulse.startTime;
        const progress = elapsed / duration;

        if (progress > 1) {
            resetPulse(pulse);
        } else {
            updatePulseProperties(pulse, progress);
        }

        updatePulseSource();
        animationFrameRef.current = requestAnimationFrame(animatePulse);
    };

    const resetPulse = (pulse) => {
        pulse.radius = 0;
        pulse.opacity = 1;
        pulse.startTime = Date.now();
    };

    const updatePulseProperties = (pulse, progress) => {
        pulse.radius = maxRadius * progress;
        pulse.opacity = 1 - progress;
    };

    const startPulse = (feature) => {
        if (pulsingFeatureRef.current?.featureId === feature.properties.id) {
            // stopPulse();
            return;
        }

        pulsingFeatureRef.current = {
            featureId: feature.properties.id,
            radius: 0,
            opacity: 1,
            startTime: Date.now(),
            coordinates: feature.geometry.coordinates,
            backgroundColor: feature.properties.backgroundColor || '#808080',
        };

        updatePulseSource();

        if (!animationFrameRef.current) {
            animationFrameRef.current = requestAnimationFrame(animatePulse);
        }
    };

    const stopPulse = () => {
        pulsingFeatureRef.current = null;
        // console.error("ON VIEW POPOUP CLOSE")

        updatePulseSource();
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }
    };

    useEffect(() => stopPulse, []);

    return { startPulse, stopPulse, pulsingFeatureRef };
};

export default usePulseAnimation;
