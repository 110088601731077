import React, {cloneElement, useMemo, useState} from 'react'
import {Button, Input, Menu, Popover, Spin} from 'antd'

import {cn} from '../../../../utils/utils'
import {useGetScreenshotsQuery, useMapStateImportToSlideMutation,} from '../../store/presentationApi'

import i18n from 'i18next'

import {useSelector} from 'react-redux'
import {DEFAULT_MAP_STATE} from '../../lib/constants'
import {generateSiteUrl} from '../../lib/utils'
import SearchIcon from '../icons/SearchIcon'
import {ArrowDownUp} from 'lucide-react'
import ScreenShotCard from './ScreenShotCard'
import useDebounce from '../../../../hooks/useDebounce'
import CardSkeleton from '../elements/CardSkeleton'
import {useTranslation} from "react-i18next";

const PresentationScreenshotSelector = ({
  onCancel,
  onSuccess,
  presentationId,
}) => {
  const { t } = useTranslation()
  const lng = i18n.language === 'en' ? 2 : 1

  const [selectedArticles, setSelectedArticles] = React.useState([])
  const [selectedMicroArticles, setSelectedMicroArticles] = React.useState([])
  const mapStyledId = useSelector((state) => state?.mapState.mapStylesId)
  const baseMapGroupId = useSelector((state) => state?.mapState.baseMapGroupId)
  const baseMapIsLightSt = useSelector(
    (state) => state?.mapState.baseMapIsLight,
  )

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [sortBy, setSortBy] = useState('createdAt')
  const [searchValue, setSearchValue] = React.useState('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const {
    data: presentationsData,
    refetch,
    isFetching,
  } = useGetScreenshotsQuery({
    page: 1,
    perPage: 1000,
    columnFilters: {
      title: debouncedSearchValue,
    },
    userIds: [userInfo?.data?.id],
    sort: [
      {
        field: sortBy,
        type: 'desc',
      },
    ],
  })

  const records = useMemo(
    () => presentationsData?.data?.[0]?.items || [],
    [presentationsData],
  )

  const [onImportToSlide] = useMapStateImportToSlideMutation()

  const onSetSelected = (item) => {
    if (selectedArticles.find((el) => el.id === item.id)) {
      setSelectedArticles(selectedArticles.filter((el) => el.id !== item.id))
    } else {
      setSelectedArticles([...selectedArticles, item])
    }
  }

  const onSetMicroArticleSelected = (item) => {
    console.log('item', item)
    if (selectedMicroArticles.find((el) => el.id === item.id)) {
      setSelectedMicroArticles(
        selectedMicroArticles.filter((el) => el.id !== item.id),
      )
    } else {
      setSelectedMicroArticles([...selectedMicroArticles, item])
    }
  }

  const submitHandler = () => {
    const queryParams = new URLSearchParams(window.location.search)

    const url = generateSiteUrl({
      presentationId,
      topicId: queryParams.get('topicID'),
      lessonId: queryParams.get('lessonID'),
    })

    const state = {
      ...DEFAULT_MAP_STATE,
      url,
      getMapStyledId: mapStyledId,
      baseMapGroupIdST: baseMapGroupId,
      baseMapIsLight: baseMapIsLightSt,
      languageId: lng,
    }

    onImportToSlide({
      presentationId,
      mapStateIds: selectedArticles.map((item) => item.id),
    })
      .unwrap()
      .then((res) => {
        onCancel?.()
        onSuccess?.()
      })
    // onConfirm(selectedArticles)
  }

  const items = [
    {
      label: t("presentation_mode.sort_alphabetically"),
      key: 'title',
    },
    {
      label: t("presentation_mode.sort_created_date"),
      key: 'createdAt',
    },
    {
      label: t("presentation_mode.sort_modified_date"),
      key: 'modifiedAt',
    },
  ]

  const clickDropdownHandler = (e) => {
    // e.domEvent.stopPropagation();
    switch (e.key) {
      case 'title':
        setSortBy('title')
        break
      case 'createdAt':
        setSortBy('createdAt')
        break
      case 'modifiedAt':
        setSortBy('modifiedAt')
        break
      default:
    }
  }

  const menuPopover = (
    <div className="-m-[12px] max-h-[250px] overflow-y-auto ">
      <div className="pt-3 px-5">
        <span className="text-[#2C476C80] uppercase">{t("presentation_mode.sort")} </span>
      </div>
      <Menu className="p-0 bg-transparent" selectable={false}>
        {items.map((item) => (
          <Menu.Item
            className={cn('p-0', {
              'bg-[#2C476C10]': sortBy === item.key,
            })}
            key={item.name}
            onClick={() => {
              clickDropdownHandler(item)
              setMenuIsOpen(false)
            }}>
            <div className={cn('flex items-center pr-2', {})}>
              {item.icon && (
                <div className="w-6 h-6 flex items-center justify-center text-[#2C476C]">
                  {item.icon}
                </div>
              )}

              <span className="ml-1 text-[#2C476C]">{item.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )

  return (
    <div className=" text-[#2C476C]" style={{ maxWidth: '100%' }}>
      <div className="flex justify-between items-center my-2 gap-3">
        <Input
          variant="borderless"
          onChange={(e) => setSearchValue(e.target.value)}
          rootClassName="search-input w-full lg:w-[31%] ml-2.5 [&>input]:!text-[#2C476C]"
          suffix={isFetching ? <Spin /> : <SearchIcon />}
          placeholder={t("presentation_mode.search_map")}
        />
        <Popover
          onOpenChange={(open) => setMenuIsOpen(open)}
          openClassName="p-0"
          placement="bottomRight"
          content={menuPopover}
          open={menuIsOpen}
          trigger={['click']}
          arrow={false}
          overlayClassName="app-dropdown presentation-article-selector--dropdown rounded-lg p-0 [&>div]:rounded-lg [&>div]:overfloxw-hidden"
          menu={{ items: items, selectable: true }}
          dropdownRender={(menus) => (
            <div className="">{cloneElement(menus)}</div>
          )}>
          <Button
            icon={<ArrowDownUp size={20} color={'#2C476C'} />}
            type="text"
          />
        </Popover>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 h-full overflow-auto max-h-[50vh] px-2">
        {records.map((item, index) => (
          <ScreenShotCard
            key={item.id}
            id={item.id}
            index={index}
            image={item.imageUrl}
            onChange={() => onSetSelected(item)}
            checked={selectedArticles.find((el) => el.id === item.id)}
            onSuccess={refetch}
            name={item.title}
          />
        ))}
        {isFetching &&
          Array(6)
            .fill(0)
            .map((_, index) => <CardSkeleton key={index} />)}
      </div>
      <div className="presentation-article-selector--footer !pb-0 flex-col gap-3">

        {!!selectedArticles?.length ? (
            <span className="text-[#2C476C80] uppercase">
            {t('presentation_mode.selected_maps', {
              count: selectedArticles?.length ,
            })}
          </span>
        ) : (
            <span className="text-[#2C476C80] uppercase">
            {t('presentation_mode.select_min_one_map')}
          </span>
        )}

        <Button
          disabled={!selectedArticles?.length}
          type="text"
          onClick={submitHandler}
          className="uppercase text-[12px] h-[30px] min-w-[130px] bg-[#FFDE5A] border-none text-black rounded-[38px] shadow-none cursor-pointer tracking-[2px] hover:!bg-[#FFDE5A] hover:!shadow-none hover:!text-black hover:!border-none">
          {t('presentation_mode.my_maps_add')}
        </Button>
      </div>
    </div>
  )
}

export default PresentationScreenshotSelector
