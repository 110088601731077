import React from 'react'
import clsx from 'clsx'
import {Button} from 'antd'
import './presentation.css'
import PresentationIcon from './icons/PresentationIcon'

const PresentationButton = ({ isActive, disabled, isLoading, ...rest }) => {
  const isActiveAndNotLoading = isActive && !isLoading;

    return (
    <Button
      icon={
        <PresentationIcon
          isActive={isActiveAndNotLoading}
          strokeColor={isActiveAndNotLoading ? '#fff' : '#2C476C'}
        />
      }
      className={clsx('presexntation__button presentation-btn header_burger', {
        active: isActiveAndNotLoading,
        'pointer-events-none': disabled,
      })}
      type="button"
      disabled={disabled}
      data-is-loading={isLoading}
      {...rest}
    />
  )
}

export default PresentationButton
