// IconSelectPopup.js

import React, {memo, Suspense, useCallback, useEffect, useMemo, useState} from 'react'
import styles from '../PopupForm.module.css'
import {Button, Empty, Input, List, Space} from 'antd'
import { PlusCircleFilled } from '@ant-design/icons'
import InfiniteIconsGrid from '../InfiniteIconsGrid'
import CustomColorPicker from '../../CustomColorPicker'
import {
  getMicroArticleIconsKeys,
  getMicroArticleIconsSpriteCategories,
  getMicroArticleSelectedCategoryId,
  getMicroArticleSpriteIconsSearch,
} from '../../../../../store/selectors'
import {
  setMicroArticleIconsKeys,
  setMicroArticleIconsSpriteCategories,
  setMicroArticleSelectedCategoryId,
  setMicroArticleSpriteIconsSearch,
} from '../../../../../store/actions/microArticle'
import { connect } from 'react-redux'
import { INITIAL_ICON_OBJECT, startTransitionPolyfill } from '../../../utils'
import SearchIcon from '../../../../../assets/imgs/search-icon.svg'
import { useTranslation } from 'react-i18next'

const IconSelectPopup = ({
  handleIconSelect,
  handleColorChangeLocal,
  recentColors,
  selectedColor,
  getMicroArticleIconsSpriteCategories,
  getMicroArticleIconsKeys,
  getMicroArticleSelectedCategoryId,
  setMicroArticleSelectedCategoryId,
  getMicroArticleSpriteIconsSearch,
  setMicroArticleSpriteIconsSearch,
  selectedIcon,
  selectedIconObject,
  setSelectedIconObject,
}) => {
  const colors = useMemo(() => ['#157EFA', '#53D669', '#FED031', '#FC3142'], [])
  const { t } = useTranslation()
  const [showIconsGrid, setShowIconsGrid] = useState(false)

  const [searchText, setSearchText] = useState(getMicroArticleSpriteIconsSearch)

  useEffect(() => {
    setMicroArticleSelectedCategoryId(null)
    const handler = setTimeout(() => {
      setMicroArticleSpriteIconsSearch(searchText)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [searchText, setMicroArticleSpriteIconsSearch])

  useEffect(() => {
    startTransitionPolyfill(() => {
      setShowIconsGrid(true)
    })
  }, [showIconsGrid])

  // Retrieve all icon names from sprite data
  const handleChangeIcon = useCallback(
    (iconKey, icon) => {
      handleIconSelect(iconKey)
    },
    [handleIconSelect],
  )

  useEffect(() => {
    if (selectedIcon) {
      if (getMicroArticleIconsKeys) {
        getMicroArticleIconsKeys.forEach((icon) => {
          if (icon.lightKey === selectedIcon || icon.darkKey === selectedIcon) {
            setSelectedIconObject(icon)
          }
        })
      }
    } else {
      setSelectedIconObject(INITIAL_ICON_OBJECT)
    }
  }, [selectedIcon, getMicroArticleIconsKeys])

  return (
    <div className={styles.environmentPopup}>
      <div style={{ maxHeight: 'inherit', width: '100%' }}>
        {/* Left Column: Categories */}
        <div
          className={`${styles.popupLeft} ${styles.hideScrollbar} custom-scroll-v3`}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}>
            {/* Sticky Title */}

            {/*<div*/}
            {/*  style={{*/}
            {/*    position: 'sticky',*/}
            {/*    top: 0,*/}
            {/*    background: '#E9ECF0',*/}
            {/*    zIndex: 10,*/}
            {/*  }}>*/}
            {/*  <Typography.Paragraph*/}
            {/*    style={{*/}
            {/*      margin: 10,*/}
            {/*      padding: 5,*/}
            {/*    }}>*/}
            {/*    {t(*/}
            {/*      'microArticle.mapView.createPopupTexts.iconPopup.columnName',*/}
            {/*    )}*/}
            {/*  </Typography.Paragraph>*/}
            {/*</div>*/}

            {/* Scrollable List */}
            <div
              className={'custom-scroll-v3'}
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflowY: 'auto',
              }}>
              <List
                itemLayout={'vertical'}
                className={styles.categoryList}
                dataSource={
                  getMicroArticleIconsSpriteCategories
                    ? [
                        {
                          name: t(
                            'microArticle.mapView.createPopupTexts.iconPopup.all',
                          ),
                          id: null,
                        },
                        ...getMicroArticleIconsSpriteCategories,
                      ]
                    : [
                        {
                          name: t(
                            'microArticle.mapView.createPopupTexts.iconPopup.all',
                          ),
                          id: null,
                        },
                      ]
                }
                renderItem={(category) => (
                  <List.Item
                    key={category.id || 'all'}
                    className={styles.categoryItem}
                    onClick={() => {
                      setMicroArticleSelectedCategoryId(category.id)
                    }}>
                    <span
                      style={{
                        fontWeight:
                          category.id === getMicroArticleSelectedCategoryId &&
                          'bold',
                      }}>
                      {category.name} {/* Render the category name */}
                    </span>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
        {/* Right Column: Search, Icons, Colors, Color Picker */}
        <div className={styles.popupRight}>
          {/* Search Section */}
          <div className={styles.topSection}>
            <Input
              suffix={
                <img
                  className={styles.customIcon}
                  src={SearchIcon}
                  alt={'Search Icon'}
                />
              }
              placeholder={t(
                'microArticle.mapView.createPopupTexts.iconPopup.searchPlaceholder',
              )}
              className={styles.searchInput}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          {/* Icons Grid Section */}
          <Suspense fallback={<Empty />}>
            {showIconsGrid && (
              <InfiniteIconsGrid
                selectedIcon={selectedIconObject}
                selectedColor={selectedColor}
                icons={getMicroArticleIconsKeys}
                onIconSelect={handleChangeIcon}
              />
            )}
          </Suspense>
          {/* Colors and Color Picker Section */}
          <div className={styles.bottomSection}>
            <div className={styles.circleColorsWrapper}>
              <Space
                style={{
                  overflowX: 'auto',
                }}
                className={styles.bottomSectionSpace}>
                {/* Existing color buttons */}
                <Button
                  key={`color-${selectedColor}`}
                  size="small"
                  shape="circle"
                  className={styles.colorButton_selected}
                  style={{ borderColor: selectedColor, color: selectedColor }}
                  onClick={() => {
                    // handleColorChangeLocal(selectedColor);
                  }}>
                  <span className={styles.colorButtonInner}></span>
                </Button>
                {colors.map((color, index) => (
                  <Button
                    key={`color-${index}`}
                    size="small"
                    shape="circle"
                    className={styles.colorButton}
                    style={{ backgroundColor: color }}
                    onClick={() => {
                      handleColorChangeLocal(color)
                    }}
                  />
                ))}
                {/* Three dynamic buttons: Recent colors or "+" */}
                {[0, 1, 2].map((i) =>
                  recentColors[i] ? (
                    <Button
                      key={`recent-color-${i}`}
                      size="small"
                      shape="circle"
                      className={styles.colorButton}
                      style={{ backgroundColor: recentColors[i] }}
                      onClick={() => {
                        handleColorChangeLocal(recentColors[i])
                      }}
                    />
                  ) : (
                    <div
                      key={`add-color-${i}`}
                      className={`${styles.colorButton} ${styles.recentColorButton}`}>
                      <PlusCircleFilled
                        style={{
                          color: '#D8D8D8',
                          fontSize: '32px',
                          minHeight: '32px',
                          minWidth: '32px',
                        }}
                      />
                    </div>
                  ),
                )}
                {/*Always Visible Color Picker*/}
              </Space>
            </div>
            <CustomColorPicker
              handleIconSelect={handleIconSelect}
              selectedIconObject={selectedIconObject}
              initialColor={selectedColor}
              onColorChange={handleColorChangeLocal}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  getMicroArticleIconsSpriteCategories:
    getMicroArticleIconsSpriteCategories(state),
  getMicroArticleIconsKeys: getMicroArticleIconsKeys(state),
  getMicroArticleSelectedCategoryId: getMicroArticleSelectedCategoryId(state),
  getMicroArticleSpriteIconsSearch: getMicroArticleSpriteIconsSearch(state),
})

const mapDispatchToProps = {
  setMicroArticleIconsSpriteCategories,
  setMicroArticleIconsKeys,
  setMicroArticleSelectedCategoryId,
  setMicroArticleSpriteIconsSearch,
}

/**
 * Connect the component to Redux and export.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(IconSelectPopup))
