import React from 'react'

const CopyIcon = (props) => {
  return (
    <svg
      className="copy-svg-icon"
      width="15"
      height="15"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.6673 21.9502C20.6673 26.0335 19.034 27.6668 14.9507 27.6668H10.0507C5.96732 27.6668 4.33398 26.0335 4.33398 21.9502V17.0502C4.33398 12.9668 5.96732 11.3335 10.0507 11.3335H14.9507C19.034 11.3335 20.6673 12.9668 20.6673 17.0502V21.9502Z"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6673 14.9502C27.6673 19.0335 26.034 20.6668 21.9507 20.6668H20.6673V17.0502C20.6673 12.9668 19.034 11.3335 14.9507 11.3335H11.334V10.0502C11.334 5.96683 12.9673 4.3335 17.0507 4.3335H21.9507C26.034 4.3335 27.6673 5.96683 27.6673 10.0502V14.9502Z"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 2V30H2V2H30Z"
        stroke="#2C476C"
        strokeWidth="3.3"
      />
    </svg>
  )
}

export default CopyIcon
