import React from 'react';

const MicroArticlesIcon = ({isActive, isMap}) => {

    return (
        <svg width="56" height="49" viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.15039 7V42C2.15039 44.7614 4.45856 47 7.30584 47H48.5494C51.3967 47 53.7049 44.7614 53.7049 42V14.5C53.7049 11.7386 51.3967 9.5 48.5494 9.5H32.2324C31.117 9.5 30.0316 9.14911 29.1392 8.5L21.5607 3C20.6683 2.35089 19.5829 2 18.4674 2H7.30584C4.45856 2 2.15039 4.23858 2.15039 7Z"
                  stroke={isActive ? isMap ? "#fff" : "#FF6539" : isMap ? "#2C476C" : "#2C476C"} fill="none" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default MicroArticlesIcon;
