import { SET_SEARCH } from "../constants";
import { setMenuData } from "./menu";
import { setMapStyleData } from "./mapStyle";
import { setHintsData } from "./hints";
import { setMapRequirementData } from "./mapRequirement";
import { setPainterStartData } from "./painterStart";
import {setQuizPopupData} from "./quizPopup";

export const setSearchData = (bool) => ({
  type: SET_SEARCH,
  payload: bool,
});

export const setSearch = (bool) => (dispatch) => {
  dispatch(setPainterStartData(false));
  dispatch(setMapRequirementData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setSearchData(bool));
};
