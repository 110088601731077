import React from 'react'

const LinkIcon = () => {
  return (
    <svg
      className="link-svg-icon"
      width="15"
      height="15"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1257 16.9998C19.1257 21.5048 15.4848 25.1457 10.9798 25.1457C6.47482 25.1457 2.83398 21.5048 2.83398 16.9998C2.83398 12.4948 6.47482 8.854 10.9798 8.854"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.166 17C14.166 12.3108 17.9768 8.5 22.666 8.5C27.3552 8.5 31.166 12.3108 31.166 17C31.166 21.6892 27.3552 25.5 22.666 25.5"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LinkIcon
