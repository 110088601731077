import React from 'react'

const SearchIcon = () => {
  return (
    <svg
      version="1.1"
      width="18"
      height="18"
      viewBox="0 0 46.0 46.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <clipPath id="i0">
          <path d="M3840,0 L3840,2162 L0,2162 L0,0 L3840,0 Z" />
        </clipPath>
      </defs>
      <g transform="translate(-3470.0 -51.0)">
        <g clipPath="url(#i0)">
          <g transform="translate(3471.666666666668 52.66666666666664)">
            <path
              d="M20.2666667,40.5333333 C9.07369588,40.5333333 0,31.4596375 0,20.2666667 C0,9.07369588 9.07369588,0 20.2666667,0 C31.4596375,0 40.5333333,9.07369588 40.5333333,20.2666667 C40.5333333,31.4596375 31.4596375,40.5333333 20.2666667,40.5333333 Z"
              stroke="#2C476C"
              strokeWidth="3.2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray=" 0.0 0.0"
            />
            <g transform="translate(38.39999999999418 38.39999999999998)">
              <path
                d="M4.26666667,4.26666667 L0,0"
                stroke="#2C476C"
                strokeWidth="3.2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray=" 0.0 0.0"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SearchIcon
