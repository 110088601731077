import { SET_MENU } from "../constants";
import { setSearchData } from "./search";
import { setMapStyleData } from "./mapStyle";
import { setHintsData } from "./hints";
import { setMapRequirementData } from "./mapRequirement";
import {setQuizPopupData} from "./quizPopup";

export const setMenuData = (bool) => ({
  type: SET_MENU,
  payload: bool,
});

export const setMenu = (bool) => (dispatch) => {
  dispatch(setSearchData(false));
  dispatch(setMapStyleData(false));
  dispatch(setMapRequirementData(false))
  dispatch(setHintsData(false));
  dispatch(setQuizPopupData(false));
  dispatch(setMenuData(bool));
};
