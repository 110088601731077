import clsx from 'clsx'
import React from 'react'
import ReactPlayer from 'react-player'
import { v4 as uuidv4 } from 'uuid'
import Play from '../../assets/imgs/icons/play.svg'

const Video = (props) => {
  const { id } = props
  const videoIdRef = React.useRef(id ? `video-${id}` : `video-${uuidv4()}`)
  console.log('videoIdRef', videoIdRef)
  const [state, setState] = React.useState({
    pip: false,
    playing: !!props.playing,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    seeking: false,
    loop: false,
  })
  const playerRef = React.useRef(null)
  const notStarted = state.played === 0

  const onToggle = () => {
    setState({
      ...state,
      playing: !state.playing,
    })
  }

  const handleDuration = (duration) => {
    setState({
      ...state,
      duration,
    })
  }

  const handleProgress = (newState) => {
    if (!state.seeking) {
      setState({
        ...state,
        ...newState,
      })
    }
  }

  const toggleFullScreen = () => {
    const element = document.getElementById(videoIdRef.current)
    if (!element) return
  }

  return (
    <div
      className={clsx(
        'relative aspect-video rounded-lg overflow-hidden ath-player',
        props.className,
      )}
      id={videoIdRef.current}>
      <ReactPlayer
        width="100%"
        height="100%"
        onProgress={handleProgress}
        ref={playerRef}
        onDuration={handleDuration}
        {...props}
        {...state}
      />
      <div
        className="absolute top-0 left-0 w-full h-full"
        onDoubleClick={toggleFullScreen}
        onClick={onToggle}
      />
      {notStarted && (
        <div
          style={{
            backgroundImage: `url(${props.src})`,
            backgroundSize: 'cover',
            backgroundBlendMode: 'darken',
          }}
          className="absolute focus:opacity-0 top-0 left-0 w-full h-full bg-black/[0.4] flex items-center justify-center cursor-pointer"
          onClick={onToggle}>
          <img
            src={Play}
            className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointe w-[80px] cursor-pointer"
            onClick={onToggle}
          />
        </div>
      )}
    </div>
  )
}

export default Video
