import React, {useEffect} from 'react';
import MapScreenShotButton from "../../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import CloseButtonSVG from '../components/svg/CloseButtonSVG'; // Import the CloseButtonSVG component
import ArrowLeftSVG from '../components/svg/ArrowLeftSVG'; // Import the ArrowLeftSVG component
import ArrowRightSVG from '../components/svg/ArrowRightSVG';
import {handleYoutubeImagePathGen} from "../../../utils/helpers";
import Play from "../../../assets/imgs/icons/play.svg";
import TiliSlides from "../components/TiliSlides";
import {connect} from "react-redux";
import {setScreenShotCurrentTiliIndex} from "../../../store/actions/mapStateAction";
import {getScreenShotGalleryIndex} from "../../../store/selectors";
import {isMobile} from "react-device-detect";

const GalleryTili = ({
                      tili,
                      title,
                      isPresentation,
                      articleId,
                      fromArticle,
                      currentTiliIndex,
                      mapState,
                      startTiliIdx,
                      toggleGallery,
                      galleryTiliRef,
                      galleryTiliContainer,
                      gallery,
                      getPageFromUrl,
                      galleryItems,
                      switchToSlide,
                  }) => {

    return (
        <div
            data-tili={tili}
            className={`gallery tili active`}
            data-full-screen="false"
            ref={galleryTiliRef}
        >
            <div className="gallery-header">
                <div className="gallery-header-title">{title}</div>
                {getPageFromUrl('playMode') !== 'true' && getPageFromUrl('preview') !== 'true'
                    ? isPresentation && !isMobile
                        ? <MapScreenShotButton
                            articleId={articleId}
                            isReadMoreArticle={fromArticle}
                            galleryTiliIndex={currentTiliIndex}
                            isGalleryMode={true}
                            map={mapState}
                            article={true}
                            white={true}
                        />
                        : !isMobile && <MapScreenShotButton
                            articleId={articleId}
                            isReadMoreArticle={fromArticle}
                            galleryTiliIndex={currentTiliIndex}
                            isGalleryMode={true}
                            map={mapState}
                            article={true}
                            white={true}
                        />
                    : null
                }
                <button
                    className="gallery-header-btn gallery-close"
                    type="button"
                    onClick={toggleGallery}
                >
                    <CloseButtonSVG />
                </button>
            </div>
            <div className="splide-content">
                <Splide
                    ref={galleryTiliContainer}
                    options={{
                        type: "loop",
                        gap: "10px",
                        pagination: false,
                        fixedWidth: window.innerWidth * 0.85,
                        fixedHeight: window.innerHeight * 0.85,
                        height: window.innerHeight * 0.85,
                        width: window.innerWidth * 0.85,
                        arrows: false,
                        start: startTiliIdx,
                    }}
                    renderControls={() => (
                        <div
                            className="splide__arrows"
                            style={{
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <button
                                className="gallery-btn prev-main splide__arrow--prev"
                                type="button"
                            >
                                <ArrowLeftSVG />
                            </button>
                            <button
                                className="gallery-btn next-main splide__arrow--next"
                                type="button"
                            >
                                <ArrowRightSVG />
                            </button>
                        </div>
                    )}
                    className="gallery-main"
                >
                    {tili && gallery
                    ? <TiliSlides
                        galleryItems={galleryItems}
                        switchToSlide={switchToSlide}
                        handleYoutubeImagePathGen={handleYoutubeImagePathGen}
                        playIcon={Play}
                     />
                    : null}
                </Splide>
            </div>
        </div>
    );
};

const mapStateTopProps = (state) => ({
    galleryIndex: getScreenShotGalleryIndex(state),
});

const mapDispatchToProps = {
    setScreenShotCurrentTiliIndex: setScreenShotCurrentTiliIndex,
};

export default connect(mapStateTopProps, mapDispatchToProps)(GalleryTili);
