import React, {memo, useEffect, useRef, useState} from 'react';
import Moveable, {makeAble} from "react-moveable";
import PropTypes from "prop-types";
import ColorPickerPopup from "./ColorPickerPopup";
import Popover from "../../../UI/Popover";
import {
    getDemiDimensions,
    getScaleValuesFromTransform,
    getTransformedDimensions,
    getTransformRotate,
    replaceShapeTransform,
    updateSvgColors
} from "../utils";
import {useShapes} from "../index";

import {CustomRotation} from "../elements/MovableElements";
import {useSelector} from "react-redux";
import {getDrawerToolboxClickedState, getEraserClickedState, getIsFormPopupVisible} from "../../../../store/selectors";
import {isMobile} from "react-device-detect";


const ShapeMarker = ({svg, keepRatio,map, transform,onLoad, onTransform,meta,Component,resizable, onDelete,isEditMode, onChangeColor, fillColor, strokeColor, uuid, width, height}) => {
    const targetRef = useRef(null);
    const containerRef = useRef(null);
    const popperRef = useRef()
    const shapePadding = isMobile ? 10 : 5
    const {shapeModeIsActive, onSetShaperClickState} = useShapes();
    const isActive = shapeModeIsActive === uuid;
    const [editMode,setEditMode] = useState(isEditMode)
    const [colorMode,setColorMode] = useState()
    const [fillColorState, setFillColorState] = useState(fillColor);
    const [strokeColorState, setStrokeColorState] = useState(strokeColor);
    const [isLoading,setIsLoading] = useState(true)
    const [keepRatioState, setKeepRatioState] = useState(false);
    const [isZooming, setIsZooming] = useState(false);
    const [targetDemeDimensions, setTargetDemeDimensions] = useState({width, height});
    const drawerIsActive = useSelector(getDrawerToolboxClickedState)
    const eraserIsActive = useSelector(getEraserClickedState)
    const isMicroArticleFormPopupVisible = useSelector(getIsFormPopupVisible);

    useEffect(() => {
        const pixelSize = onLoad?.()
        // matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)
        if (targetRef.current && !targetRef.current?.style?.transform?.includes('scale')) {
            const transformValue = `translate(-50%,-50%)  rotate(0)  scale(${1 / pixelSize}, ${1 / pixelSize})`;
            targetRef.current.style.transform = transformValue;
            onTransform?.(uuid, transformValue);
        }

    }, []);

    useEffect(() => {
        // use mutation observer for get current element width and height
        if (targetRef.current) {
            setTargetDemeDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            })
            if (resizable) {
                const observer = new MutationObserver((mutationsList) => {
                    for (let mutation of mutationsList) {
                        if (mutation.type === 'attributes') {
                            //get new width and height
                            const originalWidth = isNaN(parseFloat(targetRef.current.style.width)) ? 0 : parseFloat(targetRef.current.style.width);
                            const originalHeight = isNaN(parseFloat(targetRef.current.style.height)) ? 0 : parseFloat(targetRef.current.style.height);
                            const width = mutationsList ? originalWidth || mutationsList[0].target.clientWidth : 0
                            const height = mutationsList ? originalHeight || mutationsList[0].target.clientHeight : 0
                            setTargetDemeDimensions({width, height})
                        }
                    }
                });
                observer.observe(targetRef.current, {attributes: true});
            }
        }
    },[resizable])

    useEffect(() => {
        setEditMode(isActive)
    }, [isActive]);

    useEffect(() => {
      setTimeout(() => {
          // targetRef.current.click();
          setIsLoading(false)
      },0)
        // const element = document.querySelector('.your-element-selector');
    },[targetRef])

    useEffect(() => {
        if (map) {
            map.on('zoom', () => {
                setEditMode(false)
                setColorMode(false)

                setIsZooming(true)
            });

            map.on("zoomend", () => {
                setIsZooming(false)
               // targetRef.current.click();
               // if (shapeModeIsActive) {
               //     setEditMode(true)
               // }
            })
        }
    }, [map, shapeModeIsActive])

    // const transformWithScale = applyScaleToTransform(transform, mapScale);
    const transformWithScale = transform;

    const handleTransform = (e) => {
        if (!shapeModeIsActive) {
            return;
        }

        const transform = e.drag.transform || 'translate(-50%,-50%) scale(1,1) rotate(0)';
        const transformStr = replaceShapeTransform(transform);

        // const scale = scaleValues[0]
        // const transformStr = transform.includes('-50%, -50%') ? transform : transform + 'translate(-50%, -50%)';
        // e.target.style.transform = getShapeMarkerTransform(e, targetRef.current, isEditMode);
        e.target.style.transform = transformStr
        onTransform?.(uuid, transformStr);
        // Call the onTransform callback if exists
        // onTransform?.(uuid, e.drag.transform, getShapeMarkerTransform(e, targetRef.current, isEditMode));
        setColorMode(false);
    };

    const clickHandler = (e) => {
        e?.preventDefault();
        if (e.type === "click") {
            e?.stopPropagation();
        }

        if (e.type === "touchstart") {
            mouseDownHandler(e)
        }

        onSetShaperClickState(uuid)
        if (isEditMode || !shapeModeIsActive){
            return;
        }
        if (colorMode) {
            setColorMode(false)
            return;
        }

        setTimeout(() => {
            setEditMode(true)
        },200)

        setTimeout(() => {
            setColorMode(!!editMode)
        },260)
    }

    const handleDelete = () => {
        setColorMode(false)
        onDelete?.(uuid)
    }

    const updateColorHandler = (color, type) => {
        if (type === "fill") {
            setFillColorState(color)
            onChangeColor?.(uuid, color, "fillColor")
        } else {
            setStrokeColorState(color)
            onChangeColor?.(uuid, color, "strokeColor")
        }
    }

    const touchMoveHandler = (e) => {
        // e?.stopPropagation();

        setEditMode(true);
        setColorMode(false)
    }

    const mouseMoveHandler = (e) => {
        if (!!e.pressure) {
            setColorMode(false)
            // setEditMode(false)
        }
    }


    const closeColorMode = (e) => {
        if (!!e.target.classList.contains('popover') || !!e.target.closest('.popover')) {
            return
        }
        setColorMode(false)
    }


    const handleMouseEnd = (e) => {
        // console.log("e", e);
        //     setIsDragging(false);
        // setColorMode(!!editMode)
    }

    const handleTouchEnd = (e) => {
        // console.log("TOUCH ENDe", e);
        //     setIsDragging(false);
        // setColorMode(!!editMode)
        e?.stopImmediatePropagation();
    }

    const mouseDownHandler = (e) => {
        if (map.draw.getSelected()) {
            map.draw.changeMode('static')
            setTimeout(() => {
                map.draw.changeMode('simple_select')
            },200)
        }

        if (drawerIsActive || eraserIsActive) {
            e?.preventDefault();
            e?.stopPropagation();
        }

        // if (!e.target.classList.contains('shape-marker--container')) {
        //     e.stopPropagation()
        // }
        onSetShaperClickState(uuid)

    }


    const mouseEnterHandler = (e) => {
        // set parent shape-marker z index to 10
         e.target.closest('.shape-marker').style.zIndex = 10;
    }

    const mouseLeaveHandler = (e) => {
        e.target.closest('.shape-marker').style.zIndex = 1;
    }


    const handleChange = (data) => {
        onTransform?.(uuid, data)
    }

    const mapNode = document.getElementById("map");

    const {newWidth, newHeight} = getDemiDimensions(targetDemeDimensions.width, targetDemeDimensions.height,  getScaleValuesFromTransform(targetRef.current?.style?.transform || ''), mapNode.dataset.scale);


    return (
         <div className="shape-marker--root"  style={{position:"relative", pointerEvents: isZooming ? 'none' : 'auto'}} >
             <Popover isOpened={colorMode}
                      onClose={closeColorMode}
                      reference={targetRef.current}
                      innerRef={popperRef}
                      placement={'top-start'}
                      parentNode={document.querySelector('main')}
                      modifiers={[{
                          name: 'offset',
                          options: {
                              offset: [0, 13],
                          },
                      },]}
                      >
                 <ColorPickerPopup containerRef={targetRef.current} handleClose={clickHandler} handleDelete={handleDelete} onChangeColor={updateColorHandler} fillColor={fillColorState} strokeColor={strokeColorState}/>
             </Popover>
            <div className="shape-marker--container"
                 style={{
                    pointerEvents: isMicroArticleFormPopupVisible ? 'none' : 'auto',
                    visibility: isMicroArticleFormPopupVisible ? 'hidden' : 'visible',
                 }}
                 onPointerMove={mouseMoveHandler} onMouseUp={handleMouseEnd} ref={containerRef}
                    onClick={clickHandler} onMouseDown={mouseDownHandler} onTouchMove={touchMoveHandler} onTouchStart={clickHandler}>
                {
                    resizable &&
                    <div className="shape-marker--ghost" onMouseLeave={mouseLeaveHandler} onMouseEnter={mouseEnterHandler} style={{
                        transform: `translate(-50%, -50%) rotate(${getTransformRotate(targetRef.current?.style?.transform || '')}deg)`,
                        width: newWidth,
                        height: newHeight
                    }}>
                        {
                            Component &&  <Component width={newWidth} height={newHeight} onChange={handleChange} arrowWidth={meta?.arrowWidth ?? 0.3} fillColor={fillColorState}
                                                     strokeColor={strokeColorState}/>
                        }
                    </div>
                }

                <div className="shape-marker--target" ref={targetRef} style={{
                    transform: transformWithScale || "translate(-50%, -50%) rotate(0deg)",
                    // width,
                    // height,
                }}>
                    {
                        !resizable &&
                        <div className="shape-marker--svg" onMouseLeave={mouseLeaveHandler}
                             onMouseEnter={mouseEnterHandler}
                             ref={targetRef}
                             dangerouslySetInnerHTML={{__html: updateSvgColors(svg, fillColorState, strokeColorState, width, height)}}/>
                    }
                </div>
                {
                    !isLoading &&
                    <Moveable
                        target={targetRef}
                        rotatable={editMode && shapeModeIsActive}
                        throttleRotate={0}
                        // rotationPosition="bottom-right"
                        transformOrigin="center center"
                        pinchable={true}
                        pinchThreshold={100}
                        zoom={1.2}
                        origin={false}
                        useAccuratePosition={false}
                        rotationTarget={".moveable-custom-rotation"}
                        rotationPosition={"none"}
                        originDraggable={true}
                        linePadding={0}
                        ables={[CustomRotation]}
                        props={{
                            customRotation: editMode,
                        }}
                        // keepRatioFinally={true}
                        hideDefaultLines={!editMode && !colorMode}
                        edge={!isMobile}

                        padding={{left: shapePadding, top: shapePadding, right: shapePadding, bottom: shapePadding}}
                        onRotate={handleTransform}
                        onScaleStart={({ set, dragStart,setMinScaleSize,direction, setRatio, clientX, clientY, setFixedDirection, ...rest }) => {
                            const directionSum = direction.reduce((a, b) => a + b, 0);

                            const isAngle = directionSum % 2 === 0;
                            setMinScaleSize([30, 30]);
                            // set(frame.scale);
                            setFixedDirection([0, 0]);

                            setKeepRatioState(isAngle);
                            // dragStart && dragStart.set(frame.translate);
                        }}
                        scalable={editMode && shapeModeIsActive}
                        keepRatio={keepRatio ||keepRatioState}
                        throttleScale={0}
                        // renderDirections={["nw","n","ne","w","e","sw","s","se"]}
                        onScale={handleTransform}
                    />
                }

         </div>
      </div>
    );
};


ShapeMarker.propTypes = {
    keepRatio: PropTypes.bool,
    svg: PropTypes.string,
    onTransform: PropTypes.func,
    transfrom: PropTypes.string,
    uuid: PropTypes.string,
    id: PropTypes.string,
    onDelete: PropTypes.func,

};

export default memo(ShapeMarker);
