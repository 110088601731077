import React from 'react'

const TextIcon = () => {
  return (
    <svg
      className="text-svg-icon"
      width="15"
      height="14"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="text">
        <path
          id="Vector"
          d="M2 7.00322V4.66238C2 3.18328 3.19614 2 4.66238 2H23.3376C24.8167 2 26 3.19614 26 4.66238V7.00322"
          stroke="#2C476C"
          strokeWidth="3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M14 24.4307V3.06738"
          stroke="#2C476C"
          strokeWidth="3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.93359 24.4307H19.0686"
          stroke="#2C476C"
          strokeWidth="3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default TextIcon
