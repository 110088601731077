import React, {memo, useEffect, useState} from 'react';
import AntPopover from "../AntPopover";
import Maximize from "../../../assets/imgs/icons/maximizeIcon.svg";
import Minimize from "../../../assets/imgs/icons/minimizeIcon.svg";
import CloseIcon from '../../../assets/imgs/icons/Close-blue.svg';
import Monitor from "../../../assets/imgs/icons/monitorIcon.svg";
import AMinus from "../../../assets/imgs/icons/aMinus.svg";
import APlus from "../../../assets/imgs/icons/aPlus.svg";
import Edit from "../../../assets/imgs/icons/EditIcon.svg";
import "./style.css";
import {useTranslation} from "react-i18next";
import MapScreenShotPopup from "../MapScreenShotPopup";
import {Modal} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import DeleteIcon from "../../../assets/imgs/PaintBar/delete-trash-black-icon.svg";
import {removeScreenShotCardAPI, setScreenShotFormIsOpen} from "../../../store/actions/mapStateAction";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getActiveScreenShot,
    getIsScreenShotMode,
    getPresentationListRefetch,
    getUserData
} from "../../../store/selectors";
import {selectScreenshotPresentation} from "../../presentation/store/presentationSlice";
import PresentationsSelector from "../../presentation/ui/PresentationsSelector";
import {
    useGetMapStateQuery,
    useGetUserQuery,
    useMapStateImportToSlideMutation
} from "../../presentation/store/presentationApi";
import styles from "../../microArticle/components/PopupDetail/PopupDetail.module.css";
import {cn} from "../../../utils/utils";
import {hasReference} from "../../../service/mapStateService";

const MapScreenShotPopupView = memo(({
                                         open,
                                         onClose,
                                         mapStateSingleData,
                                         title,
                                         isEditMode,
                                         data,
                                         isEditModePopup,
                                         globalLoading,
                                         setIsViewOpen,
                                         removeScreenShotCardAPI,
                                         user,
                                         id,
                                         isReadMoreArticle,
                                         galleryIndex,
                                         isGalleryMode,
                                         isMapHeader,
                                         activeScreenShot,
                                         presentationListRefetch,
                                         ...rest
                                     }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMinimize, setIsMinimize] = useState(false);
    const [fontSize, setFontSize] = useState(14);
    const [isOpenEditing, setIsOpenEditing] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    let pageLoc = +queryParams.get("page");
    let screenSlideID = queryParams.get("screenSlide");
    let [searchParams] = useSearchParams();
    const [ presentationsIsOpen,setPresentationsIsOpen] = useState(false);
    const [onImportToSlide] = useMapStateImportToSlideMutation()
    const isPreview = searchParams.get('preview')

    const screenshotPresentation = useSelector(selectScreenshotPresentation)
    const mapStateId = mapStateSingleData?.mapStateId ? mapStateSingleData?.mapStateId : (!mapStateSingleData?.microArticleId && !mapStateSingleData?.articleId) ? mapStateSingleData?.id : null;

    const {data:mapStateData, refetch} = useGetMapStateQuery({id:mapStateId}, {
        skip: !mapStateId,
        refetchOnMountOrArgChange: true
    })
    const mapState = mapStateData?.data?.[0]
    // console.log("user?.id", user?.id, mapState?.userId);
    const isOwner = user?.id === mapState?.userId && !isPreview;

    const {data:creatorData} = useGetUserQuery(mapState?.userId, {
        skip: isOwner || !mapState?.userId
    })

    const screenWidth = window.innerWidth;
    const creator = creatorData?.data?.[0]

    const parts = creator?.fullName?.trim()?.split(/\s+/) ?? [];
    const firstInitial = parts[0]?.slice(0, 1) ?? '';
    const lastInitial = parts.length > 1 ? parts[parts.length - 1]?.slice(0, 1) ?? '' : '';
    const creatorNameFirstLetters = (firstInitial + lastInitial).toUpperCase();

    const handleMinimize = () => setIsMinimize((prevState) => !prevState);

    const configureFontSize = (type) => {
        const maxFontSize = screenWidth < 1024 ? 28 : 20;
        const minFontSize = screenWidth < 1024 ? 20 : 14;

        if (type === 'plus' && fontSize <= maxFontSize) {
            setFontSize(fontSize + 2);
        } else if (type === 'minus' && fontSize > minFontSize) {
            setFontSize(fontSize - 2);
        }
    };
    const deleteModal = () => {
        Modal.confirm({
            title: <h3 className='map-screenshot-popup__confirm-text' style={{
                fontSize: 20,
                letterSpacing: 2,
            }}>{t('delete').toUpperCase()}</h3>,
            centered:true,
            content: (
                <div style={{marginBottom: '10px'}}>
                    <p className='map-screenshot-popup__confirm-text'
                       style={{fontSize: 16,}}>
                        {t('screenShot.delete_screen_shot_description')}
                    </p>
                </div>
            ),
            icon: null,
            cancelButtonProps: {
                className: 'map-screenshot-popup__confirm-button',
                style: {
                    backgroundColor: '#FFDE5A',
                },
                type: 'primary'
            },
            okButtonProps: {
                className: 'map-screenshot-popup__confirm-button',
                style: {
                    backgroundColor: '#FF4015',
                },
            },
            cancelText: t('no'),
            okText: t('yes'),
            onOk: async () => {
                await removeScreenShotCardAPI(pageLoc, mapStateSingleData.id, user.id).then(() => {
                    presentationListRefetch?.()
                })
                setIsOpenEditing(false)
                onClose();
            },
            width: 486,
            wrapClassName: 'map-screenshot-popup_confirm-modal'
        })
    }
    const hasReferenceModal = () => {
        Modal.confirm({
            title: <h3 className='map-screenshot-popup__confirm-text' style={{
                fontSize: 20,
                letterSpacing: 2,
            }}>{t('delete').toUpperCase()}</h3>,
            centered:true,
            content: (
                <div style={{marginBottom: '10px'}}>
                    <p className='map-screenshot-popup__confirm-text'
                       style={{fontSize: 16,}}>
                        {t('deletePopups.savedMapPresentation')}
                    </p>
                </div>
            ),
            icon: null,
            cancelButtonProps: {
                className: 'map-screenshot-popup__confirm-button',
                style: {
                    backgroundColor: '#FFDE5A',
                },
                type: 'primary',

            },
            okButtonProps: {
                className: 'map-screenshot-popup__confirm-button',
                style: {
                    backgroundColor: '#FF4015',
                },
            },
            cancelText: t('cancel'),
            okText: t('deletePopups.continue'),
            onOk: deleteModal,
            width: 486,
            wrapClassName: 'map-screenshot-popup_confirm-modal'
        })

    }
    const deleteScreenShot = () => {
        hasReference(mapStateSingleData.id).then((res) => {
            if(res?.data?.data?.[0]){
                hasReferenceModal()
            }else{
                deleteModal()
            }
        })
    };

    const changePresentationsHandler = (presentations) => {
        presentations.forEach(presentation => {
            const presentationId = presentation.id
            onImportToSlide({
                presentationId,
                mapStateIds: [mapStateId],
            })
        })
        setPresentationsIsOpen(false)
    }

    const content = () => (
        <div
            className={`map-screenshot-popup-edit ${isMinimize ? "map-screenshot-popup-edit--minimized" : "map-screenshot-popup-edit--expanded"}`}>
            <div className="map-screenshot-popup-edit__header">
                <div style={{width: '80%'}}>
                    <span className="map-screenshot-popup-edit__header-title"
                          style={{fontFamily: '"Noto Sans",sans-serif'}}>
                         {
                             !isOwner && creatorNameFirstLetters &&
                             <span className={cn(styles.nameInitials, "tracking-widest")}>
                                {creatorNameFirstLetters}
                              </span>
                         }
                        {mapState?.title}</span>
                </div>
                <div className="map-screenshot-popup-edit__header-buttons">
                    {isMinimize ? (
                        <button onClick={handleMinimize}>
                            <img style={{width: '24px', height: '24px'}} src={Maximize} alt="Maximize"/>
                        </button>
                    ) : (
                        <button onClick={handleMinimize}>
                            <img style={{width: '20px', height: '20px'}} src={Minimize} alt="Minimize"/>
                        </button>
                    )}
                    <button onClick={() => onClose()}>
                        <img style={{width: '20px', height: '20px'}} src={CloseIcon} alt="Close"/>
                    </button>
                </div>
            </div>
            {!isMinimize && (
                <div>
                    {
                        !!mapState?.description && mapState?.description.trim()?.length > 0 &&
                        <p
                            dangerouslySetInnerHTML={{__html: mapState?.description}}
                            className="map-screenshot-popup-edit__description"
                            style={{
                                fontSize: `${fontSize}px`,
                                lineHeight: `${fontSize >= 14 && fontSize < 20 ? 24 : 30}px`,
                                fontFamily: '"Noto Sans",sans-serif'
                            }}>

                        </p>
                    }
                    <div className="map-screenshot-popup-edit__actions">
                        <div className="map-screenshot-popup-edit__action">
                            <button
                                disabled={fontSize >= (screenWidth < 1024 ? 28 : 20)}
                                className="map-screenshot-popup-edit__icon-container"
                                onClick={() => configureFontSize('plus')}
                            >
                                <img className="map-screenshot-popup-edit__icon-minus" src={APlus} alt="Plus"/>
                            </button>
                            <button
                                disabled={fontSize <= (screenWidth < 1024 ? 18 : 14)}
                                className="map-screenshot-popup-edit__icon-container"
                                onClick={() => configureFontSize('minus')}
                            >
                                <img className="map-screenshot-popup-edit__icon-minus" src={AMinus} alt="Minus"/>
                            </button>
                            {
                                isOwner  && (
                                    <>
                                        <div className="map-screenshot-popup-edit__icon-container"
                                             onClick={() => setPresentationsIsOpen(true)}>
                                            <img className="map-screenshot-popup-edit__icon" src={Monitor} alt="Monitor"/>
                                        </div>
                                        <MapScreenShotPopup
                                            onOpenChange={(newOpen) => {
                                                if (!isPopoverOpen) {
                                                    setIsPopoverOpen(true);
                                                }
                                                // setIsOpenEditing(!isConfirmDelete && newOpen);
                                                setIsOpenEditing(!isConfirmDelete);

                                                if (!isOpenEditing) {
                                                    queryParams.delete('playBack');
                                                    navigate({
                                                        pathname: window.location.pathname,
                                                        search: queryParams.toString()
                                                    }, {replace: true})
                                                }
                                                // queryParams.set('screenShot', 'true');
                                            }}
                                            onClose={() => {
                                                Modal.confirm({
                                                    title: <h3
                                                        className='map-screenshot-popup__confirm-text'
                                                        style={{
                                                            fontSize: 18,
                                                            letterSpacing: 2,
                                                        }}>{t('screenShot.close').toUpperCase()}</h3>,
                                                    content: (
                                                        <div style={{marginBottom: '10px'}}>
                                                            <p className='map-screenshot-popup__confirm-text'
                                                               style={{
                                                                   fontSize: 15,
                                                               }}>{t('screenShot.close_popup_edit_mode_description')}</p>
                                                            <p className='map-screenshot-popup__confirm-text' style={{
                                                                fontSize: 15,
                                                            }}>{t('screenShot.close_popup_edit_mode_description_2')}</p>
                                                        </div>
                                                    ),
                                                    icon: null,
                                                    cancelButtonProps: {
                                                        className: 'map-screenshot-popup__confirm-button',
                                                        style: {
                                                            backgroundColor: '#FFDE5A',
                                                        },
                                                        type: 'primary'
                                                    },
                                                    okButtonProps: {
                                                        className: 'map-screenshot-popup__confirm-button',
                                                        style: {
                                                            backgroundColor: '#FF4015',
                                                        },
                                                    },
                                                    cancelText: t('no'),
                                                    okText: t('yes'),
                                                    onOk: async () => {
                                                        await setIsConfirmDelete(true);
                                                        setTimeout(() => {
                                                            setIsConfirmDelete(false);
                                                        },300)
                                                        setIsOpenEditing(false);
                                                        // onClose(true);
                                                    },
                                                    onCancel: () => {
                                                        setIsConfirmDelete(false);
                                                    },
                                                    width: 480,
                                                    centered: true,
                                                    wrapClassName: 'map-screenshot-popup_confirm-modal'
                                                })
                                            }}
                                            open={isOpenEditing}
                                            trigger="click"

                                            imageUrl={mapState?.imageUrl}
                                            mediaId={mapState?.mediaId}
                                            title={mapState?.title}
                                            description={mapState?.description}
                                            data={data}
                                            onSuccess={() => {
                                                refetch({forceRefetch: true});
                                                rest?.onSuccess?.();
                                                setIsOpenEditing(false);
                                            }}
                                            isEditMode
                                            overlayClassName="map-screenshot-popup__overlay"
                                            isReadMoreArticle={isReadMoreArticle}
                                            galleryIndex={galleryIndex}
                                            isGalleryMode={isGalleryMode}
                                            isOwner={isOwner}
                                            onDelete={deleteScreenShot}
                                            isMapHeader={isMapHeader}
                                        >
                                            <div className="map-screenshot-popup-edit__icon-container">
                                                <img className="map-screenshot-popup-edit__icon" src={Edit} alt="Edit"/>
                                            </div>
                                        </MapScreenShotPopup>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            )}
            <Modal
                destroyOnClose
                title={t('presentation_mode.presentations')}
                centered
                classNames={{
                    header:
                        'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
                }}
                // getContainer={() => document.querySelector('.ant-popover-inner')}
                className="app-modal max-h-[90vh]"
                wrapClassName="!z-[1240]"
                width={950}
                footer={false}
                style={{padding: 0}}
                onCancel={() => setPresentationsIsOpen(false)}
                open={presentationsIsOpen}>
                <PresentationsSelector onSelect={changePresentationsHandler} defaultSelected={screenshotPresentation}/>
            </Modal>
        </div>
    );

    useEffect(() => {
        const handleResize = () => {
            const newScreenWidth = window.innerWidth;
            if (newScreenWidth < 1024 && fontSize === null) {
                setFontSize(20);
            } else if (fontSize === null) {
                setFontSize(14);
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [fontSize]);

    useEffect(() => {
        if (screenSlideID) {
            setIsOpenEditing(false)
        }
    }, [screenSlideID])

    useEffect(() => {
        if (!open) {
            setIsConfirmDelete(false)
            setIsOpenEditing(false)
        }
    }, [open])

    useEffect(() => {
        if (screenshotPresentation) {
            setIsOpenEditing(false)
            searchParams.delete("screenShot")
            navigate({
                pathname: window.location.pathname,
                search: searchParams.toString(),
            }, {replace: true});
        }
    }, [screenshotPresentation])


    useEffect(() => {
        dispatch(setScreenShotFormIsOpen(isOpenEditing))
    },[isOpenEditing])

    if (globalLoading || !mapStateId || +mapStateId !== +mapState?.id) {
        return null;
    }

    return (
        <AntPopover
            arrow={false}
            overlayStyle={isOpenEditing && {opacity: 0, width: 0, height: 0, padding: 0}}
            overlayInnerStyle={{
                borderRadius: "10px",
                position: 'relative',
                top: activeScreenShot === "article-read-more" ? 20 : 0,
                left: activeScreenShot === "article-read-more" ? -15 : -5
            }}
            open={open}
            destroyOnClose
            trigger="click"
            content={content}
            {...rest}
        />
    );
});

const mapStateTopProps = (state) => ({
    user: getUserData(state),
    activeScreenShot: getActiveScreenShot(state),
    presentationListRefetch:getPresentationListRefetch(state),
});

const mapDispatchToProps = {
    removeScreenShotCardAPI: removeScreenShotCardAPI,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(MapScreenShotPopupView);
