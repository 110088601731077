import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import './locales/i18n';
import axios from "axios"
axios.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("accessToken")
    if (token) {
      config.headers["accessToken"] = `Bearer ${token}`
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    return new Promise(async (resolve, reject) => {
      if (err.response?.status === 401) {
        const token = localStorage.getItem("accessToken")
        const refreshToken = localStorage.getItem("refreshToken")
        const headers = {
          accessToken: token,
          refreshToken: refreshToken,
        }

        if (!token) {
           window.location.href = '/login'
        }
        await axios
          .put(
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/RefreshAccessToken`,
            {
              headers: headers,
            }
          )
          .then(async (response) => {
            if (response.data.accepted === true) {
              await localStorage.setItem(
                "accessToken",
                response.data.data[0].accessToken
              )
              await localStorage.setItem(
                "refreshToken",
                response.data.data[0].refreshToken
              )
              err.config.headers[
                "accessToken"
              ] = `Bearer ${response.data.data[0].accessToken}`
              err.config.headers[
                "Authorization"
              ] = `Bearer ${response.data.data[0].accessToken}`
              return axios(err.config)
            } else {
              await localStorage.removeItem("accessToken")
              await localStorage.removeItem("refreshToken")
              return Promise.reject(err)
            }
          })
        return axios(err.config)
      } else {
        return reject(err)
      }
    })
  }
)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
