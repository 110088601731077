import React from 'react'
import {cn} from '../../../../utils/utils'
import NotPatumIcon from '../icons/NotPatumIcon'
import PatumIcon from '../icons/PatumIcon'
import {useTranslation} from 'react-i18next'

const PatumToggle = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(!!value)
  const { t } = useTranslation()

  const handleToggle = () => {
    onChange?.(!isOpen)
    setIsOpen(!isOpen)
  }

  return (
    <div>
      <button
        type="button"
        onClick={handleToggle}
        className={cn(
          'w-full z-[8888] text-[15px] [&>svg>path]:stroke-[#2C476C] p-0 text-[#2C476C] flex pr-1 transition-all hover:bg-[#f3f3f3] hover:text-[#2C476C] items-center relative rounded-2xl',
          {
            'bg-[#036147] hover:bg-[#036147] text-white hover:text-white  [&>svg>path]:stroke-white':
              isOpen,
          },
        )}>
        <span
          className={cn(
            'p-3 flex items-center rounded-2xl [&>svg>path]:stroke-[#2C476C] bg-[#f3f3f3]',
            {
              'bg-[#036147] text-white [&>svg>path]:stroke-white': isOpen,
            },
          )}>
          {!isOpen ? <NotPatumIcon /> : <PatumIcon />}
        </span>
        <span className="whitespace-nowrap ml-1 pr-2 pl-0 py-3">
          {isOpen
            ? t('presentation_mode.display_narrative')
            : t('presentation_mode.hide_narrative')}
        </span>
      </button>
    </div>
  )
}

export default PatumToggle
