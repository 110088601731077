import {
    IS_MICRO_ARTICLES_LOADING,
    IS_SINGLE_MICRO_ARTICLE_LOADING,
    MICRO_ARTICLE_ICONS_SPRITE_CATEGORIES,
    MICRO_ARTICLE_ICONS_SPRITE_JSON,
    SET_ACTIVE_MICROARTICLE_ID,
    SET_ALL_MICRO_ARTICLES,
    SET_ALL_MICRO_ARTICLES_BOARD,
    SET_CLOSE_AND_DEACTIVATE_ALL_ACTIVATOR,
    SET_CURRENT_FEATURE_ID, SET_FEATURE_MICROARTICLE_ID,
    SET_FORM_POPUP_VISIBILITY,
    SET_MICRO_ARTICLE_DATA,
    SET_MICRO_ARTICLE_ICONS_KEYS,
    SET_MICRO_ARTICLE_SELECTED_CATEGORY_ID,
    SET_MICRO_ARTICLE_SPRITE_ICONS_SEARCH, SET_POPUP_DETAIL_FONT_SIZE, SET_RECENT_COLORS,
    SET_VIEW_POPUP_VISIBILITY
} from "../constants";
import {getCloseAndDeactivateAllActivator} from "../selectors";

export const setIsMicroArticlesLoading = (bool) => ({
   type: IS_MICRO_ARTICLES_LOADING,
   payload: bool
});

export const setMicroArticleData = (data) => ({
    type: SET_MICRO_ARTICLE_DATA,
    payload: data,
});

export const setAllMicroArticlesBoard = (data) => ({
    type: SET_ALL_MICRO_ARTICLES_BOARD,
    payload: data,
});

export const setIsSingleMicroArticleLoading = (bool) => ({
    type: IS_SINGLE_MICRO_ARTICLE_LOADING,
    payload: bool
})

export const setCloseAndDeactivateAllMicroArticlesActivator = (bool) => ({
    type: SET_CLOSE_AND_DEACTIVATE_ALL_ACTIVATOR,
    payload: bool
})

export const setMicroArticleIconsKeys = (data) => ({
    type: SET_MICRO_ARTICLE_ICONS_KEYS,
    payload: data
})

export const setMicroArticleSelectedCategoryId = (id) => ({
    type: SET_MICRO_ARTICLE_SELECTED_CATEGORY_ID,
    payload: id
})

export const setMicroArticleSpriteIconsSearch = (data) => ({
    type: SET_MICRO_ARTICLE_SPRITE_ICONS_SEARCH,
    payload: data
})

export const setPopupDetailFontSize = (fontSize) => ({
    type: SET_POPUP_DETAIL_FONT_SIZE,
    payload: fontSize
})

export const setRecentColors = (data) => ({
    type: SET_RECENT_COLORS,
    payload: data
})

export const setMicroArticleIconsSpriteJSON = (data) => ({
    type: MICRO_ARTICLE_ICONS_SPRITE_JSON,
    payload: data
})
export const setMicroArticleIconsSpriteCategories = (data) => ({
    type: MICRO_ARTICLE_ICONS_SPRITE_CATEGORIES,
    payload: data
})

export const setActiveMicroArticleId = (id) => ({
    type: SET_ACTIVE_MICROARTICLE_ID,
    payload: id,
});

export const setFeatureMicroArticleId = (id) => ({
    type: SET_FEATURE_MICROARTICLE_ID,
    payload: id,
});

export const setCurrentFeatureId = (featureId) => ({
    type: SET_CURRENT_FEATURE_ID,
    payload: featureId,
});

export const setViewPopupVisibility = (isVisible) => ({
    type: SET_VIEW_POPUP_VISIBILITY,
    payload: isVisible,
});

export const setFormPopupVisibility = (isVisible) => ({
    type: SET_FORM_POPUP_VISIBILITY,
    payload: isVisible,
});

export const setAllMicroArticles = (data) => ({
    type: SET_ALL_MICRO_ARTICLES,
    payload: data,
})
