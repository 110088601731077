import React, {cloneElement, useMemo, useState} from 'react'
import PresentationSlidesList from './slides/PresentationSlidesList'
import {Button, Checkbox, Input, Menu, Modal, Popover, Spin} from 'antd'
import SearchIcon from './icons/SearchIcon'
import {ArrowDownUp} from 'lucide-react'
import PresentationCard from './PresentationCard'
import {useGetPresentationsQuery,} from '../store/presentationApi'
import useDebounce from '../../../hooks/useDebounce'
import {cn} from '../../../utils/utils'
import CardSkeleton from './elements/CardSkeleton'
import {useTranslation} from 'react-i18next'

const PresentationsSelector = ({onSelect, data = [], defaultSelected}) => {
  const { t } = useTranslation()
  const [activeId, setActiveId] = React.useState(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [sortBy, setSortBy] = useState('createdAt')
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedList, setSelectedList] = React.useState(() => {
    return [...new Map([...(defaultSelected ? [defaultSelected] : []), ...data].map(item => [item.id, item])).values()];
  });
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [currentUser] = useState(JSON.parse(localStorage.getItem('userInfo'))?.data)

  const {
    data: presentationsData,
    refetch,
    isFetching,
  } = useGetPresentationsQuery({
    page: 1,
    perPage: 1000,
    columnFilters: {
      title: debouncedSearchValue,
      createdBy: String(currentUser?.id),
    },
    sort: [
      {
        field: sortBy,
        type: 'desc',
      },
    ],
  })

  const records = useMemo(
    () => presentationsData?.data?.[0]?.items || [],
    [presentationsData],
  )

  const activePresentation = useMemo(() => {
    if (!activeId) return null
    return records.find((item) => item.id === activeId)
  }, [activeId, records])

  const items = [
    {
      label: t("presentation_mode.sort_alphabetically"),
      key: 'title',
    },
    {
      label: t("presentation_mode.sort_created_date"),
      key: 'createdAt',
    },
    {
      label: t("presentation_mode.sort_modified_date"),
      key: 'modifiedAt',
    },
  ]

  const clickDropdownHandler = (e) => {
    // e.domEvent.stopPropagation();
    switch (e.key) {
      case 'title':
        setSortBy('title')
        break
      case 'createdAt':
        setSortBy('createdAt')
        break
      case 'modifiedAt':
        setSortBy('modifiedAt')
        break
      default:
    }
  }

  const submitHandler = () => {
    onSelect?.(selectedList)
  }

  const onSetMicroArticleSelected = (item) => {
    if (selectedList.find((el) => el.id === item.id)) {
      setSelectedList(selectedList.filter((el) => el.id !== item.id))
    } else {
      setSelectedList([...selectedList, item])
    }
  }

  const onSetSelected = (item) => {
    if (selectedList.find((el) => el.id === item.id)) {
      setSelectedList(selectedList.filter((el) => el.id !== item.id))
    } else {
      setSelectedList([...selectedList, item])
    }
  }



  const menuPopover = (
    <div className="-m-[12px] max-h-[250px] overflow-y-auto ">
      <div className="pt-3 px-5">
        <span className="text-[#2C476C80] uppercase">{t("presentation_mode.sort")} </span>
      </div>
      <Menu className="p-0 bg-transparent" selectable={false}>
        {items.map((item) => (
          <Menu.Item
            className={cn('p-0', {
              'bg-[#2C476C10]': sortBy === item.key,
            })}
            key={item.name}
            onClick={() => {
              clickDropdownHandler(item)
              setMenuIsOpen(false)
            }}>
            <div className={cn('flex items-center pr-2', {})}>
              {item.icon && (
                <div className="w-6 h-6 flex items-center justify-center text-[#2C476C]">
                  {item.icon}
                </div>
              )}

              <span className="ml-1 text-[#2C476C]">{item.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )

  return (
    <div className="text-[#2C476C]">
      <div className="flex justify-between items-center my-4 gap-3">
        <Input
          variant="borderless"
          onChange={(e) => setSearchValue(e.target.value)}
          rootClassName="search-input w-full lg:w-[31%] ml-2.5 [&>input]:!text-[#2C476C]"
          suffix={isFetching ? <Spin /> : <SearchIcon />}
          placeholder={t('presentation_mode.search_presentation')}
        />
        <Popover
          onOpenChange={(open) => setMenuIsOpen(open)}
          openClassName="p-0 "
          placement="bottomRight"
          content={menuPopover}
          open={menuIsOpen}
          trigger={['click']}
          arrow={false}
          overlayClassName="app-dropdown presentation-article-selector--dropdown rounded-lg p-0 !z-[1300] [&>div]:rounded-lg [&>div]:overfloxw-hidden"
          menu={{ items: items, selectable: true }}
          dropdownRender={(menus) => (
            <div className="">{cloneElement(menus)}</div>
          )}>
          <Button
            icon={<ArrowDownUp size={20} color={'#2C476C'} />}
            type="text"
          />
        </Popover>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 h-full overflow-auto max-h-[55vh] px-2">
        {isFetching &&
          Array(6)
            .fill(0)
            .map((_, index) => <CardSkeleton key={index} />)}
        {records.map((item, index) => (
           <PresentationCard
               key={item.id}
               id={item.id}
               hideMoreButton
               presentation={item}
               onChange={() => onSetSelected(item)}
               selected={selectedList.some((el) => +el.id === +item.id)}
               hasSlides={item.slidesCount > 0}
               disabledSelection={+defaultSelected?.id === +item.id}
               index={index}
               image={item.imageUrl}
               selectable
               onClick={() => item.slidesCount > 0 && setActiveId(item.id)}
               name={item.title}
           />
        ))}
      </div>

      {!isFetching && !records?.length && (
        <div className="h-full min-h-[40dvh] flex flex-col justify-center items-center text-center text-2xl md:text-3xl uppercase max-w-[280px] mx-auto">
          {debouncedSearchValue && (
            <span className="text-[#2C476C80]">
              {t('presentation_mode.no_search_results_message')}
            </span>
          )}
        </div>
      )}

      <div className="flex justify-center items-center pt-4">
        <Button
            disabled={!selectedList?.length && !data?.length}
            type="text"
            onClick={submitHandler}
            className="uppercase text-[12px] h-[30px] min-w-[130px] bg-[#FFDE5A] border-none text-black rounded-[38px] shadow-none cursor-pointer tracking-[2px] hover:!bg-[#FFDE5A] hover:!shadow-none hover:!text-black hover:!border-none">
          {t(!!selectedList?.length ? 'presentation_mode.my_maps_add' : 'presentation_mode.save')}
        </Button>
      </div>

      {!!activePresentation && (
        <Modal
          open={activeId}
          maskClosable={false}
          centered
          title={t('presentation_mode.presentation_slides')}
          width={420}
          wrapClassName="!z-[1244]"
          classNames={{
            body: '!p-0',
            header: 'uppercase text-sm font-medium text-center !p-4',
          }}
          onCancel={() => setActiveId(null)}
          footer={null}
          className="no-padding app-modal">
          <PresentationSlidesList
            hidePresentation
            viewMode
            presentationId={activePresentation.id}
            presentation={activePresentation}
          />
        </Modal>
      )}

    </div>
  )
}

export default PresentationsSelector
