import * as React from 'react'

const PatumIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6 12.7549V10.0249C6 8.29992 7.395 6.91992 9.105 6.91992H30.885C32.61 6.91992 33.99 8.31492 33.99 10.0249V12.7549"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 33.0801H25.82"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 33L20 8"
      stroke="white"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 38L2 2L38 2L38 38L2 38Z"
      stroke="white"
      strokeWidth={2.5}
    />
  </svg>
)
export default PatumIcon
