//generate circle outline plus icon component

import React from 'react'

const SettingsIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M41.2566 12.1875H30.0049"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2521 12.1875H3.75098"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.753 18.75C15.128 18.75 12.1895 15.8119 12.1895 12.1875C12.1895 8.56313 15.128 5.625 18.753 5.625C22.3779 5.625 25.3165 8.56313 25.3165 12.1875C25.3165 15.8119 22.3779 18.75 18.753 18.75Z"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.256 32.8125H33.7549"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0027 32.8125H3.75098"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2539 39.375C22.629 39.375 19.6904 36.4369 19.6904 32.8125C19.6904 29.1881 22.629 26.25 26.2539 26.25C29.8789 26.25 32.8175 29.1881 32.8175 32.8125C32.8175 36.4369 29.8789 39.375 26.2539 39.375Z"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SettingsIcon
