import mapboxgl from 'mapbox-gl'
import lampIconArticle from "../../../assets/imgs/lamp_icon_article.svg";
import moment from "moment";
import i18n from "i18next";
import {debeounced100, debeounced500} from "./helper";
import {MAP_TOOLBOX_ITEMS, MAP_TOOLBOX_KEYS} from "../../../components/mapToolbox";
import axios from "axios";
import {sanitizeResult} from "../../../service/legends";
import * as turf from "@turf/turf";
import {BRAINOGRAPH_GIS_API, BRAINOGRAPH_PIN_GIS_API} from "./featureHelpers";
import {dispatchChangesTimeRange} from "../../../store/actions/mapStateAction";

const {request} = require("https-browserify")
const zlib = require("react-zlib-js")

export const MAX_ZOOM_MAP = 8
export const mapUtilities = (map, timeLine) => {
    const setData = (items, groups) => {
        timeLine.current.setData({ items, groups })
    }

    return {
        setData,
    }
}
const parseGzip = (gzipBuffer) => new Promise((resolve, reject) =>{
    zlib.gunzip(gzipBuffer, (err, buffer) => {
        if (err) {
            reject(err)
            return
        }
        resolve(buffer)
    })
})


const fetchJson = (url) => new Promise((resolve, reject) => {
    const r = request(url)
    r.on("response", (response) => {
        if (response.statusCode !== 200) {
            reject(new Error(`${response.statusCode} ${response.statusMessage}`))
            return
        }

        const responseBufferChunks = []

        response.on("data", (data) => {
            responseBufferChunks.push(data)
        })
        response.on("end", async () => {
            const responseBuffer = Buffer.concat(responseBufferChunks)
            const unzippedBuffer = await parseGzip(responseBuffer)
            resolve(JSON.parse(unzippedBuffer.toString()))
        })
    })
    r.end()
})

export const getZippedData = async (url) => fetchJson(url)

export const getData = (url) => {
    return fetch(url).then((response) => response.json())
}

export const isSymbol = (item) => item.type === 'symbol'
export const isPoint = (item) => item.id.split('-')[2] === 'point'

export const addSymbol = (categories, current, getArticleFromAPI) => (item) => {
    const popup = new mapboxgl.Popup({ className: "pin-shortTitle-popup" })

    current.on('click', item.id, (e) => {
        getArticleFromAPI(item.ArticleId)
    })
    current.on('mouseenter', item.id, (e) => {
        current.getCanvas().style.cursor = 'pointer'
        let catId = e.features[0].properties.categoryId
        let cat = categories.find((x) => x.id === catId)
        popup
            .setLngLat(e.features[0].geometry.coordinates.slice())
            .setHTML(
                `
                                <div style="background-color: ${
                    cat ? cat.color : '#fff'
                };padding: 10px 20px 10px !important;box-shadow: 0 1px 2px rgb(0 0 0 / 10%);    border-radius: 200px !important;" onClick={getArticleFromAPI(item.ArticleId)}>${
                    item?.ArticleShortTitle
                        ? item?.ArticleShortTitle
                        : item.ArticleTitle
                }</div>
                                <div class="mapboxgl-popup-tip" style="border-top-color: ${
                    cat ? cat.color : '#fff'
                };margin: 0 auto;"></div>`
            )
            .addTo(current)
    })
    current.on('mouseleave', item.id, () => {
        current.getCanvas().style.cursor = ''
        popup.remove()
    })
}

export const addArticlePoint = (current, data) => {
    let color = data?.layers[0]?.paint['icon-color']

    const el = document.createElement('div')
    const width = 35
    const height = 35
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    el.style.backgroundColor = color
    el.style.padding = '10px'
    el.style.top = '-30px'
    el.style.boxShadow = '0 1px 2px rgb(0 0 0 / 10%)'
    el.style.borderRadius = '200px'
    el.style.padding = '10px !important'
    el.style.overflow = 'hidden'
    const elInner = document.createElement('div')
    elInner.style.height = '100%'
    elInner.style.width = '100%'
    elInner.style.overflow = 'hidden'
    const elImg = document.createElement('img')
    elImg.style.height = '100%'
    elImg.style.width = 'auto'
    elImg.style.filter = 'brightness(10)'
    elImg.setAttribute('src', data?.images[Object.keys(data?.images)[0]])
    elInner.appendChild(elImg)
    el.appendChild(elInner)
    setTimeout(() => {
        new mapboxgl.Marker(el).setLngLat(data?.center).addTo(current)
    }, 1000)
}

export const addPoint = (current, getArticleFromAPI, response, lampData) => (item) => {
    if (document.getElementById(item.id)) {
        document.getElementById(item.id).remove()
    }
    let catImg = null
    let catColor = '#fff'
    const layerPars = Object.entries(item.layout)
    let cats = layerPars.find((x) => x[0] === 'icon-image')
    const PaintPars = Object.entries(item.paint)
    let paint = PaintPars.find((x) => x[0] === 'icon-color')
    if (paint) {
        catColor = paint[1]
    }
    if (cats) {
        let catImgs = Object.entries(response.images).find(
            (x) => x[0] === cats[1]
        )
        if (catImgs) {
            catImg = catImgs[1]
        }
    }
    let featuresId = null
    let pinCenter = [0, 0]
    if (item.filter[0] === 'all') {
        featuresId = item.filter[1][2]
    } else {
        featuresId = item.filter[2]
    }
    if (featuresId !== null) {
        const cordinates = response.features.find(
            (x) => x.properties.id === featuresId
        )
        if (cordinates) {
            pinCenter = cordinates.geometry.coordinates
        }
    }

    const getFromLamp = lampData.find(
        (x) => x.id === item.ArticleId
    );
    const el = document.createElement('div')
    el.classList.add(`article_${item.ArticleId}`)
    const width = 35
    const height = 35
    el.onclick = () => getArticleFromAPI(item.ArticleId)
    el.id = item.id
    el.style.cursor = 'pointer'
    el.style.width = `${width}px`
    el.style.height = `${height}px`
    if (getFromLamp !== undefined) {
        const isMainData =  getFromLamp?.subCategory.filter((item)=> item.isMain)[0]?.subCategoryId
        el.classList.add(`lamp_pin`)
        el.classList.add(`lamp_pin_${item.ArticleId}`)
        el.classList.add(`show_lamp_article`)
        el.style.backgroundColor = '#2C476C'
    } else{
        el.style.backgroundColor = catColor
    }

    el.style.padding = '10px'
    el.style.visibility = 'visible'
    el.style.top = '-30px'
    el.style.boxShadow = '0 1px 2px rgb(0 0 0 / 10%)'
    el.style.borderRadius = '200px'
    el.style.padding = '10px !important'
    el.style.overflow = 'hidden'
    const elInner = document.createElement("div");
    elInner.style.height = "100%";
    elInner.style.width = "100%";
    elInner.style.overflow = "hidden";
    elInner.style.display = "flex";
    elInner.style.justifyContent = "center";
    const elImg = document.createElement("img");
    elImg.style.height = "100%";
    elImg.style.width = "auto";

    if (getFromLamp !== undefined) {
        elImg.setAttribute("src", lampIconArticle);
    } else {
        elImg.setAttribute("src", catImg);
    }

    elInner.appendChild(elImg);
    el.appendChild(elInner);

    new mapboxgl.Marker(el).setLngLat(pinCenter).addTo(current)
}

export const addAndPositionLayer =
    (current, loadFirst, firstLayer) => (item) => {
        // if (!current.getLayer(item.id)) {
        current.addLayer(item)
        // if (!loadFirst && item.id.includes('country')) {
        //     if (current.getLayer(item.id)) {
        //         current.moveLayer(item.id, firstLayer)
        //     }
        // }
        // }
    }
function convertToFourDigitYear(year,manth,day) {
    var isNegative = year < 0;
    var absoluteYear = Math.abs(year);
    var paddedYear = absoluteYear.toString().padStart(4, '0');
    var date = new Date(`0001-${manth ? manth : '01'}-${day ? day : '01'}`);
    date.setFullYear(paddedYear);
    if (isNegative) {
        // Convert negative year to BCE
        date.setFullYear(-paddedYear);
    }
    return date;
}
export const formatServerResponseTime = ({timeEnd,timeStart}) => {
    const {
        day:endDay,
        year:endYear,
        month:endMonth,
        isBc:endTimeIsBC,

    } = timeEnd || {year:new Date().getFullYear(),day:new Date().getDay(),month:new Date().getMonth() + 1,isBc:false}
    const {
        day:startDay,
        year:startYear,
        month:startMonth,
        isBc:startTimeIsBC,
    } = timeStart || {year:4714,day:1,month:1,isBc:true}
    const startFrom = convertToFourDigitYear(startYear,startMonth,startDay)
    if(startTimeIsBC) startFrom.setFullYear(`-${startFrom.getFullYear()}`)
    const endTo =  convertToFourDigitYear(endYear,endMonth,endDay)
    if(endTimeIsBC) endTo.setFullYear(`-${endTo.getFullYear()}`)
    return { startFrom, endTo }
}


export const formatLessonTime = ({timeEnd,timeStart}) => {
    const {
        day:endDay,
        year:endYear,
        month:endMonth,
        isBc:endTimeIsBC,
    } = timeEnd || {year:new Date().getFullYear(),day:new Date().getDay(),month:new Date().getMonth() + 1,isBc:false}

    const {
        day:startDay,
        year:startYear,
        month:startMonth,
        isBc:startTimeIsBC,
    } = timeStart || {year:4714,day:1,month:1,isBc:true}
    const startMonthF = `${startMonth !== null ? endMonth : 1}`.padStart(2, '0')
    const startDatF = `${startDay !== null ? endDay : 1}`.padStart(2, '0')
    const startYearF = `${
        startTimeIsBC
            ? `-${String(startYear).padStart(4, '0')}`
            : String(startYear).padStart(4, '0')
    }`

    const endMonthF = `${endMonth !== null ? endMonth : 12}`.padStart(2, '0')
    const endDayF = `${endDay !== null ? endDay : 1}`.padStart(2, '0')
    const endYearF = endTimeIsBC
        ? `-${String(endYear).padStart(4, '0')}`
        : String(endYear).padStart(4, '0')

    const lessonStartFrom = startTimeIsBC
        ? new Date(startYearF, startMonthF, startDatF)
        : new Date(`${startYearF}-${startMonthF}-${startDatF}`)
    const lessonEndTo = endTimeIsBC
        ? new Date(endYearF, endMonthF, endDayF)
        : new Date(`${endYearF}-${endMonthF}-${endDayF}`)
    return { lessonStartFrom, lessonEndTo }
}

export const formatArticleTime = ({timeEnd,timeStart,year,isBc}) => {
    const {
        day:endDay,
        year:endYear,
        month:endMonth,
        isBc:endTimeIsBC,
    } = timeEnd
    || (year && {year:year,day:new Date().getDay() || 1,month:new Date().getMonth() + 1,isBc:isBc})
    || {year:new Date().getFullYear(),day:new Date().getDay() || 1,month:new Date().getMonth() + 1,isBc:false}

    const {
        day:startDay,
        year:startYear,
        month:startMonth,
        isBc:startTimeIsBC,
    } = timeStart
    || (year && {year:year,day:new Date().getDay() || 1,month:new Date().getMonth() + 1,isBc:isBc})
    || {year:4714,day:1,month:1,isBc:true}

    let startMonthF = 1;
    let startDatF = 1;
    let startYearF = 1945;

    let endMonthF,endDayF,endYearF;
    let articleStartFrom,articleEndTo;
    // if(timeStart){
        startMonthF = `${startMonth !== null ? startMonth : 1}`.padStart(2, '0')
        startDatF = `${startDay !== null ? startDay : 1}`.padStart(2, '0')
        startYearF = `${
            startTimeIsBC
                ? `-${String(startYear).padStart(4, '0')}`
                : String(startYear).padStart(4, '0')
        }`
        articleStartFrom = startTimeIsBC
            ? new Date(startYearF, startMonthF, startDatF)
            : new Date(`${startYearF}-${startMonthF}-${startDatF}`)
    // }
    // if(timeEnd){
        endMonthF = `${endMonth !== null ? endMonth : 12}`.padStart(2, '0')
        endDayF = `${endDay !== null ? endDay : 1}`.padStart(2, '0')
        endYearF = endTimeIsBC
            ? `-${String(endYear).padStart(4, '0')}`
            : String(endYear).padStart(4, '0')
        articleEndTo = endTimeIsBC
            ? new Date(endYearF, endMonthF, endDayF)
            : new Date(`${endYearF}-${endMonthF}-${endDayF}`)
    // }

    return { articleStartFrom, articleEndTo }
}

export const generateQueryString = (url) => {
    let newObj = {}
    let type = url.type
    if (url.lessonID) {
        newObj.lessonid = url.lessonID
    }
    if (url.subjectID) {
        newObj.subjectId = url.subjectID
    }
    if (url.topicID) {
        newObj.topicId = url.topicID
    }
    if (url.gradeID) {
        newObj.gradeId = url.gradeID
    }
    if (url.articleID) {
        newObj.articleId = url.articleID
    }
    return { newObj, type }
}

export const generateTimeLineQueryString = (
    fullYear,
    url,
    selectedLesson,
    id,
    lng,
    changesTimeRange
) => {
    let newObj = {
        // year: fullYear < 0 ? (fullYear) * -1 : fullYear,
        languageId: lng
    }
    newObj.year = getMappedYearOptimized(fullYear,changesTimeRange)
    if (fullYear < 0) {
        newObj.isBc = true
    } else {
        newObj.isBc = false
    }
    return newObj
}


export const determinateURLParams = (url) => {
    let newObj = {
        type: url.type,
    }
    if (url.lessonID) {
        newObj.id = url.lessonID
    }
    if (url.topicID) {
        newObj.id = url.topicID
    }
    if (url.articleID) {
        newObj.id = url.articleID
    }
    return newObj
}

export const determineCatIds = (resData, type) => {
    const catIds = []
    if (type === 'lesson') {
        resData.articles.forEach((item) => {
            if(Array.isArray(item.subcategory)){
                catIds.push({
                    catID: item.subcategory[0]?.categoryId,
                    subCatID: item.subcategory[0]?.subCategoryId,
                })
            }
        })
    } else if (type === 'topic') {
        resData?.lessons.forEach((item) => {
            item?.articles.forEach((obj) => {
                if(Array.isArray(obj.subcategory)) {
                    catIds.push({
                        catID: obj?.subcategory[0]?.categoryId,
                        subCatID: obj.subcategory[0]?.subCategoryId,
                    })
                }
            })
        })
    } else if (type === 'grade') {
        resData?.topics.forEach((topic) => {
            topic?.lessons.forEach((item) => {
                item?.articles.forEach((obj) => {
                    if(Array.isArray(obj.subcategory)) {
                        catIds.push({
                            catID: obj.subcategory[0]?.categoryId,
                            subCatID: obj.subcategory[0]?.subCategoryId,
                        })
                    }
                })
            })
        })
    } else if (type === 'article' || type === 'lamp') {
        if(Array.isArray(resData.subcategory)) {
            catIds.push({
                catID: resData.subcategory[0]?.categoryId,
                subCatID: resData.subcategory[0]?.subCategoryId,
            })
        }
    }

    return catIds
}

export const extractId = (layer) => layer.id
export const _filterByFeatureId = (feature) => (layer) => {
    const featuresID = feature.properties.id
    if (layer.filter[0] === 'all' && layer.filter[1][2] === featuresID) {
        return true
    } else {
        if (layer.filter[2] === featuresID) {
            return true
        }
    }
}

export const getShowByType = (categories, categoryId, subCategoryId, type,articleItemId) => {
    const categoryIndex = categories.findIndex((x) => x.id === categoryId)
    let subCategoryIndex = null
    let articleItem = null
    let isShow = categories[categoryIndex].show
    if (type === 'subCategory') {
        subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
            (x) => x.id === subCategoryId
        )
        isShow = categories[categoryIndex].subCategories[subCategoryIndex].show
    }

    if (type === 'article' || type === 'lamp') {
        articleItem = categories[categoryIndex].subCategories.find(
            (x) => x.id === subCategoryId
        )?.articles?.find(el=> el.id === articleItemId)
        isShow = articleItem?.show
    }
    //new for legend
    if (type === 'layar') {
        subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
            (x) => x.id === subCategoryId
        )
        isShow = categories[categoryIndex].subCategories[subCategoryIndex].show
    }
    return isShow
}

export const subCatFilterLogic = (categories, feature, id, isShow,subID) => {
    let catIndex = categories.findIndex((x) => x.id === id)
    let subsDisabledFromCat = categories[catIndex]?.subCategories?.filter(
        (x) => x.show === isShow
    )

    if (
        subsDisabledFromCat.length ===
        categories[catIndex]?.subCategories.length
    ) {
        return true
    } else {
        const isContain = []
        feature.properties.subCategories.forEach((subs) => {
            let subID = subs.SubCategoryId
            if (subsDisabledFromCat.find((x) => x.id === subID) !== undefined) {
                isContain.push(true)
            }
        })
        if (isContain.length === feature.properties.subCategories.length) {
            return true
        }
    }
}
function getFullYear(value) {
    if (typeof value === 'number') {
        // If the value is a number, assume it's a year
        return value;
    } else if (typeof value === 'string' || typeof value === 'object') {

        // Parse the value as a date
        const date = moment(value);
        if (date.isValid()) {
            return date.year();
        } else {
            console.error('Invalid date:', value);
            return null;
        }
    } else {
        console.error('Unsupported value type:', value);
        return null;
    }
}
export const getSelectedYear = (time, timeLine) => {
    let fullYear = getFullYear(time)
    return fullYear
}

export const handleGetPopupAnchor = () => {
    return window.innerWidth < 512
        ?'bottom'
        :'left'
};

export const  handleGetPopupOffset =  () => {
    return window.innerWidth < 512
        ? 50
        : 20
}

export const  handleGetPopupWidth = () => {
    return window.innerWidth < 512
        ?'55vw'
        :'30vw'
}

export const  navigateTo = (to,navigationType,isTopicMode) => {
    const baseUrl = isTopicMode ? "/map?type=topic&topicID=" : "/map?type=lesson&lessonID=";
    let path;
    if (to === 'next') {
        path = `${baseUrl}${navigationType?.next?.id}`;
    } else if (to === 'prev') {
        path = `${baseUrl}${navigationType?.previous?.id}`;
    }
    if(!path) return
    window.location.href = path;
}
export const  stopPinAnimation = (mapRef) => {
    mapRef.current?.fire('closeAnimationMarker');
    const popupELems = document.getElementsByClassName('elements-popup-radius-content')
    if (popupELems && popupELems.length > 0) {
        [...popupELems].forEach(el => {
            el.dataset.active = false
        })
    }
};

export const handleTimeLineClickActionST = (e,isScreenShot, timeLineRef, setTimeLineChanged, handleTimeChange, changeClusterItemsTime) => {
    if (e.targetId === "group_content") return;
    if (e.targetId === "lesson-item") return;
    setTimeLineChanged(true)
    const customBar = document.querySelector(".t1");
    if (customBar === null) {
        timeLineRef.current.addCustomTime(e.time, "t1");
    } else {
        timeLineRef.current.setCustomTime(e.time, "t1");
    }
    handleTimeChange (e.time,isScreenShot);
    changeClusterItemsTime(e.time)
};

export const onMouseEnterScrollZone = (timeLineRef,customLineRef,timeLineRelatedRef,handleTimeChange) => {
    const scrollZone = document.getElementsByClassName('scroll_and_line_change')[0];
    timeLineRef.current.on("timechange", (properties) => {
        const eventPopover = document.getElementById('event-popover')
        const eventIcon = document.getElementById('event-icon')
        if (eventIcon) eventIcon.remove()
        if (eventPopover) {
            eventPopover.dataset.timeYear = properties.time.getFullYear() < 0 ? `${i18n.t('bc')} ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
        } else {
            const popover = document.createElement('span')
            popover.className = 'event-popover'
            popover.id = 'event-popover'
            popover.dataset.timeYear = properties.time.getFullYear() < 0 ? `${i18n.t('bc')} ${Math.abs(properties.time.getFullYear())}` : properties.time.getFullYear()
            document.getElementsByClassName('t1')[0]?.appendChild(popover)
        }
        const scrollZoneParams = scrollZone.getBoundingClientRect();
        const customTimeLineParams = customLineRef?.current?.getBoundingClientRect();
        if (customTimeLineParams?.x + customTimeLineParams?.width >= scrollZoneParams.x) {
            const ms = new Date(properties.time).getTime();
            const changeMs = new Date(ms - 4.154e10);
            timeLineRef.current.removeCustomTime("t1");
            timeLineRef.current.addCustomTime(changeMs, "t1");
            timeLineRef.current.redraw();
            handleTimeChange(properties.time);
            const customBar = document.querySelector(".t1");
            customLineRef.current = customBar;
        }
    });
    timeLineRef.current.on("rangechange", () => {
        const {start, end} = timeLineRef.current.getWindow();
        if (start) {
            timeLineRelatedRef.current?.setWindow(start, end, {animation: false});
        }
    });
    timeLineRelatedRef.current?.on("rangechange", () => {
        const {start, end} = timeLineRelatedRef.current?.getWindow();
        if (start) {
            timeLineRef.current.setWindow(start, end, {animation: false});
        }
    });
    timeLineRef.current.on("mouseUp", () => {
        const customBar = document.querySelector(".t1");
        customLineRef.current = customBar;
    });
};

export const setCurrentOnTimeline = (data,customLineRef,timeLineRef,selectedLesson) => {
    // if (url.type !== 'article' && !mapData?.showTimeline) return;
    const customDate = data.start
    const customBar = document.querySelector('.t1')
    customLineRef.current = customBar
    if(!selectedLesson){
        customBar === null &&  timeLineRef.current.addCustomTime(null, 't1')
        customBar !== null && timeLineRef.current.setCustomTime(customDate, 't1')
    }else{
        customBar === null
            ? timeLineRef.current.addCustomTime(customDate, 't1')
            : timeLineRef.current.setCustomTime(customDate, 't1')
    }
}

export  const changeTimeLineOptions = (data,timeLineRef,timeLineRelatedRef) => {
    timeLineRef.current.setOptions?.({
        start: data.start,
        end: data.end,
        min: data.start,
        max: data.end,
    });
    timeLineRelatedRef.current?.setOptions?.({
        start: data.start,
        end: data.end,
        min: data.start,
        max: data.end,
    });
};

export const goToTopics = async (topicId) =>{
    if (
        window.location.href.includes("preview=true") &&
        window.location.href.includes("type=lesson")
    ) {
        window.location.href = `/map?preview=true&type=topic&topicID=${topicId}`;
    } else if (
        !window.location.href.includes("preview=true") &&
        window.location.href.includes("type=lesson")
    )
        window.location.href = `/map?type=topic&topicID=${topicId}`;
    else {
        window.location.reload();
    }
};

export const  filterFeatuers = (mapRef,filteredLegends) => {
    const features = mapRef.current.querySourceFeatures(BRAINOGRAPH_GIS_API, {
        'sourceLayer': "brainograph"
    })
    const layers = mapRef.current.getStyle().layers.filter(x => x['source-layer'] == 'brainograph' || x['sourceLayer'] == 'brainograph')
    const filterOptions = {}
    features.forEach(el => {
        if (!Array.isArray(filterOptions[el.properties.layer])) {
            filterOptions[el.properties.layer] = layers.filter(x => x.filter.find(f => f[0] === "==" && f[1] === "layer" && f[2] == el.properties.layer))
            filterOptions[el.properties.layer].show = true
            filterOptions[el.properties.layer].textShow = true
            if (filteredLegends?.checkIsHideItem?.(el.properties.layer)) {
                filterOptions[el.properties.layer].show = false
            }
            if (filteredLegends?.checkIsHideItemText?.(el.properties.layer)) {
                filterOptions[el.properties.layer].textShow = false
            }
        }
    });
    Object.keys(filterOptions).forEach(layer => {
        filterOptions[layer].forEach(targetLayer => {
            if (filterOptions[layer].show) {
                mapRef.current.setLayoutProperty(targetLayer.id, 'visibility', 'visible');
            } else {
                mapRef.current.setLayoutProperty(targetLayer.id, 'visibility', 'none');
            }
            if (targetLayer?.layout && 'text-field' in targetLayer?.layout) {
                if (filterOptions[layer].textShow) {
                    mapRef.current.setLayoutProperty(targetLayer.id, 'text-field', '{name}');
                } else {
                    mapRef.current.setLayoutProperty(targetLayer.id, 'text-field', '');
                }
            }
        })
    })
}

export const onMapRenderComplete = (map, fn,i=0) =>{
    if(i >= 500) return new Error('RENDER COUNT IS MUCH')
    if (map.isStyleLoaded()) {
        debeounced100(() => process.nextTick(fn))
        if(i !== -1) debeounced500(() => onMapRenderComplete(map, fn, -1))
        return
    }
    map.once('data', () => onMapRenderComplete(map, fn, ++i))
}

export const  move = (percentage, timeLineRef, timeLineRelatedRef) => {
    const currentTime = timeLineRef.current.current_time;
    let range = timeLineRef.current.getWindow();
    let interval = range.end - range.start;

    timeLineRef.current.setWindow({
        start: range.start.valueOf() - interval * percentage,
        end: range.end.valueOf() - interval * percentage,
    })
    timeLineRef.current.setCurrentTime(currentTime);
    timeLineRelatedRef.current?.setCurrentTime(currentTime);
}

export const changeMapZoom =  (zoom, mapRef) => {
    mapRef.current.zoomTo(zoom, {duration: 1000});
};

export const  clickSliderItemHandler = (item,type) => {
    if (type === "topic") {
        window.location.href = `/map?type=lesson&lessonID=${item.id}`
    }
}
export const handleTimelineChange = ({
                                         timeLineChanged,
                                         setTimeLineChanged,
                                         setMetadata,
                                         metadataRef,
                                         selectors,
                                         url,
                                         mapData,
                                         mapRef
                                     }) => {
    setTimeLineChanged(false);
    setMetadata({...metadataRef.current, timeStart: {...metadataRef.current.timeStart, year: metadataRef.current.timeStart?.year } });

    const type = selectors.selectedLesson !== null ? 'lesson' : url.type;
    const newObj = {};

    // Формируем строку запроса
    const queryString = Object.keys(newObj)
        .map((key) => `${key}=${newObj[key]}`)
        .join("&");
    // Обновляем URL плитки с новыми параметрами
    const tileUrl = new URL(mapRef.current.getStyle().sources[BRAINOGRAPH_GIS_API].tiles[0]);
    mapRef.current.getSource(BRAINOGRAPH_GIS_API).setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
};

export async function getSubjectSettings(id = 1, language, isScreenshot, actions, setSubject, screenshotLanguageRef) {
    // Determine the language
    const lng = isScreenshot ? screenshotLanguageRef.current : language === 'en' ? 2 : 1;

    try {
        // Make an API request
        const res = await axios.get(`${process.env.REACT_APP_DICTIONARY_URL}/api/SubjectSetting/Subject/${id}/Language/${lng}`);
        const setting = res.data.data[0];
        // Create the toolbox object based on the setting data
        const toolbox = {};
        MAP_TOOLBOX_ITEMS.forEach(item => {
            if (setting?.toolboxSetting & (2 ** item.id)) {
                toolbox[item.key] = item;
            }
        });
        // Set subject with the updated setting and toolbox
        setSubject(() => ({
            setting,
            toolbox
        }));

        // Return the result for the actions call
        return actions.setSubjectsSettings({ ...setting, ...toolbox });
    } catch (error) {
        console.error("Error fetching subject settings:", error);
    }
}

export async function getChangesTimeRange (actions, baseMapId ) {
    try {
        const res = await axios.get(`${process.env.REACT_APP_GIS_URL}/Tiles/${baseMapId}`);
        const changes = res.data
        return actions.dispatchChangesTimeRange([...changes]);
    } catch (error) {
        console.error("Error fetching subject settings:", error);
    }
}

export const showArticleReadMoreSection = (actions,url) => {
    actions.getArticleFromAPI(url?.bookmarkID);
};

export function mapTransformRequest(url, resourceType, queryString, lng, currentBaseMapIdRef,time,type, typeId, changesTimeRange, isEmptyResult) {
    let sendUrl = url;
    if (sendUrl === `${process.env.REACT_APP_GIS_URL}/BaseSprite/sprite@2x.png`) {
        return {
            url: `${sendUrl}?icon=${new Date().getTime()}`,
            headers: { "access-control-allow-origin": "*" }
        };
    }
    if (url.startsWith(process.env.REACT_APP_GIS_URL) || url.startsWith('https://gis.brainograph.org')) {
        if(url.startsWith(`${process.env.REACT_APP_GIS_URL}/Tiles`) || url.startsWith('https://gis.brainograph.org/Tiles')) {
            const queryString = url.split('?')[1];
            const params = new URLSearchParams(queryString);
            if (!params.has('baseMapId')) params.append('baseMapId', currentBaseMapIdRef.current);
            if (!params.has('languageId')) params.append('languageId', lng);
            // if (!params.has('emptyResult') && isEmptyResult.current) params.append('emptyResult', isEmptyResult.current);
            if (!params.has('year')) {
                const year = new Date(time).getFullYear()
                const yearFromRange = getMappedYearOptimized(time,changesTimeRange,time < 0 ? true : false)
                params.append('year', yearFromRange)
            };
            if(!params.has('isBc')) params.append('isBc', time < 0);

            sendUrl = `${sendUrl.split('?')[0]}?${params.toString()}`;
            return {
                url: sendUrl,
                headers: {'Cache-Control': 'public'}
            }
        }
        return {
            url: sendUrl,
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')}
        }
    }

    return { url };
}

export function determineMapStyle (selectedMapStyleRef,selectors,screenShotFirstLoad,url) {
    // Return the available style (light or dark) if one is missing
    if (!selectedMapStyleRef.current?.light || !selectedMapStyleRef.current?.dark) {
        return selectedMapStyleRef.current?.light || selectedMapStyleRef.current?.dark;
    }

    // Check conditions for screenshot mode and return appropriate style
    const isScreenshotMode = typeof selectors.baseMapIsLight === 'boolean' && url.screenShot && screenShotFirstLoad && !!url.userId;
    if (isScreenshotMode) {
        return selectors.baseMapIsLight ? selectedMapStyleRef.current?.light : selectedMapStyleRef.current?.dark;
    }
    // Return the style based on the active setting
    return selectedMapStyleRef.current[selectedMapStyleRef.current.active];
}




export function filterFeaturesBySource(mapRef) {
    return mapRef.current.queryRenderedFeatures().filter(feature => (
        feature.source === BRAINOGRAPH_GIS_API
        || feature.source === "Brainograph"
        || feature.source === BRAINOGRAPH_PIN_GIS_API
    ));
}


export function extractFeatureLayerKeys(features, filteredLegends) {
    return new Set(features.reduce((accum, feature) => {
        if (filteredLegends.hasElement(feature.properties.layer)) {
            accum.push(feature.properties.layer);
        }
        return accum;
    }, []));
}

export function organizeFilterLayers(data, featureLayerKeys) {
    const layerKey = {};
    const filterLayers = data.reduce((accum, layer) => {
        layerKey[layer.key] = false;

        layer.subLegends.forEach(subLayer => {
            layerKey[subLayer.key] = false;
            if (featureLayerKeys.has(subLayer.key)) {
                accum.layers.push(subLayer);
            }
        });

        accum.parents[layer.id] = layer;
        return accum;
    }, { layers: [], parents: {} });

    return { filterLayers, layerKey };
}

export function finalizeLayers(filterLayers, layerKey) {
    const uniqueIds = [];
    return filterLayers.layers.reduce((accum, el) => {
        layerKey[el.key] = true;
        accum.push(el);

        if (!uniqueIds.includes(el.parentId)) {
            layerKey[filterLayers.parents[el.parentId].key] = true;
            accum.push(filterLayers.parents[el.parentId]);
        }

        uniqueIds.push(el.parentId);
        return accum;
    }, []);
}

export function updateAndDispatchLayers(layerKey, selectors, actions, result) {
    Object.keys(layerKey).forEach(key => {
        selectors.filteredLegends.updateId(key, layerKey[key]);
    });

    actions.dispatchFilteredLegends(sanitizeResult(result));
}

/**
 * Closes all active popups and animation markers on the map.
 */
export function closeMapPopupsAndMarkers(mapRef) {
    mapRef.current?.fire('closeAllPopups');
    mapRef.current?.fire('closeAnimationMarker');
}

/**
 * Formats the given time string to include "B.C." if needed.
 * Returns a formatted string based on the provided time.
 */
export function formatTimeString(time) {
    let formattedTime = time.toString().split('-');
    return formattedTime.length > 1 ? formattedTime.join('B.C. ') : formattedTime.join('');
}

/**
 * Updates the map tiles with the new query string for the given timeline.
 * Applies updated query parameters to refresh the map tiles.
 */
export function updateMapTiles(mapRef, newObj) {
    if (Object.keys(newObj).length === 0) return;

    const queryString = Object.keys(newObj)
        .map((key) => `${key}=${newObj[key]}`)
        .join("&");
    const tileUrl = new URL(mapRef.current.getStyle().sources[BRAINOGRAPH_GIS_API].tiles[0]);
    mapRef.current.getSource(BRAINOGRAPH_GIS_API).setTiles([`${tileUrl.origin}${decodeURI(tileUrl.pathname)}?${queryString}`]);
}
export  function fitMapToClusterBounds(resData, mapRef, actions, options = { padding: 0.1, buffer: 0.5 }) {
    const { padding, buffer } = options;

    setTimeout(() => {
        try {
            // Validate clusterBounds
            if (!resData?.clusterBounds?.type || !resData?.clusterBounds?.coordinates) {
                throw new Error("Invalid or missing clusterBounds data");
            }

            // Create geometry and apply buffer
            const point = turf?.[resData.clusterBounds.type.toLowerCase()]?.(resData.clusterBounds.coordinates);
            if (!point) throw new Error("Failed to create geometry from clusterBounds");

            const buffered = turf.buffer(point, buffer, { units: 'kilometers' });
            const bboxMap = turf.bbox(buffered);
            // Calculate padded bounds
            const width = bboxMap[2] - bboxMap[0];
            const height = bboxMap[3] - bboxMap[1];

            const calculatePadding = (value, paddingValue, limitMin, limitMax) =>
                value > limitMax || value < limitMin ? value : value + paddingValue;

            const paddedBounds = [
                calculatePadding(bboxMap[0], -width * padding, -180, 180),
                calculatePadding(bboxMap[1], -height * padding, -90, 90),
                calculatePadding(bboxMap[2], width * padding, -180, 180),
                calculatePadding(bboxMap[3], height * padding, -90, 90),
            ];
            // Update map and trigger action
            mapRef.current.fitBounds(bboxMap, { padding: padding });
            actions.setMapBounce(paddedBounds);
        } catch (error) {
            console.error("Error adjusting map bounds:", error.message);
        }
    }, 700);
}

export function handleMobileTouch(isMobile, touchStartTime, isDragging, startCoordinates) {
    if (!isMobile) return { touchStartTime, isDragging, startCoordinates };

    const touchEndTime = Date.now();
    const touchDuration = touchEndTime - touchStartTime;

    if (touchDuration < 800 && !isDragging) {
        // Handle single tap
        isDragging = false;
    } else if (isDragging) {
        // Handle drag
        return { shouldReturn: true, touchStartTime, isDragging, startCoordinates };
    }

    // Reset variables
    touchStartTime = 0;
    startCoordinates = null;
    isDragging = false;

    return { shouldReturn: false, touchStartTime, isDragging, startCoordinates };
}

// utils/mapListeners.js

// Helper for mousemove listener
export const setupMouseMoveListener = (mapRef, toolbox, setToolboxValues, toolboxKeys) => {
    let heightTimer;
    let coordsTimer;

    mapRef.current.on('mousemove', (e) => {
        if (toolbox[toolboxKeys.HEIGHT]) {
            clearTimeout(heightTimer);
            heightTimer = setTimeout(async () => {
                const coordinates = { lng: e.lngLat.wrap().lng, lat: e.lngLat.wrap().lat };
                const heightResult = Math.floor(
                    +(await mapRef.current.queryTerrainElevation(coordinates))
                );
                setToolboxValues((prev) => ({
                    ...prev,
                    [toolboxKeys.HEIGHT]: heightResult,
                }));
            }, 200);
        }

        if (toolbox[toolboxKeys.COORDS]) {
            clearTimeout(coordsTimer);
            coordsTimer = setTimeout(() => {
                setToolboxValues((prev) => ({
                    ...prev,
                    [toolboxKeys.COORDS]: {
                        lng: e.lngLat.wrap().lng,
                        lat: e.lngLat.wrap().lat,
                    },
                }));
            }, 200);
        }
    });
};

// Helper for zoomend listener
export const setupZoomEndListener = (mapRef, toolbox, setToolboxValues, toolboxKeys, getMapScale) => {
    const handleCalculateScale = () => {
        if (toolbox[toolboxKeys.SCALE]) {
            const scaleValue = getMapScale(mapRef.current);
            setToolboxValues((prev) => ({
                ...prev,
                [toolboxKeys.SCALE]: scaleValue,
            }));
        }
    };

    mapRef.current.on('zoomend', handleCalculateScale);
    handleCalculateScale(); // Initial scale calculation
};

// Calculate circular positions for child elements
export const calculateChildPositions = (child) => {
    const circleRadius = 85; // Adjust the radius as needed
    return child.map((item, index) => {
        const angle = (360 / child.length) * index;
        const radians = (angle * Math.PI) / 180;
        const x = circleRadius * Math.cos(radians);
        const y = circleRadius * Math.sin(radians);

        return {
            ...item,
            x: x * -1,
            y,
            radians,
        };
    });
};

export const handleFlyToObject = async (mapRef, selectedArticle) => {
    if (!selectedArticle?.id || !mapRef?.current) return;

    try {
        const url = `${process.env.REACT_APP_GIS_URL}/Articles/${selectedArticle.id}/Elements/Geometries/Bounds`;

        // Fetch bounding geometry data
        const response = await axios.get(url);
        const bufferDistance = 0.5; // Kilometers

        // Apply turf buffering and calculate bounding box
        const bufferedGeometry = turf.buffer(response.data, bufferDistance, { units: 'kilometers' });
        const bounds = turf.bbox(bufferedGeometry);

        // Synchronize the map and fly to the bounding box
        mapRef.current.syncMapAndCompassStart();
        mapRef.current.on('idle', mapRef.current.syncMapAndCompassEnd);
        mapRef.current.fitBounds(bounds, { padding: 2 });
    } catch (error) {
        console.error('Error flying to object:', error);
    }
};

export function getMappedYearOptimized(year, ranges) {
    let left = 0;
    let right = ranges.length - 1;

    while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const range = ranges[mid];

        if (year >= range.startYear && year < range.endYear) {
            return range.startYear < 0 ? range.startYear * -1 : range.startYear; // Year is within this range
        }

        if (year < range.startYear) {
            right = mid - 1; // Search in the left half
        } else {
            left = mid + 1; // Search in the right half
        }
    }
    return year < 0 ? year * -1 : year; // Year not found in any range
}

export function getBoundingBoxFromPoint(lng, lat, radiusInKm) {
    // Step 1: Create a point using Turf
    const point = turf.point([lng, lat]);

    // Step 2: Create a buffer (circular area) around the point
    const buffered = turf.buffer(point, radiusInKm, { units: 'kilometers' });

    // Step 3: Extract the bounding box from the buffered area
    const bbox = turf.bbox(buffered);

    return bbox; // Returns [minLng, minLat, maxLng, maxLat]
}

export function extractYear(value) {
    if (/^\d{4}$/.test(value)) {
        // If the value is a four-digit year, return it as a number
        return parseInt(value, 10);
    } else if (/^-?\d{6}-\d{2}-\d{2}T/.test(value)) {
        // Extract the first 6 characters (handles negative and positive years)
        let year = value.substring(0, 7);
        return Number(year); // Convert to number correctly
    } else if (/^\d{4}-\d{2}-\d{2}T/.test(value)) {
        // Extract the first 4 characters (for standard dates)
        let year = value.substring(0, 4);
        return Number(year);
    } else if(!isNaN(value?.getTime?.())){
        return value.getFullYear()
    }
    return null; // Return null if the format doesn't match
}
