import React from 'react'

const PlayIcon = (props) => {
  return (
    <svg
      width="65"
      height="68"
      viewBox="0 0 65 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <ellipse
        opacity="0.3"
        cx="32.7319"
        cy="34.2455"
        rx="30.8608"
        ry="32.2455"
        fill="white"
        stroke="#222"
        strokeWidth="2.73"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9357 34.8907L27.601 43.4911C27.0311 43.833 26.2997 43.6349 25.9674 43.0487C25.8608 42.8608 25.8047 42.6472 25.8047 42.4296V25.2288C25.8047 24.5502 26.3395 24 26.9992 24C27.2107 24 27.4184 24.0577 27.601 24.1673L41.9357 32.7677C42.5056 33.1096 42.6981 33.8621 42.3658 34.4483C42.2619 34.6315 42.1138 34.7839 41.9357 34.8907Z"
        fill="#2C476C"
      />
    </svg>
  )
}

export default PlayIcon
