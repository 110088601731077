import {useEffect, useRef, useState} from 'react';
import useMapSelectors from './useMapSelectors';

const usePrevMapSelectors = () => {
    const currentSelectors = useMapSelectors(); // Current state selectors
    const prevSelectorsRef = useRef({}); // Ref to hold previous states
    const selectorsRef = useRef(currentSelectors) // Ref to hold previous states
    useEffect(() => {
        // Update the ref with the current state after every render
        prevSelectorsRef.current = JSON.parse(JSON.stringify( selectorsRef.current));
        selectorsRef.current = currentSelectors;
    }, [currentSelectors]); // Runs whenever currentSelectors changes

    return {
        selectors: currentSelectors,
        previousSelectors: prevSelectorsRef.current,
    };
};

export default usePrevMapSelectors;
