import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
// COMMENTED IMPORT FOR RIGHT WORKING OF ARTICLE READ MORE SECTION.
// import 'react-quill/dist/quill.snow.css';
import './style.css';
import {isMobile} from "react-device-detect";

const TextBoxEditor = (props) => {
    const {
        checkCharacterCount,
        id,
        text,
        setText,
        isEditing,
        editorRef,
    } = props;

    useEffect(() => {
        if (!editorRef?.current) return;
        const quillInstance = editorRef.current.getEditor();
        const editorContainer = quillInstance.root;

        const stopEventPropagation = (event) => {
            event.stopPropagation();
        };

        const handleDoubleClick = (event) => {
            event.stopPropagation();
            quillInstance.focus();
            quillInstance.setSelection(0, quillInstance.getLength());
        };

        const handleArrowKeys = (event) => {
            event.stopPropagation();
            // if(event.key === ' ') console.log ('snvksd')
            // if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            //     event.stopPropagation();
            // }
        };

        const handleSelectStyle = (event) => {
            const quillInstance = editorRef.current.getEditor();
            const button = event.target;
            const currentFormat = quillInstance.getFormat();

            if (button.classList.contains('ql-bold')) {
                quillInstance.format('bold', !currentFormat.bold);
            } else if (button.classList.contains('ql-italic')) {
                quillInstance.format('italic', !currentFormat.italic);
            } else if (button.classList.contains('ql-underline')) {
                quillInstance.format('underline', !currentFormat.underline);
            } else if (button.classList.contains('ql-strike')) {
                quillInstance.format('strike', !currentFormat.strike);
            }
        };

        if (isEditing) {
            editorContainer.addEventListener('mousedown', stopEventPropagation);
            editorContainer.addEventListener('touchstart', stopEventPropagation);
            editorContainer.addEventListener('dblclick', handleDoubleClick);
            editorContainer.addEventListener('keydown', handleArrowKeys);
            if(isMobile) {
                const touchItems = document.querySelectorAll(".ql-formats button");
                isMobile && touchItems.forEach(item => {
                    isMobile && item.addEventListener("touchstart", handleSelectStyle, { passive: false });
                });
                return () => {
                    isMobile && touchItems.forEach(item => {
                        isMobile && item.removeEventListener("touchstart", handleSelectStyle);
                    });
                };
            }
        } else {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
        }

        return () => {
            editorContainer.removeEventListener('mousedown', stopEventPropagation);
            editorContainer.removeEventListener('touchstart', stopEventPropagation);
            editorContainer.removeEventListener('dblclick', handleDoubleClick);
        };
    }, [isEditing]);

    useEffect(() => {
        const quillInstance = editorRef.current.getEditor();

        const handlePaste = (event) => {
            event.preventDefault();

            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text/plain') || clipboardData.getData('text') || '';

            const cleanedText = pastedText.replace(/[\r\n]+/g, ' ').trim();

            const range = quillInstance.selection.savedRange;

            if (cleanedText) {
                if (range) {
                    quillInstance.deleteText(range.index, range.length); // Remove current selection
                    quillInstance.insertText(range.index, cleanedText); // Insert cleaned text
                } else {
                    // If no range is selected, insert text at the end
                    const endIndex = quillInstance.getLength(); // Get end of document
                    quillInstance.insertText(endIndex, cleanedText); // Append cleaned text at the end
                }
                setTimeout(() => quillInstance.setSelection(range.index + cleanedText.length, 0), 100)
            }
        };

        const editorContainer = quillInstance.root;
        editorContainer.addEventListener('paste', handlePaste);

        return () => {
            editorContainer.removeEventListener('paste', handlePaste);
        };
    }, []);

    useEffect(() => {
        const qlEditorTextArea = document.querySelector('.ql-editor');

        if (qlEditorTextArea) {
            qlEditorTextArea.addEventListener('keydown', e => {
                if (e.code === 'Space' || e.key === ' ') {
                    e.preventDefault();

                    const editor = document.activeElement;
                    if (editor) {
                        document.execCommand('insertText', false, ' ');
                    }
                }
            });
        }

        return () => {
            if (qlEditorTextArea) {
                qlEditorTextArea.removeEventListener('keydown', e => {}); // Clean up event listener
            }
        };
    }, []);

    return (
        <ReactQuill
            onKeyDown={checkCharacterCount}
            className={`quill-text-tool-${id} quill-text-tool no-select`}
            ref={editorRef}
            theme='snow'
            value={text}
            onChange={setText}
            preserveWhitespace={true}
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike']
                ],
                clipboard: {
                    matchVisual: false // Prevent Quill from auto-adding visual formatting
                }
            }}
        />
    );
}

export default TextBoxEditor;
