import {SET_CATEGORIES, SET_FILTERED_CATEGORIES, SET_FILTERED_CATEGORIES_ST} from "../constants"
import {
  addArticlesToCategories,
  filterCategories,
  getCategoriesAPI,
} from "../../service/categories"
import { getCategoriesAPIParams } from "../../shared/mockData"

export const setCategories = (categories) => ({
  type: SET_CATEGORIES,
  payload: categories,
})

export const setFilteredCategories = (filteredCategories) => ({
  type: SET_FILTERED_CATEGORIES,
  payload: filteredCategories,
})

export const setFilteredCategoriesST = (filteredCategories) => ({
  type: SET_FILTERED_CATEGORIES_ST,
  payload: filteredCategories,
})

export const getCategoriesFromAPI = (lng) => (dispatch) => {
  const { url, body } = getCategoriesAPIParams(lng)
  return getCategoriesAPI(url, body).then((categories) => {
      dispatch(setCategories(categories))
      return categories
  })
}

const _toggleCategory = (categories, categoryId,action) => {
  const categoryIndex = categories.findIndex((x) => x.id === categoryId)
    if(action ==='showInTimeLine') {
        categories[categoryIndex].showInTimeLine = !categories[categoryIndex].showInTimeLine
        categories.updateshowInTimeLineById(categories[categoryIndex].id,categories[categoryIndex].showInTimeLine)
        categories[categoryIndex].subCategories.forEach((item) => {
            // item.show = categories[categoryIndex].show
            //  new for legend
            item.showInTimeLine = categories[categoryIndex].showInTimeLine
            categories.updateshowInTimeLineById(item.id,item.showInTimeLine)
            item.articles.forEach(el=> {
                el.showInTimeLine = categories[categoryIndex].showInTimeLine
                categories.updateshowInTimeLineById('a_' + el.id,el.showInTimeLine)
            })
        })
    }
    else if(action ==='text') {
        categories[categoryIndex].textShow = !categories[categoryIndex].textShow
        categories[categoryIndex].subCategories.forEach((item) => {
            // item.show = categories[categoryIndex].show
            //  new for legend
            item.textShow = categories[categoryIndex].textShow
            item.articles.forEach(el=>el.textShow = !categories[categoryIndex].textShow)
        })
  }else{
        categories[categoryIndex].show = !categories[categoryIndex].show
        categories.updateShowById(categories[categoryIndex].id,categories[categoryIndex].show)
        categories[categoryIndex].subCategories.forEach((item) => {
            // item.show = categories[categoryIndex].show
            //  new for legend
            item.show = categories[categoryIndex].show
            categories.updateShowById(item.id,categories[categoryIndex].show)

            item.articles.forEach(el=> {
                el.show = categories[categoryIndex].show
                categories.updateShowById('a_'+ el.id,categories[categoryIndex].show)
            })
        })
  }
 return categories

}

const _toggleSubCategory = (categories, categoryId, subCategoryId,articleItemId,action) => {
  const categoryIndex = categories.findIndex((x) => x.id === categoryId)

  const subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
    (x) => x.id === subCategoryId
  )
 const articleIndex = categories[categoryIndex].subCategories[subCategoryIndex].articles.findIndex((el)=>  el.id === articleItemId)
    if(action ==='showInTimeLine') {
        categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].showInTimeLine = !categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].showInTimeLine
        categories.updateshowInTimeLineById('a_' + categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].id,categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].showInTimeLine)

        let falseCount = categories[categoryIndex].subCategories.filter((x, idx) => {
            const subCatIsShow = x.articles.filter(el => el.showInTimeLine === true && el.isUniq).length > 0
            categories[categoryIndex].subCategories[idx].showInTimeLine = subCatIsShow
            categories.updateshowInTimeLineById(categories[categoryIndex].subCategories[idx].id,categories[categoryIndex].subCategories[idx].showInTimeLine)
            return subCatIsShow
        })
        categories[categoryIndex].showInTimeLine = falseCount.length > 0
        categories.updateshowInTimeLineById(categories[categoryIndex].id, categories[categoryIndex].showInTimeLine)
    }
    else if(action ==='text') {
        categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].textShow = !categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].textShow
        let falseCount = categories[categoryIndex].subCategories.filter((x,idx) =>{
            const subCatIsShow = x.articles.filter(el=> el.textShow === false && el.isUniq).length > 0
            categories[categoryIndex].subCategories[idx].textShow = subCatIsShow
            return subCatIsShow
        })
        categories[categoryIndex].textShow = falseCount.length > 0
    }
    else{
        categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].show = !categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].show
        categories.updateShowById('a_' + categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].id,categories[categoryIndex].subCategories[subCategoryIndex].articles[articleIndex].show)

        // categories[categoryIndex].subCategories[subCategoryIndex].show =
        // !categories[categoryIndex].subCategories[subCategoryIndex].show

        // const falseCount = categories[categoryIndex].subCategories.filter(
        //   (x) => x.show === false
        // )
        let falseCount = categories[categoryIndex].subCategories.filter((x, idx) => {
            const subCatIsShow = x.articles.filter(el => el.show === true && el.isUniq).length > 0
            categories[categoryIndex].subCategories[idx].show = subCatIsShow
            categories.updateShowById(categories[categoryIndex].subCategories[idx].id,categories[categoryIndex].subCategories[idx].show)
            return subCatIsShow
        })
        categories[categoryIndex].show = falseCount.length > 0
        categories.updateShowById(categories[categoryIndex].id, categories[categoryIndex].show)

        // categories[categoryIndex].show =
        // falseCount.length !== categories[categoryIndex].subCategories.length
    }
    return categories

}
const _toggleSubCategoryLamp = (categories, categoryId, subCategoryId,articleItemId,action,show) => {
  const categoryIndex = categories.findIndex((x) => x.id === categoryId)
  const subCategoryIndex = categories[categoryIndex].subCategories.findIndex(
    (x) => x.id === subCategoryId
  )
    categories[categoryIndex].subCategories[subCategoryIndex].articles.forEach((el)=> {
        if(el.id === articleItemId) {
            el.show = show
        }
    })
    let falseCount = categories[categoryIndex].subCategories.filter((x, idx) => {
        categories[categoryIndex].subCategories[idx].show = show
        const subCatIsShow = x.articles.filter(el => el.show === false && el.isUniq).length > 0
        return subCatIsShow
    })
    // categories[categoryIndex].show = falseCount.length > 0
    return categories

}

//new for legend
export const updateCategoriesToggle =
  (categoryId, subCategoryId, type,articleItemId,action,show) => (dispatch, getState) => {
    // if (type === 'lamp') return
    const state = getState()

    let categories;
    const filteredCategories = state.filteredCategories.data
    if (type === "category") {
        categories = _toggleCategory(filteredCategories, categoryId,action)
    }

    if (type === "subCategory" || type === 'article') {
        categories = _toggleSubCategory(filteredCategories, categoryId, subCategoryId,articleItemId,action)
    }

    if (type === 'lamp') {
        categories = _toggleSubCategoryLamp(filteredCategories, categoryId, subCategoryId,articleItemId,action,show)
    }
   const newProxy = state.filteredCategories.data.updateItems(categories)
    dispatch(setFilteredCategories(newProxy))
  }

export const getFilteredCategoriesFromAPI =
  (data, categoryIds, type,categoriesReq) => async (dispatch, getState) => {
    let categories =  window.structuredClone(getState().categories.data)
   if(categories?.length === 0 ) categories =  window.structuredClone(categoriesReq)
    let articles = []

    if (type === "lesson") {
      articles = data.articles
    }
    if (type === "topic") {
      const lesson = data.lessons
      lesson.forEach((item) => {
        item.articles.forEach((article) => {
            articles.push(article)
        })
      })
    }

    if (type === "article") {
      articles = [data]
    }
    //mock data remove
  return await filterCategories(categoryIds, categories)
          .then((categories) => addArticlesToCategories(categories, articles))
          .then((filteredCategories) => {
            dispatch(setFilteredCategories(filteredCategories))
              return filteredCategories
          })
  }
