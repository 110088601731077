import * as React from 'react'

const PlayCircle2Icon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9577 31.1921C9.13365 31.1921 2.79102 24.8443 2.79102 17.0138C2.79102 9.1833 9.13365 2.83545 16.9577 2.83545C24.7817 2.83545 31.1244 9.1833 31.1244 17.0138C31.1244 24.8443 24.7817 31.1921 16.9577 31.1921Z"
      stroke="#2C476C"
      strokeWidth={3.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3828 14.9719C12.3828 12.0228 14.4653 10.8176 17.0153 12.2922L19.0695 13.4832L21.1236 14.6741C23.6736 16.1487 23.6736 18.559 21.1236 20.0336L19.0695 21.2245L17.0153 22.4155C14.4653 23.8901 12.3828 22.6849 12.3828 19.7358V17.3397V14.9719Z"
      fill="#2C476C"
    />
  </svg>
)
export default PlayCircle2Icon
