import React from 'react'

const PenIcon = () => {
  return (
    <svg
      className="pen-svg-icon"
      width="15"
      height="14"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Edit">
        <path
          id="Vector"
          d="M3.77663 19.0855C3.25373 19.6421 2.74769 20.7385 2.64649 21.4975L2.02238 26.9627C1.8031 28.9362 3.21999 30.2856 5.17665 29.9483L10.6081 29.0206C11.3671 28.8856 12.4298 28.329 12.9527 27.7555L26.8011 13.0974C29.1963 10.5673 30.2758 7.68288 26.5481 4.15752C22.8372 0.665904 20.0202 1.89725 17.625 4.42741L3.77663 19.0855Z"
          stroke="#2C476C"
          strokeWidth="3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15.3145 6.87354C16.0398 11.529 19.8181 15.0881 24.5074 15.5604"
          stroke="#2C476C"
          strokeWidth="3.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default PenIcon
