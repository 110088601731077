import React, { useEffect, useState } from 'react'
import PresentationListHeader from './PresentationListHeader'
import PresentationListItem from './PresentationListItem'
import PresentationSlidesList from './slides/PresentationSlidesList'
import {
  useGetExternalPresentationsQuery,
  useGetPresentationsQuery,
} from '../store/presentationApi'
import { Skeleton } from 'antd'
import {
  selectPlaybackPresentation,
  selectSelectedPresentation,
  setSelectedPresentation,
} from '../store/presentationSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIsFormPopupVisible } from '../../../store/selectors'
import { cn } from '../../../utils/utils'
import { useNavigate, useSearchParams } from 'react-router-dom'

const PresentationList = ({isLoading, isCreator}) => {
  const dispatch = useDispatch()
  const activeId = useSelector(selectSelectedPresentation)
  const microArticleIsFormPopupVisible = useSelector(getIsFormPopupVisible)
  const playbackPresentation = useSelector(selectPlaybackPresentation)
  const { t } = useTranslation()
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem('userInfo'))?.data,
  )
  const [search] = useSearchParams()
  const navigate = useNavigate()

  const { data: presentationsData, isFetching } = useGetPresentationsQuery({
    page: 1,
    perPage: 7,
    columnFilters: {
      createdBy: String(currentUser?.id),
    },
    sort: [
      {
        field: 'LastOpenedDate',
        type: 'Desc',
      },
    ],
  })

  const {
    data: externalPresentations,
    isFetching: externalPresentationsIsFetching,
  } = useGetExternalPresentationsQuery({
    page: 1,
    perPage: 1000,
  })

  const records = presentationsData?.data?.[0]?.items || []

  const externalRecords = externalPresentations?.data?.[0]?.items || []

  const clickHandler = (id) => {
    dispatch(setSelectedPresentation(id))
    if (id) {
      search.set('presentation', id)
      search.set('open','true')
    } else {
      search.delete('presentation')
      search.delete('microArticleMode')
      search.delete('articleMode')
      search.delete('screenShot')
      search.delete('screenSlide')
      search.delete('playBack')
      search.delete('open')
      search.delete('screenShotId')
      search.delete('userId')
    }

    navigate(
      {
        pathname: window.location.pathname,
        search: search.toString(),
      },
      { replace: true },
    )
  }

  useEffect(() => {
    if (search.get('presentation') && !playbackPresentation) {
      dispatch(setSelectedPresentation(search.get('presentation')))
    }

    return () => {
      dispatch(setSelectedPresentation(null))
    }
  }, [search, playbackPresentation])

  return (
    <div
      className={cn({
        'pointer-events-none opacity-90': microArticleIsFormPopupVisible,
      })}>
      <PresentationListHeader
        canGoBack={!!activeId}
        isLoading={isLoading}
        onClickBack={() => clickHandler(null)}
      />
      {externalPresentationsIsFetching && !externalRecords?.length && (
        <div className="p-2">
          <Skeleton active paragraph={{ rows: 1 }} />
        </div>
      )}
      {!activeId && (
        <div className="presentation-list">
          {externalRecords.map((item, index) => (
            <PresentationListItem
              key={item.id}
              isCreator={isCreator}
              description={item.description || item.shortDescription}
              presentation={item}
              isLoading={isLoading}
              id={item.id}
              index={index}
              hasSlides={item.slidesCount > 0}
              onClick={() => clickHandler(item.id)}
              name={item.title}
            />
          ))}
          <div className="px-2 pt-3 pb-1">
            <span className="uppercase text-[#2C476C80]">
              {t('presentation_mode.my_last_presentations')}
            </span>
          </div>
          {isFetching && !records?.length && (
            <div className="p-2">
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          )}
          {records.map((item, index) => (
            <PresentationListItem
              key={item.id}
              description={item.description || item.shortDescription}
              presentation={item}
              isCreator={isCreator}
              isLoading={isLoading}
              id={item.id}
              index={index}
              hasSlides={item.slidesCount > 0}
              onClick={() => clickHandler(item.id)}
              name={item.title}
            />
          ))}
        </div>
      )}

      {!!activeId && <PresentationSlidesList isCreator={isCreator} presentationId={activeId} isLoading={isLoading}/>}
    </div>
  )
}

export default PresentationList
