import React, {useEffect, useMemo} from 'react'
import {Dropdown} from 'antd'
import {v4 as uuidv4} from 'uuid'
import {ChevronDown} from 'lucide-react'
import {cn} from '../../../../utils/utils'

const Selector = ({ options = [], label, icon, value, onChange }) => {
  const uuid = useMemo(() => uuidv4(), [])
  const [isOpen, setIsOpen] = React.useState(false)
  const [valueState, setValueState] = React.useState(
    value ? String(value) : null,
  )

  useEffect(() => {
    setValueState(value)
  }, [value])

  const changeHandler = (e) => {
    onChange?.(e?.key)
    setIsOpen(false)
    setValueState(e?.key)
  }

  const showingLabel = useMemo(() => {
    return (
      options.find(
        (option) => valueState && String(option.key) === String(valueState),
      )?.label || label
    )
  }, [options, valueState, label])

  return (
    <div className="relative w-full app-dropdown--selector">
      <div id={uuid} className="absolute w-full h-full left-0" />
      <Dropdown
        onOpenChange={setIsOpen}
        trigger={['click']}
        overlayClassName="dropdown-selector"
        getPopupContainer={() => document.getElementById(uuid)}
        overlayStyle={{ width: '100%', minWidth: '100%' }}
        menu={{
          selectable: true,
          selectedKeys: [valueState],
          items: options,
          onClick: changeHandler,
        }}
        placement="bottom">
        <button
          type="button"
          className={cn(
            'w-full z-[8888] text-[15px] bg-[#f3f3f3] [&>svg>path]:stroke-[#2C476C] text-[#2C476C] px-4 py-3 flex items-center relative rounded-2xl',
            {
              'bg-[#036147] text-white  [&>svg>path]:stroke-white': isOpen,
            },
          )}>
          {icon}
          <span className="flex items-center ml-4 flex-1 justify-between">
            <span className="text-nowrap">{showingLabel}</span>
            <ChevronDown size={14} />
          </span>
        </button>
      </Dropdown>
    </div>
  )
}

export default Selector
