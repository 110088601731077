import * as React from 'react'

const AddImageIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 17C13.8431 17 12.5 15.6569 12.5 14C12.5 12.3431 13.8431 11 15.5 11C17.1569 11 18.5 12.3431 18.5 14C18.5 15.6569 17.1569 17 15.5 17Z"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.5 5H15.5C8 5 5 8 5 15.5V24.5C5 32 8 35 15.5 35H24.5C32 35 35 32 35 24.5V17"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.625 9.5H33.875"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <path
      d="M29.75 13.625V5.375"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
    />
    <path
      d="M6.00488 30.4248L13.3999 25.4598C14.5849 24.6648 16.2949 24.7548 17.3599 25.6698L17.8549 26.1048C19.0249 27.1098 20.9149 27.1098 22.0849 26.1048L28.3249 20.7498C29.4949 19.7448 31.3849 19.7448 32.5549 20.7498L34.9999 22.8498"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 2V38H2V2H38Z"
      stroke="#2C476C"
      strokeWidth={2.5}
    />
  </svg>
)
export default AddImageIcon
