import React, {memo} from 'react'
import {Skeleton} from 'antd'

const getRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min
}

const ListSkeleton = ({ rows = 7, hideMedia = false }) => {
  const randomWidth = getRandomNumber(60, 100)
  return Array(rows).map((item, index) => (
    <div key={index} className="flex gap-3 mb-2 items-center pl-5">
      {' '}
      {hideMedia && <Skeleton.Avatar size={18} active={true} />}
      <Skeleton.Input
        style={{ width: `${randomWidth}%` }}
        size={18}
        active={true}
        block
      />
    </div>
  ))
}

export default memo(ListSkeleton)
