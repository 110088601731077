import * as React from 'react'

const VolumeOffIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M24.5 14.5552V13.1152C24.5 8.64517 21.395 6.93517 17.615 9.30517L13.235 12.0502C12.755 12.3352 12.2 12.5002 11.645 12.5002H9.5C6.5 12.5002 5 14.0002 5 17.0002V23.0002C5 26.0002 6.5 27.5002 9.5 27.5002H12.5"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6152 30.6948C21.3952 33.0648 24.5002 31.3398 24.5002 26.8848V21.4248"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.215 16.1299C31.565 19.3549 31.16 23.1199 29 25.9999"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7241 13.7002C35.9291 18.9352 35.2691 25.0552 31.7441 29.7502"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 5L5 35"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 2V38H2V2H38Z"
      stroke="#2C476C"
      strokeWidth={2.5}
    />
  </svg>
)
export default VolumeOffIcon
