// InfiniteIconsGrid.js

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  memo,
  useMemo,
} from 'react'
import { Row, Col, Spin, Button, Empty } from 'antd'
import styles from '../PopupForm.module.css'
import ConnectedSpriteSvgComponent from '../../../../UI/ConnectedSpriteSvgComponent'
import {
  setMicroArticleIconsKeys,
  setMicroArticleIconsSpriteCategories,
} from '../../../../../store/actions/microArticle'
import {
  getMicroArticleIconsKeys,
  getMicroArticleIconsSpriteCategories,
} from '../../../../../store/selectors'
import { connect } from 'react-redux'
import {useTranslation} from "react-i18next"; // Ensure correct path

const ITEMS_INITIAL = 48
const ITEMS_INCREMENT = 24
const SCROLL_THRESHOLD = 100 // Pixels from the bottom to trigger loading more

const InfiniteIconsGrid = ({
  icons,
  onIconSelect,
  selectedColor,
  selectedIcon,
}) => {
  // Changed prop from getSpriteData to icons
  const [visibleCount, setVisibleCount] = useState(ITEMS_INITIAL)
  const [loading, setLoading] = useState(false)
  const scrollContainerRef = useRef(null) // Ref for the scroll container
  const [visibleIcons, setVisibleIcons] = useState([])
  const {t} = useTranslation();
  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current
    if (!scrollContainer) return

    const { scrollTop, scrollHeight, clientHeight } = scrollContainer

    if (icons) {
      if (
        scrollHeight - (scrollTop + clientHeight) < SCROLL_THRESHOLD &&
        !loading &&
        visibleCount < icons.length
      ) {
        setLoading(true)
        // Simulate async loading
        setTimeout(() => {
          setVisibleCount((prev) =>
            Math.min(prev + ITEMS_INCREMENT, icons.length),
          )
          setLoading(false)
        }, 500) // Adjust the timeout as needed
      }
    }
  }, [loading, visibleCount, icons])

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  // Reset visible count when the icons list changes (e.g., new search)
  useEffect(() => {
    setVisibleCount(ITEMS_INITIAL)
    if (icons && icons.length) {
      setVisibleIcons(icons.slice(0, visibleCount))
    } else {
      setVisibleIcons([]);
    }
  }, [icons])

  useEffect(() => {
    const iconWrappers = document.querySelectorAll('.buttonSpriteIconWrapper')

    iconWrappers.forEach((wrapper) => {
      const isSelected = wrapper.getAttribute('data-is-selected')

      wrapper.addEventListener('mouseover', () => {
        wrapper.style.background = selectedColor
      })

      wrapper.addEventListener('mouseout', () => {
        // Only clear the background if it's not the selected icon
        if (isSelected !== 'true') {
          wrapper.style.background = ''
        } else {
          wrapper.style.background = selectedColor // Retain selected color
        }
      })
    })

    return () => {
      iconWrappers.forEach((wrapper) => {
        wrapper.removeEventListener('mouseover', () => {})
        wrapper.removeEventListener('mouseout', () => {})
      })
    }
  }, [selectedColor, visibleIcons, selectedIcon])

  return (
    <div
      ref={scrollContainerRef}
      className={`${styles.middleSection} custom-scroll-v3`}
      style={{ overflowY: 'auto' }} // Ensure the div is scrollable
    >
      {visibleIcons.length > 0 ? (
        <div className={styles.iconsGrid}>
          {visibleIcons.map((icon, index) => {
            return (
              <div
                key={`${icon?.darkKey}-${index}`}
                className={styles.iconItem}>
                <div className={styles.iconButtonWrapper}>
                  <Button
                    data-is-selected={selectedIcon?.id === icon?.id}
                    type={'text'}
                    style={{
                      background:
                        selectedIcon?.id === icon?.id ? selectedColor : '',
                    }}
                    className={
                      styles.iconWrapper +
                      ' buttonSpriteIconWrapper ' +
                      styles.noClickEffect
                    }
                    onClick={() => {
                      onIconSelect(icon?.lightKey, icon)
                    }}
                    icon={
                      <span className={styles.spriteIconWrapperMicro} style={{transform: 'scale(0.7)'}}>
                        <ConnectedSpriteSvgComponent
                          scale={0.7}
                          name={icon?.darkKey}
                        />
                      </span>
                    }
                  />
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className={styles.emptyPlaceholder}>
          <Empty description={t('microArticle.mapView.createPopupTexts.iconPopup.empty')} />
        </div>
      )}
      {loading && (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Spin />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  getMicroArticleIconsSpriteCategories:
    getMicroArticleIconsSpriteCategories(state),
  getMicroArticleIconsKeys: getMicroArticleIconsKeys(state),
})

const mapDispatchToProps = {
  setMicroArticleIconsSpriteCategories,
  setMicroArticleIconsKeys,
}

/**
 * Connect the component to Redux and export.
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(InfiniteIconsGrid))
