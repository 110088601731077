// src/components/microArticle/components/DualDateInput/MicroArticleBoardCard.js
import React, { useEffect, useRef } from 'react';
import { Input, Tooltip, Checkbox, Typography } from 'antd';
import IconButton from './../../UI/IconButton';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import styles from "../PopupFormMobile/PopupForm.module.css";
import './style.css';

const DualDateInput = ({
                           date1,
                           setDate1,
                           era1,
                           setEra1,
                           error1,
                           setError1,
                           withoutStart,
                           setWithoutStart,
                           date2,
                           setDate2,
                           era2,
                           setEra2,
                           error2,
                           setError2,
                           withoutEnd,
                           setWithoutEnd,
                           alwaysShowOnMap,
                           setAlwaysShowOnMap,
                       }) => {
    const { t } = useTranslation();

    // Create a ref for the start date input
    const startInputRef = useRef(null);

    // When the start date input is disabled because of alwaysShowOnMap,
    // clicking on its overlay will remove alwaysShowOnMap and focus the input.
    const handleClickOnStartDateInput = (e) => {
        if (alwaysShowOnMap) {
            setAlwaysShowOnMap(false);
            // Let the re-render happen, then focus the input.
            setTimeout(() => {
                if (startInputRef.current) {
                    startInputRef.current.focus();
                }
            }, 0);
        }
    };

    const handleDateChange = (setterDate, setterError, value, era, isStart) => {
        // Allow digits and '/' separators
        const cleaned = value.replace(/[^\d/]/g, '');
        setterDate(cleaned);

        if (isValidDate(cleaned, era, !isStart)) {
            setterError('');
        } else if (cleaned.length > 0) {
            // Optionally, set an error message here.
        } else {
            setterError('');
        }
    };

    // Validate date string in Y+/MM/DD format (accepts partial dates)
    const isValidDate = (dateString, era, isEnd) => {
        const regex = /^(\d+)(\/(\d{1,2})(\/(\d{1,2}))?)?$/;
        const match = dateString.match(regex);
        if (!match) return false;

        let year = parseInt(match[1], 10);
        let month = match[3] ? parseInt(match[3], 10) : null;
        let day = match[5] ? parseInt(match[5], 10) : null;



        if (year === 0) return false; // Year 0 is invalid

        if (era === 'BC') {
            if (year < 1) return false;
        } else { // AD
            const currentYear = new Date().getFullYear();
            if (year < 1 || year > currentYear + 100) return false;
        }

        // Month validation
        if (month !== null && (month < 1 || month > 12)) return false;

        // Day validation
        if (day !== null) {
            if (day < 1 || day > 31) return false;

            if (month !== null) {
                const monthLengths = [
                    31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30,
                    31, 31, 30, 31, 30, 31
                ];
                if (day > monthLengths[month - 1]) return false;
            }
        }

        // Ensure end date is not beyond current date
        if (isEnd && era === 'AD') {
            const inputDate = new Date(year, (month || 1) - 1, day || 1);
            const currentDate = new Date();
            if (inputDate > currentDate) return false;
        }

        return true;
    };

    const isLeapYear = (year) => {
        if (year % 4 !== 0) return false;
        if (year % 100 !== 0) return true;
        return year % 400 === 0;
    };

    const parseDate = (date, era) => {
        const parts = date.split('/').map(Number);
        return {
            era,
            year: parts[0],
            month: parts[1],
            day: parts[2],
        };
    };

    // Compare two dates
    const compareDates = (dateA, eraA, dateB, eraB) => {
        if (!dateA) return null;
        const a = parseDate(dateA, eraA);
        if (a.year === 0) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            return 1;
        }

        if (!dateB) return null;
        const b = parseDate(dateB, eraB);
        if (b.year === 0) {
            setError2(t('microArticle.mapView.timeParameters.labels.error'));
            return 1;
        }

        if (a.era === 'BC' && b.era === 'AD') return -1;
        if (a.era === 'AD' && b.era === 'BC') return 1;

        if (a.era === 'BC' && b.era === 'BC') {
            if (a.year > b.year) return -1;
            if (a.year < b.year) return 1;
        } else if (a.era === 'AD' && b.era === 'AD') {
            if (a.year > b.year) return 1;
            if (a.year < b.year) return -1;
        }

        if (a.month > b.month) return 1;
        if (a.month < b.month) return -1;

        if (a.day > b.day) return 1;
        if (a.day < b.day) return -1;

        return 0;
    };

    const validateDateRange = () => {
        const a = parseDate(date1, era1);
        const b = parseDate(date2, era2);

        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth() + 1;
        const currentDay = now.getDate();

        const isDateAfterNow = (parsedDate) => {
            if (parsedDate.era === 'AD') {
                if (parsedDate.year > currentYear) return true;
                if (parsedDate.year === currentYear && parsedDate.month > currentMonth) return true;
                if (parsedDate.year === currentYear && parsedDate.month === currentMonth && parsedDate.day > currentDay) return true;
            }
            return false;
        };

        if (era1 === 'BC' && a.year > 4713) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            return;
        }

        if (isDateAfterNow(a)) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            return;
        }

        if (isDateAfterNow(b)) {
            setError2(t('microArticle.mapView.timeParameters.labels.error'));
            return;
        }

        if (!date1 || !date2) {
            setError1('');
            setError2('');
        }

        const comparison = compareDates(date1, era1, date2, era2);
        if (comparison > 0) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            setError2(t('microArticle.mapView.timeParameters.labels.error'));
        } else {
            setError1('');
            setError2('');
        }
    };

    useEffect(() => {
        validateDateRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date1, era1, date2, era2]);

    /**
     * renderInputWithTooltip renders an Input with an overlay if the input is disabled.
     * The overlay catches click events, so that even when the Input is disabled, our click
     * handler can be executed.
     */
    const renderInputWithTooltip = (value, onClick = () => {}, onChange, error, disabled, inputRef) => (
        <Tooltip
            title={error}
            placement="top"
            open={!!error}
            overlayStyle={{ maxWidth: '250px' }}
        >
            <div style={{ position: 'relative' }}>
                <Input
                    ref={inputRef}
                    className={isMobile ? styles.titleInput : styles.desktopInput}
                    type="text" // Ensures the input accepts '/'
                    value={value}
                    onChange={onChange}
                    placeholder="YYYY/MM/DD"
                    status={error ? 'error' : undefined}
                    disabled={disabled}
                />
                {disabled && onClick && (
                    <div
                        onClick={onClick}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 1,
                            background: 'transparent',
                        }}
                    />
                )}
            </div>
        </Tooltip>
    );

    const toggleEra = (currentEra, setEra) => {
        setEra(currentEra === 'AD' ? 'BC' : 'AD');
    };

    // Enforce "Always show on the map" behavior
    useEffect(() => {
        if (alwaysShowOnMap) {
            setWithoutStart(true);
            setWithoutEnd(true);
            setDate1('');
            setDate2('');
            setError1('');
            setError2('');
            setEra1(date1.length ? "AD" : "BC");
        } else {
            setWithoutStart(false);
            setWithoutEnd(false);
        }
    }, [alwaysShowOnMap]);

    return (
        isMobile ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* First Date Input Group - Start Date */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <IconButton
                            noHoverEffect={true}
                            style={{ textAlign: 'center' }}
                            className={styles.smallButton}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontFamily: "Noto Sans Armenian",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        textUnderlinePosition: "from-font",
                                        textDecorationSkipInk: "none",
                                        color: era1 === 'BC' ? 'white' : '#2C476C',
                                    }}
                                >
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era1, setEra1)}
                            toggled={era1 === 'BC'}
                            disabled={alwaysShowOnMap || withoutStart}
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date1,
                            handleClickOnStartDateInput, // Overlay click handler for the start date input
                            (e) => handleDateChange(setDate1, setError1, e.target.value, era1, true),
                            error1,
                            alwaysShowOnMap || withoutStart, // When disabled, the overlay is rendered
                            startInputRef // Pass the ref for focusing later
                        )}
                    </div>
                    {/* Optionally, a checkbox for "withoutStart" may be placed here */}
                </div>

                {/* Second Date Input Group - End Date */}
                <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
                        <IconButton
                            noHoverEffect={true}
                            style={{ textAlign: 'center' }}
                            className={styles.smallButton}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontFamily: "Noto Sans Armenian",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                        textUnderlinePosition: "from-font",
                                        textDecorationSkipInk: "none",
                                        color: era2 === 'BC' ? 'white' : '#2C476C',
                                    }}
                                >
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era2, setEra2)}
                            toggled={era2 === 'BC'}
                            disabled={alwaysShowOnMap || withoutEnd}
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date2,
                            () => {}, // No special click handler for the end date input
                            (e) => handleDateChange(setDate2, setError2, e.target.value, era2, false),
                            error2,
                            alwaysShowOnMap || withoutEnd
                        )}
                    </div>
                    <Checkbox
                        style={{ width: "fit-content" }}
                        checked={withoutEnd}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setWithoutEnd(checked);
                            if (checked) {
                                setDate2('');
                                setError2('');
                            }
                        }}
                        className={'form-checkbox'}
                        disabled={alwaysShowOnMap}
                    >
                        {t('microArticle.mapView.timeParameters.labels.untilToday')}
                    </Checkbox>
                </div>
            </div>
        ) : (
            <div style={{ display: 'flex', gap: '20px' }}>
                {/* First Date Input Group - Start Date */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <IconButton
                            noHoverEffect={true}
                            style={{ textAlign: 'center' }}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: era1 === 'BC' ? 'white' : '#2C476C',
                                    }}
                                >
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era1, setEra1)}
                            toggled={era1 === 'BC'}
                            disabled={alwaysShowOnMap || withoutStart}
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date1,
                            handleClickOnStartDateInput, // Overlay click handler for the start date input
                            (e) => handleDateChange(setDate1, setError1, e.target.value, era1, true),
                            error1,
                            alwaysShowOnMap || withoutStart,
                            startInputRef
                        )}
                    </div>
                    {/* Optionally, a checkbox for "withoutStart" can be added here */}
                </div>

                {/* Second Date Input Group - End Date */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <IconButton
                            noHoverEffect={true}
                            style={{ textAlign: 'center' }}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: era2 === 'BC' ? 'white' : '#2C476C',
                                    }}
                                >
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era2, setEra2)}
                            toggled={era2 === 'BC'}
                            disabled={alwaysShowOnMap || withoutEnd}
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date2,
                            () => {},
                            (e) => handleDateChange(setDate2, setError2, e.target.value, era2, false),
                            error2,
                            alwaysShowOnMap || withoutEnd
                        )}
                    </div>
                    <Checkbox
                        checked={withoutEnd}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setWithoutEnd(checked);
                            if (checked) {
                                setDate2('');
                                setError2('');
                            }
                        }}
                        className={'form-checkbox'}
                        disabled={alwaysShowOnMap}
                    >
                        {t('microArticle.mapView.timeParameters.labels.untilToday')}
                    </Checkbox>
                </div>
            </div>
        )
    );
};

DualDateInput.propTypes = {
    date1: PropTypes.string.isRequired,
    setDate1: PropTypes.func.isRequired,
    era1: PropTypes.string.isRequired,
    setEra1: PropTypes.func.isRequired,
    error1: PropTypes.string.isRequired,
    setError1: PropTypes.func.isRequired,
    withoutStart: PropTypes.bool.isRequired,
    setWithoutStart: PropTypes.func.isRequired,
    date2: PropTypes.string.isRequired,
    setDate2: PropTypes.func.isRequired,
    era2: PropTypes.string.isRequired,
    setEra2: PropTypes.func.isRequired,
    error2: PropTypes.string.isRequired,
    setError2: PropTypes.func.isRequired,
    withoutEnd: PropTypes.bool.isRequired,
    setWithoutEnd: PropTypes.func.isRequired,
    alwaysShowOnMap: PropTypes.bool.isRequired,
    setAlwaysShowOnMap: PropTypes.func.isRequired,
};

export default DualDateInput;
