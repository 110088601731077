// useWorldMapAnimation.js
import { useEffect, useRef } from 'react';

const useWorldMapAnimation = (svgRef) => {
    const pathLengths = useRef(new Map());

    useEffect(() => {
        const worldMap = svgRef.current;
        if (!worldMap) return;

        const paths = worldMap.querySelectorAll('path');
        if (!paths.length) return;

        // Store lengths and set initial styles
        paths.forEach((path) => {
            let length = pathLengths.current.get(path);
            if (!length) {
                length = path.getTotalLength();
                pathLengths.current.set(path, length);
            }

            path.style.setProperty('--dashoffset', length);
            Object.assign(path.style, {
                strokeDasharray: length,
                strokeDashoffset: length,
                opacity: '0.6' // Initial opacity matching animation start
            });
        });

        // Trigger animation after styles are set
        requestAnimationFrame(() => {
            paths.forEach(path => path.classList.add('animate-path'));
        });

        return () => {
            paths.forEach(path => {
                path.classList.remove('animate-path');
                path.style.strokeDasharray = '';
                path.style.strokeDashoffset = '';
                path.style.opacity = '';
            });
        };
    }, [svgRef]);
};

export default useWorldMapAnimation
