import {MAX_ZOOM_MAP} from "./utils";
import {BRAINOGRAPH_PIN_GIS_API} from "./featureHelpers";

export const  debounce_C = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};

export const debeounced700 = debounce_C((fn) => fn(), 700);
export const debeounced500 = debounce_C((fn) => fn(), 500);
export const debeounced100 = debounce_C((fn) => fn(), 100);

export const debeounced10 = debounce_C((fn) => fn(), 10);

// Обработчик touch событий для мобильных устройств
export const handleTouchStart = (e, touchStartTime, startCoordinates) => {
    if (e.originalEvent instanceof TouchEvent) {
        touchStartTime = Date.now();
        startCoordinates = [e.point.x, e.point.y];
        return { touchStartTime, startCoordinates, isDragging: false };
    }
};

// Обработчик движения
export const handleTouchMove = (e, startCoordinates) => {
    if (!startCoordinates) return false;
    const moveX = e.point.x - startCoordinates[0];
    const moveY = e.point.y - startCoordinates[1];
    return Math.abs(moveX) > 5 || Math.abs(moveY) > 5;
};

// Обработчик для кластеров
export const handleCluster = (mapRef, cluster, clusterId) => {
    const zoomCallback = (err, zoom) => {
        if (err) return;
        if (zoom <= MAX_ZOOM_MAP) {
            mapRef.current.easeTo({
                center: cluster.geometry.coordinates,
                zoom: zoom + 0.1,
            });
        }
    };

    mapRef.current.getSource(BRAINOGRAPH_PIN_GIS_API).getClusterExpansionZoom(clusterId, zoomCallback);
};

// Универсальный обработчик событий на карте
export const touchAndClickHandlerForMap = (mapRef, el, e, activeSources) => {
    const allFeatures = mapRef
        .queryRenderedFeatures(e.point)
        ?.filter((feature) => activeSources.includes(feature.source));

    if (allFeatures[0] && allFeatures[0].properties.cluster) {
        const cluster = mapRef.queryRenderedFeatures(e.point, { layers: ['clusters'] })[0];
        const clusterId = cluster?.properties.cluster_id;
        handleCluster(mapRef, cluster, clusterId);
    }
};

export const adjustMapPitchAndCompass = (mapRef, actions) => {
    const maxPitch = mapRef.current.getMaxPitch();
    if (mapRef.current.getZoom() >= 5) {
        if (!maxPitch) {
            mapRef.current.setMaxPitch(45);
            actions.setDisableCompass(false);
        }
    } else {
        if (mapRef.current.getPitch() !== 0) {
            if (maxPitch) {
                mapRef.current.setPitch(0);
                mapRef.current.compassTilt3D();
            }
        }
        if (maxPitch) {
            mapRef.current.setMaxPitch(0);
            actions.setDisableCompass(true);
        }
    }
};

export const handleSelectedElement = (mapRef, selectedElement, actions, generAnimationMarker,getAllClusters) => {
    if(!selectedElement) return
    const unclusterPoint = mapRef.current.querySourceFeatures(BRAINOGRAPH_PIN_GIS_API, {
        filter: ['==', ['get', 'id'], selectedElement.properties?.id]
    });
    if (unclusterPoint?.length > 0) {
        const markerInfo = {
            id: unclusterPoint[1].properties.id,
            cordinates: unclusterPoint[1].geometry.coordinates,
            pointCount: 0,
            articleIds: unclusterPoint[1].properties.articleIds,
            catColor: unclusterPoint[1].properties.catColor
        };
        actions.dispatchMapMarkerInfo(markerInfo);
        generAnimationMarker(markerInfo);
    } else {
        const features = getAllClusters();
        let dx = selectedElement?.geometry?.coordinates[0];
        let dy = selectedElement?.geometry?.coordinates[1];
        const markerInfo = features?.reduce((acum, el) => {
            if (!acum?.id) {
                acum.id = el.id;
                acum.cordinates = el.geometry.coordinates;
                acum.pointCount = el.properties.point_count || 0;
            } else {
                let acumX = dx - acum?.cordinates[0];
                let acumy = dy - acum?.cordinates[1];
                let elX = dx - el.geometry?.coordinates[0];
                let ely = dy - el.geometry?.coordinates[1];
                let acumRadius = Math.sqrt(acumX * acumX + acumy * acumy);
                let elRadius = Math.sqrt(elX * elX + ely * ely);
                if (acumRadius > elRadius) {
                    acum.id = el.id;
                    acum.cordinates = el.geometry.coordinates;
                    acum.pointCount = el.properties.point_count || 0;
                }
            }
            return acum;
        }, {});

        actions.dispatchMapMarkerInfo(markerInfo);
        generAnimationMarker(markerInfo);
    }
};





