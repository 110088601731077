import React from 'react'

const TrashIcon = (props) => {
  return (
    <svg
      className="trash-svg-icon"
      width="15"
      height="15"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.75 8.47152C25.0325 8.00402 20.2867 7.76318 15.555 7.76318C12.75 7.76318 9.945 7.90485 7.14 8.18818L4.25 8.47152"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.041 7.041L12.3527 5.18516C12.5793 3.83933 12.7493 2.8335 15.1435 2.8335H18.8552C21.2494 2.8335 21.4335 3.896 21.646 5.19933L21.9577 7.041"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.7033 12.9482L25.7824 27.2141C25.6266 29.4382 25.4991 31.1666 21.5466 31.1666H12.4516C8.49909 31.1666 8.37159 29.4382 8.21576 27.2141L7.29492 12.9482"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6348 23.375H19.3523"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.459 17.7085H20.5423"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrashIcon
