import React from "react";

const PresentationIcon = ({isActive, activeColor = '#FFFFFFFF', disableColor = '#2C476CFF'}) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.88 2C32.576 2 34 3.424 34 9.104V19.232C34 24.928 32.576 26.336 26.896 26.336H9.104C3.424 26.352 2 24.928 2 19.248V9.104C2 3.424 3.424 2 9.104 2H26.88Z"
                stroke={isActive ? "#FF6539" : "#2C476C"} fill="none" strokeWidth="2.5" strokeLinecap="round"
                strokeLinejoin="round"/>
            <path d="M18 26.3516V33.9996" stroke={isActive ? "#FF6539" : "#2C476C"} fill="none" strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M2 19.6001H34" stroke={isActive ? "#FF6539" : "#2C476C"} fill="none" strokeWidth="2.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.7988 34.0005H25.1988" stroke={isActive ? "#FF6539" : "#2C476C"} fill="none" strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};
export default PresentationIcon;
