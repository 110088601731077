import * as React from 'react'

const Link2Icon = (props) => (
  <svg
    width={18}
    height={24}
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.4517 12C12.4517 15.18 10.1491 17.75 7.29984 17.75C4.45062 17.75 2.14795 15.18 2.14795 12C2.14795 8.82 4.45062 6.25 7.29984 6.25"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.31641 12C9.31641 8.69 11.7266 6 14.6923 6C17.658 6 20.0682 8.69 20.0682 12C20.0682 15.31 17.658 18 14.6923 18"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default Link2Icon
