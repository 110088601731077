import React, {forwardRef, useRef} from 'react';
import BaseWebcam from "react-webcam";
import style from './style.module.css';
const facingMode = 'user'

const Webcam = forwardRef((props, webcamRef) => {
    return (
        <div className={style.webcamInputContainer}>
            <BaseWebcam
                audio={false}
                screenshotQuality={1}
                width={800}
                height={600}
                style={{  maxWidth: '100%' }}
                className={style.webcamInput}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    facingMode,
                }}
                mirrored={false}
            />
        </div>
    );
});

export default Webcam;
