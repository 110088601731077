import * as React from 'react'

const EyeIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M13.9959 18.1766C11.6865 18.1766 9.82031 16.3099 9.82031 13.9999C9.82031 11.6899 11.6865 9.82324 13.9959 9.82324C16.3053 9.82324 18.1715 11.6899 18.1715 13.9999C18.1715 16.3099 16.3053 18.1766 13.9959 18.1766Z"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.6205 17.0215C25.6702 15.3765 25.6702 12.6115 24.6205 10.9665C21.9495 6.76651 18.1122 4.33984 13.9949 4.33984C9.87765 4.33984 6.04031 6.76651 3.36933 10.9665C2.3196 12.6115 2.3196 15.3765 3.36933 17.0215C6.04031 21.2215 9.87765 23.6482 13.9949 23.6482C18.1122 23.6482 21.9495 21.2215 24.6205 17.0215Z"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default EyeIcon
