import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import './style.css'
import {
  getAboutData,
  getIsSeeAllMode,
  getIsSlidePlay,
  getMenuData,
  getIsPresentationMode,
  getPresentationModeData,
  getSlidesData,
  getPresentationData,
  getMapStateData,
  getSlidesSelectedSlideData,
  getPresentationFilteredTotal,
  getScreenShotLoadingST,
  getExternalPresentationData,
  getSubjectsSettingsData,
  getMapStyles,
  getSlidesFilteredLength,
  getSlidesPage,
  getIsPresentationShow,
} from '../../store/selectors'
import { setMenu } from '../../store/actions/menu'
import { connect, useSelector } from 'react-redux'
import Close from '../UI/Close'
import { setAbout } from '../../store/actions/about'
import About from '../../pages/about'
import PresentationIcon from '../UI/Presentation'
import {
  createPresentationAPI,
  editPresentationAPI,
  getPresentationAPI,
  postExternalPresentationAPI,
  setIsPresentationMode,
} from '../../store/actions/presentationMode'
import CreatePresentationIcon from '../UI/CreatePresentation'
import OpenPresentationIcon from '../UI/OpenPresentation'
import CreateNewPresentation from '../UI/Slides/CreateNewPresentation'
import ChooseSlides from '../UI/Slides/ChooseSlides'
import Popup from '../UI/Popup'
import {
  getMultipleSlidsByPresentationAPI,
  setPlaySlides,
  setSeeAllSlides,
  setSelectedSlide,
  setSlidesItems,
  setSlidesPage,
} from '../../store/actions/slides'
import PlayMode from '../UI/Slides/PlayMode'
import ShowSlidesMode from '../UI/Slides/ShowSlidesMode'
import SeeAllSlides from '../UI/Slides/SeeAllSlides'
import DraggableDiv from '../UI/DraggableModal'
import PresentationPlayIcon from '../UI/PresentationPlayIcon'
import PresentationOpenIcon from '../UI/PresentationOpenIcon'
import PresentationAddIcon from '../UI/PresentationAddIcon'
import { getAllPresentationsFromAPI } from '../../store/actions/presentation'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import useCountdownTimer from '../../hooks/useCountdownTimer'
import { useTranslation } from 'react-i18next'
import LocalLocation from '../../navigation/location'
import { getExternalDuration } from '../UI/Slides/utils'
import { updateURLParameter } from '../../utils/utils'
import PresentationWrapper from '../presentation/ui/PresentationWrapper'
import PresentationProvider from '../presentation/ui/PresentationProvider'
import PlaybackWrapper from '../presentation/ui/playback/PlaybackWrapper'
import { selectPlaybackPresentation } from '../presentation/store/presentationSlice'
import { useGetPresentationSlidesQuery } from '../presentation/store/presentationApi'
import { isMobile } from 'react-device-detect'
const debounce = (fn, wait) => {
  let timeoutID
  return (...arg) => {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...arg), wait)
  }
}
const debeounced300 = debounce((fn) => fn(), 300)
export const LeftNavigationMobile = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const query = new URLSearchParams(location.search)
  const {
    getPresentationFilteredTotal,
    getScreenShotLoadingST,
    createPresentationAPI,
    getPresentationAPI,
    slides: slidesOld,
    presentationsData,
    isPlaying,
    getPresentationModeData,
    getSlidesSelectedSlideData,
    setSelectedSlide,
    editPresentationAPI,
    getSubjectsSettingsData,
    postExternalPresentationAPI,
    getExternalPresentationData,
    runScreenShotStates,
    getSlidesFilteredLength,
    getSlidesByPresentationID,
    setSlidesPage,
    slidePage,
    setSlidesItems,
  } = props
  const { url, prevUrl } = LocalLocation()
  const changes = getChangedParams(prevUrl, url)
  const [createPresentationModal, setCreatePresentationModal] = useState(false)
  const [showSlides, setShowSlides] = useState(false)
  const [deleteSlide, setDeleteSlide] = useState(false)
  const [showPresentation, setShowPresentation] = useState(false)
  const [slideInfoPopup, setSlideInfoPopup] = useState(false)
  const [popupContentItem, setPopupContentItem] = useState({})
  const [page, setPage] = useState(1)
  const [searchParamInfo, setSearchParamsInfo] = useState({})
  const [slideIsPlaying, setSlideIsPlaying] = useState(false)
  const [playBackIsEnd, setPlayBackIsEnd] = useState(false)
  const [activePlayMode, setActivePlayMode] = useState(false)
  const [nextToSlide, setNextToSlide] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const playbackPresentation = useSelector(selectPlaybackPresentation)
  const [search] = useSearchParams()
  const [currentUser] = useState(
    JSON.parse(localStorage.getItem('userInfo'))?.data,
  )
  const isPreview = search.get('preview')

  const isCreator =
    currentUser && +currentUser?.id === +getPresentationModeData?.createdBy && !isPreview

  const slideID = search.get('screenSlide')

  const { data: slidesData } = useGetPresentationSlidesQuery(
    {
      page: 1,
      perPage: 1000,
      columnFiltersMany: {
        presentationId: [String(query.get('presentation'))],
      },
      columnFilters: {
        isHidden: 'false',
      },
      sort: [
        {
          field: 'order',
          type: 'asc',
        },
      ],
    },
    { skip: !query.get('presentation') },
  )
  const slides = useMemo(() => slidesData?.data?.[0]?.items.filter((item) => (!item.isDeleted)) || [], [slidesData])

  const playPresentationRef = useRef()

  const durationFromStorage =
    localStorage.getItem('slideTransitionDuration') && !isCreator
      ? +localStorage.getItem('slideTransitionDuration') * 1000
      : getPresentationModeData?.slideTransitionDuration * 1000

  const {
    stopCountdown,
    pauseCountdown,
    playCountdown,
    changeInitialTime,
    restartCountdown,
    changeNextTime,
  } = useCountdownTimer(
    getExternalDuration(true) || durationFromStorage || 15000,
    setNextToSlide,
    false,
  )

  const requestExternalPresentationData = () => {
    const type = query.get('type')
    if (!type) {
      return
    }
    const typeID = query.get(`${type}ID`)
    const relatedKey = `related${type[0].toUpperCase() + type.slice(1)}s`

    const body = {
      page: 1,
      perPage: 1000,
    }
    body[relatedKey] = [+typeID]
    postExternalPresentationAPI(body)
  }

  const restartCountdownHandler = () => {
    restartCountdown()
    if (slides?.[0]?.id) {
      // const url = new URL(window.location.href);
      // const newURL = updateURLParameter(url, 'screenSlide', slides?.[0]?.id);
      // window.history.pushState({ path: newURL }, '', newURL);

      search.set('screenSlide', slides?.[0]?.id)
      search.set('playBack', 'true')
      if (slides?.[0]?.mapStateId) {
        search.set('screenShotId', slides?.[0]?.mapStateId)
      } else {
        search.delete('screenShotId')
      }
      if (slides?.[0]?.microArticleId) {
        search.set('microArticleMode', slides?.[0]?.microArticleId)
      } else {
        search.delete('microArticleMode')
      }
      // navigate({search: search.toString()}, {replace: true})
      // window.location.href = `${url}?${search.toString()}`

      window.location.href =
        window.location.origin +
        window.location.pathname +
        '?' +
        search.toString()
    }
  }

  function handlePresentationMode() {
    if (props.isPresentationMode) {
      handleReplaceUrlPresentation()
      setSlidesPage(1)
      setSlideInfoPopup(false)
      setSlideIsPlaying(false)
    }
    props.setIsPresentationMode(!props.isPresentationMode)
    props.setPlaySlides(false)
    setCreatePresentationModal(false)
    setShowSlides(false)
    setSelectedSlide(null)
    stopCountdown()
  }

  const openPresentations = () => {
    handlePresentationMode()
    setShowPresentation(true)
  }
  const closePresentations = () => {
    handlePresentationMode()
    setShowPresentation(false)
  }

  const createPresentation = () => {
    createPresentationAPI().then((resp) => {
      handleGetData()
      setCreatePresentationModal(true)
      setShowSlides(false)
    })
  }
  const handleGetPresentation = (id, fromDelete) => {
    const order = slides.findIndex((el) => el.id === +query.get('screenSlide'))
    setCreatePresentationModal(false)
    fromDelete && setSelectedSlide(null)
    fromDelete && setDeleteSlide(true)
    return getPresentationAPI(id).then(() => {
      setCreatePresentationModal(true)
      setShowSlides(false)
      setDeleteSlide(false)
      return
    })
  }
  const openPresentation = () => {
    setSlidesItems([])
  }

  const handleShowSlides = () => {
    if (!showSlides) setCreatePresentationModal(false)
    setShowSlides(!showSlides)
  }

  const closeSeeAll = () => {
    props.setSeeAllSlides(false)
  }

  // const handleReplaceUrl = useCallback((isPresentationMode) =>{
  //   // Use the browser's history API to update the URL when isPresentationMode changes
  //   const presentationParam = isPresentationMode ? '?presentation=true' : '';
  //   const newUrl = `/map?type=topic&topicID=61${presentationParam}`;
  //   window.history.replaceState(null, null, newUrl);
  // },[])
  // useEffect(()=>{
  //     handleReplaceUrl(props.isPresentationMode)
  // },[props.isPresentationMode])

  const handleSlideInfoPopup = () => {
    setSlideInfoPopup(true)
  }
  const handleCloseSlideInfoPopup = () => {
    setSlideInfoPopup(false)
  }
  const handleChangePage = (e, value) => {
    const pageLoc = +value
    if (!isNaN(pageLoc) && typeof pageLoc === 'number' && pageLoc !== page) {
      setPage(() => pageLoc)
    }
  }
  const handleNextPage = () => {
    const nextPage = page + 1
    handleChangePage(null, nextPage)
  }
  const handleSetSearchParams = (e) => {
    debeounced300(() => {
      handleChangePage(null, 1)
      const newSearch = { ...searchParamInfo }
      newSearch.searchTerm = e?.target?.value || ''
      setSearchParamsInfo((prev) => newSearch)
    })
  }

  const handleGetData = () => {
    let pageLoc = page

    if (pageLoc > Math.ceil(presentationsData.total / 10))
      pageLoc = Math.ceil(presentationsData.total / 10)
    if (pageLoc < 1) pageLoc = 1
    if (!isNaN(pageLoc) && typeof pageLoc === 'number') {
      if (pageLoc !== page) setPage(() => pageLoc)
      props.getAllPresentationsFromAPI({
        page: pageLoc,
        perPage: 9,
        ...searchParamInfo,
      })
    }
  }

  function handleNextSlide() {
    setNextToSlide(false)
    if (!slides.length) return
    const slideIndex = slides.findIndex(
      (el) => el.id === +query.get('screenSlide'),
    )
    if (slideIndex === -1) return setSelectedSlide(slides[0])
    // changeInitialTime(getPresentationModeData?.slideTransitionDuration * 1000)
    const nextItemIndex = slideIndex + 1
    if (
      !getPresentationModeData?.showDescriptionPopup ||
      !slides[nextItemIndex]?.shortDescription
    )
      handleCloseSlideInfoPopup()
    console.log(slides,'slides')
    if (!slides[nextItemIndex]) {
      const url = new URL(window.location.href)
      // const newURL = updateURLParameter(url, 'playBack', 'false');

      search.set('playBack', 'false')

      navigate(
        {
          pathname: window.location.pathname,
          search: search.toString(),
        },
        { replace: false },
      )

      stopCountdown()
      setSlideIsPlaying(false)
      setTimeout(() => {
        setPlayBackIsEnd(true)
      }, 400)
      handleCloseSlideInfoPopup()
      return
    }
    if (!slides[nextItemIndex + 1] && slides.length !== nextItemIndex + 1) {
      if (!showSlides) {
        let pageLoc = slidePage + 1
        const search = {
          page: pageLoc,
          perPage: 10,
        }
        const maxPage = Math.ceil(slides.length / 10)
        if (pageLoc > maxPage) pageLoc = maxPage
        if (pageLoc < 1) pageLoc = 1
        if (!isNaN(pageLoc) && typeof pageLoc === 'number') {
          getSlidesByPresentationID(
            [`${props.getPresentationModeData?.id}`],
            search,
            'add',
          ).then((resp) => {
            if (pageLoc !== page) setSlidesPage(pageLoc)
          })
        }
      } else {
        setSlidesPage(slidePage + 1)
      }
    }
    setSelectedSlide(slides[nextItemIndex])
    console.log(2)
    stopCountdown()
  }
  const handleEditDurationTime = (duration) => {
    changeNextTime(duration * 1000)
    const body = {
      slideTransitionDuration: duration,
    }
    editPresentationAPI(getPresentationModeData?.id, body).then((resp) => {
      getPresentationAPI(getPresentationModeData?.id)
    })
  }

  const handleSetInfoPopupContent = (slide) => {
    const searchParams = new URLSearchParams(location.search)
    if (!+query.get('presentation') || isNaN(+query.get('presentation'))) return
    if (
      +searchParams.get('screenSlide') &&
      !isNaN(searchParams.get('screenSlide'))
    ) {
      if (!slide.length)
        slide = slides.find((el) => el.id === +query.get('screenSlide'))
      const durFromStorage =
        typeof getExternalDuration(true) === 'number'
          ? getExternalDuration(true)
          : getPresentationModeData?.slideTransitionDuration * 1000
      changeInitialTime(durFromStorage)
      if (searchParams.get('playBack') === 'true' && !props.globalLoading) playCountdown()
      return setPopupContentItem(slide)
    }
    const durFromStorage =
      typeof getExternalDuration(true) === 'number'
        ? getExternalDuration(true)
        : getPresentationModeData?.slideTransitionDuration * 1000
    changeInitialTime(durFromStorage)
    if (searchParams.get('playBack') === 'true') playCountdown()
    setPopupContentItem(getPresentationModeData)
  }
  const handleSlideNavigation = (type) => {
    if (typeof type !== 'number' && isNaN(type) && (type > 1 || type < -1))
      return

    handleCloseSlideInfoPopup()
    const durFromStorage =
      typeof getExternalDuration(true) === 'number'
        ? getExternalDuration(true)
        : getPresentationModeData?.slideTransitionDuration * 1000
    changeInitialTime(durFromStorage)
    const searchParams = new URLSearchParams(location.search)

    if (!searchParams.get('screenSlide') && !!slides.length && type > 0) {
      setSelectedSlide(slides[0])
    }
    let slideIndex = slides.findIndex(
      (el) => el.id === +searchParams.get('screenSlide'),
    )

    if (slideIndex === -1) return

    if (type > 0 && slideIndex + 1 < slides.length) {
      setSelectedSlide(slides[slideIndex + 1])
    }
    if (type < 0 && slideIndex > 0) {
      setSelectedSlide(slides[slideIndex - 1])
    }
  }

  const handleReplaceUrlPresentation = useCallback((id, isExternal) => {
    // if(+query.get("screenSlide")) return
    // Use the browser's history API to update the URL when isPresentationMode changes
    // const presentationParam = id ? `&presentation=${id}` : '';
    const newUrl = new URLSearchParams(window.location.search)
    if (id) {
      newUrl.set('presentation', id)
      if (isExternal) {
        newUrl.set('external', true)
      }
    } else if (newUrl.has('presentation')) {
      newUrl.delete('presentation')
      newUrl.delete('playMode')
      newUrl.delete('playBack')
      if (newUrl.has('screenSlide')) {
        newUrl.delete('screenSlide')
        newUrl.delete('screenShot')
        newUrl.delete('userId')
      }
      if (newUrl.has('external')) {
        newUrl.delete('external')
      }
    }

    // const newUrl = `/map?type=topic&topicID=61${presentationParam}`;
    navigate(
      {
        pathname: window.location.pathname,
        search: newUrl.toString(),
      },
      { replace: true },
    )
  }, [])

  const handleReplaceUrlPlayMod = useCallback(() => {
    const newUrl = new URLSearchParams(window.location.search)
    if (!isPlaying) {
      newUrl.set('playMode', 'true')
    } else {
      newUrl.delete('playMode')
      newUrl.delete('playBack')
    }
    // const newUrl = `/map?type=topic&topicID=61${presentationParam}`;
    navigate(
      {
        pathname: window.location.pathname,
        search: newUrl.toString(),
      },
      { replace: true },
    )
  }, [])

  const handleExternalPresentationPlayButton = (id) => {
    handleGetPresentation(id).then((resp) => {
      setCreatePresentationModal(true)
      handleReplaceUrlPresentation(id, true)
      setTimeout(() => {
        handleReplaceUrlPlayMod()
      }, 300)
    })
  }

  useEffect(() => {
    handleGetData()
  }, [searchParamInfo, page])

  function getChangedParams(prevUrl, newUrl) {
    // Function to parse URL parameters
    function parseParams(url) {
      const params = {}
      const searchParams = new URLSearchParams(url)
      for (const [key, value] of searchParams.entries()) {
        params[key] = value
      }
      return params
    }

    // Parse parameters of both URLs
    const prevParams = parseParams(prevUrl)
    const newParams = parseParams(newUrl)

    // Find changed parameters
    const changedParams = {}
    for (const key in prevParams) {
      if (prevParams.hasOwnProperty(key)) {
        if (prevParams[key] !== newParams[key]) {
          changedParams[key] = {
            oldValue: prevParams[key],
            newValue: newParams[key],
          }
        }
      }
    }

    return changedParams
  }

  useEffect(() => {
    if (nextToSlide) {
      handleCloseSlideInfoPopup()
      handleNextSlide()
    }
  }, [nextToSlide])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (!popupContentItem || 'playBack' in changes || slides.length === 0)
      return
    setTimeout(() => {
      handleSetInfoPopupContent(getSlidesSelectedSlideData)
    }, 500)
  }, [location, slides])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.get('playMode') === 'true' && !activePlayMode) {
      setActivePlayMode(true)
    } else if (
      (playPresentationRef.current &&
        !isPlaying &&
        Object.keys(getSubjectsSettingsData).length > 0 &&
        (!getScreenShotLoadingST || !+searchParams.get('screenSlide'))) ||
      !searchParams.get('playMode')
    ) {
      setActivePlayMode(false)
    }
  }, [location, props.globalLoading])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (
      searchParams.get('playMode') === 'true' &&
      !isPlaying &&
      Object.keys(getSubjectsSettingsData).length > 0 &&
      (!getScreenShotLoadingST || !+searchParams.get('screenSlide'))
    ) {
      if (playPresentationRef.current) {
        playPresentationRef.current.click()
      }
      const durFromStorage =
        typeof getExternalDuration(true) === 'number'
          ? getExternalDuration(true)
          : getPresentationModeData?.slideTransitionDuration * 1000

      changeInitialTime(durFromStorage)
      if (searchParams.get('playBack') === 'true') playCountdown()
    }
  }, [playPresentationRef.current, location])

  useEffect(() => {
    if (
      !isPlaying ||
      !slideIsPlaying ||
      'playBack' in changes ||
      slides?.length === 0
    )
      return
    const searchParams = new URLSearchParams(location.search)
    handleSetInfoPopupContent(getSlidesSelectedSlideData)
    if (!searchParams.get('screenSlide')) {
      handleSlideInfoPopup()
    } else if (getPresentationModeData?.showDescriptionPopup) {
      if (+searchParams.get('screenSlide')) {
        let slide = getSlidesSelectedSlideData
        if (getSlidesSelectedSlideData.length === 0) {
          let slideIndex = slides.findIndex(
            (el) => el.id === +searchParams.get('screenSlide'),
          )
          if (slideIndex === -1) return
          slide = slides[slideIndex]
          setSelectedSlide(slides[slideIndex])
        }
        if (slide?.shortDescription) {
          handleSlideInfoPopup()
        } else if (slideInfoPopup) {
          handleCloseSlideInfoPopup()
        }
      }
    }
  }, [isPlaying, slides, slideIsPlaying])

  useEffect(() => {
    if (slideIsPlaying && playBackIsEnd) {
      if (slides.length === 1) window.location.reload()
      const searchParams = new URLSearchParams(location.search)
      setPlayBackIsEnd(false)
      setSelectedSlide(slides[0])
      stopCountdown()
      handleCloseSlideInfoPopup()
      // if(slides?.[0]?.shortDescription && typeof +searchParams.get('screenSlide') === 'number' && !isNaN(searchParams.get('screenSlide')) && getPresentationModeData?.showDescriptionPopup ){
      //   handleSlideInfoPopup()
      // }else if(slideInfoPopup){
      //   handleCloseSlideInfoPopup()
      // }
    }
  }, [slideIsPlaying, playBackIsEnd])

  useEffect(() => {
    requestExternalPresentationData()

    if (+query.get('presentation') && !isNaN(+query.get('presentation'))) {
      props.setIsPresentationMode(true)
      // pauseCountdown()
      handleGetPresentation(+query.get('presentation'))
    }
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    // console.log('stop ---- 2 -----', searchParams.get('playBack'), getScreenShotLoadingST, props.globalLoading)

    // if(searchParams.get('playBack') === 'true' && !props.globalLoading && !isPlaying) {
    if (searchParams.get('playBack') === 'true' && !props.globalLoading) {
      console.log('stop -----3 ----')
      setTimeout(() => playCountdown(), 300)
      let slide = getSlidesSelectedSlideData
      if (
        slide?.shortDescription &&
        getPresentationModeData?.showDescriptionPopup
      ) {
        handleSlideInfoPopup()
      } else if (slideInfoPopup) {
        handleCloseSlideInfoPopup()
      }
    }
  }, [slideID, props.globalLoading])

  useEffect(() => {
    if (slideID) {
      console.log('stop ---------')
      setTimeout(() => stopCountdown(), 200)
    }
  }, [slideID])

  return (
    <>
      <button
        className={`header__burger header_burger_mobile ${
          props.menu ? 'active' : ''
        }`}
        type="button"
        onClick={() => props.setMenu(!props.menu)}
        data-is-loading={props.globalLoading}>
        {t('shortName')}
      </button>
      {props.isPresentationShow && (
        <>
          {/*<button*/}
          {/*    className={`presentation__button header_burger_mobile ${props.isPresentationMode ? 'active' : ''}`}*/}
          {/*    type="button"*/}
          {/*    onClick={openPresentations}*/}
          {/*    data-is-loading={props.globalLoading}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    <PresentationIcon isActive={props.isPresentationMode}/>*/}
          {/*  </div>*/}
          {/*</button>*/}
          <PresentationProvider>
            {window.innerWidth > 1024 && (
              <PresentationWrapper isGlobalLoading={props.globalLoading} />
            )}
            {(playbackPresentation || search.get('presentation')) && (
              <PlaybackWrapper
                firstLoad={firstLoad}
                firstRunScreenShotStates={runScreenShotStates}
                handleEditDurationTime={handleEditDurationTime}
                slideNavigation={handleSlideNavigation}
                handleSlideInfoPopup={handleSlideInfoPopup}
                pauseCountdown={pauseCountdown}
                stopCountdown={stopCountdown}
                playCountdown={playCountdown}
                handleShowSlides={handleShowSlides}
                restartCountdown={restartCountdownHandler}
                showSlide={showSlides}
                map={props.map}
                currentSlide={getSlidesSelectedSlideData}
                slideTransitionDuration={
                  getPresentationModeData?.slideTransitionDuration
                }
                setIsPlaying={setSlideIsPlaying}
                isPlaying={slideIsPlaying}
                globalLoading={props.globalLoading}
              />
            )}
          </PresentationProvider>
          {/*<div className={'presentation__actions'}>*/}
          {/*  { !!getExternalPresentationData?.items?.length && !createPresentationModal &&*/}
          {/*      (<button*/}
          {/*          className={'presentation__play__button'}*/}
          {/*          onClick={() =>  handleExternalPresentationPlayButton(getExternalPresentationData.items[0]?.id)}*/}
          {/*          data-is-loading={props.globalLoading}*/}
          {/*      >*/}
          {/*        <div>*/}
          {/*          <PresentationPlayIcon/>*/}
          {/*        </div>*/}
          {/*      </button>)*/}
          {/*  }*/}
          {/*</div>*/}
          {createPresentationModal && (
            <CreateNewPresentation
              hidd={props.isPlaying}
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
              openSlides={handleGetPresentation}
              close={handlePresentationMode}
              map={props.map}
              playPresentationRef={playPresentationRef}
              handleReplaceUrlPlayMod={handleReplaceUrlPlayMod}
              handleReplaceUrlPresentation={handleReplaceUrlPresentation}
              globalLoading={props.globalLoading}
              activePlayMode={activePlayMode}
              firstRunScreenShotStates={runScreenShotStates}
              handleCloseSlideInfoPopup={handleCloseSlideInfoPopup}
            />
          )}
          {(props.isPlaying || activePlayMode) && (
            <PlayMode
              handleEditDurationTime={handleEditDurationTime}
              slideNavigation={handleSlideNavigation}
              handleSlideInfoPopup={handleSlideInfoPopup}
              pauseCountdown={pauseCountdown}
              playCountdown={playCountdown}
              handleShowSlides={handleShowSlides}
              showSlide={showSlides}
              currentSlide={getSlidesSelectedSlideData}
              slideTransitionDuration={
                getPresentationModeData?.slideTransitionDuration
              }
              setIsPlaying={setSlideIsPlaying}
              isPlaying={slideIsPlaying}
              globalLoading={props.globalLoading}
            />
          )}
          {activePlayMode && (
            <ShowSlidesMode
              hidd={!showSlides}
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
              handleGetPresentation={handleGetPresentation}
              handleShowInfo={handleSlideInfoPopup}
              map={props.map}
              globalLoading={props.globalLoading}
              handleCloseSlideInfoPopup={handleCloseSlideInfoPopup}
              firstRunScreenShotStates={runScreenShotStates}
            />
          )}
        </>
      )}

      <div className={`header__nav ${props.menu ? 'active' : ''}`}>
        <h1 className="header__title title">{t('name')}</h1>
        <div className="header__content">
          <nav>
            <ul className="header__nav-items">
              <li className="header__nav-item">
                <a className="header__nav-link" href="/account-page">
                  <span>{t('slideMenu.myAccount')}</span>
                </a>
              </li>
              <li className="header__nav-item">
                <a
                  className="header__nav-link"
                  href="#"
                  onClick={() => {
                    props.setAbout(true)
                  }}>
                  <span>{t('slideMenu.aboutUs')}</span>
                </a>
              </li>
              <li className="header__nav-item">
                <a
                  className="header__nav-donate"
                  href="https://visualarmenia.org/#donate"
                  target={'_blank'}
                  rel="noreferrer">
                  {t('slideMenu.donate')}
                </a>
              </li>
            </ul>
          </nav>
          <div className="header__nav-copy">
            <p>{`${t('footer.copyright')} ${new Date().getFullYear()}`}</p>
            <p>{t('footer.allRightsReserved')}</p>
          </div>
        </div>
      </div>
      {props.about && <About />}
      <Popup
        isOpened={
          props.isPresentationMode &&
          showPresentation &&
          !createPresentationModal &&
          !props.isPlaying &&
          !activePlayMode &&
          !deleteSlide
        }
        onClose={closePresentations}
        styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}>
        <ChooseSlides
          createNew={createPresentation}
          openSlides={handleGetPresentation}
          multiple={false}
          onClose={openPresentation}
          onCancel={closePresentations}
          data={presentationsData}
          filteredTotal={getPresentationFilteredTotal}
          handleSetSearchParams={handleSetSearchParams}
          handleNextPage={handleNextPage}
        />
      </Popup>
      <Popup
        isOpened={props.isSeeAllMode}
        onClose={closeSeeAll}
        styleOverlayUser={{ backgroundColor: 'rgba(44, 71, 108, 0.5' }}>
        <SeeAllSlides onClose={closeSeeAll} />
      </Popup>
      {/*<Popup*/}
      {/*    isOpened={slideInfoPopup}*/}
      {/*    onClose={handleCloseSlideInfoPopup}*/}
      {/*    stylePopupUser={{ height: 0 }}*/}
      {/*    overlayHidde={true}*/}
      {/*    cssText={'z-index: 3;'}*/}
      {/*>*/}
      {/*  {slideInfoPopup && <DraggableDiv onClose={handleCloseSlideInfoPopup} item={popupContentItem}/>}*/}
      {/*</Popup>*/}
    </>
  )
}

const mapStateTopProps = (state) => ({
  menu: getMenuData(state),
  about: getAboutData(state),
  isPresentationMode: getIsPresentationMode(state),
  isPresentationShow: getIsPresentationShow(state),
  getPresentationModeData: getPresentationModeData(state),
  isPlaying: getIsSlidePlay(state),
  isSeeAllMode: getIsSeeAllMode(state),
  slides: getSlidesData(state),
  presentationsData: getPresentationData(state),
  getMapStateData: getMapStateData(state),
  getSlidesSelectedSlideData: getSlidesSelectedSlideData(state),
  getPresentationFilteredTotal: getPresentationFilteredTotal(state),
  getScreenShotLoadingST: getScreenShotLoadingST(state),
  getSubjectsSettingsData: getSubjectsSettingsData(state),
  getExternalPresentationData: getExternalPresentationData(state),
  getSlidesFilteredLength: getSlidesFilteredLength(state),
  slidePage: getSlidesPage(state),
})

const mapDispatchToProps = {
  setMenu: setMenu,
  setAbout: setAbout,
  setIsPresentationMode: setIsPresentationMode,
  setSlidesItems: setSlidesItems,
  setPlaySlides: setPlaySlides,
  setSeeAllSlides: setSeeAllSlides,
  createPresentationAPI: createPresentationAPI,
  getPresentationAPI: getPresentationAPI,
  getAllPresentationsFromAPI: getAllPresentationsFromAPI,
  setSelectedSlide: setSelectedSlide,
  editPresentationAPI: editPresentationAPI,
  postExternalPresentationAPI: postExternalPresentationAPI,
  setSlidesPage: setSlidesPage,
  getSlidesByPresentationID: getMultipleSlidsByPresentationAPI,
}

export default connect(
  mapStateTopProps,
  mapDispatchToProps,
)(LeftNavigationMobile)
