import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const API_URL = `${process.env.REACT_APP_INDEXING_URL}/api`;

export const indexingStoreApi = createApi({
    reducerPath: 'indexingApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}`,
        prepareHeaders: (headers, x) => {
            if (window.parent === window) {
                const token = localStorage.getItem("accessToken");
                if (token) {
                    headers.set('authorization', `Bearer ${token}`);
                }
                return headers;
            }
        },
    }),
    tagTypes: [

    ],
    endpoints: (builder) => ({}),
});
