import React from 'react';
import { Modal, Button, Typography, Space } from 'antd';
import styles from './DeleteConfirmationModal.module.css';
import {useTranslation} from "react-i18next";
import { isMobile } from 'react-device-detect';
const DeleteConfirmationModal = ({ visible, onCancel, onAgree, isClose }) => {

    const {t} = useTranslation();

    return (
      <Modal
        width={'fit-content'}
        style={{ borderRadius: '50px' }}
        open={visible}
        title={
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography.Text
              style={{
                color: '#2C476C',
                fontSize: '24px',
                fontWeight: 600,
                letterSpacing: 3,
              }}>
              {isClose
                ? t(
                    'microArticle.mapView.createPopupTexts.closePopup.title',
                  ).toUpperCase()
                : t(
                    'microArticle.mapView.createPopupTexts.deletePopup.title',
                  ).toUpperCase()}
            </Typography.Text>
          </div>
        }
        onCancel={onCancel}
        className={styles.confirmationModalClass}
        rootClassName={styles.confirmationModal}
        footer={[
          <Space
            direction={'horizontal'}
            className={styles.buttonsWrapper}
            key="footer-buttons">
            <Button
              outline
              type={'text'}
              key="cancel"
              onClick={onCancel}
              className={styles.cancelButton}>
              {t(
                'microArticle.mapView.createPopupTexts.buttons.no',
              ).toUpperCase()}
            </Button>
            <Button
              key="confirm"
              onClick={onAgree}
              type="primary"
              danger
              className={styles.deleteButton}>
              {t(
                'microArticle.mapView.createPopupTexts.buttons.yes',
              ).toUpperCase()}
            </Button>
          </Space>,
        ]}
        centered
        closable={false}>
        <div className={styles.textContent}>
          {!isClose ? (
            <>
              <Typography.Text
                style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500, textWrap: isMobile ? 'wrap' : 'nowrap',}}>
                {t(
                  'microArticle.mapView.createPopupTexts.deletePopup.message',
                )}
              </Typography.Text>
            </>
          ) : (
            <>
              <Typography.Text
                style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500, textWrap: isMobile ? 'wrap' : 'nowrap', }}>
                  {t(
                      'microArticle.mapView.createPopupTexts.closePopup.message1',
                  )}
              </Typography.Text>
              <br />
              <Typography.Text
                style={{ color: '#2C476C', fontSize: '16px', fontWeight: 500, textWrap: isMobile ? 'wrap' : 'nowrap', }}>
                  {t(
                      'microArticle.mapView.createPopupTexts.closePopup.message2',
                  )}
              </Typography.Text>
            </>
          )}
        </div>
      </Modal>
    )
};

export default DeleteConfirmationModal;
