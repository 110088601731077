// utils/componentSetupUtils.js

export const handleScreenshotMode = async (
    url,
    actions,
    screenShotFirstLoad,
    screenShoteGenFRef,
    setIsScreenshot
) => {
    if (screenShotFirstLoad && !!url.userId) {
        setIsScreenshot(true);
        window.scrrenShot = true;
        const apiType = !isNaN(+url.screenShot) ? 'MapState' : 'Slide';
        const getApi = await actions.getScreenShotSingleDataAPI(+url.screenShot || +url.screenSlide, apiType);

        await getApi.call()
            .then(getApi.serialized)
            .then(getApi.setLanguage)
            .then(getApi.setLocation);

        screenShoteGenFRef.current = getApi;
    }
};

export const fetchMetadata = async (id, type, lng, mapLiveAPIRequest) => {
    return await mapLiveAPIRequest(id, type, lng);
};

export const processMetadata = async (metadataResp, url, setMetadata, setMapData, setHasLanguageContent) => {
    const metadata = await metadataResp;

    const noLanguageContent = (url.type !== 'article' && !metadata?.language?.length) ||
        (url.type === 'article' && !metadata?.content?.length);

    if (noLanguageContent) {
        setHasLanguageContent(false);
        throw new Error('No language content available');
    }

    setMetadata(() => metadata);
    setMapData(() => metadata);

    return metadata;
};

export const fetchLegends = async (url, lng, actions, selectedMapStyleRef) => {
    const styleId = selectedMapStyleRef.current?.lightIsDefault
        ? selectedMapStyleRef.current?.light
        : selectedMapStyleRef.current?.dark;

    const legendsBody = {
        [`${url.type}Id`]: +url[`${url.type}ID`],
        baseMapId: styleId || 1,
        includeSubLegends: true,
    };

    return await actions.getLegendsFromAPI(legendsBody, lng);
};

export const setupMapFirstViewMode = async (
    metadata,
    url,
    screenShotFirstLoad,
    selectedMapStyleRef,
    selectorsRef,
    setupMapFirstView
) => {
    const styleId = screenShotFirstLoad && !!url.userId
        ? selectorsRef.current.baseMapIsLight
            ? selectedMapStyleRef.current?.light
            : selectedMapStyleRef.current?.dark
        : selectedMapStyleRef.current?.lightIsDefault
            ? selectedMapStyleRef.current?.light
            : selectedMapStyleRef.current?.dark;
    return await setupMapFirstView(styleId || 1, metadata);
};

export const finalizeMapSetup = (
    id,
    type,
    legends,
    metadata,
    mapRef,
    initMapToolboxListenersRef,
    mapLiveViewRef
) => {
    mapRef.current?.on('load', () => {
        initMapToolboxListenersRef.current();
        mapLiveViewRef.current(id, type, legends, null, null, null, null, metadata);
    });
};
