import * as React from 'react'

const VoiceIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.91699 4.375H32.0837"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91699 13.125H16.042"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91699 21.875H11.667"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.91699 30.625H8.75033"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2671 32.0829C15.5113 32.0829 14.0879 30.6596 14.0879 28.9038C14.0879 27.148 15.5113 25.7246 17.2671 25.7246C19.0229 25.7246 20.4462 27.148 20.4462 28.9038C20.4462 30.6596 19.0229 32.0829 17.2671 32.0829Z"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.0838 26.7892V14.3788C32.0838 11.7392 30.4213 11.3746 28.7442 11.8267L22.4005 13.5621C21.2484 13.883 20.4463 14.7871 20.4463 16.1142V18.3309V19.8184V28.9038"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9048 29.9687C27.1489 29.9687 25.7256 28.5453 25.7256 26.7895C25.7256 25.0337 27.1489 23.6104 28.9048 23.6104C30.6606 23.6104 32.0839 25.0337 32.0839 26.7895C32.0839 28.5453 30.6606 29.9687 28.9048 29.9687Z"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4463 19.8325L32.0838 16.6533"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default VoiceIcon
