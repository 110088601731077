import React, {useEffect, useState} from 'react';
import AntPopover from "../AntPopover";
import Maximize from "../../../assets/imgs/icons/maximizeIcon.svg";
import Minimize from "../../../assets/imgs/icons/minimizeIcon.svg";
import CloseIcon from '../../../assets/imgs/icons/Close-blue.svg'
import Monitor from "../../../assets/imgs/icons/monitor.svg";
import {TextInput} from "../TextInput";
import "./style.css";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {parse} from "flatted";
import {
    createScreenShotAPI, editScreenDataAPI, generateScreenShotTitle, setScreenShotCurrentTiliIndex
} from "../../../store/actions/mapStateAction";
import {connect, useSelector} from "react-redux";
import {
    getActiveScreenShot, getIsScreenShotMode,
    getScreenShotIntermediateDataST,
    getScreenShotSendDataST,
    getTopicStaticTitleST
} from "../../../store/selectors";
import {handleCaptureTypeBlob, uploadImage} from "../../../utils/helpers";
import DeleteIcon from "../../../assets/imgs/PaintBar/delete-trash-black-icon.svg";
import Button from 'antd/lib/button';
import Loader from "../Loader";
import {Modal} from "antd";
import PresentationsGrid from "../../presentation/ui/PresentationsGrid";
import PresentationsSelector from "../../presentation/ui/PresentationsSelector";
import {useMapStateImportToSlideMutation} from "../../presentation/store/presentationApi";
import {selectScreenshotPresentation} from "../../presentation/store/presentationSlice";
import {cn} from "../../../utils/utils";
import i18n from "../../../locales/i18n";


const checkValidationErrors = (fields) => {
    const titleMaxLength = 70;
    const descMaxLength = 1000;

    let errors = {};
    let hasError = false;
    const hasTitle = fields.hasOwnProperty("title");
    // Title validation
    if (hasTitle && (fields["title"]?.trim() === "")) {
        hasError = true;
        errors["title"] = i18n.t('errors.requiredField');
    } else if (hasTitle && fields["title"]?.length > 0) {
        errors["title"] = null;
    }
    if (hasTitle && fields["title"]?.length > titleMaxLength) {
        hasError = true;
        errors["title"] = i18n.t('errors.textLength', {maxLength: titleMaxLength});
    } else if (hasTitle && fields["title"]?.length > 0 && fields["title"]?.length <= titleMaxLength) {
        errors["title"] = null;
    }

    // Description validation
    if (fields["description"] && fields["description"].length > descMaxLength) {
        hasError = true;
        errors["description"] = i18n.t('errors.textLength', {maxLength: descMaxLength});
    } else {
        errors["description"] = null;
    }
    return errors;
};


const MapScreenShotPopup = ({
                                open,
                                onClose,
                                imageUrl,
                                mediaId,
                                lessonNavigationData,
                                topicNavigation,
                                title,
                                description,
                                isEditMode,
                                createScreenShotAPI,
                                editScreenDataAPI,
                                data,
                                getTopicStaticTitleST,
                                getScreenShotSendDataST,
                                getScreenShotIntermediateDataST,
                                onSuccess,
                                globalLoading,
                                handleCaptureClick,
                                isScreenShotMode,
                                isMapHeader,
                                isReadMoreArticle,
                                galleryIndex,
                                isGalleryMode,
                                isOwner,
                                onDelete,
                                activeScreenShot,
                                setScreenShotCurrentTiliIndex,
                                disabled,
                                children,
                                ...rest
                            }) => {
    const {t} = useTranslation();
    let [searchParams] = useSearchParams();
    const [fields, setFields] = useState({});
    const [errors, setErrors] = useState({});
    const [apiErrorMsg, setApiErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [isMinimize, setIsMinimize] = useState(false);
    const [ presentationsIsOpen,setPresentationsIsOpen] = useState(false);
    const [initialFields, setInitialFields] = useState({title: "", description: ""});
    const screenshotPresentation = useSelector(selectScreenshotPresentation)
    const [presentations, setPresentations] = useState(screenshotPresentation ? [screenshotPresentation] : []);
    const screenWidth = window.innerWidth;
    const [screenshotUrl, setScreenshotUrl] = useState(imageUrl);
    const [onImportToSlide] = useMapStateImportToSlideMutation()
    const navigate = useNavigate();
    const [initialTitleResponse, setInitialTitleResponse] = useState(null);
    const initialData = data?.()
    const handleMinimize = () => setIsMinimize((prevState) => !prevState);
    // console.log("SEND DATA", initialData);
    const presentationFromUrl = searchParams.get('presentation')

    useEffect(() => {
        if(screenshotPresentation || presentationFromUrl) {
            setPresentations(screenshotPresentation ? [screenshotPresentation] : presentationFromUrl ? [{id:presentationFromUrl}] : [])
        } else if (!screenshotPresentation && !presentationFromUrl) {
            setPresentations([])
        }
    },[screenshotPresentation, presentationFromUrl])

    useEffect(() => {
        if (!open || isEditMode) {
            return;
        }
        const defaultTitle = initialData?.languageId === 1 ? 'Պահպանված քարտեզ' : 'Saved map state'
        generateScreenShotTitle({title:defaultTitle, languageId: initialData?.languageId}).then(res => {
            setInitialTitleResponse(res.data.data?.[0])
            setFields({
                ...fields,
                title: res.data.data?.[0]?.visibleTitle,
            })
        })
    },[initialData?.languageId, open,isEditMode])

    const handleErrors = (fields) => {
        const validationErrors = checkValidationErrors(fields, t);
        if (validationErrors) {
            setErrors({
                ...errors,
                ...validationErrors
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const validationErrors = checkValidationErrors(fields, t);
        if (Object.values(validationErrors || {}).filter(error => error).length > 0) {
            setLoading(false);
            setErrors(validationErrors);
            return;
        }

      setTimeout(() => {
          handleCaptureTypeBlob(
              async (blob) => {
                  await uploadImage(blob)
                      .then(async resp => {
                          const body = data()
                          if (isEditMode) {
                              await editScreenDataAPI(
                                  body.languageId,
                                  parse(getScreenShotSendDataST).id,
                                  fields?.title.slice(0, 70),
                                  body,
                                  null,
                                  searchParams.get(`topicID`),
                                  searchParams.get(`lessonID`),
                                  fields?.description,
                                  resp?.mediaInfo?.optimized,
                                  resp?.id
                              );

                              const id = parse(getScreenShotSendDataST).id;
                              presentations.forEach(presentation => {
                                  const presentationId = presentation.id
                                  if (presentationId === screenshotPresentation?.id || presentationId === presentationFromUrl) {
                                      return;
                                  }

                                  onImportToSlide({
                                      presentationId,
                                      mapStateIds: [id],
                                  })
                              })


                          }
                          else {
                              const title = fields?.title || topicNavigation.current?.title || lessonNavigationData.current?.title

                              const createResponse = await createScreenShotAPI(
                                  body.languageId,
                                  title === initialTitleResponse?.visibleTitle ? initialTitleResponse?.title : title.slice(0, 70),
                                  body,
                                  searchParams.get(`topicID`),
                                  searchParams.get(`lessonID`),
                                  fields?.description,
                                  resp?.id,
                                  resp?.mediaInfo?.optimized,
                              );
                              const id = createResponse?.data?.data?.[0]

                              setInitialTitleResponse(null)

                              presentations.forEach(presentation => {
                                  const presentationId = presentation.id
                                  onImportToSlide({
                                      presentationId,
                                      mapStateIds: [id],
                                  })
                              })

                          }
                          await setScreenshotUrl(resp?.mediaInfo?.optimized);
                          onSuccess();
                          setScreenShotCurrentTiliIndex(null)
                          if (!searchParams.has('screenShot') && searchParams.has('screenSlide')) {
                              searchParams.set(`screenShot`,'true')
                              navigate({search: searchParams.toString(), replace:true})
                          }
                      }).catch(() => {
                          setApiErrorMsg(t('screenShot.error'))
                      }).finally(() => {
                          setLoading(false);
                      })
              },
              isReadMoreArticle && !(galleryIndex >= 0) && !isGalleryMode ? 'article-read-more' :
                  ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && !isReadMoreArticle ? 'gallery-section' :
                      ((galleryIndex !== null && +galleryIndex >= 0) || isGalleryMode) && isReadMoreArticle ? 'article-gallery' :
                          activeScreenShot,
              galleryIndex
          )
      },200)
    };

    const isDisabled = () => {
        if (!isEditMode) return false;
        return (
            fields.title === initialFields.title &&
            fields.description === initialFields.description
        );
    };


    const content = () => (
        <div
            style={{fontFamily: '"Noto Sans",sans-serif'}}
            className={`map-screenshot-popup !pt-0 !px-2 ${
                isMinimize ? "map-screenshot-popup--minimized !pb-0" : "map-screenshot-popup--expanded"
            }`}
        >
            <div className="map-screenshot-popup__header">
                {isMinimize ? (
                    <Button
                        type='primary'
                        onClick={handleSubmit}
                        className="map-screenshot-popup__submit-button"
                        loading={loading}
                        disabled={loading}
                    >{loading ? '' : t('screenShot.save')}</Button>
                ) : (
                    <h1 className="map-screenshot-popup__header-title" style={{fontFamily: '"Noto Sans",sans-serif'}}>
                        {isEditMode ? t("screenShot.edit_map") : t("screenShot.new_map")}
                    </h1>
                )}
                <div className="map-screenshot-popup__header-buttons"
                     style={{minWidth: isEditMode ? isMinimize ? 50 : 72 : 50}}>
                    {isMinimize ? (
                        <button className="ml-2" onClick={handleMinimize}>
                            <img style={{width: "24px", height: "24px"}} src={Maximize} alt="Maximize"/>
                        </button>
                    ) : (
                        <div style={{display: "flex", gap: "15px"}}>
                            {isOwner && isEditMode && (
                                <button onClick={onDelete}>
                                    <img style={{width: '20px', height: '20px'}} src={DeleteIcon} alt="Delete"/>
                                </button>
                            )}
                            <button onClick={handleMinimize}>
                                <img style={{width: "20px", height: "20px"}} src={Minimize} alt="Minimize"/>
                            </button>
                        </div>

                    )}
                    <button onClick={onClose}>
                        <img style={{width: "20px", height: "20px"}} src={CloseIcon} alt="Close"/>
                    </button>
                </div>
            </div>
            {!isMinimize && (
                <form className="map-screenshot-popup__form" onSubmit={handleSubmit}>
                    <div className="map-screenshot-popup__preview" data-is-loading={!screenshotUrl}>
                        {screenshotUrl && <img src={screenshotUrl} alt="Screenshot Preview"/>}
                    </div>
                    <div className="map-screenshot-popup__fields">
                        <div className="mb-1">
                            <label className="map-screenshot-popup__label">
                                {t("screenShot.name")}
                                <TextInput
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className={cn("map-screenshot-popup__textarea fixed-size-textarea", {
                                        "!border-red-500": errors["title"],
                                    })}
                                    style={{fontFamily: '"Noto Sans",sans-serif'}}
                                    onChange={(e) => {
                                        errors["title"] && setErrors({...errors, title: ""})
                                        handleErrors({title: e.target.value})
                                        setFields({
                                            ...fields,
                                            title: e.target.value,
                                        })
                                    }}
                                    value={fields["title"] || ""}
                                />
                                {errors["title"] && (
                                    <span style={{fontFamily: '"Noto Sans",sans-serif'}}
                                          className="map-screenshot-popup__error max-w-[85%] !text-[11px] leading-[12px] top-[62px]">{errors["title"]}</span>
                                )}
                                <span style={{fontFamily: '"Noto Sans",sans-serif'}}
                                      className="map-screenshot-popup__counter">
                                    <span className={cn({"text-red-500": fields["title"]?.length > 70})}>{fields["title"]?.length || 0}</span> / 70
                                </span>
                            </label>
                        </div>
                        <div className="mb-5">
                            <label className="map-screenshot-popup__label map-screenshot-popup__label--description">
                                {t("screenShot.description")}
                                <textarea
                                    className={cn("map-screenshot-popup__textarea fixed-size-textarea", {
                                        "!border-red-500": errors["description"],
                                    })}
                                    rows="5"
                                    style={{fontFamily: '"Noto Sans",sans-serif'}}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    value={fields["description"] || ""}
                                    onChange={(e) => {
                                        handleErrors({description: e.target.value})
                                        setFields({
                                            ...fields,
                                            description: e.target.value,
                                        })
                                    }
                                    }
                                />
                                {errors["description"] && (
                                    <span style={{fontFamily: '"Noto Sans",sans-serif'}}
                                          className="map-screenshot-popup__error max-w-[80%] !text-[11px] leading-[12px] top-[150px]">{errors["description"]}</span>
                                )}
                                <span className="map-screenshot-popup__counter"
                                      style={{fontFamily: '"Noto Sans",sans-serif'}}>
                                    <span className={cn({"text-red-500": fields["description"]?.length > 1000})}>{fields["description"]?.length || 0}</span>  / 1000
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="map-screenshot-popup__actions !mt-2">
                        <div className="map-screenshot-popup__action cursor-pointer" onClick={() => setPresentationsIsOpen(true)}>
                            <div  className={cn("map-screenshot-popup__icon-container", {
                                "!bg-[#036147]": presentations.length > 0
                            })}>
                                <img className={cn("map-screenshot-popup__icon", {
                                    "invert brightness-0": presentations.length > 0
                                })} src={Monitor} alt="Monitor"/>
                            </div>
                            <span
                                style={{
                                    fontFamily: '"Noto Sans",sans-serif',
                                    color: '#2c476c',
                                    fontWeight: 400,
                                }}>
                                {t("screenShot.add_to_presentation")}
                            </span>
                        </div>
                    </div>
                    <div className="map-screenshot-popup__submit !mt-3">
                        <Button
                            type='text'
                            onClick={handleSubmit}
                            // className="map-screenshot-popup__submit-button"
                            rootClassName="uppercase text-[12px] h-[30px] min-w-[130px] bg-[#FFDE5A] border-none text-black rounded-[38px] shadow-none cursor-pointer tracking-[2px] hover:!bg-[#FFDE5A] hover:!shadow-none hover:!text-black hover:!border-none"
                            loading={loading}
                            disabled={loading}
                        >{loading ? '' : t('screenShot.save')}</Button>
                    </div>
                </form>
            )}
        </div>
    );

    const changePresentationsHandler = (data) => {
        setPresentations([...new Map(data.map(item => [item.id, item])).values()])
        setPresentationsIsOpen(false)
    }

    useEffect(() => {
        if (!open) {
            setFields({});
            setErrors({});
            setInitialFields({});
            setScreenshotUrl(null)
            setApiErrorMsg("");
            setIsMinimize(false);
            setLoading(false);
        } else {
            const initialState = {
                // title: title?.slice?.(0, 70) || `${lessonNavigationData?.current?.title ? (`${t('lesson')} - ${lessonNavigationData?.current?.title} |`) : ""} ${topicNavigation?.current?.title ? (`${t('topic')} - ${topicNavigation?.current?.title}`) : ""}`.slice(0, 70) || "",
                title:'',
                description: '',
            };

            if (isEditMode) {
                initialState.title = title?.slice?.(0, 70) || `${lessonNavigationData?.current?.title ? (`${t('lesson')} - ${lessonNavigationData?.current?.title} |`) : ""} ${topicNavigation?.current?.title ? (`${t('topic')} - ${topicNavigation?.current?.title}`) : ""}`.slice(0, 70) || ""
                initialState.description = description ? description.replace(/<\/p>/g, '\n').replace(/<p>/g, '').trim()  : ""
            }

            if (fields && Object.keys(fields).length === 0) {
                setFields(initialState);
                setInitialFields(initialState);
            }
            setScreenshotUrl(imageUrl)

        }
    }, [imageUrl, description, isEditMode, lessonNavigationData, open, t, title, topicNavigation]);
    //
    //
    // useEffect(() => {
    //    if (open) {
    //        searchParams.set('playBack', 'false');
    //
    //        navigate({
    //            pathname: window.location.pathname,
    //            search: searchParams.toString()
    //        }, {replace: true})
    //    }
    //
    //     return () => {
    //        if (open) return;
    //         searchParams.set('playBack', 'true');
    //
    //         navigate({
    //             pathname: window.location.pathname,
    //             search: searchParams.toString()
    //         }, {replace: true})
    //     }
    //
    // },[open])

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         const activeElement = document.activeElement
    //         const isInputOrTextareaFocused =
    //             activeElement.tagName === 'INPUT' ||
    //             activeElement.tagName === 'TEXTAREA' || activeElement.classList.contains('ql-editor')
    //         if (event.key === ' ' && !isInputOrTextareaFocused) {
    //
    //             event.stopPropagation()
    //         }
    //     }
    //
    //     document.addEventListener('keydown', handleKeyDown)
    //     return () => {
    //         document.removeEventListener('keydown', handleKeyDown)
    //     }
    // }, [])


    if (globalLoading) {
        return null
    }

    return (
        <>
            <AntPopover
                placement="left"
                overlayStyle={{
                    position: "absolute",
                    top: screenWidth < 3000 ? activeScreenShot === 'article-read-more' ? 72 : 52 : 80,
                    right: activeScreenShot !== 'map' ? 15 : 5,
                }}
                overlayInnerStyle={{borderRadius: "20px"}}
                arrow={false}
                open={open}
                destroyOnClose
                trigger="click"
                content={content}
                onOpenChange={rest.onOpenChange}
            >
                {children}
            </AntPopover>
            <Modal
                destroyOnClose
                title={t('presentation_mode.presentations')}
                centered
                classNames={{
                    header:
                        'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
                }}
                // getContainer={() => document.querySelector('.ant-popover-inner')}
                className="app-modal max-h-[90vh]"
                wrapClassName="!z-[1240]"
                width={950}
                footer={false}
                style={{ padding: 0 }}
                onCancel={() => setPresentationsIsOpen(false)}
                open={presentationsIsOpen}>
                <PresentationsSelector onSelect={changePresentationsHandler} data={presentations} defaultSelected={screenshotPresentation}/>
            </Modal>
        </>

    );
};


const mapStateTopProps = (state) => ({
    getTopicStaticTitleST: getTopicStaticTitleST(state),
    getScreenShotSendDataST: getScreenShotSendDataST(state),
    getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
    activeScreenShot: getActiveScreenShot(state),
    isScreenShotMode: getIsScreenShotMode(state)
});

const mapDispatchToProps = {
    createScreenShotAPI,
    editScreenDataAPI,
    setScreenShotCurrentTiliIndex
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(MapScreenShotPopup);
