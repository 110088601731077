import * as React from 'react'

const RestartIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M31.2602 21.8545H25.6502L24.2568 26.0528H28.4551C29.9951 26.0528 31.2602 27.2995 31.2602 28.8578C31.2602 30.3978 30.0135 31.6628 28.4551 31.6628H24.2568"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4893 31.6444V21.8545L16.7393 24.9161"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.6309 10.1953L24.001 5.66699"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.9989 16.2997C39.0339 19.013 40.2989 22.368 40.2989 26.0347C40.2989 35.0364 33.0022 42.333 24.0005 42.333C14.9988 42.333 7.70215 35.0364 7.70215 26.0347C7.70215 17.033 14.9988 9.73633 24.0005 9.73633C25.2472 9.73633 26.4571 9.90142 27.6305 10.1764"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46 2V46H2V2H46Z"
      stroke="#2C476C"
      strokeWidth={3.3}
    />
  </svg>
)
export default RestartIcon
