import React, { useCallback, useEffect, useState, useRef } from "react";
import Account from "../account";
import styles from "./style.module.css";
import "./style.css";
import LeftNavigation from "../../components/leftNavigation";
import AccountFooter from "../../components/accountFooter";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
    getUserData,
    getAllMicroArticlesBoard,
} from "../../store/selectors";
import { setAllMicroArticlesBoard } from "../../store/actions/microArticle";
import { Pagination } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageLoader from "../../components/UI/PageLoader";
import { useTranslation } from "react-i18next";
import TopBar from "../../components/topBar";
import MicroArticleBoardGrid from "../../components/UI/MicroArticleBoardGrid";
import { MicroArticleService } from "../../components/microArticle/service";
import SearchSection from "../../components/UI/PresentationsCard/SearchSection";
import {getSpriteFromAPI} from "../../store/actions/sprite";
import i18n from "i18next";
import {isMobile} from "react-device-detect";

// Higher-Order Component to inject navigation
export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};

// Debounce hook to limit the rate of function execution
const useDebounce = (callback, delay) => {
    const callbackRef = useRef();
    callbackRef.current = callback;

    const timeoutRef = useRef();

    return useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callbackRef.current(...args);
        }, delay);
    }, [delay]);
};

const MicroArticleBoard = (props) => {
    const { t } = useTranslation();
    const matches = useMediaQuery("(min-width:600px)");
    const [page, setPage] = useState(0); // Start from 0
    const [itemsTotal, setItemsTotal] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [searchParams, setSearchParams] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Debounced search handler
    const debouncedSearch = useDebounce((value) => {
        setPage(0); // Reset to first page on new search (0-based)
        setSearchParams(value);
    }, 300);

    // Handle search input changes
    const handleSetSearchParams = (e) => {
        const value = e?.target?.value.trim() || "";
        debouncedSearch(value);
    };

    // Handle page change in pagination
    const handleChangePage = (e, value) => {
        const pageLoc = Number(value) - 1; // Convert to 0-based
        if (!isNaN(pageLoc) && pageLoc !== page) {
            setPage(pageLoc);
            // Update URL with new page and current searchParams
            const newUrl = new URL(window.location);
            newUrl.searchParams.set("page", pageLoc);
            if (searchParams) {
                newUrl.searchParams.set("search", searchParams);
            } else {
                newUrl.searchParams.delete("search");
            }
            window.history.replaceState(null, null, newUrl.toString());
        }
    };

    // Fetch microarticles data based on current page and search parameters
    const handleGetData = useCallback(async (deleted = false) => {
        setIsDeleted(!!deleted);
        const queryParams = new URLSearchParams(window.location.search);
        let pageLoc = Number(queryParams.get("page"));
        if (isNaN(pageLoc)) {
            pageLoc = 0; // Default to 0 if not set
        }

        // Calculate total pages based on itemsTotal
        const totalPages = Math.ceil(itemsTotal / 10) || 1;

        if (pageLoc >= totalPages) pageLoc = totalPages - 1;
        if (pageLoc < 0) pageLoc = 0;

        if (!isNaN(pageLoc)) {
            if (pageLoc !== page) setPage(pageLoc);
            setIsLoading(true);
            try {
                // Fetch microarticles from the service
                const userId = props.user?.id;
                const res = await MicroArticleService.getMicroArticlesForBoard(pageLoc, userId, searchParams ? searchParams : null);

                // Update total items and set microarticles in Redux store
                const data = res.data.data[0];
                setItemsTotal(data.filteredTotal);
                props.setAllMicroArticlesBoard(data.items);

                // Update the URL with the current page and search parameters
                const newUrl = new URL(window.location);
                newUrl.searchParams.set("page", pageLoc);
                if (searchParams) {
                    newUrl.searchParams.set("search", searchParams);
                } else {
                    newUrl.searchParams.delete("search");
                }
                window.history.replaceState(null, null, newUrl.toString());
            } catch (error) {
                console.error("Error fetching microarticles:", error);
                // Optionally, handle errors (e.g., show a notification)
            } finally {
                setIsLoading(false);
            }
        }
    }, [page, itemsTotal, props.user, searchParams]);



    // Effect to initialize searchParams from URL on component mount
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const search = queryParams.get("search") || "";
        setSearchParams(search);
    }, []);

    // Effect to fetch data when page, searchParams, or user changes
    useEffect(() => {
        if (props.user && props.user.id) {
            handleGetData().then(r => true).catch(e => console.error(e));
        }
    }, [page, searchParams, props.user, handleGetData, i18n.language]);

    useEffect(() => {
        props.getSpriteFromAPI('/BaseSprite')
    },[])

    return (
        <Account onlyBG={true}>
            <div className={'headerWrapper'}>
                <LeftNavigation />
                <TopBar />
            </div>
            <div className={styles["microArticle-dashboards"] + " main-wrapper-microArticleBoard"}>
                <div className={styles["account-dashboards--search"]}>
                    <SearchSection styles={
                        isMobile ? {wrapper: {width: 'fit-content', padding: 0} } : {}
                    } handleSetSearchParams={handleSetSearchParams} />
                </div>
                <div style={{maxHeight: 'calc(83% + 40px)', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%'}}>
                    {!isLoading || isDeleted ? (
                        props.getAllMicroArticlesBoard && props.getAllMicroArticlesBoard.length > 0 ? (
                            <MicroArticleBoardGrid microArticleData={props.getAllMicroArticlesBoard} handleGetData={handleGetData} />
                        ) : (
                            <div className={styles["microArticle-empty-data"]}>
                                <h2>{t("microArticle.userDashboard.notFoundArticles")}</h2> {/* Updated translation key */}
                            </div>
                        )
                    ) : (
                        <div className={styles["loader-container"]}>
                            <PageLoader />
                        </div>
                    )}
                </div>
                <div className={"account-dashboards--pagination"} style={isMobile ? {} : {minHeight: 32, maxHeight: 32}}>
                    {(!isLoading || isDeleted) && itemsTotal > 10 && (
                        <Pagination
                            className={"pagination"}
                            count={Math.ceil(itemsTotal / 10)}
                            page={page + 1} // Convert itemsTotalto 1-based
                            onChange={handleChangePage}
                            color="primary"
                            variant="outlined"
                            shape="rounded"
                            siblingCount={isMobile && window.innerWidth < 1024 ? 0 : 1}
                            boundaryCount={1}
                        />
                    )}
                </div>
            </div>
            <AccountFooter />
        </Account>
    );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
    user: getUserData(state),
    getAllMicroArticlesBoard: getAllMicroArticlesBoard(state),
});

// Map dispatch actions to component props
const mapDispatchToProps = {
    setAllMicroArticlesBoard,
    getSpriteFromAPI,
};

// Export the connected component with navigation
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNavigation(MicroArticleBoard));
