import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ChevronLeft,
  ChevronRight,
  CirclePause,
  Maximize2,
  MenuIcon,
} from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import PlayIcon from '../icons/PlayIcon'
import { Button, Dropdown, Modal } from 'antd'
import DotsIcon from '../icons/DotsIcon'
import TextIcon from '../icons/TextIcon'
import PenIcon from '../icons/PenIcon'

import MinimizeIcon from '../icons/MinimizeIcon'
import VoiceIcon from '../icons/VoiceIcon'
import PlayCircleIcon from '../icons/PlayCircleIcon'
import RestartIcon from '../icons/RestartIcon'
import CloseIcon from '../icons/CloseIcon'
import { cn, getLocalStorage, setLocalStorage } from '../../../../utils/utils'
import PlaybackSlideItem from './PlaybackSlideItem'
import {
  selectIsMinimized,
  selectPatumSize,
  selectPlaybackPresentation, setCurrentSlide,
  setIsMinimized, setMicroArticlePresentation,
  setPatumSize,
  setPlaybackPresentation, setScreenshotPresentation,
} from '../../store/presentationSlice'
import PresentationFormWrapper from '../form/PresentationFormWrapper'
import {
  useGetPresentationSlidesQuery, useLazyGetPresentationSlidesQuery,
  useUpdatePresentationMutation,
} from '../../store/presentationApi'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  getArticles, getIsFormPopupVisible,
  getSubjectsSettingsData as getSubjectsSettingsDataST,
} from '../../../../store/selectors'
import {
  dispatchScreenShotLoader,
  getScreenShotSingleDataAPI,
} from '../../../../store/actions/mapStateAction'
import { getExternalDuration } from '../../../UI/Slides/utils'
import { useTranslation } from 'react-i18next'
import { setViewPopupVisibility } from '../../../../store/actions/microArticle'
import PresentationIntro from "../PresentationIntro";
import {setSelectedSlideData} from "../../../../store/actions/slides";

const debounce = (fn, wait) => {
  let timeoutID
  return (...arg) => {
    if (timeoutID) clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...arg), wait)
  }
}
const debeounced300 = debounce((fn) => fn(), 300)

const PlaybackWrapper = ({
  firstLoad,
  map,
  firstRunScreenShotStates,
  stopCountdown,
  handleEditDurationTime,
  slideNavigation,
  onExit,
  restartCountdown,
  handleSlideInfoPopup,
  pauseCountdown,
  playCountdown,
  handleShowSlides,
  showSlide,
  slideTransitionDuration,
  setIsPlaying,
  isPlaying,
  globalLoading,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isMinimized = useSelector(selectIsMinimized)
  const presentation = useSelector(selectPlaybackPresentation)
  const getSubjectsSettingsData = useSelector(getSubjectsSettingsDataST)

  const mapArticles = useSelector(getArticles)

  const [slidesIsShow, setSlidesIsShow] = useState(
    !!sessionStorage.getItem('slidesIsShow'),
  )
  const [footerHeight, setFooterHeight] = useState(0)
  const patumSize = useSelector(selectPatumSize)
  const screenShotFormIsOpen = useSelector(state => state.mapState.screenShotFormIsOpen)
  const microArticleFormIsOpen = useSelector(getIsFormPopupVisible)

  const firstLoadRef = React.useRef(true)

  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [editIsOpen, setIsEditMode] = useState(false)
  const [settings, setSettings] = useState({
    text: patumSize,
    audioGender: 'off',
    isVoiceEnabled: false,
  })

  const durFromStorage =
    typeof getExternalDuration() === 'number'
      ? getExternalDuration()
      : slideTransitionDuration
  const [duration, setDuration] = useState(durFromStorage)
  const [isMenuHided, setIsMenuHided] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [prevScreenSlide, setPrevScreenSlide] = useState(null)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [searchParams] = useSearchParams()
  const [runScreenShotStates, setRunScreenShotStates] = useState(null)
  const [isIntroOpen, setIsIntroOpen] = useState(searchParams.get('presentation') && !searchParams.get('open') && !searchParams.get('screenSlide') && !searchParams.get('playBack'))

  const [currentUser] = useState(
    JSON.parse(localStorage.getItem('userInfo'))?.data,
  )
  const [getSlides] = useLazyGetPresentationSlidesQuery()

  const isPreview = searchParams.get('preview')

  const presentationFromUrl = searchParams.get('presentation')

  const [onUpdatePresentation, { isLoading: isUpdateLoading }] =
    useUpdatePresentationMutation()

  // const {onNextSlide,onExit, currentSlide,restartCountdown, onPlayToggle, isPaused, onPrevSlide} = usePlayback()

  const url = new URL(window.location.href)

  const { data: slidesData, refetch: refetchSlides } =
    useGetPresentationSlidesQuery(
      {
        page: 1,
        perPage: 1000,
        columnFiltersMany: {
          presentationId: [String(presentation?.id || presentationFromUrl)],
          // isHidden: 0,
        },
        columnFilters: {
          isHidden: 'false',
        },
        sort: [
          {
            field: 'order',
            type: 'asc',
          },
        ],
      },
      { skip: !presentation?.id && !presentationFromUrl },
    )
  const slides = useMemo(
    () =>
      slidesData?.data?.[0]?.items
        ? slidesData?.data?.[0]?.items.filter((item) => !item.isHidden && (!item.isDeleted))
        : [],
    [slidesData],
  )
  const currentSlideNumber =
    slides?.findIndex(
      (slide) => slide.id === Number(query.get('screenSlide')),
    ) + 1

  const playbackPresentation = useSelector(
    (state) => state.presentations.playbackPresentation,
  )
  const isCreator =
    currentUser && +currentUser?.id === +playbackPresentation?.createdBy && !isPreview

  const items = [
    {
      icon: <MinimizeIcon />,
      label: t('presentation_mode.minimise'),
      key: 'minimize',
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'edit',
      disabled: !isCreator,
    },
    {
      icon: <VoiceIcon />,
      label: t('presentation_mode.voiceover'),
      key: 'audioGender',
      popupOffset: [8, 0],
      children: [
        {
          key: 'Male',
          label: t('presentation_mode.voiceover_on_option'),
          disabled:true
        },
        {
          key: 'off',
          label: t('presentation_mode.voiceover_off_option'),
        },
      ],
    },
    {
      icon: <TextIcon />,
      label: (
        <span className="patum-dropdown--item">
          {t('presentation_mode.narrative')}{' '}
          <span
            className="patum-pt--overview"
            style={{ fontSize: `${settings.text}px` }}>
            {t('presentation_mode.font_size_tooltip')}
          </span>
        </span>
      ),
      key: 'text',
      className: 'patum-dropdown--item-menu',
      popupClassName: 'patum-dropdown--item-menu--popup',
      popupOffset: [8, 0],
      children: [
        {
          key: '10',
          label: t('presentation_mode.font_size_x', { size: 10 }),
        },
        {
          key: '14',
          label: t('presentation_mode.font_size_x', { size: 14 }),
        },
        {
          key: '18',
          label: t('presentation_mode.font_size_x', { size: 18 }),
        },
        {
          key: '24',
          label: t('presentation_mode.font_size_x', { size: 24 }),
        },
        {
          key: '36',
          label: t('presentation_mode.font_size_x', { size: 36 }),
        },
        {
          key: 'disabled',
          label: t('presentation_mode.font_size_turn_off'),
        },
      ],
    },
    {
      icon: <PlayCircleIcon className="" />,
      label: t('presentation_mode.duration_label'),
      key: 'slideTransitionDuration',
      popupOffset: [8, 0],
      children: [
        {
          key: '15',
          label: t('presentation_mode.x_seconds', { seconds: 15 }),
        },
        {
          key: '30',
          label: t('presentation_mode.x_seconds', { seconds: 30 }),
        },
        {
          key: '45',
          label: t('presentation_mode.x_seconds', { seconds: 45 }),
        },
        {
          key: '60',
          label: t('presentation_mode.x_seconds', { seconds: 60 }),
        },
      ],
    },
    {
      icon: <RestartIcon />,
      label: t('presentation_mode.replay'),
      key: 'restart',
    },
    {
      icon: <CloseIcon />,
      label: t('presentation_mode.exit'),
      key: 'close',
    },
  ]

  const clickDropdownHandler = ({ key, keyPath, domEvent }) => {
    domEvent.stopPropagation()
    if (keyPath.length > 1) {
      const parentKey = keyPath[1]
      const childKey = keyPath[0]
      const params = {
        id: presentation?.id,
      }

      setSettings({
        ...settings,
        [parentKey]: childKey,
      })

      if (parentKey === 'audioGender') {
        params.audioGender = childKey
        params.isVoiceEnabled = childKey === 'off'
        localStorage.setItem('audioGender', childKey)
        localStorage.setItem('isVoiceEnabled', params.isVoiceEnabled)
      } else if (parentKey === 'slideTransitionDuration') {
        params[parentKey] = childKey
        localStorage.setItem('slideTransitionDuration', childKey)
      }

      if (parentKey === 'text') {
        localStorage.setItem('patumTextSize', childKey)
        dispatch(setPatumSize(childKey))
        params.showDescriptionPopup = childKey !== 'disabled'
        params.descriptionFontSize = params.showDescriptionPopup
          ? Number(childKey)
          : 0
      }

      onUpdatePresentation(params)
        .unwrap()
        .then(() => {
          if (parentKey === 'slideTransitionDuration') {
            handleEditDurationTime?.(childKey)
          }
        })
      return
    }
    // setSelectedKey(key);
    switch (key) {
      case 'text':
        // setPatumIsOpen(true);
        break
      case 'edit':
        // dispatch(setPlaybackPresentation(null))
        setIsEditMode(true)
        // setTimeout(() =>  closeHandler(), 300)
        break
      case 'restart':
        setIsIntroOpen("reload")
        // restartCountdown?.()
        break
      case 'minimize':
        dispatch(setIsMinimized(true))
        query.append('minimize', 'true')
        navigate(
          {
            pathname: window.location.pathname,
            search: query.toString(),
          },
          { replace: true },
        )
        break
      case 'close':
        closeHandler()
        break
      default:
    }
  }

  const closeHandler = () => {

    stopCountdown?.()

   setTimeout(() => {
     dispatch(setIsMinimized(false))
     dispatch(setPlaybackPresentation(null))
     dispatch(setViewPopupVisibility(false))
     query.delete('minimize')
     query.delete('presentation')
     query.delete('screenSlide')
     query.delete('playMode')
     query.delete('playBack')
     query.delete('screenShot')
     query.delete('userId')
     query.delete('external')
     query.delete('articleMode')
     query.delete('microArticleMode')
     query.delete('screenShotId')
     localStorage.removeItem('patumTextSize')
     localStorage.removeItem('slideTransitionDuration')
     localStorage.removeItem('audioGender')
     localStorage.removeItem('isVoiceEnabled')
     sessionStorage.removeItem('slidesIsShow')


     if (isPreview) {
       const language = localStorage.getItem("language")
       localStorage.clear();
       localStorage.setItem("language", language || 'hy');
       query.delete('preview')

       setTimeout(() => window.location.href = `${window.location.pathname}/login`, 300)
       return;
     }

     // window.location.href = `${window.location.pathname}?${query.toString()}`

     // window.history.pushState(
     //     null,
     //     null,
     //     `${window.location.pathname}?${query.toString()}`,
     // )
     //
     navigate(
         {
           pathname: window.location.pathname,
           search: query.toString(),
         },
         { replace: true },
     )

     onExit?.()
   },200)
  }

  const selectHandler = (e) => {
    console.log(e)
  }

  useEffect(() => {
    if (!menuIsOpen) {
      return
    }

    let interval = null

    interval = setInterval(() => {
      const menuItems = document.querySelectorAll(
        '.patum-dropdown--item-menu--popup .ant-dropdown-menu-item',
      )
      if (menuItems.length > 0) {
        clearInterval(interval) // Stop checking once elements are found
        menuItems.forEach((item) => {
          item.addEventListener('mouseenter', (e) => {
            const fontSize = parseInt(e.target.innerText)
            const overviewElement = document.querySelector(
              '.patum-pt--overview',
            )
            if (!isNaN(fontSize)) {
              overviewElement.style.fontSize = `${fontSize}px`
            } else {
              overviewElement.style.fontSize = 'inherit'
            }
          })
        })
      }
    }, 200) // Check every 100ms

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [menuIsOpen])

  useEffect(() => {
    let resizeObserver
    setTimeout(() => {
      const footerElement =
        document.querySelector('#mapSlider') ||
        document.querySelector('.footer')
      if (!footerElement) return

      resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target === footerElement && entry.contentRect.height) {
            setFooterHeight(entry.contentRect.height)
          }
        }
      })

      resizeObserver.observe(footerElement)
    }, 2000)

    // Cleanup on unmount
    return () => {
      resizeObserver?.disconnect()
    }
  }, [])

  useEffect(() => {
    if (playbackPresentation) {
      let dataForGuset = {}

      if (!isCreator) {
        dataForGuset = {
          audioGender:
            localStorage.getItem('audioGender') ||
            playbackPresentation?.audioGender ||
            'off',
          isVoiceEnabled:
            localStorage.getItem('isVoiceEnabled') ||
            playbackPresentation?.isVoiceEnabled ||
            false,
          slideTransitionDuration:
            localStorage.getItem('slideTransitionDuration') ||
            playbackPresentation?.slideTransitionDuration ||
            15,
          text:
            localStorage.getItem('patumTextSize') ||
              (playbackPresentation?.showDescriptionPopup ? String(playbackPresentation?.descriptionFontSize || '') :
            'disabled'),
        }
      }
      setSettings({
        ...settings,
        audioGender: playbackPresentation?.audioGender || 'off',
        isVoiceEnabled: playbackPresentation?.isVoiceEnabled || false,
        slideTransitionDuration: String(
          playbackPresentation?.slideTransitionDuration === 10
            ? 15
            : playbackPresentation?.slideTransitionDuration || 15,
        ),
        text: playbackPresentation?.showDescriptionPopup
          ? String(playbackPresentation?.descriptionFontSize || '10')
          : 'disabled',
        ...dataForGuset,
      })
    }
  }, [playbackPresentation, isCreator])

  useEffect(() => {
    if (query.get('minimize') === 'true') {
      dispatch(setIsMinimized(true))
    }
    return () => {
      dispatch(setPlaybackPresentation(null))
      dispatch(setIsMinimized(false))
    }
  }, [])

  useEffect(() => {
    if (!playbackPresentation) return

    if (currentUser && +currentUser?.id === +playbackPresentation?.createdBy) {
      onUpdatePresentation({
        id: playbackPresentation?.id,
        LastOpenedDate: new Date(),
      })
    }
  }, [playbackPresentation?.id, currentUser?.id])

  const openMenuHandler = (menuIsOpen) => {
    if (isPlaying) {
      handlePlayingMode()
    }
    setMenuIsOpen(menuIsOpen)
  }

  const afterEditSuccess = () => {
    refetchSlides()
    // restartCountdown?.()
    setIsEditMode(false)
  }

  const handleReplaceUrl = useCallback(
    (slide, isPlaying) => {
      const selectedId = slide?.id
      const articleId = slide?.articleId
      // Use the browser's history API to update the URL when isPresentationMode changes
      const newUrl = new URLSearchParams(window.location.search)
      // const presentationParam = (!!selectedId && typeof selectedId === 'number')
      //                                     ? `&screenSlide=${selectedId}&userId=101&screenShot=true&loading=true`
      //                                     : '';
      const currentUser = JSON.parse(localStorage.getItem('userInfo'))?.data
      if (!!selectedId && typeof selectedId === 'number') {
        newUrl.set('screenSlide', selectedId)
        newUrl.set('screenShot', 'true')

        currentUser?.id && !playbackPresentation?.createdBy ? newUrl.set('userId', `${currentUser?.id}`) : newUrl.delete('userId')

        if (playbackPresentation?.createdBy) {
          newUrl.set('userId', `${playbackPresentation.createdBy}`)
        } else {
          newUrl.delete('userId')
        }

        newUrl.set('loading', 'true')
        if (slide?.mapStateId) {
          newUrl.set('screenShotId', slide?.mapStateId)
        } else {
          newUrl.delete('screenShotId')
        }
        if (slide?.microArticleId) {
          newUrl.set('microArticleMode', slide?.microArticleId)
        } else {
          newUrl.delete('microArticleMode')
        }
      } else if (newUrl.has('screenSlide')) {
        newUrl.delete('screenSlide')
        newUrl.delete('screenShot')
        newUrl.delete('userId')
      }

      if (!!articleId) {
        newUrl.set('articleMode', articleId)
      } else {
        newUrl.delete('articleMode')
      }

      if (isPlaying) {
        newUrl.set('playBack', 'true')
      }

      navigate(
        {
          pathname: window.location.pathname,
          search: newUrl.toString(),
        },
        { replace: false },
      )
    },
    [navigate],
  )

  const runSlide = async (id) => {
    if (!id) return
    window.scrrenShot = true
    firstLoadRef.current = true
    dispatch(dispatchScreenShotLoader(true))
    const getApi = await dispatch(getScreenShotSingleDataAPI(id, 'Slide'))
    await getApi
      .call()
      .then((res) => getApi.serialized(res))
      .then((res) => getApi.setLanguage(res))
      .then((res) => getApi.setLocation(res))
      .then((res) => {
        getApi.setData(res).then((runScreenShotStates) => {
          setRunScreenShotStates(() => runScreenShotStates)
        })
      })
    // await getScreenShotSingleDataAPI(id,'Slide')
    //     .then((runScreenShotStates)=>{
    //         setRunScreenShotStates(()=>runScreenShotStates)
    //     })
  }

  useEffect(() => {
    let timer = null
    const isPlaying = searchParams.get('playBack') === 'true'
    if (!map || globalLoading) return null
    if (Object.keys(getSubjectsSettingsData).length > 0) {
      let statesST = [
        'checkSettingsSTF',
        'loadBaseMap',
        'mapSTF',
        'toolBarSTF',
        'leftBarSTF',
        'timelineSTF',
        'leftBarCatOpenSTF',
        'compassSTF',
        'loadDoneSTF',
      ]
      let slideData = slides.find(
        (item) => item.id === +searchParams.get('screenSlide'),
      )
      if (slideData && slideData?.articleId) {
        statesST = ['checkSettingsSTF','loadBaseMap', 'toolBarSTF', 'leftBarSTF', 'timelineSTF', 'leftBarCatOpenSTF', 'articleModeSTF', 'compassSTF', 'loadDoneSTF']
      }

      if (slideData && slideData?.microArticleId) {
        statesST = ['loadBaseMap','checkSettingsSTF','microArticleSTF', 'toolBarSTF', 'checkSettingsSTF', 'leftBarSTF', 'timelineSTF', 'leftBarCatOpenSTF', 'compassSTF', 'loadDoneSTF']
        // statesST = ['microArticleSTF', 'loadBaseMap', 'loadDoneSTF']
      }

      timer = setTimeout(
        () => {
          if (firstLoadRef.current) {
            runScreenShotStates?.(statesST)

            firstLoadRef.current = false
            // setTimeout(()=>{
            //   firstLoadRef.current = true;
            // },1000)
          }
          if (isPlaying) runScreenShotStates?.(statesST)
        },

        firstLoadRef.current ? 400 : 0,
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [
    getSubjectsSettingsData,
    runScreenShotStates,
    globalLoading,
    firstLoad,
    map,
    slides,
  ])
  // useEffect(()=>{
  //     console.log("getSlidesSelectedSlideData", );
  //     if(!getSlidesSelectedSlideData?.id) return
  //     if(!isNaN(+query.get("screenSlide")) && (+query.get("screenSlide") === getSlidesSelectedSlideData?.id)) return;
  //     handleReplaceUrl(getSlidesSelectedSlideData?.id)
  // },[getSlidesSelectedSlideData])

  useEffect(() => {
    if (query.get('loading') === 'true') return
    const screenSlide = query.get('screenSlide')
    const isPlaying = searchParams.get('playBack') === 'true'
    // eslint-disable-next-line no-mixed-operators
    if (screenSlide !== null && screenSlide !== prevScreenSlide) {
      runSlide(+query.get('screenSlide'))
      setPrevScreenSlide(screenSlide) // Update the previous value
    }
  }, [searchParams, firstLoad])

  const handlePlayingMode = () => {
    if (!query.get('screenSlide') && slides.length) {
      // query.set("screenSlide", slides[0]?.id)
      handleReplaceUrl(slides[0], true)
      return
    }
    if (isPlaying) pauseCountdown()
    if (!isPlaying) playCountdown(true)
    setIsPlaying(!isPlaying)
    searchParams.set('playBack', !isPlaying)
    navigate(
      { search: searchParams.toString(), pathname: window.location.pathname },
      { replace: false },
    )
  }


  const startHandler = async (e) => {
    e.stopPropagation()
    // dispatch(setPlaybackPresentation(presentation))
    // dispatch(setCurrentSlide(''))
    let firstSlide;

    try {
      const slidesResponse = await getSlides({
        page: 1,
        perPage: 1000,
        columnFiltersMany: {
          presentationId: [String(presentation?.id)],
        },
        columnFilters: {
          isHidden: 'false',
        },
        sort: [
          {
            field: 'order',
            type: 'asc',
          },
        ],
      })?.unwrap()
      const slides = slidesResponse?.data?.[0].items
      firstSlide = slides.find((slide) => !slide.isDeleted)
    } catch (e) {
    }
    searchParams.set('presentation', presentation?.id)

    if (firstSlide?.articleId) {
      // search.set('screenShot', true)
      searchParams.set('articleMode', firstSlide?.articleId)
    } else {
      searchParams.delete('articleMode')
    }
    searchParams.set('screenShot', 'true')
    searchParams.set('loading', true)
    searchParams.set('playBack', true)
    searchParams.delete('open')

    if (playbackPresentation?.createdBy) {
      searchParams.set('userId', playbackPresentation.createdBy)
    } else {
      searchParams.delete('userId')
    }

    if (firstSlide) {
      searchParams.set('screenSlide', firstSlide?.id)
      dispatch(setCurrentSlide(firstSlide?.id))
      dispatch(setSelectedSlideData(firstSlide))
    }

    if (firstSlide?.microArticleId) {
      searchParams.set('microArticleMode', firstSlide?.microArticleId)
    } else {
      searchParams.delete('microArticleMode')
    }

    if (firstSlide?.mapStateId) {
      searchParams.set('screenShotId', firstSlide?.mapStateId)
    } else {
      searchParams.delete('screenShotId')
    }


    if (isIntroOpen === 'reload') {
      window.location.href =
          window.location.origin +
          window.location.pathname +
          '?' +
          searchParams.toString()
    }

    navigate({
        pathname: window.location.pathname,
        search: searchParams.toString()
    }, {replace: true})

    setIsIntroOpen(false)



    // navigate({
    //   pathname: window.location.pathname,
    //   search: search.toString(),
    // }, { replace: true })

    // onStart?.(presentation)
  }


  const handleMuteState = () => {
    setIsMuted(!isMuted)
  }

  const playBack = searchParams.get('playBack')

  useEffect(() => {
    let timer = null

    if (globalLoading) return

    const play = playBack === 'true'

    if (playBack !== undefined) {
      if (isPlaying === play) return

      timer = setTimeout(() => {
        if (!play) pauseCountdown()
        if (play) playCountdown(true)
        setIsPlaying(play)
      }, 1000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isPlaying, playBack, globalLoading])

  useEffect(() => {
    if (!!query.get('external')) {
      const storageTransitionDuration = {
        ...getLocalStorage('slideTransitionDuration'),
      }
      storageTransitionDuration[query.get('presentation')] = duration
      setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      return
    }
    if (slideTransitionDuration === duration) return
    debeounced300(() => handleEditDurationTime(duration))
  }, [duration])

  useEffect(() => {
    if (
      slideTransitionDuration === duration ||
      typeof +slideTransitionDuration !== 'number' ||
      isNaN(+slideTransitionDuration)
    )
      return

    if (!!query.get('external')) {
      let storageTransitionDuration = getLocalStorage('slideTransitionDuration')
      const presentationId = query.get('presentation')
      if (!storageTransitionDuration?.[query.get('presentation')]) {
        const newObj = {
          [presentationId]: slideTransitionDuration,
          ...storageTransitionDuration,
        }
        setLocalStorage('slideTransitionDuration', newObj)
        storageTransitionDuration = getLocalStorage('slideTransitionDuration')
      }
      if (storageTransitionDuration?.[query.get('presentation')] !== duration) {
        const storageTransitionDuration = getLocalStorage(
          'slideTransitionDuration',
        )
        storageTransitionDuration[query.get('presentation')] =
          duration || slideTransitionDuration
        setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      }
      if (
        ![0, 15, 30, 45].includes(
          storageTransitionDuration[query.get('presentation')],
        )
      ) {
        storageTransitionDuration[query.get('presentation')] =
          slideTransitionDuration
        setLocalStorage('slideTransitionDuration', storageTransitionDuration)
      }
      setDuration(() => storageTransitionDuration[query.get('presentation')])
      return
    }
    setDuration(() => slideTransitionDuration)
  }, [slideTransitionDuration])

  useEffect(() => {
    const handleKeyDown = (event) => {
      const activeElement = document.activeElement
      const isInputOrTextareaFocused =
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' || activeElement.classList.contains('ql-editor')

      if (event.key === ' ' && !isInputOrTextareaFocused && !screenShotFormIsOpen && !microArticleFormIsOpen) {
        event.preventDefault()
        handlePlayingMode()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handlePlayingMode, editIsOpen, screenShotFormIsOpen, microArticleFormIsOpen])

  useEffect(() => {
    if (slidesIsShow) {
      sessionStorage.setItem('slidesIsShow', 'true')
    } else {
      sessionStorage.removeItem('slidesIsShow')
    }
  }, [slidesIsShow])

  // useEffect(() => {
  //   if (presentationFromUrl) {
  //     dispatch(setScreenshotPresentation({ id: presentationFromUrl }))
  //     dispatch(setMicroArticlePresentation({ id: presentationFromUrl }))
  //   } else {
  //     dispatch(setScreenshotPresentation(null))
  //     dispatch(setMicroArticlePresentation(null))
  //   }
  // }, [presentationFromUrl])


  // useEffect(() => {
  //   const mapArticle = mapArticles?.data?.[0];
  //
  //   if (playbackPresentation && mapArticle && !slides.some((slide) => slide?.articleId === mapArticle?.id)) {
  //       dispatch(setIsMinimized(true))
  //   } else {
  //       dispatch(setIsMinimized(false))
  //   }
  // },[mapArticles,playbackPresentation, slides])

  if (!presentation) return null

  return (
    <div
      style={{
        top: isMinimized ? `calc(100vh - ${footerHeight + 60}px)` : '5px',
      }}
      className={cn(
        'playback-wrapper text-[#2C476C] transition-all duration-300 bg-white absolute top-[0px] left-[7rem] min-w-[140px] rounded-lg h-[42px] px-0',
        {
          'left-3 h-[47px]': isMinimized,
          'min-w-[350px]': !isMinimized,
          'hidden': isIntroOpen
        },
      )}>
      <div
        className={cn('flex items-center rounded-lg h-[42px] ', {
          'shadow-[0px_7px_26px_0px_rgba(44,71,108,0.1)]': slidesIsShow,
          'px-2  h-[47px]': isMinimized,
          'w-full': !isMinimized,
        })}>
        {!isMinimized && (
          <>
            <Button
              data-is-loading={globalLoading}
              onClick={() => setSlidesIsShow(!slidesIsShow)}
              className={cn('h-[42px] !w-[42px]', {
                'shadow-[0px_7px_26px_0px_rgba(44,71,108,0.1)]': slidesIsShow,
              })}
              icon={
                <MenuIcon color="#2C476C" className="playback-wrapper--icon" />
              }
              type="text"
            />
            <span
              data-is-loading={globalLoading}
              className="text-[14px] mx-4 flex-1 whitespace-nowrap line-clamp-1 max-w-[190px] overflow-hidden text-ellipsis"
              title={playbackPresentation?.title || ''}>
              {playbackPresentation?.title}
            </span>
          </>
        )}
        <div
          className={cn('flex items-center gap-2 mr-2', {
            'mr-0': isMinimized,
          })}>
          <span
            data-is-loading={globalLoading}
            className="cursor-pointer rounded-full flex items-center"
            onClick={handlePlayingMode}>
            {!isPlaying ? (
              <PlayIcon width={32} height={32} />
            ) : (
              <CirclePause strokeWidth={1} width={32} height={32} />
            )}
          </span>
          <div className="flex items-center gap-1">
            <span
              data-is-loading={globalLoading}
              className={cn('cursor-pointer flex items-center justify-center', {
                'text-gray-400 pointer-events-none opacity-40':
                  currentSlideNumber === 1,
              })}
              role={'button'}
              onClick={() => slideNavigation(-1)}>
              {' '}
              <ChevronLeft size={14} color="#2C476C" />
            </span>
            <span
              data-is-loading={globalLoading}
              className="text-[14px] whitespace-nowrap  flex items-center justify-center">
              {currentSlideNumber || '1'} / {slides?.length || ''}
            </span>
            <span
              data-is-loading={globalLoading}
              className={cn(
                'cursor-pointer  flex items-center justify-center',
                {
                  'text-gray-400 pointer-events-none opacity-40':
                    currentSlideNumber === slides?.length,
                },
              )}
              role={'button'}
              onClick={() => slideNavigation(1)}>
              <ChevronRight size={14} color="#2C476C" />
            </span>
          </div>
          {isMinimized ? (
            <Button
              data-is-loading={globalLoading}
              type="text"
              icon={<Maximize2 size={14} color="#2C476C" />}
              onClick={() => {
                dispatch(setIsMinimized(false))
                query.delete('minimize')
                navigate(
                  {
                    pathname: window.location.pathname,
                    search: query.toString(),
                  },
                  { replace: true },
                )
              }}
            />
          ) : (
            <Dropdown
              getPopupContainer={() => document.body}
              overlayClassName="app-dropdown w-[160px]"
              open={menuIsOpen}
              onOpenChange={openMenuHandler}
              placement="bottomLeft"
              menu={{
                items,
                onClick: clickDropdownHandler,
                selectedKeys: Object.values(settings).map((item) =>
                  String(item),
                ),
                onSelect: selectHandler,
              }}
              trigger={['click']}>
              <button
                data-is-loading={globalLoading}
                className="flex"
                onClick={(e) => e.stopPropagation()}>
                <DotsIcon width={13} height={13} />
              </button>
            </Dropdown>
          )}
        </div>
      </div>
      {slidesIsShow && !isMinimized && (
        <div className="bg-white top-0 left-0 w-full absolute pt-12 rounded-lg -z-10 overflow-hidden">
          <div className="overflow-y-auto max-h-[400px] pb-4 pl-4 pr-5">
            {slides.map((slide, index) => {
              if (slide.isHidden) return null
              return (
                  <PlaybackSlideItem
                      isLoading={globalLoading}
                      data={slide}
                      isActive={slide.id === Number(query.get('screenSlide'))}
                      key={index}
                      number={index + 1}
                      id={slide.id}
                      image={slide?.imageUrl}
                      articleId={slide?.articleId}
                      microArticleId={slide?.microArticleId}
                      name={slide.title}
                  />
              )
            })}
          </div>
        </div>
      )}
      <Modal
        destroyOnClose
        maskClosable={false}
        title={playbackPresentation?.title || ''}
        centered
        classNames={{
          body: 'max-h-[80vh] overflow-auto',
          mask: '!z-[1240]',
          header:
            'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-10',
        }}
        getContainer={() => document.body}
        className="app-modal no-padding max-h-[90vh]"
        width={950}
        wrapClassName="!z-[1240]"
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsEditMode(false)}
        open={editIsOpen}>
        <PresentationFormWrapper
          id={playbackPresentation?.id}
          onSuccess={afterEditSuccess}
        />
      </Modal>
      <Modal
          destroyOnClose
          centered
          closable={!globalLoading}
          maskClosable={false}
          classNames={{
            body: 'max-h-[82vh] overflow-auto',
            content:"[&>.ant-modal-close]:bg-[#f4f4f4]",
            header:
                'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-10',
          }}
          getContainer={() => document.body}
          className="app-modal no-padding max-h-[80vh]"
          width={700}
          footer={false}
          style={{ padding: 0 }}
          onCancel={() => setIsIntroOpen(false)}
          open={isIntroOpen && presentation}>
        <PresentationIntro id={presentation?.id} loading={globalLoading} onStart={startHandler} />
      </Modal>
    </div>
  )
}

export default PlaybackWrapper
