import React, { useRef } from 'react'
import Collapsible from 'react-collapsible'
import withRedux from '../../mapper'

import Flag from '../../../UI/Flag'
import Text from '../../../UI/Text'
import Eye from '../../../UI/Eye'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'
import {useTranslation} from "react-i18next";

const legendButtonStyle = (item, selectedCategoryId) => ({
  backgroundColor: selectedCategoryId === item.id ? item?.color : 'transparent',
})

const mainSubitemStyle = (item, index, legendMainBtnRef, calculateHeight) => ({
  height: 'fit-content',
  minHeight: '100px',
  maxHeight:
    window.innerWidth >= 900 && window.innerWidth <= 1800
      ? `${item.height || calculateHeight(legendMainBtnRef.current)}px`
      : `calc(100vh - 293px - ${index * 65}px)`,
})

const mainSubitemWrapperStyle = (
  item,
  index,
  legendMainBtnRef,
  calculateHeight,
  browserInfo,
) => ({
  flexDirection: 'column',
  height: 'fit-content',
  maxHeight:
    window.innerWidth >= 900 && window.innerWidth <= 1800
      ? `${
          (item.height || calculateHeight(legendMainBtnRef.current)) - 70 > 0
            ? (item.height || calculateHeight(legendMainBtnRef.current)) - 70
            : 0
        }px`
      : browserInfo.isSafari && window.innerWidth > 1800
        ? `calc(100dvh - 657px - ${index * 65}px)`
        : `calc(100dvh - 357px - ${index * 65}px)`,
})

const legendHeaderSpanStyle = (item) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  '--data-color': item.color,
})

const subLayerItemStyle = (item, subLayer) => ({
  '--data-color': item.color,
  opacity: subLayer.show ? 1 : 0.5,
})

const LegendTab = ({
  item,
  selectedCategoryId,
  globalLoading,
  index,
  categoryModalRefs,
  openCategory,
  setMapRequirement,
  setHints,
  setQuizPopup,
  calculateHeight,
  toggleLegendsFromMapByID,
  sortBoard,
  toggleSorting,
  filterAction,
  browserInfo,
  filteredLegends,
  toggleCollapsible,
}) => {
  const legendMainBtnRef = useRef()
  const {t} = useTranslation()

  const handleMainButtonClick = (e) => {
    openCategory(item.id)
    setMapRequirement(false)
    setHints(false)
    setQuizPopup(false)
    item.height = calculateHeight(e.target)
  }

  const renderLegendHeader = () => (
    <div className="main__subitem-heading" data-color={item.color}>
      <span style={legendHeaderSpanStyle(item)} />
      <h2 className="main__subitem-title">{item.title}</h2>
      {renderLegendCheckboxes()}
      {renderSortBoard()}
    </div>
  )

  const renderLegendCheckboxes = () => (
    <>
      <label className="main__subitem-text-label">
        <input
          className="main__subitem-text-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              item.id,
              item.key,
              null,
              null,
              'all-layers',
              'text',
            )
          }
          checked={item.textShow}
          disabled={!item.show}
        />
        <Text show={item.textShow} disable={!item.show} />
      </label>
      <label className="main__subitem-label">
        <input
          className="main__subitem-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              item.id,
              item.key,
              null,
              null,
              'all-layers',
            )
          }
          checked={item.show}
        />
        <Eye show={item.show} />
      </label>
    </>
  )

  const renderSortBoard = () => (
    <div
      className="sort-board category_sorted_tooltip"
      id="category_sorted_tooltip"
      data-active={sortBoard}>
      <span className="sort-title" style={{ textTransform: 'uppercase' }}>
        {t('sortBy.title')}
      </span>
      {renderSortOptions()}
    </div>
  )

  const renderSortOptions = () => (
    <>
      <div onClick={() => toggleSorting(false, 'chronologically')}>
        <input
          name={`sort${item.id}`}
          type="radio"
          id={`chronologically${item.id}`}
        />
        <label htmlFor={`chronologically${item.id}`}>{t('sortBy.timeRange')}</label>
      </div>
      <div onClick={() => toggleSorting(false, 'alphabetically')}>
        <input
          checked={filterAction === 'alphabetically'}
          name={`sort${item.id}`}
          type="radio"
          id={`alphabetically${item.id}`}
        />
        <label htmlFor={`alphabetically${item.id}`}>{t('sortBy.alphabetically')}</label>
      </div>
    </>
  )

  const renderCollapsibleLayer = (layer) => (
    <Collapsible
      onTriggerOpening={toggleCollapsible(layer.key, true)}
      onTriggerClosing={toggleCollapsible(layer.key, false)}
      key={layer.id + 'collapse'}
      trigger={
        <>
          <span>{layer.title}</span>
          <span className="collapseIcon" />
        </>
      }
      triggerSibling={renderLayerCheckboxes(layer)}
      open={!filteredLegends.checkIsClose(layer.key)}>
      {renderSubLayerItems(layer)}
    </Collapsible>
  )

  const renderLayerCheckboxes = (layer) => (
    <div>
      <label
        className="main__subitem-text-label legend-layar-text"
        key={`b-${layer.id}`}
        htmlFor={`input-text-${layer.id}`}>
        <input
          id={`input-text-${layer.id}`}
          className="main__subitem-text-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              item.id,
              item.key,
              layer.id,
              layer.key,
              'layer',
              'text',
            )
          }
          checked={layer.textShow}
          disabled={!layer.show}
        />
        <Text show={layer.textShow} disable={!layer.show} />
      </label>
      <label
        className="main__subitem-label legend-layar-eye"
        key={`c-${layer.id}`}
        htmlFor={`input-${layer.id}`}>
        <input
          id={`input-${layer.id}`}
          className="main__subitem-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              item.id,
              item.key,
              layer.id,
              layer.key,
              'layer',
            )
          }
          checked={layer.show}
          disabled={layer.isDisabled}
        />
        <Eye show={layer.show} disable={layer.isDisabled} />
      </label>
    </div>
  )

  const renderSubLayerItems = (layer) =>
    layer.children.map((subLayer) => {
      if (
        filteredLegends?.findById(subLayer.key) ||
        filteredLegends?.checkIsHideItem?.(subLayer.key) ||
        (filteredLegends?.checkItemIsDisabled?.(subLayer.key) &&
          filteredLegends?.checkIsHideItemText?.(subLayer.key))
      ) {
        return (
          <li
            key={`cat_${item.id}_${layer.id}_${subLayer.id}`}
            className="main__subitem-li">
            <div style={subLayerItemStyle(subLayer, layer)}>
              <p className="with-svg">
                <ConnectedSpriteSvgComponent name={subLayer?.iconPath} />
              </p>
              <div className="main__subitem-libtn">{subLayer.title}</div>
            </div>
            {renderSubLayerCheckboxes(layer, subLayer)}
          </li>
        )
      }
      return null
    })

  const renderSubLayerCheckboxes = (layer, subLayer) => (
    <>
      <label
        id={`category_text_filters_icon_${subLayer.id}`}
        className="main__subitem-label item"
        data-show={subLayer.show}>
        <input
          id={`input-text-${layer.id}`}
          className="main__subitem-text-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              layer.id,
              layer.key,
              subLayer.id,
              subLayer.title,
              'sub-layer',
              'text',
            )
          }
          checked={subLayer.textShow}
          disabled={!subLayer.show}
        />
        <Text show={subLayer.textShow} disable={!subLayer.show} />
      </label>
      <label
        id={`category_filters_icon_${subLayer.id}`}
        className={`main__subitem-label category-filters category_filters_icon ${
          subLayer.isDisabled ? 'inActive' : ''
        }`}
        data-show={subLayer.show}>
        <input
          className="main__subitem-checkbox show-checkbox"
          type="checkbox"
          onChange={() =>
            toggleLegendsFromMapByID(
              layer.id,
              layer.key,
              subLayer.id,
              subLayer.title,
              'sub-layer',
            )
          }
          checked={subLayer.show}
          disabled={subLayer.isDisabled}
        />
        <Eye show={subLayer.show} disable={subLayer.isDisabled} />
      </label>
    </>
  )

  return (
    <li
      key={item.id + 'legend'}
      className={`main__tab left_bar_tab persons legend ${selectedCategoryId === item.id ? 'active' : ''}`}
      data-is-loading={globalLoading}>
      <button
        ref={legendMainBtnRef}
        className="main__tab-btn"
        type="button"
        style={legendButtonStyle(item, selectedCategoryId)}
        onClick={handleMainButtonClick}>
        <span className="main__tab-icon icon-wrapper legend-icon">
          <Flag />
        </span>
      </button>

      <div
        ref={categoryModalRefs[index]}
        className="main__subitem"
        style={mainSubitemStyle(
          item,
          index,
          legendMainBtnRef,
          calculateHeight,
        )}>
        {selectedCategoryId === item.id && renderLegendHeader()}
        <div
          className="main__subitem-itemswrapper"
          style={mainSubitemWrapperStyle(
            item,
            index,
            legendMainBtnRef,
            calculateHeight,
            browserInfo,
          )}>
          {item.children.length > 0 ? (
            item.children.map(
              (layer) =>
                (filteredLegends?.findById(layer.key) ||
                  filteredLegends?.checkIsHideItem?.(layer.key) ||
                  filteredLegends?.checkIsHideAnyItem?.(layer.key) ||
                  filteredLegends?.checkIsTextHideAnyItem?.(layer.key)) &&
                renderCollapsibleLayer(layer),
            )
          ) : (
            <div className="empty-items">{t('nothingFound')}</div>
          )}
        </div>
      </div>
    </li>
  )
}

export default withRedux(LegendTab)
