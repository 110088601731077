import * as React from 'react'

const CloseIcon = (props) => (
  <svg
    width={15}
    height={15}
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M12.416 11.584L35.5777 34.7456"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4122 34.748L35.5801 11.5801"
      stroke="#2C476C"
      strokeWidth={3.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default CloseIcon
