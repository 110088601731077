import { useEffect, useState } from 'react'
import Video from '../../videoPlayer/VideoPlayer'

const VideoSlide = (props) => {
  const { convertToNoCookie, currentIndex, slide } = props
  const [loading, setLoading] = useState(false)
  const videoId = slide?.path.split('/').reverse()[0].trim()
  const thumbnail = `https://img.youtube.com/vi/${videoId}/0.jpg`

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 100)
  }, [currentIndex])

  if (loading) return null

  return (
    <Video id={slide.id} url={convertToNoCookie(slide?.path)} src={thumbnail} />
  )
}

export default VideoSlide
