//generate circle outline plus icon component

import React from 'react'

const PinIcon = () => {
  return (
    <svg
      className="pin-svg-icon"
      width="15"
      height="15"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5002 24.0618C18.4129 24.0618 15.9102 21.5591 15.9102 18.4718C15.9102 15.3846 18.4129 12.8818 21.5002 12.8818C24.5874 12.8818 27.0902 15.3846 27.0902 18.4718C27.0902 21.5591 24.5874 24.0618 21.5002 24.0618Z"
        stroke="#2C476C"
        strokeWidth="3.3"
      />
      <path
        d="M36.5141 15.2293C38.5745 24.331 32.9128 32.0352 27.9499 36.801C24.3486 40.2768 18.6511 40.2768 15.032 36.801C10.087 32.0352 4.4253 24.3131 6.48572 15.2114C10.0153 -0.304417 33.0024 -0.2865 36.5141 15.2293Z"
        stroke="#2C476C"
        strokeWidth="3.3"
      />
    </svg>
  )
}

export default PinIcon
