import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import middleWares from "./middleWares/index";
import { aboutReducer } from "./reducers/about";
import { articlesReducer } from "./reducers/articles";
import { articlesAllReducer } from "./reducers/articlesAll";
import { authReducer } from "./reducers/auth";
import {
  categoriesReducer,
  filteredCategoriesReducer,
} from "./reducers/categories";
import { compassReducer } from "./reducers/CompassReducer";
import { countriesReducer } from "./reducers/countries";
import { timeGeoJSONReducer } from "./reducers/geoData";
import { filteredGradesReducer, gradesReducer } from "./reducers/grades";
import {
  handleNextLessonIdReducer,
  handlePrevLessonIdReducer,
  handleSelectLessonReducer,
} from "./reducers/handleLessonId";
import { hintsReducer } from "./reducers/hints";
import { lampReducer } from "./reducers/lamp";
import { lessonsReducer } from "./reducers/lessons";
import { mapReducer } from "./reducers/map";
import { mapRequirementReducer } from "./reducers/mapRequirement";
import { mapRequirementsReducer } from "./reducers/mapRequirements";
import { mapStateReducer } from "./reducers/mapStateReducer";
import { mapStyleReducer } from "./reducers/mapStyle";
import { mapStylesReducer } from "./reducers/mapStyles";
import { menuReducer } from "./reducers/menu";
import { menuExpendReducer } from "./reducers/menuExpend";
import { painterGeojsonReducer } from "./reducers/painterGeojson";
import { painterStartReducer } from "./reducers/painterStart";
import { quizReducer } from "./reducers/quiz";
import { quizPopupReducer } from "./reducers/quizPopup";
import { regionsReducer } from "./reducers/regions";
import { resetReducer } from "./reducers/reset";
import { schoolsReducer } from "./reducers/schools";
import { searchReducer } from "./reducers/search";
import { statesReducer } from "./reducers/states";
import { filteredSubjectsReducer, subjectsReducer } from "./reducers/subjects";
import {
  handleTimeLineGroupsReducer,
  handleTimeLineItemsReducer,
} from "./reducers/timeLine";
import { timeLineExpendReducer } from "./reducers/timeLineExpend";
import { timeLineOptionsReducer } from "./reducers/timeLineOptions";
import { topicsReducer } from "./reducers/topics";
import { userReducer } from "./reducers/user";
import { bounceReducer, zoomReducer } from "./reducers/zoom";
import { audioReducer } from "./reducers/audio";
import {bookmarksReducer} from "./reducers/bookmarks";
import {legendsReducer} from "./reducers/legends";
import {spriteReducer} from "./reducers/sprite";
import {presentationModeReducer} from "./reducers/presentationMode";
import {presentationReducer} from "./reducers/presentation";
import {slidesManipulation, slidesReducer} from "./reducers/slides";
import {shapeToolReducer} from "./reducers/shapeToolReducer";
import {microArticleReducer} from "./reducers/microArticle";
import presentationSlice from "../components/presentation/store/presentationSlice";
import {identityStoreApi} from "./api/identityStoreApi";
import {indexingStoreApi} from "./api/indexingStoreApi";
import {articleStoreApi} from "./api/articleStoreApi";
import {mediaContentStoreApi} from "./api/mediaContentStoreApi";

const getInitialState = () => {};

export const storeReducers = {
  user: userReducer,
  categories: categoriesReducer,
  filteredCategories: filteredCategoriesReducer,
  topics: topicsReducer,
  lessons: lessonsReducer,
  subjects: subjectsReducer,
  filteredSubjects: filteredSubjectsReducer,
  grades: gradesReducer,
  filteredGrades: filteredGradesReducer,
  schools: schoolsReducer,
  countries: countriesReducer,
  regions: regionsReducer,
  states: statesReducer,
  auth: authReducer,
  menu: menuReducer,
  presentation: presentationReducer,
  presentationMode: presentationModeReducer,
  slides: slidesReducer,
  slidesManipulation: slidesManipulation,
  search: searchReducer,
  mapStyle: mapStyleReducer,
  hints: hintsReducer,
  quizPopup: quizPopupReducer,
  mapRequirement: mapRequirementReducer,
  about: aboutReducer,
  sprite: spriteReducer,
  menuExpend: menuExpendReducer,
  timeLineExpend: timeLineExpendReducer,
  mapStyles: mapStylesReducer,
  mapRequirements: mapRequirementsReducer,
  articles: articlesReducer,
  selectedArticle: articlesReducer,
  articlesAll: articlesAllReducer,
  bookmarks: bookmarksReducer,
  selectedArticleAll: articlesAllReducer,
  lampArticles: lampReducer,
  quiz: quizReducer,
  mapZoom: zoomReducer,
  timeLineOptions: timeLineOptionsReducer,
  prevLessonId: handlePrevLessonIdReducer,
  nextLessonId: handleNextLessonIdReducer,
  timeLineItems: handleTimeLineItemsReducer,
  timeLineGroups: handleTimeLineGroupsReducer,
  selectedLesson: handleSelectLessonReducer,
  painterStart: painterStartReducer,
  painterGeoJson: painterGeojsonReducer,
  mapBounce: bounceReducer,
  reset: resetReducer,
  timeGeoJSON: timeGeoJSONReducer,
  map: mapReducer,
  mapState: mapStateReducer,
  compass: compassReducer,
  audio: audioReducer,
  legends: legendsReducer,
  shapes:shapeToolReducer,
  microArticle: microArticleReducer,


  presentations: presentationSlice,
  [identityStoreApi.reducerPath]: identityStoreApi.reducer,
  [indexingStoreApi.reducerPath]: indexingStoreApi.reducer,
  [articleStoreApi.reducerPath]: articleStoreApi.reducer,
  [mediaContentStoreApi.reducerPath]: mediaContentStoreApi.reducer

}

//export const storeV2 = configureStore({
//     reducer: reducers,
//     devTools: process.env.NODE_ENV !== 'production',
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware().concat([
//             identityStoreApi.middleware,
//             indexingStoreApi.middleware,
//             articleStoreApi.middleware,
//             mediaContentStoreApi.middleware
//         ]),
// });
//
// setupListeners(storeV2.dispatch);

export const rootReducer = combineReducers(storeReducers);



const configureStore = (initialState) => {
  const store = createStore(
    rootReducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? compose(
          applyMiddleware(thunk, ...middleWares),
          window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      : compose(applyMiddleware(thunk, ...middleWares))
  );

  return store;
};
export const store = configureStore(getInitialState());
