//generate circle outline plus icon component

import React from 'react'

const ScreenshotIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9502 2H34.2502C40.7001 2 45.0001 6.29997 45.0001 12.7499V17.0499"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1118 23.8306C31.1118 27.8516 27.8521 31.1113 23.8311 31.1113C19.8101 31.1113 16.5504 27.8516 16.5504 23.8306C16.5504 19.8096 19.8101 16.5499 23.8311 16.5499C27.8521 16.5499 31.1118 19.8096 31.1118 23.8306Z"
        stroke="#2C476C"
        strokeWidth="3.3"
      />
      <path
        d="M2 17.0499V12.7499C2 6.29997 6.29997 2 12.7499 2H17.0499"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9502 45.0001H34.2502C40.7001 45.0001 45.0001 40.7001 45.0001 34.2502V29.9502"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 29.9497V34.2497C2 40.6996 6.29997 44.9996 12.7499 44.9996H17.0499"
        stroke="#2C476C"
        strokeWidth="3.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ScreenshotIcon
