import * as React from 'react'

const NotPatumIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.00391 12.7549V10.0249C6.00391 8.29992 7.39891 6.91992 9.10891 6.91992H30.8889C32.6139 6.91992 33.9939 8.31492 33.9939 10.0249V12.7549"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 33V26"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 20V8"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0898 33.0801H25.9098"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.01}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 38L2 2L38 2L38 38L2 38Z"
      stroke="#2C476C"
      strokeWidth={2.5}
    />
    <path
      d="M27 13L13 27"
      stroke="#2C476C"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default NotPatumIcon
