import React from 'react'

const DotsIcon = (props) => {
  return (
    <svg
      className="dots-svg-icon"
      width="6"
      height="20"
      viewBox="0 0 10 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.70703 4H5.18508"
        stroke="#2C476C"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M4.70703 20H5.18508"
        stroke="#2C476C"
        strokeWidth="8"
        strokeLinecap="round"
      />
      <path
        d="M4.70703 36H5.18508"
        stroke="#2C476C"
        strokeWidth="8"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default DotsIcon
