import { connect } from "react-redux";
import "./style.css";
import DynamicTitle from "../../CustomCardComponents/DynamicTitle";
import Line from "../../CustomCardComponents/Line";
import CardBody from "../../CustomCardComponents/CardBody";
import CloseDark from "../../CloseDark";
import CopyShareLink from "../CopyShareLink";
import CloseBtn from "../../CustomCardComponents/CloseBtn";
import {
  getScreenShotDataFromAPI,
  removeScreenShotCardAPI,
} from "../../../../store/actions/mapStateAction";
import { useEffect, useState } from "react";
import { getRemoveCardId } from "../../../../store/selectors";
import {useTranslation} from "react-i18next";
import {hasReference} from "../../../../service/mapStateService";
const cardBodyStyle = {
  aspectRatio: "590/255",
  gridTemplateRows: "1fr 1fr",
  padding: "20px",
};
const DynamicScreenShotRemove = (props) => {
  const { onClose, id, userId, removeScreenShotCardAPI } = props;
  const {t} = useTranslation()
  const [isReferencePresent, setIsReferencePresent] = useState(false)

  const queryParams = new URLSearchParams(window.location.search);
  let pageLoc = +queryParams.get("page");

  const [state, setState] = useState(false);

  const onCardRemove = () => {
    setState(true);
    removeScreenShotCardAPI(pageLoc, id, userId);
    setTimeout(() => {
      onClose()
    }, 2000);
  };

  const onContinue = () => {
    setIsReferencePresent(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (state) {
        props.getScreenShotDataFromAPI(pageLoc, userId, onClose, false);
        setState(false);
      }
    }, 1000);
  }, [props.getRemoveCardId, state, pageLoc]);

  useEffect(() => {
    hasReference(id).then((res) => {
      setIsReferencePresent(res?.data?.data?.[0])
    })
  }, [id])

  return (
    <div className="card-body__dynamic-screen-shot-share-content dynamic-screen-shot-share-content">
      <CardBody hasIcon={false} styles={cardBodyStyle}>
        <div className={"dynamic-screen-shot-share-content__header"}>
          <CloseBtn onClose={onClose}/>
          <div className="card-body__dynamic-title dynamic-title remove_screenShot_card_body__dynamic-title">
            <h4>{t('deletePopups.delete')}</h4>
          </div>
          <p className="remove_screenShot_card_body_dynamic_sub_title">
            {isReferencePresent ? t('deletePopups.savedMapPresentation') : t('deletePopups.savedMap')}
          </p>
          <div className="screenshot_zone">
            <button
                className={`${isReferencePresent ? 'screenshot_remove' : 'screenshot_cancel'} screenshot_buttons`}
                onClick={onClose}
            >
              {t('cancel')}
            </button>
            <button
                className={`${isReferencePresent ? 'screenshot_continue' : 'screenshot_remove'} screenshot_buttons`}
                onClick={isReferencePresent ? onContinue : onCardRemove}
            >
              {isReferencePresent ? t('deletePopups.continue') : t('delete')}
            </button>
          </div>
        </div>
      </CardBody>
    </div>
  );
};

const mapStateTopProps = (state) => ({
  getRemoveCardId: getRemoveCardId(state),
});

const mapDispatchToProps = {
  removeScreenShotCardAPI: removeScreenShotCardAPI,
  getScreenShotDataFromAPI,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(DynamicScreenShotRemove);
