import React, { useCallback, useRef } from 'react'
import withRedux from '../../mapper'
import LampItem from './LampItem'

import EyeLamp from '../../../UI/EyeLamp'

import { filterLamp } from '../../utils/filtring'

import LampActive from '../../../../assets/imgs/lamp_icon_article.svg'
import LampSortedSelectIcon from '../../../../assets/imgs/checked-icon.svg'
import SortWhite from '../../../../assets/imgs/sorting-white.svg'
import SortBlue from '../../../../assets/imgs/sorting-blue.svg'
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const LampTab = ({
  hints,
  getLampSortedTooltipState,
  setLampSortedTooltipState,
  sortedTooltipSelectItem,
  onChangeSortedItem,
  toggleSorting,
  globalLoading,
  lampData,
  setHints,
  setQuizPopup,
  setSelectedCategoryId,
  setOpenCategoryID,
  timelineExtend,
  setFilterAction,
  setSortedTooltipSelectItem,
  browserInfo,
  setLampEyeIconState,
  setLampDataState,
  lampEyeIconState,
  lampDataState,
  sortBoard,
  getCategories,
  getArticleFromAPI,
  stopPinAnimation,
  changeLampDataShow,
  toggleItemsFromMap,
  getLampSelectedId,
  setLampSelectedId,
  filterAction,
}) => {
  const lampModalRef = useRef()
  const lampScrollZone = useRef()
  const {t} = useTranslation()

  const lampRend = useCallback(
    (item) => filterLamp(filterAction, item),
    [filterAction],
  )

  const toggleLampPinsVisibility = (newEyeIconState) => {
    const lampPins = document.getElementsByClassName('lamp_pin')
    Array.from(lampPins).forEach((lampPin) => {
      lampPin.classList.toggle('hide_lamp_article', newEyeIconState)
      lampPin.classList.toggle('show_lamp_article', !newEyeIconState)
    })
  }

  const handleLampHeaderEyeClick = () => {
    const newEyeIconState = !lampEyeIconState
    setLampEyeIconState(newEyeIconState)
    toggleLampPinsVisibility(newEyeIconState)

    setLampDataState(
      lampDataState.map((item) => {
        const subcategory = item?.subcategory.find((el) => el.isMain)
        item.isHide = newEyeIconState
        toggleItemsFromMap(
          subcategory?.categoryId,
          subcategory?.subCategoryId,
          'lamp',
          item.id,
          item.elementIds,
          null,
          !newEyeIconState,
        )
        return item
      }),
    )
  }

  const handleSortedTooltipSelect = (e, index, type) => {
    onChangeSortedItem(e, index)
    toggleSorting(false, type)
  }

  const handleHintButtonClick = () => {
    if (lampData.length > 0) {
      setHints(!hints)
      setQuizPopup(false)
      setSelectedCategoryId(null)
      setOpenCategoryID(null)

      if (timelineExtend) {
        setFilterAction('alphabetically')
        setSortedTooltipSelectItem(1)
      }
    }
  }

  const getModalMaxHeight = () => {
    if (window.innerHeight >= 320 && window.innerHeight <= 800) {
      return browserInfo.isSafari && window.innerWidth < 1800
        ? `calc(100dvh - 820px)`
        : `calc(100dvh - 520px)`
    }
    return lampModalRef?.current?.getBoundingClientRect().y + 300 + 'px'
  }

  return (
    <div className={`left-side__hints left_bar_tab hints ${hints ? 'active' : ''}`}>
      {getLampSortedTooltipState && (
        <div className="sorted_tooltip">
          <div className="sorted_tooltip_arrow"></div>
          <p className="sorted_tooltip_title">{t('sortBy.title')}</p>
          {['chronologically', 'alphabetically'].map((type, index) => (
            <div
              key={type}
              className={`sorted_tooltip_content ${
                sortedTooltipSelectItem === index
                  ? 'sorted_tooltip_content_active'
                  : ''
              }`}
              onClick={(e) => handleSortedTooltipSelect(e, index, type)}>
              <div>
                <img src={LampSortedSelectIcon} width="7.5px" alt="icon" />
              </div>
              <span>
                {type === 'chronologically'
                  ? t('sortBy.timeRange')
                  : t('sortBy.alphabetically')
                }
              </span>
            </div>
          ))}
        </div>
      )}
      <button
        className="hints__btn main__tab hints__btn_lamp"
        style={{ backgroundColor: hints ? '#ffb301' : '#fff' }}
        type="button"
        data-is-loading={globalLoading}
        onClick={handleHintButtonClick}>
        <img src={LampActive} alt="icon" />
      </button>
      <div
        ref={lampModalRef}
        className="hints__content"
        style={{ height: 'fit-content', maxHeight: `max(60px,${getModalMaxHeight()})`}}>
        <div className="hints__content_item">
          <div className="hints__content_title">{t('lamp')}</div>
          <div className="hints__content_buttons">
            <div
              className="hints__content_buttons_sort lamp_header_icon"
              onClick={handleLampHeaderEyeClick}>
              <EyeLamp className="eye_lamp_icon" show={!lampEyeIconState} />
            </div>
            <div
              className="hints__content_buttons_eye"
              onClick={() => {
                setLampSortedTooltipState(!getLampSortedTooltipState)
                toggleSorting(!sortBoard)
              }}>
              <img
                srcSet={!getLampSortedTooltipState ? SortWhite : SortBlue}
                alt="icon"
              />
            </div>
          </div>
        </div>
        <div className="hints__content_itemswrapper hints__content_itemswrapper_lamp"
             style={{maxHeight: `calc(${getModalMaxHeight()} - 60px)`}}
        >
          <ul
            ref={lampScrollZone}
            className="main__subitem-items custom-scroll-v3 main__subitem-items_lamp"
            id="lamp_scroll_zone"
            style={{minHeight: 100, maxHeight: 300}}
          >
            {getCategories.length > 0 &&
              lampData.length > 0 &&
              lampRend(lampDataState).map((item) => {
                const mainLampSubcategory = item?.subcategory?.find(
                  (el) => el.isMain,
                )
                const subCategories = getCategories
                  .filter((category) => category.subCategories)
                  .flatMap((category) => category.subCategories)

                const subCategoryData = subCategories.find(
                  (subCategory) =>
                    subCategory.id === mainLampSubcategory?.subCategoryId,
                )

                return (
                  <LampItem
                    key={item.id}
                    lampEyeIconState={lampEyeIconState}
                    data={item}
                    iconPath={subCategoryData?.iconPath}
                    getArticleFromAPI={(arg) => {
                      getArticleFromAPI(arg)
                      stopPinAnimation()
                    }}
                    changeLampDataShow={changeLampDataShow}
                    mainlampSubcategory={mainLampSubcategory}
                    subCategoryData={subCategoryData}
                    toggleItemsFromMap={toggleItemsFromMap}
                    getLampSelectedId={getLampSelectedId}
                    setLampSelectedId={setLampSelectedId}
                  />
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default withRedux(LampTab)
