import { SET_HINTS } from "../constants";
import { setSearchData } from "./search";
import { setMenuData } from "./menu";
import { setMapStyleData } from "./mapStyle";
import { setMapRequirementData } from "./mapRequirement";

export const setHintsData = (bool) => ({
  type: SET_HINTS,
  payload: bool,
});

export const setHints = (bool) => (dispatch) => {
  dispatch(setSearchData(false));
  dispatch(setMenuData(false));
  dispatch(setMapStyleData(false));
  dispatch(setMapRequirementData(false))
  dispatch(setHintsData(bool));
};
