import React from 'react'
import styles from './PopupDetail.module.css'
import {
  Alert,
  Button,
  Card,
  Carousel,
  Skeleton,
  Space,
  Typography,
} from 'antd'
import CollapseIn from '../../../../assets/imgs/MicroArticleIcons/collapse_in_icon.svg'
import CloseIcon from '../../../../assets/imgs/MicroArticleIcons/close_icon.svg'
import CollapseOut from '../../../../assets/imgs/MicroArticleIcons/collapse_out_icon.svg'
import MediaRenderer from '../MediaRenderer'
import Dots from '../Dots'
import IconButton from '../../UI/IconButton'
import PresentationIcon from '../../../../assets/imgs/MicroArticleIcons/presentation_icon.svg'
import EditIcon from '../../../../assets/imgs/MicroArticleIcons/edit_icon.svg'
import Portal from '../Portal'
import Title from 'antd/lib/typography/Title'
import slides from "../../../gallery-new/components/Slides";

const CardDesktopVersion = ({
  isOwner,
  isMaximized,
  getMicroArticleData,
  getIsSingleMicroArticleLoading,
  microArticleOwner,
  togglePopupSize,
  setMicroArticleFirstImageIdx,
  onClose,
  microArticleFirstImageIdx,
  loading,
  error,
  carouselContainerRef,
  handleMouseDown,
  handleTouchStart,
  carouselRef,
  handleAfterChange,
  ignoreClickRef,
  handleOpenGallery,
  currentSlide,
  handleDotClickOrSwipe,
  getPopupDetailFontSize,
  increaseFontSize,
  decreaseFontSize,
  setPresentationsIsOpen,
  changeToEditMode,
}) => {
  return (
    <Portal containerSelector=".main" className={[styles.portal, 'fixed', 'top-[52px]', 'right-0', '!z-[99999999999]']}>
      <Card
        className={isMaximized ? styles.card : styles.cardMinimized}
        styles={{
          body: { padding: '0' },
          header: {
            padding: !isMaximized ? '16px' : '9px 0 0 0',
            borderBottom: 'none',
            width: '100%',
          },
        }}
        title={
          isMaximized ? (
            <div className="headerSkeletonWrapper">
              {getIsSingleMicroArticleLoading ? (
                <div className="headerSkeleton">
                  <Skeleton.Avatar
                    className="headerSkeletonInitials"
                    active
                    shape="square"
                    size={30}
                  />
                  <Skeleton.Input active style={{ width: 200 }} />
                </div>
              ) : (
                <div className={styles.header}>
                  <div className={styles.titleContainer}>
                    <div
                      style={{
                        minHeight: 'max-content',
                        margin: '.8em 0 .5em 0',
                      }}
                    />
                    <Title level={4} className={styles.title}>
                      <div
                        className={styles.titleText}
                        style={{
                          fontSize:
                            getMicroArticleData &&
                            getMicroArticleData?.title &&
                            getMicroArticleData?.title.length > 60
                              ? '18px'
                              : '20px',
                        }}>
                        <span
                          className={styles.nameInitials}
                          style={
                            {
                              // background: getMicroArticleData?.iconBackgroundColor,
                              // color: getContrastColor(getMicroArticleData?.iconBackgroundColor),
                            }
                          }>
                          {microArticleOwner.id
                            ? `${microArticleOwner.firstName[0].toUpperCase()} ${microArticleOwner.lastName[0].toUpperCase()}`
                            : ''}
                        </span>
                        <span style={{ color: '#2C476C' }}>
                          {getMicroArticleData
                            ? getMicroArticleData?.title?.toUpperCase()
                            : ''}
                        </span>
                      </div>
                    </Title>
                  </div>
                </div>
              )}
              <div className={styles.buttons}>
                <Button
                  type="text"
                  icon={<img className={styles.customIcon} src={CollapseIn} />}
                  onClick={togglePopupSize}
                  className={styles.toggleButton}
                />
                <Button
                  type="text"
                  icon={
                    <img
                      style={{ width: '24px', height: '24px' }}
                      src={CloseIcon}
                    />
                  }
                  onClick={() => {
                    setMicroArticleFirstImageIdx(null)
                    onClose()
                  }}
                  className={styles.closeButton}
                />
              </div>
            </div>
          ) : (
            <div className={styles.minimizedHeader}>
              <div className={styles.minimizedImageWrapper}>
                {microArticleFirstImageIdx !== null &&
                getMicroArticleData?.media?.length ? (
                  <img
                    src={
                      getMicroArticleData?.media[microArticleFirstImageIdx].path
                    }
                    alt={
                      getMicroArticleData?.media[microArticleFirstImageIdx]
                        .caption
                    }
                    className={styles.minimizedImage}
                  />
                ) : null}
                <div
                  className={styles.minimizedTitleWrapper}
                  style={{
                    maxWidth: microArticleFirstImageIdx
                      ? 'calc(100% - 126px)'
                      : '100%',
                    marginLeft: microArticleFirstImageIdx ? 16 : 0,
                  }}>
                  <Typography.Text className={styles.minimizedTitle}>
                    {getMicroArticleData
                      ? getMicroArticleData?.title?.toUpperCase()
                      : ''}
                  </Typography.Text>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  flexGrow: 1,
                  maxWidth: 'fit-content',
                }}>
                <div className={styles.minimizedButtons}>
                  <Button
                    type="text"
                    icon={
                      <img className={styles.customIcon} src={CollapseOut} />
                    }
                    onClick={togglePopupSize}
                    className={styles.toggleButton}
                  />
                  <Button
                    type="text"
                    icon={
                      <img
                        style={{ width: '24px', height: '24px' }}
                        src={CloseIcon}
                      />
                    }
                    onClick={onClose}
                    className={styles.closeButton}
                  />
                </div>
              </div>
            </div>
          )
        }
        bordered={false}>
        {isMaximized && (
          <>
            {getIsSingleMicroArticleLoading || loading ? (
              <div className="skeletonContainer">
                <div className="carouselSkeleton">
                  <Skeleton.Image
                    active
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <div className="descriptionSkeleton">
                  <Skeleton paragraph={{ rows: 2 }} active />
                </div>
                <div className="buttonsSkeleton">
                  <Skeleton.Button
                    className="skeletonButton"
                    active
                    shape="square"
                  />
                  <Skeleton.Button
                    className="skeletonButton"
                    active
                    shape="square"
                  />
                  <Skeleton.Button
                    className="skeletonButton"
                    active
                    shape="square"
                  />
                  <Skeleton.Button
                    className="skeletonButton"
                    active
                    shape="square"
                  />
                </div>
              </div>
            ) : error ? (
              <Alert message={error} type="error" showIcon />
            ) : getMicroArticleData ? (
              <div className={styles.content}>
                {/* Carousel Section */}
                {getMicroArticleData?.media?.length > 0 && (
                  <div
                    className={styles.carouselContainer}
                    ref={carouselContainerRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleTouchStart}>
                    <Carousel
                      ref={carouselRef}
                      draggable={true}
                      arrows={false}
                      infinite={false}
                      autoplay={false}
                      dots={false}
                      centerPadding={"50px"}
                      afterChange={handleAfterChange} // Sync Carousel -> State
                      className={styles.carousel}>
                      {getMicroArticleData?.media.map((mediaItem, index) => (
                        <div
                          key={mediaItem.mediaId || index}
                          className={styles.carouselItem}
                          onClick={(e) => {
                            // If user was dragging, ignore click
                            if (ignoreClickRef.current) {
                              e.preventDefault()
                              e.stopPropagation()
                              return
                            }
                            handleOpenGallery()
                          }}>
                          <MediaRenderer mediaItem={mediaItem} />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                )}

                {/* Dot Pagination Section */}
                {getMicroArticleData?.media?.length > 0 && (
                  <Dots
                    total={getMicroArticleData?.media.length}
                    active={currentSlide}
                    onScrollTo={handleDotClickOrSwipe}
                    maxDots={6}
                    passiveDotWidth={8}
                    activeDotWidth={10}
                    passiveDotHeight={8}
                    activeDotHeight={10}
                    passiveColor="#dbdbdb"
                    activeColor="#2C476C"
                    marginHorizontal={3}
                    smallerDotScale={0.7}
                  />
                )}

                {/* Description Section */}
                {getMicroArticleData?.description && (
                  <div
                    className={`${styles.description} ${styles.customScroll}`}>
                    <Typography.Paragraph
                      className={styles.paragraph}
                      style={{ fontSize: `${getPopupDetailFontSize}px` }}>
                      {getMicroArticleData?.description}
                    </Typography.Paragraph>
                  </div>
                )}

                {/* Action Buttons */}
                <div className={styles.actionButtons}>
                  <Space>
                    <IconButton
                      disabled={
                        !getMicroArticleData?.description?.length ||
                        getPopupDetailFontSize === 20
                      }
                      icon={<Typography.Text strong>A +</Typography.Text>}
                      onClick={increaseFontSize}
                    />
                    <IconButton
                      disabled={
                        !getMicroArticleData?.description?.length ||
                        getPopupDetailFontSize === 14
                      }
                      icon={<Typography.Text strong>A -</Typography.Text>}
                      onClick={decreaseFontSize}
                    />

                    {isOwner && (
                      <IconButton
                        onClick={() => setPresentationsIsOpen(true)}
                        icon={
                          <img
                            style={{ width: '16px', height: '16px' }}
                            src={PresentationIcon}
                          />
                        }
                      />
                    )}
                    {isOwner &&
                      <IconButton
                        icon={
                          <img
                            style={{ width: '16px', height: '16px' }}
                            src={EditIcon}
                          />
                        }
                        onClick={changeToEditMode}
                        disabled={loading}
                      />
                    }
                  </Space>
                </div>
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </>
        )}
      </Card>
    </Portal>
  )
}

export default CardDesktopVersion
