import * as React from 'react'

const FilterIcon = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M26.7442 2C28.5349 2 30 3.50023 30 5.33385V9.00108C30 10.3346 29.186 12.0015 28.3721 12.835L21.3721 19.1693C20.3953 20.0028 19.7442 21.6697 19.7442 23.0032V30.171C19.7442 31.1711 19.093 32.5047 18.2791 33.0048L16 34.505C13.8837 35.8385 10.9535 34.3383 10.9535 31.6712V22.8365C10.9535 21.6697 10.3023 20.1695 9.65116 19.336L3.46512 12.6683C2.65116 11.8348 2 10.3346 2 9.33446V5.50054C2 3.50023 3.46512 2 5.25581 2H26.7442Z"
      stroke="#2C476C"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2581 2L6.23252 15.1687"
      stroke="#2C476C"
      strokeWidth={2.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default FilterIcon
